import React, {useState} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux'
import Modal, {RoundedModal} from '../../components/Modal';
import UIState from "../../store/uiState";
import _ from "lodash";
import {
    CurrentNetwork, IsPLASMLive,
    ModalTypes,
} from "../../constants";
import plusIcon from "../../assets/icons/thick-plus@2x.png";
import supplyStoreLogo from "../../assets/stake/supply-store/logo@2x.png";
import {Tab, TabList, Tabs} from "react-tabs";
import {
    getContractAddressByKey,
    selectContract, selectContractByKey, selectCraftableItem,
    selectCraftableItems,
    selectSupplyStoreProduct,
    selectSupplyStoreProducts
} from "../../store/models/config";
import Wallet, {actions} from "../../store/models/wallet";
import {ProductCard} from "../../components/staking/ProductCard";
import {CraftableItemCard} from "../../components/staking/CraftableItemCard";
import {RoundedButton} from "../../components/Button";
import {CraftableItemInputCard} from "../../components/staking/CraftableItemInputCard";
import {formatWei, preloadImage} from "../../utils";
import backCaretIcon from '../../assets/icons/back-caret@2x.png';
import Nft, {selectClaimablePassawayTransphormOrders, selectNftBalance, selectNftsByContract} from "../../store/models/nft";
import {store} from "../../store";
import classNames from "classnames";
import PassawayWithMeta from "../../components/staking/PassawayWithMeta";
import {createSelector} from "reselect";
import modalCloseIcon from "../../assets/icons/modal-close@2x.png";
import poweredBy7734Logo from "../../assets/logo/powered-by-7734@2x.png";
import {getNFTImage} from "../../utils/nfts";
import Nfts from "../../store/uiState";
import {selectCurrentUserId} from "../../store/models/user";
import {TransphormPassawayOrderCard} from "./PassawaySelector";
import {RequiredApprovals} from "../../components/staking/RequiredApprovals";
import {ethers} from "ethers";
import {pushTo} from "../../utils/history";

const TEST_MODE = false;

function createImageAsync(src, onLoad) {
    var image = new Image();
    image.onload = (e) => {
        onLoad(e.target);
    };
    image.onerror = function (e) {
        console.log('Failed to load image: ' + src);
    }
    image.src = src;
}

class Fire extends React.Component {
    constructor() {
        super();

        this.fireSpriteSheetImage = null;
        this.stage = null;
    }

    componentDidMount() {
        createImageAsync('/animation/fire-spritesheet-8bit.png', (image) => {
            this.fireSpriteSheetImage = image;
            const createjs = window.createjs;

            this.stage = new createjs.Stage(document.getElementById("flame-canvas"));
            createjs.Ticker.timingMode = createjs.Ticker.RAF_SYNCHED;
            createjs.Ticker.framerate = 45;
            createjs.Ticker.addEventListener("tick", this.stage);
            createjs.Ticker.paused = false;

            var data = {
                images: [
                    this.fireSpriteSheetImage
                ],
                frames: {
                    width: 480,
                    height: 480,
                    regX: 240,
                    regY: 240
                }
            };
            let spriteSheet = new createjs.SpriteSheet(data);
            let sprite = new createjs.Sprite(spriteSheet);
            var flame = sprite.clone();
            flame.addEventListener('animationend', () => {
                flame.stop();
                flame.alpha = 0;
            });
            flame.x = 240;
            flame.y = 240;
            // flame.alpha = 0.8;
            flame.regX = 0;
            flame.regY = 30;
            flame.name = 'fire';
            flame.scale = 1.2;

            flame.gotoAndStop(0);
            this.stage.addChild(flame);
            flame.play();

            this.stage.update();
        });
    }

    componentWillUnmount() {
        if (this.stage) {
            this.stage.paused = true;
            this.stage.removeAllEventListeners();
            this.stage.removeAllChildren();
            this.stage.clear();
            this.stage.canvas = null;
            this.stage = null;
        }
    }

    render() {
        const {className} = this.props;

        return (
            <canvas id={'flame-canvas'}
                    height={480}
                    width={480}
                    className={className}
            >
            </canvas>
        )
    }
}

const TransphormablePassawayInputCard = ({nft, onDetails, onSelect, selectedPassaway, disabled}) => {
    const {address, name, description, image, balance} = nft;

    return (
        <div className={classNames('CraftableItemInputCard', {
            'CraftableItemInputCard--missing': (balance === 0)
        })}
             onClick={onDetails}
        >
            {
                selectedPassaway &&
                <PassawayWithMeta nft={selectedPassaway}/>
            }
            {
                _.isNil(selectedPassaway) &&
                <img src={image}
                     className={'CraftableItemInputCard__image'}
                />
            }
            <div className={'CraftableItemInputCard__name'}>{name}</div>
            {
                description &&
                <div className={'CraftableItemInputCard__description'}>{description}</div>
            }
            <div className={'CraftableItemInputCard__footer'}>
                <RoundedButton title={selectedPassaway ? 'Remove' : 'Add'}
                               onClick={(e) => {
                                   e.preventDefault();

                                   onSelect();
                               }}
                               color={selectedPassaway ? 'grey-outline' : 'green'}
                />
            </div>
        </div>
    );
}

export class TransphormModal extends React.Component {
    constructor() {
        super();

        this.state = {
            isLoading: true,
            selectedPassaway: null,
            isTransphormed: false,
            showClaimableOrders: false,
            latestOrder: null,
        }
    }

    async componentDidMount() {
        const {address} = this.props;
        await this.props.dispatch(UIState.actions.showLoader('Loading...'));
        const orders = await this.props.dispatch(Nft.actions.fetchUnclaimedPassawayTransphormOrders(address));
        await this.props.dispatch(UIState.actions.hideLoader());

        this.setState({
            isLoading: false,
            showClaimableOrders: orders.length > 0
        });

        // To test state of transphorm
        if(TEST_MODE){
            setTimeout(async () => {
                const selectedPassaway = {
                    metadata: {
                        attributes: {
                            tier: 'base'
                        },
                        edition: 2,
                        image: "https://user-assets-thetadrop.imgix.net/user_c14333f0y1c7hnd7jzjf1wz4hsi/drop_md3pkczu54s0vih196gdwfparzm/images/2.png"
                    },

                }

                await preloadImage(selectedPassaway.metadata.image);

                this.setState({
                    isLoading: false,
                    showClaimableOrders: false,
                    selectedPassaway: selectedPassaway,
                });
            }, 2000);

            setTimeout(async () => {
                const order = {
                    transphormedPassaway: {
                        metadata: {
                            image: "https://assets.passaways.com/nfts/transphormed-passaways/2-jizq4ga6o5.png"
                        }
                    }
                };

                await preloadImage(order.transphormedPassaway.metadata.image);

                this.setState({
                    isLoading: false,
                    showClaimableOrders: false,
                    isTransphormed: true,
                    latestOrder: order
                });
            }, 5000);
        }
    }

    onClose = () => {
        this.props.dispatch(UIState.actions.hideModal());
    }

    onTransphorm = async () => {
        const {selectedPassaway} = this.state;
        const {success, order} = await this.props.dispatch(Wallet.actions.transphormPassaway(selectedPassaway.token_id));
        if(success){
            this.setState({
                showClaimableOrders: false,
                isTransphormed: success,
                latestOrder: order,
            })
        }
    }

    onBuy = (nft) => {
        const {ESSUTransphormKitContractInfo} = this.props;
        const craftableItem = selectCraftableItem(store.getState(), ESSUTransphormKitContractInfo.address);
        this.props.dispatch(UIState.actions.showModal(ModalTypes.Crafting, {
            defaultSelectedCraftableItemId: craftableItem?.id,
        }))
    }

    onSelectPassaway = async () => {
        const {selectedPassaway} = this.state;
        const {dispatch, passawayTokenIds} = this.props;
        const transphormableByTokenId = await dispatch(Wallet.actions.getTransphormables(passawayTokenIds));
        console.log('transphormableByTokenId == ', transphormableByTokenId);

        if(selectedPassaway){
            this.setState({
                selectedPassaway: null,
            })
        }
        else{
            dispatch(UIState.actions.showModal(ModalTypes.PassawaySelector, {
                title: "Select Passaway",
                subtitle: 'Only Base OG Passaways can be transphormed, currently.',
                cardButtonTitle: 'Select',
                isCardButtonEnabled: (nft) => {
                    return _.isNil(nft.stake)
                },
                cardButtonDisabledTitle: 'Unstake Required',
                predicate: ({token_id, stake}) => {
                    // Must be transphormable and not staked
                    return transphormableByTokenId[token_id];
                },
                onSelect: async (nft) => {
                    const transphormPrice = await dispatch(Wallet.actions.getTransphormPrice(nft));
                    this.setState({
                        selectedPassaway: nft,
                        transphormPrice: transphormPrice.toString()
                    });
                    dispatch(UIState.actions.hideModal());
                }
            }))
        }
    }

    onSelectOrder = async (order) => {
        // if not claimable, show the modal to expedite
        if(order.claimableAt > Date.now()){
            this.props.dispatch(UIState.actions.showModal(ModalTypes.ExpeditePassawayTransphormOrder, {
                order,
                onBuy: () => {
                    this.props.dispatch(UIState.actions.hideModal());
                    this.props.dispatch(Wallet.actions.expediteTransphormPassawayOrder(order.transphormedPassawayTokenId));
                }
            }));
        }
        else{
            const {orders} = await this.props.dispatch(Wallet.actions.claimTransphormPassawayOrder(order.transphormedPassawayTokenId));

            if(orders.length === 0){
                this.setState({
                    showClaimableOrders: false,
                })
            }

            // Show the transphorm page
            this.props.dispatch(UIState.actions.hideModal());
            pushTo(`/transphorm/${order.transphormedPassaway?.metadata?.edition}`);
        }
    }

    render() {
        let {ESSUTransphormKitContractInfo, PassawaysContractInfo, claimableOrders} = this.props;
        const {isLoading, showClaimableOrders, selectedPassaway, isTransphormed, transphormPrice, latestOrder} = this.state;
        const canTransphorm = (ESSUTransphormKitContractInfo.balance > 0 && selectedPassaway);

        // For testing...
        if(TEST_MODE){
            claimableOrders = [
                {
                    transphormedPassawayTokenId: 100001,
                    claimableAt: (new Date()).getTime() + (1000 * 86400 * 14),
                    transphormedPassaway: {
                        token_id: 100001,
                        metadata: {
                            name: 'John Doe',
                            image: 'https://assets.passaways.com/nfts/transphormed-passaways/2-jizq4ga6o5.png',
                            edition: 2,
                            attributes: {
                                tier: 'Base'
                            },
                            tierNum: 1,
                        }
                    }
                }
            ];
        }

        return (
            <RoundedModal title={'Transphorm'}
                          onClose={this.onClose}
                          className={classNames('TransphormModal', {
                              'TransphormModal--transphormed': isTransphormed
                          })}
                          header={(
                              <div className={'RoundedModalHeader'}>
                                  <div className={'RoundedModalHeader__title'}>
                                      Transphorm
                                  </div>
                                  <img src={poweredBy7734Logo}
                                       className={'RoundedModalHeader__logo'}
                                       onClick={() => {
                                           window.open('https://www.7734initiative.com', '_blank');
                                       }}
                                  />
                                  <a className={'RoundedModalHeader__close-button'}
                                     onClick={this.onClose}
                                  >
                                      <img src={modalCloseIcon}/>
                                  </a>
                              </div>
                          )}
            >
                {
                    showClaimableOrders &&
                    <div className={'TransphormOrdersContent'}>
                        <div className={'TransphormOrdersContent__description'}>
                            A full Passaway Transphormation will take 14 days but can be sped up using PLASM.
                        </div>
                        <div className={'TransphormOrdersContent__orders'}>
                            {
                                claimableOrders.map((order, index) => {
                                    return (
                                        <TransphormPassawayOrderCard
                                            order={order}
                                            onSelect={this.onSelectOrder}
                                        />
                                    )
                                })
                            }
                        </div>
                        <div>
                            <RoundedButton title={claimableOrders.length > 0 ? `Transphorm Another Passaway` : `Transphorm Passaway`}
                                           color={'green'}
                                           size={'medium'}
                                           className={'TransphormOrdersContent__transphorm-button'}
                                           onClick={() => {
                                                  this.setState({
                                                      isLoading: false,
                                                      showClaimableOrders: false,
                                                  });
                                           }}
                            />
                        </div>
                    </div>
                }
                {
                    !showClaimableOrders && isTransphormed &&
                    <div className={'TransphormedContent'}>
                        <div className={classNames('TransphormedContent__result')}>
                            <img src={latestOrder?.transphormedPassaway?.metadata?.image}
                                 className={'TransphormedContent__result__after animate__animated animate__slower animate__fadeIn'}
                            />
                            <img src={selectedPassaway.metadata.image}
                                 className={'TransphormedContent__result__before animate__animated animate__slower animate__fadeOut'}
                            />
                            <Fire className={''}/>
                        </div>
                        <div className={'TransphormedContent__title'}>
                            <span className={'TransphormedContent__title__before  animate__animated animate__slower animate__fadeOut'}>
                                Goodbye, Friend.
                            </span>
                            <span className={'TransphormedContent__title__after animate__animated animate__slower animate__fadeIn'}>
                                Oh no...
                            </span>
                        </div>
                        <div className={'TransphormedContent__description'}>
                            Transphorm in progress...
                        </div>
                        <div>
                            <RoundedButton title={`Continue`}
                                           color={'green'}
                                           size={'medium'}
                                           className={'TransphormedContent__done-button'}
                                           onClick={() => {
                                                  this.setState({
                                                      isLoading: false,
                                                      showClaimableOrders: true,
                                                      isTransphormed: false,
                                                      selectedPassaway: null,
                                                      latestOrder: null
                                                  });
                                           }}
                            />
                        </div>
                    </div>
                }
                {
                    !showClaimableOrders && !isTransphormed &&
                    <div className={'TransphormContent'}>
                        <div className={'TransphormContent__description'}>
                            Transphorm your Passaway by combining your ESSU Transphorm Kit with a compatible Passaway.
                        </div>
                        <div className={'TransphormContent__inputs'}>
                            <CraftableItemInputCard nft={ESSUTransphormKitContractInfo}
                                                    onBuy={this.onBuy}
                                                    buttonTitle={'Craft'}
                            />
                            <img src={plusIcon}
                                 className={'TransphormContent__plus-icon'}
                            />
                            <TransphormablePassawayInputCard nft={PassawaysContractInfo}
                                                             selectedPassaway={selectedPassaway}
                                                             onSelect={this.onSelectPassaway}
                                                             disabled={false}
                            />
                        </div>
                        <div>
                            <RoundedButton title={`Transphorm Passaway`}
                                           color={'green'}
                                           size={'medium'}
                                           className={'TransphormContent__transphorm-button'}
                                           disabled={!canTransphorm}
                                           onClick={this.onTransphorm}
                            />
                            <div className={'TransphormContent__price-container'}
                                 style={{
                                     opacity: _.isNil(transphormPrice) ? 0 : 1
                                 }}
                            >
                                <div className={'TransphormContent__price-prefix'}>
                                    TRANSPHORM COST
                                </div>
                                <div className={'TransphormContent__price'}>{formatWei(transphormPrice)}</div>
                            </div>
                        </div>
                        <RequiredApprovals spenderAddress={getContractAddressByKey('passawayTransphormer')}
                                           tokenAddress={getContractAddressByKey('plasm')}
                                           tokenAmount={transphormPrice || ethers.utils.parseEther('5000')}
                                           nftAddresses={[getContractAddressByKey('passaways'), getContractAddressByKey('essuTransphormKit')]}
                        />

                    </div>
                }

            </RoundedModal>
        );
    }
}

const mapStateToProps = (state, props) => {
    const address = selectCurrentUserId(state);
    let PassawaysContractInfo = selectContractByKey(state, 'passaways');
    let passaways = selectNftsByContract(state, PassawaysContractInfo.address);
    let ESSUTransphormKitContractInfo = selectContractByKey(state, 'essuTransphormKit');
    ESSUTransphormKitContractInfo = Object.assign({}, ESSUTransphormKitContractInfo, {
        balance: selectNftBalance(state, ESSUTransphormKitContractInfo.address),
        image: getNFTImage(ESSUTransphormKitContractInfo.key),
    });
    PassawaysContractInfo = Object.assign({}, PassawaysContractInfo, {
        balance: selectNftBalance(state, PassawaysContractInfo.address),
        image: getNFTImage(PassawaysContractInfo.key),
        name: 'Passaway',
        description: '7734 Initiative Research Required'
    });

    if(TEST_MODE){
        ESSUTransphormKitContractInfo.balance = 1;
    }

    return {
        ...props,
        address: address,
        ESSUTransphormKitContractInfo: ESSUTransphormKitContractInfo,
        PassawaysContractInfo: PassawaysContractInfo,
        passawayTokenIds: _.map(passaways, 'token_id'),
        claimableOrders: selectClaimablePassawayTransphormOrders(state),
    }
};

export default connect(mapStateToProps)(TransphormModal);

import React, {useState} from 'react';
import {connect, useSelector} from 'react-redux'
import Modal, {RoundedModal} from '../../components/Modal';
import logo from '../../assets/logo/misadventure-club-white.svg';
import modalCloseIcon from '../../assets/icons/modal-close@2x.png';
import UIState from "../../store/uiState";
import {selectCurrentUser} from "../../store/models/user";
import {createSelector} from "reselect";
import {selectNftsByContract} from "../../store/models/nft";
import _ from "lodash";
import PassawayWithMeta from "../../components/staking/PassawayWithMeta";
import plasmLogo from "../../assets/tokens/plasm-front-small@2x.png";
import Button, {RoundedButton} from "../../components/Button";
import classNames from "classnames";
import Wallet, {selectBalances} from "../../store/models/wallet";
import {formatBalance} from "../../utils";
import * as thetajs from '@thetalabs/theta-js';
import {PercentageInput, PlasmActionContainer, PlasmActionInputField} from "./Plasm";

export class UnstakePlasmModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            amountToUnstake: ""
        };
    }

    componentDidMount() {
        this.props.dispatch(Wallet.actions.getBalances())
    }

    onClose = () => {
        this.props.dispatch(UIState.actions.hideModal());
    }

    onChange = (inputName, value) => {
        this.setState({
            [inputName]: value
        })
    }

    onUnstake = async () => {
        const percentage = parseInt(this.state.amountToUnstake);
        if(percentage > 100 || percentage < 0){
            alert('Please enter a valid percentage between 0 and 100');
            return;
        }
        const success = await this.props.dispatch(Wallet.actions.unstakePlasm(this.state.amountToUnstake));
        if(success){
            this.setState({
                amountToUnstake: ''
            });
            this.props.dispatch(UIState.actions.hideModal());
        }
    }

    render() {
        const {balances} = this.props;
        const {plasmStaked} = balances;

        return (
            <RoundedModal title={'Unstake PLASM'}
                          className={'UnstakePlasmModal'}
                          onClose={this.onClose}
            >
                <div className={'PlasmModal__content'}>
                    <PlasmActionContainer title={'Staked PLASM'}
                                          subtitle={'(+EST. REWARD)'}
                                          className={'PlasmActionContainer--center'}
                                          balance={formatBalance(plasmStaked)}
                    >
                        <PlasmActionInputField title={'Percentage to unstake'}>
                            <PercentageInput value={this.state.amountToUnstake}
                                             onChange={(e) => {
                                                 this.onChange('amountToUnstake', e.target.value);
                                             }}
                                             onAll={() => {
                                                 // Unstake 100%
                                                 this.setState({
                                                     amountToUnstake: 100
                                                 });
                                             }}
                            />
                            <RoundedButton color={'green'}
                                           size={'small'}
                                           title={'Unstake'}
                                           onClick={this.onUnstake}
                            />
                        </PlasmActionInputField>
                    </PlasmActionContainer>
                </div>
            </RoundedModal>
        );
    }
}

const mapStateToProps = (state, props) => {
    const balances = selectBalances(state);

    return {
        ...props,
        balances,
    }
};

export default connect(mapStateToProps)(UnstakePlasmModal);

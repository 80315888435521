import React, {useState} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux'
import Modal, {RoundedModal} from '../../components/Modal';
import UIState from "../../store/uiState";
import _ from "lodash";
import classNames from "classnames";
import {RoundedButton} from "../../components/Button";
import {formatBalance} from "../../utils";
import QtySelector from "../../components/staking/QtySelector";


export class ProductModal extends React.Component {
    constructor() {
        super();

        this.state = {
            qty: 1
        }
    }

    onClose = () => {
        this.props.dispatch(UIState.actions.hideModal());
    }

    render() {
        let {title, productImage, productComponent, productName, productDescription, productLineItems, product, footerComponent, onBuy} = this.props;
        let {qty} = this.state;

        if(!footerComponent){
            footerComponent = (
                <RoundedButton title={'Buy'}
                               color={'green'}
                               size={'medium'}
                               className={'ProductModal__buy-button'}
                               onClick={() => {
                                   onBuy(qty);
                               }}
                               disabled={product?.remaining === 0}
                />
            );
        }

        return (
            <RoundedModal title={title}
                          onClose={this.onClose}
                          className={'ProductModal'}
            >
                <div className={'ProductModal__content'}>
                    {
                        productComponent &&
                        <div className={'ProductModal__image'}>
                            {productComponent}
                        </div>
                    }
                    {
                        _.isNil(productComponent) &&
                        <img className={'ProductModal__image'}
                             src={productImage}
                        />
                    }
                    <div className={'ProductModal__details'}>
                        <div className={'ProductModal__name'}>
                            <span>{productName}</span>
                            {
                                product?.nft?.beta &&
                                <div className={'BetaBadge'}>{`BETA`}</div>
                            }
                        </div>
                        <div className={'ProductModal__description'}>{productDescription}</div>
                        <div className={'ProductModal__line-items'}>
                            {
                                _.map(productLineItems, ({title, content, hidden}) => {
                                    return (
                                        <div className={'ProductModalLineItem'}
                                             style={{display: hidden ? 'none' : 'flex'}}
                                        >
                                            <div className={'ProductModalLineItem__left'}>
                                                {title}
                                            </div>
                                            <div className={'ProductModalLineItem__right'}>
                                                {content}
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                        {
                            product && product.remaining > 0 &&
                            <>
                            <QtySelector qty={this.state.qty}
                                         max={Math.min(product.remaining, product.nft.maxQtyPerTx) }
                                         onChange={(qty) => {
                                             this.setState({qty});
                                         }}
                            />
                                <div className={'ProductModalLineItem'}
                                >
                                    <div className={'ProductModalLineItem__left'}>
                                        Total
                                    </div>
                                    <div className={'ProductModalLineItem__right'}>
                                        <div className={'PlasmPrice'}>
                                            { formatBalance(product.plasmPrice * qty)}
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        {
                            product && product.remaining === 0 &&
                            <RoundedButton title={'Sold Out'}
                                           color={'red'}
                                           size={'medium'}
                                           className={'ProductModal__sold-out-button'}
                            />
                        }

                        {
                            (_.isNil(product) || product?.remaining > 0) &&
                            <div className={'ProductModal__footer'}>
                                {
                                    footerComponent
                                }
                            </div>
                        }

                    </div>
                </div>
            </RoundedModal>
        );
    }
}

const mapStateToProps = (state, props) => {
    const {action} = props;

    return {
        ...props,
    }
};

export default connect(mapStateToProps)(ProductModal);

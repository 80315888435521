const classNames = require("classnames");
const {VIPTiers} = require("../../constants");
const React = require("react");

function VIPTab ({tier, onTierClick, selected}) {
    return (
        <div className={classNames('VIPTab', {
            'VIPTab--selected': selected
        })} onClick={() => {
            onTierClick(tier);
        }}>
            <img src={`/vip-icons/transparent/${tier.name}@2x.png`}
                 className={'VIPTab__icon'}
            />
            <div className={'VIPTab__name'}>
                {tier.name}
            </div>
        </div>
    );
}
export function VIPTabs ({tier, onTierClick}) {
    return (
        <div className={'VIPTabs'}>
            <VIPTab tier={VIPTiers.Bronze}
                    selected={tier.name === VIPTiers.Bronze.name}
                    onTierClick={onTierClick}
            />
            <VIPTab tier={VIPTiers.Silver}
                    selected={tier.name === VIPTiers.Silver.name}
                    onTierClick={onTierClick}
            />
            <VIPTab tier={VIPTiers.Gold}
                    selected={tier.name === VIPTiers.Gold.name}
                    onTierClick={onTierClick}
            />
            <VIPTab tier={VIPTiers.ESSU}
                    selected={tier.name === VIPTiers.ESSU.name}
                    onTierClick={onTierClick}
            />
        </div>
    );
}
import _ from 'lodash';

export function getHost() {
    let l = window?.location;
    return `${l.protocol}//${l.host}`;
}

export function getPathAndQuery() {
    let location = window?.location; // beaware of server-side rendering
    return `${location?.pathname}${location?.search}`;
}

export function getQueryParams(search) {
    if(!search || search.indexOf('=') === -1)
        return {};
    let hashes = search.slice(search.indexOf('?') + 1).split('&')
    return hashes.reduce((params, hash) => {
        let [key, val] = hash.split('=');
        return {...params, [key]: decodeURIComponent(val) };
    }, {});
}

export function getPathWithoutQuery(url) {
    let pathOnly = url
        ? url.split('?')[0]
        : window?.location?.pathname;
    return pathOnly;
}

export function paramsToString(obj) {
    let parts = [];
    for (let i in obj) {
        let value = obj[i];
        if(_.isNil(value))
            continue;
        value = _.castArray(value);
        value.forEach(v => {
            if (obj.hasOwnProperty(i) && !_.isNil(obj[i])) {
                parts.push(encodeURIComponent(i) + "=" + encodeURIComponent(v));
            }
        })
    }
    return parts.join("&");
}

export function mergeUrl(url, params, urlMap) {
    if(!url) {
        console.error("URL is not defined for action: ", params);
    }
    if(!params)
        return url;
    params = {... params };
    if(urlMap) {
        _.keys(urlMap).forEach(key => {
            params[urlMap[key]] = params[key];
            delete params[key];
        });
    }
    if(url.indexOf(":") !== -1) {
        _.forEach(url.match(/:([0-9_a-z]+)/gi), match => {
            let key = match.replace(":", "");
            if(_.isNil(params[key]))
                return;
            url = url.replace(match, params[key]);
            delete params[key];
        });
    }

    if(_.keys(params).length > 0) {
        if(url.indexOf("?") !== -1) {
            url = url + "&" + paramsToString(params);
        } else {
            url = url + "?" + paramsToString(params);
        }
    }
    return url;
}

import _ from 'lodash';
import React, {useState, useEffect, useContext} from 'react';
import badgeRewards from '../../assets/events/floor-raiser/badges@2x.png';
import passawayReward from '../../assets/events/floor-raiser/passaway@2x.png';
import shirtReward from '../../assets/events/floor-raiser/prizes/shirt.png';
import goldBadgeReward from '../../assets/events/floor-raiser/prizes/nft_floorraiser_gold.png';
import silverBadgeReward from '../../assets/events/floor-raiser/prizes/nft_floorraiser_silver.png';
import bronzeBadgeReward from '../../assets/events/floor-raiser/prizes/nft_floorraiser_bronze.png';
import greenBadgeReward from '../../assets/events/floor-raiser/prizes/nft_floorraiser_green.png';
import Button from "../../components/Button";
import NavBar from "../../components/NavBar";
import {Urls} from "../../constants";
import {Tab, TabList, Tabs} from "react-tabs";
import {useDispatch, useSelector} from "react-redux";
import {selectEventLeaderboard} from "../../store/models/event";
import Event from "../../store/models/event";

function DetailsTab(){
    return (
        <React.Fragment>
            <img src={passawayReward}
                 className={'FloorRaiserEventPage__passaway'}
            />
            <img src={badgeRewards}
                 className={'FloorRaiserEventPage__badges'}
            />
            <div className={'FloorRaiserEventPage__welcome'}>Earn & hold a Floor Raiser Badge for a chance to win an ultra-rare Passaway.</div>
            <div className={'FloorRaiserEventPage__welcome-subtitle'}>2/1/22 3 PM PST - 2/8/22 6 PM PST</div>

            <div className={'FloorRaiserEventPage__section'}>
                <div className={'FloorRaiserEventPage__section-title'}>FLOOR RAISER PASSAWAY AIRDROP</div>
                <div>1 random holder of a Floor Raiser Badge will be chosen on 2/16/22</div>
                <div>All Floor Raiser badges (all rarities) will be snapshotted on 02/16 @ 9 AM PST.</div>
                <div>Winner will be airdropped the Floor Raiser Passaway</div>
                <div>1 Floor Raiser Badge = 1 entry (no limit per account)</div>
                <div>Floor Raiser Badge must not be listed for sale during snapshot</div>
            </div>

            <div className={'FloorRaiserEventPage__section'}>
                <div className={'FloorRaiserEventPage__section-title'}>FLOOR RAISER PODIUM PRIZES</div>
                <div>Gold Badge - 1st place</div>
                <div>Silver Badge - 2nd place</div>
                <div>Bronze Badge - 3rd place</div>
                <div>T-Shirt - Top 20 places</div>
                <div>Green Badge - Consolation</div>
                <div>(1st-3rd place based on number of points earned)</div>
            </div>

            <div className={'FloorRaiserEventPage__section'}>
                <div className={'FloorRaiserEventPage__section-title'}>HOW TO EARN POINTS</div>
                <div>Purchase & HODL:</div>
                <div>Passaway</div>
                <div>Passaway Containment Pack</div>
                <div>999ER Badge</div>
                <div>(Each purchase will earn 1 point)</div>
            </div>

            <div className={'FloorRaiserEventPage__section'}>
                <div className={'FloorRaiserEventPage__section-title'}>POINT MULTIPLIERS</div>
                <div>HODLing a 999ER Badge (max one per account) will 1.5x your points</div>
                <Button title='Open Marketplace'
                        size={'large'}
                        target={'_blank'}
                        href={Urls.THETA_DROP_MARKETPLACE}
                        style={{width: 250, marginTop: 30}}
                />
            </div>

            <div className={'FloorRaiserEventPage__section'}>
                <div className={'FloorRaiserEventPage__section-title'}>PODIUM ELIGIBILITY</div>
                <div>No wash trading</div>
                <div>No cheating</div>
                <div>Tie-breakers will be broken by total Passaway related NFTs in collection</div>
            </div>

            <div className={'FloorRaiserEventPage__section'}>
                <div className={'FloorRaiserEventPage__section-title'}>FREE WAYS TO GET A FLOOR RAISER BADGE</div>
                <div>Spread the word on social to earn entries</div>
                <div>10 lucky entrants will be airdropped a Green FLOOR RAISER Badge</div>
                <Button title='Earn Free Entries'
                        size={'large'}
                        href={'https://giv.gg/floor-raiser'}
                        target={'_blank'}
                        style={{width: 250, marginTop: 30}}
                />
            </div>

            <div className={'FloorRaiserEventPage__section'}>
                <div className={'FloorRaiserEventPage__section-title'}>FLOOR RAISER PASSAWAY ELIGIBILITY</div>
                <div>Any NFTs purchased must NOT be sold before snapshot</div>
                <div>Any NFTs purchased must NOT be listed for sale during snapshot</div>
                <div>Floor Raiser Badge must NOT be listed for sale during snapshot</div>
            </div>
        </React.Fragment>
    )
}

function LeaderboardTab({leaders}){
    const getPrize = (idx) => {
        let prize = null;

        if(idx === 0){
            prize = {
                image: goldBadgeReward,
                name: 'Gold Floor Raiser Badge'
            }
        }
        else if(idx === 1){
            prize = {
                image: silverBadgeReward,
                name: 'Silver Floor Raiser Badge'
            }
        }
        else if(idx === 2){
            prize = {
                image: bronzeBadgeReward,
                name: 'Bronze Floor Raiser Badge'
            }
        }
        else{
            prize = {
                image: greenBadgeReward,
                name: 'Green Floor Raiser Badge'
            }
        }

        return prize;
    }

    return (
        <div className={'LeaderboardTabContent'}>
            <div className={'ParticipantsCounter'}>
                <span className={'ParticipantsCounter__count'}>{leaders.length}</span>
                <span>Participants</span>
            </div>
            <div className={'PrizeCard'}>
                <img src={shirtReward}/>
                <div>The 20 highest point holders will receive a redeemable Limited Edition Floor Raiser T-Shirt.</div>
            </div>
            <table className={'LeaderboardTable'}>
                <thead>
                <tr>
                    <th style={{paddingLeft: 36}}>Prize</th>
                    <th>Participant</th>
                    <th style={{textAlign: 'right', paddingRight: 6}}>Points</th>
                </tr>
                </thead>
                <tbody>
                {
                    leaders &&
                        _.map(leaders, (leader, idx) => {
                            const prize = getPrize(idx);

                            return (
                                <tr>
                                    <td>
                                        <div className={'PlacePrize'}>
                                            <div className={'Place'}>{(idx < 9 ? '0' : '') + (idx + 1)}</div>
                                            <div className={'Prize'}>
                                                <img className={'PrizeImage'}
                                                     src={prize.image}/>
                                                <div className={'PrizeInfo'}>
                                                    <div className={'MainPrizeName'}>{prize.name}</div>
                                                    {
                                                        idx < 20 &&
                                                        <div className={'SubPrizeName'}>+ LE Floor Raiser Tee</div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className={'Participant'}>
                                            <img src={leader.avatar_url || 'https://assets.thetadrop.com/0.7.377/assets/images/default_avatar.png'}/>
                                            <span>{ leader.display_name || leader.username }</span>
                                        </div>
                                    </td>
                                    <td className={'Points'}>
                                        {
                                            leader.points
                                        }
                                    </td>
                                </tr>
                            )
                        })
                }
                </tbody>
            </table>

            <div style={{marginTop: 15}}>Leaderboard does NOT account for non-HODLed NFTs or tie-breakers.</div>
        </div>
    )
}

function FloorRaiserEventPage() {
    const eventSlug = 'floor-raiser';
    const dispatch = useDispatch();
    const [tabIndex, setTabIndex] = useState(0);
    const leaders = useSelector((state) =>
        selectEventLeaderboard(state, eventSlug)
    );

    useEffect(() => {
        dispatch(Event.actions.fetchEventLeaderboard(eventSlug));
    }, []);

    return (
        <div className={'PageWrapper'}>
            <div className="Page FloorRaiserEventPage">
                <NavBar/>
                <div style={{height: 30}}/>
                <div className={'FloorRaiserEventPage__title'}>FLOOR RAISER</div>
                <Tabs selectedIndex={tabIndex}
                      onSelect={index => setTabIndex(index)}
                      style={{marginTop: 0}}
                      className={'TextTabs'}>
                    <TabList className={'TextTabList'}>
                        <Tab>
                            <span>Details</span>
                        </Tab>
                        <Tab>
                            <span>Leaderboard</span>
                        </Tab>
                    </TabList>
                </Tabs>
                {
                    tabIndex === 0 &&
                    <DetailsTab/>
                }
                {
                    tabIndex === 1 &&
                    <LeaderboardTab leaders={leaders || []}/>
                }
            </div>
        </div>
    );
}

export default FloorRaiserEventPage;

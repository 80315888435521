import _ from 'lodash';
import React, {useState, useEffect, useContext} from 'react';
import badgeRewards from '../../assets/events/operation-manhunt/manhunt badges@2x.png';
import passawayReward from '../../assets/events/thetacon22/Thetacon_passaways.png';
import Button from "../../components/Button";
import {TPMCNavBar} from "../../components/NavBar";
import Event from "../../store/models/event";
import {useDispatch} from "react-redux";
const sha256 = require('js-sha256');

const sdhsduihsd = () => {
    return 't' + 'c' + '2' + 2 + '!' + '$' + '';
}

const ddskjhdsudshsdisdjd = (body) => {
    return sha256(''+body.latitude+''+body.longitude+'' + sdhsduihsd());
}

function ThetaCon22Page() {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [isEligible, setIsEligible] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(async () => {
        const result = await dispatch(Event.actions.fetchThetaCon22RaffleTicketEligibility());
        console.log(result);
        setIsEligible(result.eligible);
        if(!result.eligible){
            setErrorMessage(result.reason);
        }
    }, []);

    const onSubmit = () => {
        setIsLoading(true);

        const options = {
            enableHighAccuracy: false,
            timeout: 15000,
        };
        navigator.geolocation.getCurrentPosition(async (position) => {
            console.log(position);

            const result = await dispatch(Event.actions.claimThetaCon22RaffleTicket({
                longitude: position.coords.longitude,
                latitude: position.coords.latitude,
                accuracy: position.coords.accuracy,
                s: ddskjhdsudshsdisdjd({
                    longitude: position.coords.longitude,
                    latitude: position.coords.latitude
                })
            }));
            console.log('result == ');
            console.log(result);

            setErrorMessage(result.reason);

            setIsLoading(false);
        }, (error) => {
            console.log(error);
            setIsLoading(false);
            alert(error.message);
        }, options);
    }

    return (
        <div className={'PageWrapper'}>
            <div className="Page OperationManhuntEventPage">
                <TPMCNavBar/>
                <div style={{height: 30}}/>
                <div className={'OperationManhuntEventPage__title'} style={{color: '#f24d84'}}>THETACON22 RAFFLE</div>

                <div className={'OperationManhuntEventPage__welcome-subtitle'}>12/2/2022 - 12/4/2022</div>

                <img src={passawayReward}
                     className={'OperationManhuntEventPage__passaway'}
                />

                {
                    _.isNil(errorMessage) &&
                    <React.Fragment>
                        <div className={'OperationManhuntEventPage__welcome'}>To celebrate THETACON22, we are raffling off 3 unique Passaways.</div>
                        <br/>
                        <div className={'OperationManhuntEventPage__welcome'}>Are you at THETACON? Claim your raffle ticket by proving you are at the event**</div>
                        <br/>
                        <div className={'OperationManhuntEventPage__welcome'}>Certain Passaways may qualify for raffle ticket airdrops throughout the event.</div>
                        <br/>
                        <br/>
                    </React.Fragment>
                }

                {
                    _.isNil(errorMessage) &&
                    <div className={'OperationManhuntEventPage__section'}>
                        <div className={'OperationManhuntEventPage__section-title'}>TICKET CLAIM ELIGIBILITY</div>
                        <div>Must own at least one Passaway (Snapshot occurred on 12/2/2022)</div>
                        <div>Must be THETACON22 Attendee</div>
                        <div>Must be near the Le Méridien Dania Beach at Fort Lauderdale Airport hotel</div>
                    </div>
                }

                {
                    errorMessage &&
                    <div className={'OperationManhuntEventPage__section'} style={{maxWidth: 450}}>
                        <div className={'OperationManhuntEventPage__section-title'} style={{color: '#f24d84'}}>OOPS</div>
                        <div style={{color: "white"}}>{errorMessage}</div>
                    </div>
                }

                {
                    _.isNil(errorMessage) &&
                    <Button title='Claim Ticket'
                            size={'large'}
                            target={'_blank'}
                            color={'thetacon-pink'}
                            onClick={onSubmit}
                            isLoading={isLoading}
                            disabled={!isEligible || isLoading}
                            style={{width: 250, marginTop: 30, marginBottom: 30}}
                    />
                }


                {
                    _.isNil(errorMessage) &&
                    <div className={'OperationManhuntEventPage__section'}>
                        <div>**We will use your device's location to determine if you are at THETACON22. Your location will not stored.</div>
                    </div>
                }

            </div>
        </div>
    );
}

export default ThetaCon22Page;

import _ from 'lodash';
import React, {useState} from 'react';
import {NavHashLink} from 'react-router-hash-link';
import {push as Menu} from 'react-burger-menu'
import discordIcon from '../assets/landing/discord@2x.png';
import twitterIcon from '../assets/landing/twitter@2x.png';
import hamburgerIcon from '../assets/landing/navbar-hamburger.svg';
import hamburgerXIcon from '../assets/landing/navbar-hamburger-close.svg';
import tpmcSkullGreyLogo from '../assets/logo/tpmc-skull-green.svg';
import tpmcHeadLogo from '../assets/landing/tpmc-head-logo@2x.png';
import backCaretIcon from '../assets/icons/back-caret@2x.png';
import {
    TwitterUrl,
    DiscordUrl,
    SHOW_TPMC_LINK,
    Urls,
    ModalTypes,
    SHOW_TPMC_DISCORD_LINK,
    IsPLASMLive, NotionUrl
} from '../constants';
import useMediaQuery from "../hooks/useMediaQuery";
import {createSelector} from "reselect";
import {selectUserNfts} from "../store/models/nft";
import User, {selectCurrentUser, selectCurrentUserId} from "../store/models/user";
import {useDispatch, useSelector} from "react-redux";
import UIState from "../store/uiState";
import {Jazzicon} from "@ukstv/jazzicon-react";
import {truncate} from "../utils";
import PlasmBalances from "./staking/PlasmBalances";
import {pushTo} from "../utils/history";
import { usePopper } from 'react-popper';
import OutsideClickHandler from "react-outside-click-handler";
import {PillButton} from "./Button";
import {selectLottery, selectNFTDrops} from "../store/models/config";
import {useLocation, useRouteMatch} from "react-router-dom";
import {selectGuardianTier} from "../store/models/wallet";


const NavLink = NavHashLink;

const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -60;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
};


const getCurrentUser = createSelector(
    (state) => selectCurrentUser(state),
    (currentUser) => ({currentUser})
);

const pagePathsToDimStakeCTA = new Set([Urls.TPMC_STAKE_NFTS, Urls.TPMC_STAKE_PLASM, Urls.TPMC_NFT_DROP]);

function StakeButton({style, className}) {
    const route = useRouteMatch();
    const isStakeOrDropPage = pagePathsToDimStakeCTA.has(route.path);
    const [visible, setVisible] = useState(false);
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const [arrowElement, setArrowElement] = useState(null);
    const {styles, attributes} = usePopper(referenceElement, popperElement, {
        placement: "bottom-start",
        strategy: "fixed",
        modifiers: [
            {
                name: "offset",
                enabled: true,
                options: {
                    offset: [0, 10]
                }
            }
        ]
    });
    const hide = () => setVisible(false);

    function handleDropdownClick(e) {
        e.preventDefault();
        setVisible(!visible);
    }

    const onStakePlasmClick = () => {
        pushTo(Urls.TPMC_STAKE_PLASM);
    }

    const onStakeNFTsClick = () => {
        pushTo(Urls.TPMC_STAKE_NFTS);
    }

    const on7734GuardianClick = () => {
        pushTo(Urls.TPMC_7734_GUARDIAN);
    }

    if(!IsPLASMLive){
        return null;
    }

    return (
        <div className={'NavBarStakeButtonWrapper'}
             style={style}
        >
            <OutsideClickHandler onOutsideClick={hide}
                                 display={'flex'}
            >
                <div ref={setReferenceElement}>
                    <PillButton title={'Stake'}
                                color={isStakeOrDropPage ? 'grey' : 'green'}
                                onClick={handleDropdownClick}
                                className={className}
                                style={{width: 84, display: 'flex'}}
                    />
                </div>

            </OutsideClickHandler>

            <div ref={setPopperElement} style={{
                ...styles.popper,
                display: visible ? 'block' : 'none',
            }}
                 className={'NavBarStakeDropdown'}
                 {...attributes.popper}>
                <OutsideClickHandler onOutsideClick={hide}>
                    <a className={'NavBarStakeDropdown__row'}
                       onClick={onStakeNFTsClick}
                    >
                        Stake NFTs
                    </a>
                    <a className={'NavBarStakeDropdown__row'}
                       onClick={onStakePlasmClick}
                    >
                        Stake PLASM
                    </a>
                    <a className={'NavBarStakeDropdown__row'}
                       onClick={on7734GuardianClick}
                    >
                        7734 Guardian
                    </a>
                </OutsideClickHandler>
            </div>
        </div>
    )
}

export function UserAvatarButton({userId}) {
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(false);
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const [arrowElement, setArrowElement] = useState(null);
    const {styles, attributes} = usePopper(referenceElement, popperElement, {
        placement: "bottom-end",
        strategy: "fixed",
        modifiers: [
            {
                name: "offset",
                enabled: true,
                options: {
                    offset: [0, 18]
                }
            }
        ]
    });
    const hide = () => setVisible(false);
    const currentTier = useSelector(selectGuardianTier);

    function handleDropdownClick(e) {
        e.preventDefault();
        setVisible(!visible);
    }

    const onInventoryClick = () => {
        dispatch(UIState.actions.showModal(ModalTypes.Inventory, {

        }));
    }

    const onRedeemClick = () => {
        dispatch(UIState.actions.showModal(ModalTypes.Redeeming, {

        }));
    }

    return (
        <>
            <OutsideClickHandler onOutsideClick={hide}
                                 display={'flex'}
            >
                <a className={'NavBarUser__avatar'}
                   ref={setReferenceElement}
                   onClick={handleDropdownClick}
                >
                    <Jazzicon address={userId}/>
                </a>
            </OutsideClickHandler>

            <div ref={setPopperElement} style={{
                ...styles.popper,
                display: visible ? 'block' : 'none',
            }}
                 className={'NavBarUserDropdown'}
                 {...attributes.popper}>
                <OutsideClickHandler onOutsideClick={hide}>
                    <a className={'NavBarUserDropdown__row'}>
                        <div>{truncate(userId, 4)}</div>
                    </a>
                    {
                        currentTier &&
                        <a className={'NavBarUserDropdownVIPTier'}
                           href={Urls.TPMC_7734_GUARDIAN}
                        >
                            <div className={'NavBarUserDropdownVIPTier__title'}>
                                7734 Guardian Tier:
                            </div>
                            <img src={`/vip-icons/word/${currentTier}@2x.png`}
                                 className={'NavBarUserDropdownVIPTier__word'}
                            />
                        </a>
                    }
                    <a className={'NavBarUserDropdown__row'}
                       href={NotionUrl}
                       target={'_blank'}
                    >
                        TPMC Wiki
                    </a>
                    {
                        IsPLASMLive &&
                        <a className={'NavBarUserDropdown__row'}
                           onClick={onRedeemClick}
                        >
                            Redeemables
                        </a>
                    }


                    <a className={'NavBarUserDropdown__row'}
                       onClick={onInventoryClick}
                    >
                        Inventory
                    </a>

                    {
                        IsPLASMLive &&
                        <NavLink to={Urls.TPMC_APPROVALS} className={'NavBarUserDropdown__row'}>
                            Approvals
                        </NavLink>
                    }
                    {
                        IsPLASMLive &&
                        <NavLink to={Urls.TPMC_7734_GUARDIAN} className={'NavBarUserDropdown__row'}>
                            7734 Guardian
                        </NavLink>
                    }
                    <NavLink to={Urls.TPMC_DISCORD} className={'NavBarUserDropdown__row'}>
                        Discord
                    </NavLink>


                    <a className={'NavBarUserDropdown__row NavBarUserDropdown__row--danger'}
                       onClick={() => {
                           dispatch(User.actions.logout());
                       }}
                    >
                        Disconnect
                    </a>
                </OutsideClickHandler>
            </div>
        </>
    )
}

export function TPMCStakingNavBar({style, className}) {
    const dispatch = useDispatch();
    const userId = useSelector(selectCurrentUserId);
    const nftDrops = useSelector(selectNFTDrops);
    const lottery = useSelector(selectLottery);
    const isMobile = useMediaQuery(768);
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const [arrowElement, setArrowElement] = useState(null);
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        modifiers: [{ name: 'arrow', options: { element: arrowElement } }],
    });

    const onOpenClick = () => {
        setIsMenuOpen(true);
    };
    const onCloseClick = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    const onMenuStateChange = (state) => {
        setIsMenuOpen(state.isOpen);
    }
    const onSuppliesClick = () => {
        onCloseClick();

        dispatch(UIState.actions.showModal(ModalTypes.SupplyStore, {

        }));
    }
    const onInventoryClick = () => {
        onCloseClick();

        dispatch(UIState.actions.showModal(ModalTypes.Inventory, {

        }));
    }
    const onCraftClick = () => {
        dispatch(UIState.actions.showModal(ModalTypes.Crafting, {

        }));
    }
    const onTransphormClick = () => {
        dispatch(UIState.actions.showModal(ModalTypes.Transphorm, {

        }));
    }
    const onRedeemClick = () => {
        dispatch(UIState.actions.showModal(ModalTypes.Redeeming, {

        }));
    }

    const onShopClick = () => {
        onCloseClick();

        dispatch(UIState.actions.showModal(ModalTypes.Shop, {

        }));
    }

    const onStakePLASMClick = () => {
        onCloseClick();

        dispatch(UIState.actions.showModal(ModalTypes.Plasm, {

        }));
    }

    const links = (
        _.isNil(userId) ?
            <>
                <PillButton title={'Members'}
                            color={'red'}
                            href={Urls.TPMC_HOME}
                            style={{width: 94, marginLeft: 10}}
                />
            </>
            :
        <>
            <NavLink to={Urls.TPMC_HOME} className={'NavBarItem'}
                     activeClassName="NavBarItem--selected" onClick={onCloseClick}>
                Home
            </NavLink>
            {
                isMobile &&
                <NavLink to={Urls.HOME} className={'NavBarItem'}
                         activeClassName="NavBarItem--selected" onClick={onCloseClick}>
                    Marketing
                </NavLink>
            }
            {
                isMobile &&
                <NavLink to={Urls.TPMC_DISCORD} className={'NavBarItem'}
                         activeClassName="NavBarItem--selected" onClick={onCloseClick}>
                    Discord
                </NavLink>
            }
            {
                nftDrops.length > 0 &&
                <NavLink to={Urls.TPMC_NFT_DROP_LIVE} className={'NavBarItem'}
                         activeClassName="NavBarItem--selected" onClick={onCloseClick}>
                    Drops
                </NavLink>
            }
            {
                !_.isNil(lottery) &&
                <NavLink to={Urls.TPMC_LOTTERY} className={'NavBarItem'}
                         activeClassName="NavBarItem--selected" onClick={onCloseClick}>
                    Raffle
                </NavLink>
            }
            <a className={'NavBarItem'}
               onClick={onSuppliesClick}>
                Supplies
            </a>
            <a className={'NavBarItem'}
               onClick={onCraftClick}>
                Craft
            </a>
            <a className={'NavBarItem'}
               onClick={onTransphormClick}>
                Transphorm
            </a>
            <NavLink to={Urls.TPMC_STAKE_NFTS} className={'NavBarItem HideOnNonMobilePhones'}
                     activeClassName="NavBarItem--selected" onClick={onCloseClick}>
                Stake NFTs
            </NavLink>
            <NavLink to={Urls.TPMC_STAKE_PLASM} className={'NavBarItem HideOnNonMobilePhones'}
                     activeClassName="NavBarItem--selected" onClick={onCloseClick}>
                Stake PLASM
            </NavLink>
            <NavLink to={Urls.TPMC_7734_GUARDIAN} className={'NavBarItem HideOnNonMobilePhones'}
                     activeClassName="NavBarItem--selected" onClick={onCloseClick}>
                7734 Guardian
            </NavLink>
            <StakeButton className={'HideOnMobile'}
                         style={{marginLeft: 16}}
            />
        </>
    )


    return (
        <div className={`NavBar NavBar--staking`}
             style={style}
        >
            {
                isMobile &&
                <React.Fragment>

                    <a onClick={onOpenClick}
                       className={'HideOnNonMobile'}
                    >
                        <img src={hamburgerIcon}/>
                    </a>
                    <Menu isOpen={isMenuOpen}
                          disableAutoFocus
                          itemListElement='div'
                          customBurgerIcon={false}
                          customCrossIcon={false}
                          onStateChange={onMenuStateChange}>
                        <div className={'NavBarMenuHeader'}>
                            <a onClick={onCloseClick}
                               className={'NavBarMenuHeader__close'}>
                                <img src={hamburgerXIcon}/>
                            </a>
                            <div className={'NavBarMenuHeader__social-icons HideOnMobile'}>
                                <a className={'NavBarSocialIcon'}
                                   href={TwitterUrl}
                                   target={'_blank'}
                                >
                                    <img src={twitterIcon}/>
                                </a>
                                <a className={'NavBarSocialIcon'}
                                   href={DiscordUrl}
                                   target={'_blank'}>
                                    <img src={discordIcon}/>
                                </a>
                            </div>
                        </div>

                        {
                            links
                        }
                    </Menu>
                    {
                        _.isNil(userId) &&
                        <PillButton title={'Members'}
                                    color={'red'}
                                    href={Urls.TPMC_HOME}
                                    style={{width: 94, marginLeft: 10}}
                        />
                    }
                    {
                        !_.isNil(userId) &&
                        <StakeButton className={'HideOnNonMobile'}
                                     style={{marginLeft: 16}}
                        />
                    }
                    {
                        <PlasmBalances/>
                    }
                    <div className={'NavBarUser'}>
                        {
                            userId &&
                            <UserAvatarButton userId={userId}/>
                        }
                    </div>
                </React.Fragment>
            }

            {
                !isMobile &&
                <React.Fragment>
                    <div className={'NavBar__left'}>
                        <img src={tpmcHeadLogo}
                             className={'NavBarLogo'}
                             onClick={() => {
                                 pushTo(Urls.TPMC_HOME)
                             }}
                        />
                        {
                            links
                        }
                    </div>

                    <div className={'NavBar__right'}>
                        {
                            <PlasmBalances/>
                        }
                        <div className={'NavBarUser'}>
                            {
                                userId &&
                                <UserAvatarButton userId={userId}/>
                            }
                        </div>
                    </div>

                </React.Fragment>
            }
        </div>
    );
}

export function TPMCNavBar({style, className}) {
    return <TPMCStakingNavBar/>
}

export default function NavBar({style, className}) {
    const isMobile = useMediaQuery(768);
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);
    const onOpenClick = () => {
        setIsMenuOpen(true);
    };
    const onCloseClick = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    const onMenuStateChange = (state) => {
        setIsMenuOpen(state.isOpen);
    }

    const links = (
        <>
            <NavLink smooth scroll={scrollWithOffset} to="/#nfts" className={'NavBarItem NavBarItem--Home'}
                     activeClassName="NavBarItem--selected"
                     onClick={onCloseClick}
            >
                NFTs
            </NavLink>
            <NavLink smooth scroll={scrollWithOffset} to="/#plasm" className={'NavBarItem NavBarItem--Project'}
                     activeClassName="NavBarItem--selected"
                     onClick={onCloseClick}
            >
                PLASM
            </NavLink>
            <NavLink smooth scroll={scrollWithOffset} to="/#tpmc" className={'NavBarItem NavBarItem--Roadmap'}
                     activeClassName="NavBarItem--selected"
                     onClick={onCloseClick}
            >
                TPMC
            </NavLink>
            <NavLink smooth scroll={scrollWithOffset} to="/#roadmap" className={'NavBarItem NavBarItem--Story'}
                     activeClassName="NavBarItem--selected"
                     onClick={onCloseClick}
            >
                ROADMAP
            </NavLink>
            <a href={NotionUrl}
               target={'_blank'}
               className={'NavBarItem NavBarItem--Roadmap'}
               onClick={onCloseClick}
            >
                Wiki
            </a>
            <NavLink to={Urls.TPMC_STAKE_PLASM} className={'NavBarItem HideOnNonMobile'}
                     activeClassName="NavBarItem--selected"
                     onClick={onCloseClick}
            >
                STAKE PLASM
            </NavLink>
            <NavLink to={Urls.TPMC_STAKE_NFTS} className={'NavBarItem HideOnNonMobile'}
                     activeClassName="NavBarItem--selected"
                     onClick={onCloseClick}
            >
                STAKE NFTs
            </NavLink>
            <NavLink to={Urls.TPMC_7734_GUARDIAN} className={'NavBarItem HideOnNonMobilePhones'}
                     activeClassName="NavBarItem--selected"
                     onClick={onCloseClick}>
                7734 Guardian
            </NavLink>
            <StakeButton className={'HideOnMobile'}
                         style={{marginLeft: 16}}
            />
        </>
    )

    return (
        <div className={`NavBar`}
             style={style}
        >
            {
                isMobile &&
                <React.Fragment>
                    <a onClick={onOpenClick}
                       className={'HideOnNonMobile'}
                    >
                        <img src={hamburgerIcon}/>
                    </a>
                    <div className={'NavBar__right'}>
                        <StakeButton/>
                        <PillButton title={'Members'}
                                    color={'red'}
                                    href={Urls.TPMC_HOME}
                                    style={{width: 94, marginLeft: 10}}
                        />
                    </div>
                    <Menu isOpen={isMenuOpen}
                          disableAutoFocus
                          itemListElement='div'
                          customBurgerIcon={false}
                          customCrossIcon={false}
                          onStateChange={onMenuStateChange}>
                        <div className={'NavBarMenuHeader'}>
                            <a onClick={onCloseClick}
                               className={'NavBarMenuHeader__close'}>
                                <img src={hamburgerXIcon}/>
                            </a>
                            <div className={'NavBarMenuHeader__social-icons'}>
                                <a className={'NavBarSocialIcon'}
                                   href={TwitterUrl}
                                   target={'_blank'}
                                >
                                    <img src={twitterIcon}/>
                                </a>
                                <a className={'NavBarSocialIcon'}
                                   href={DiscordUrl}
                                   target={'_blank'}>
                                    <img src={discordIcon}/>
                                </a>
                            </div>
                        </div>
                        {
                            links
                        }
                    </Menu>
                </React.Fragment>
            }

            {
                !isMobile &&
                <React.Fragment>
                    <div className={'NavBar__left'}>
                        <img src={tpmcHeadLogo}
                             className={'NavBarLogo'}
                        />
                    </div>
                    {
                        links
                    }

                    <div className={'NavBar__right'}>
                        <a className={'NavBarSocialIcon'}
                           href={TwitterUrl}
                           target={'_blank'}
                        >
                            <img src={twitterIcon}/>
                        </a>
                        <a className={'NavBarSocialIcon'}
                           href={DiscordUrl}
                           target={'_blank'}>
                            <img src={discordIcon}/>
                        </a>
                        <PillButton title={'Members'}
                                    color={'red'}
                                    href={'/tpmc'}
                                    style={{width: 94, marginLeft: 24}}
                        />
                    </div>
                </React.Fragment>
            }
        </div>
    );
}

import React from 'react';
import {connect} from 'react-redux'
import Modal from '../../components/Modal';
import logo from '../../assets/logo/misadventure-club-white.svg';
import shopHeader from '../../assets/header/the-shop-header.png';

import UIState from "../../store/uiState";
import {selectCurrentUser} from "../../store/models/user";
import Button, {RoundedButton} from "../../components/Button";
import {Urls} from "../../constants";
import {isThetaWalletBrowser} from "../../utils";

export class ShopModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false
        };
    }

    onClose = () => {
        this.props.dispatch(UIState.actions.hideModal());
    }

    openShop = () => {
        const {userToken} = this.props;
        this.onClose();
        console.log('userToken == ', userToken);
        if(isThetaWalletBrowser()) {
            window.location.href = `${Urls.SHOP_ACCESS_URI}?token=${userToken}`;
        }
        else{
            window.open(`${Urls.SHOP_ACCESS_URI}?token=${userToken}`, '_blank');
        }
    }

    render() {
        const {user} = this.props;

        return (
            <Modal>
                <div className="ShopModal">
                    <div className={'ShopModal__header'}>
                        <img src={shopHeader}/>
                    </div>
                    <div className={'ShopModal__exclusive'}>
                        {`TPMC Members-only`}
                    </div>
                    <div className={'ShopModal__shop-title'}>
                        TPMC SHOP
                    </div>
                    <div className={'ShopModal__message'}>
                        Rep TPMC anywhere you go. This exclusive shop is for members-only. Conversation starters ready for some misadventure.
                    </div>
                    <RoundedButton title={'Open Shop'}
                                   color={'green'}
                            size={'xlarge'}
                            onClick={this.openShop}
                            style={{width: 250}}
                    />
                    <a className={'ShopModal__continue'}
                       onClick={this.onClose}
                    >Close</a>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: selectCurrentUser(state),
        userToken: state.app.userToken
    }
};

export default connect(mapStateToProps)(ShopModal);

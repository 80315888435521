import React, {Fragment, useState} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux'
import Modal, {RoundedModal} from '../../components/Modal';
import UIState from "../../store/uiState";
import _ from "lodash";
import {
    CurrentNetwork,
    ModalTypes,
} from "../../constants";
import {
    getContractAddressByKey,
    selectContract, selectContractAddressByKey, selectContractByKey,
    selectCraftableItems,
    selectSupplyStoreProduct,
    selectSupplyStoreProducts
} from "../../store/models/config";
import Wallet, {actions} from "../../store/models/wallet";
import {CraftableItemCard} from "../../components/staking/CraftableItemCard";
import {RoundedButton} from "../../components/Button";
import {CraftableItemInputCard} from "../../components/staking/CraftableItemInputCard";
import {countdownRenderText, formatWei, preloadImage} from "../../utils";
import backCaretIcon from '../../assets/icons/back-caret@2x.png';
import Nft, {
    selectClaimableCraftableItemOrders,
    selectClaimablePassawayTransphormOrders,
    selectNftBalance, selectUnclaimedLockedTokensFromCraftableItemOrders
} from "../../store/models/nft";
import {store} from "../../store";
import classNames from "classnames";
import {getNFTImage, getNFTImageThumb} from "../../utils/nfts";
import {RequiredApprovals} from "../../components/staking/RequiredApprovals";
import Countdown from "react-countdown";
import modalCloseIcon from "../../assets/icons/modal-close@2x.png";
import {Tab, TabList, Tabs} from "react-tabs";
import {TransphormPassawayOrderCard} from "./PassawaySelector";
import PassawayWithMeta from "../../components/staking/PassawayWithMeta";
import {pushTo} from "../../utils/history";
import {selectCurrentUserId} from "../../store/models/user";

const renderAvailableIn = (countDownData) => {
    if(countDownData.completed){
        return null;
    }

    return (
        <div className={'RoundedModalHeader__available-in'}>
            {`Available in ${countdownRenderText(countDownData)}`}
        </div>
    )
}

const renderAvailableFor = (countDownData) => {
    if(countDownData.completed){
        return null;
    }

    return (
        <div className={'RoundedModalHeader__available-for'}>
            {`Available for ${countdownRenderText(countDownData)}`}
        </div>
    )
}

const renderReadyIn = (countDownData) => {
    if(countDownData.completed){
        return null;
    }

    return (
        <div className={'CraftableItemOrderCard__ready-in'}>
            <span>Ready in: </span>
            <span className={'color--green'}>{countdownRenderText(countDownData)}</span>
        </div>
    )
}

export const LockedTokenCard = ({lockedToken, onSelect}) => {
    const lockedNft = lockedToken.lockedNft;
    const buttonTitle = lockedToken.claimableAt > Date.now() ? null : 'Claim';

    return (
        <div className={'LockedTokenCard'}>
            <img src={lockedNft.image}
                 className={'LockedTokenCard__image'}
            />
            <div className={'LockedTokenCard__name'}>{lockedNft.name}</div>
            {
                (lockedNft.description || lockedNft.descriptionShort) &&
                <div className={'LockedTokenCard__description'}>{lockedNft.descriptionShort || lockedNft.description}</div>
            }
            {
                lockedToken.claimableAt &&
                <Countdown
                    date={(new Date(lockedToken.claimableAt))}
                    renderer={renderReadyIn}
                />
            }
            <div className={'LockedTokenCard__footer'}>
                <RoundedButton title={buttonTitle}
                               onClick={() => {
                                   onSelect(lockedToken);
                               }}
                               color={'green'}
                               disabled={_.isNil(buttonTitle)}
                />
            </div>
        </div>
    );
}


export const CraftableItemOrderCard = ({order, onSelect}) => {
    const craftableItem = order.craftableItem;
    const outputNft = craftableItem.outputNft;
    const buttonTitle = order.claimableAt > Date.now() ? 'Expedite' : 'Claim';

    return (
        <div className={'CraftableItemOrderCard'}>
            <img src={outputNft.image}
                 className={'CraftableItemOrderCard__image'}
            />
            <div className={'CraftableItemOrderCard__name'}>{outputNft.name}</div>
            {
                order.claimableAt &&
                <Countdown
                    date={(new Date(order.claimableAt))}
                    renderer={renderReadyIn}
                />
            }
            <div className={'CraftableItemOrderCard__footer'}>
                <RoundedButton title={buttonTitle}
                               onClick={() => {
                                   onSelect(order);
                               }}
                               color={'green'}
                />
            </div>
        </div>
    );
}

export class CraftingModal extends React.Component {
    constructor(props) {
        super(props);

        const {defaultSelectedCraftableItemId} = props;

        this.state = {
            selectedCraftableItemId: defaultSelectedCraftableItemId,
            isCrafted: false,
            isLoading: true,
            showClaimableOrders: false,
            showLockedTokens: false,
            tabIndex: 0
        }
    }

    async componentDidMount() {
        const {address, dispatch, defaultSelectedCraftableItemId} = this.props;
        await dispatch(UIState.actions.showLoader('Loading...'));
        const orders = await dispatch(Nft.actions.fetchUnclaimedCraftableItemOrders(address));
        const lockedTokens = dispatch(Nft.actions.fetchUnclaimedLockedTokensFromCraftableItemOrders(address));
        await dispatch(UIState.actions.hideLoader());

        this.setState({
            isLoading: false,
            tabIndex: (_.isNil(defaultSelectedCraftableItemId) ? (orders.length > 0 ? 1 : 0) : 0),
        });
    }

    onClose = () => {
        this.props.dispatch(UIState.actions.hideModal());
    }

    onSelectCraftableItem = (craftableItem) => {
        this.setState({
            selectedCraftableItemId: craftableItem.id
        })
    }

    onSelectLockedToken = async (lockedToken) => {
        const success = await this.props.dispatch(Wallet.actions.reclaimLockedTokenFromCrafting(lockedToken.id));
    }

    onSelectOrder = async (order) => {
        // if not claimable, show the modal to expedite
        if(order.claimableAt > Date.now()){
            this.props.dispatch(UIState.actions.showModal(ModalTypes.ExpediteCraftableItemOrder, {
                order,
                onBuy: () => {
                    this.props.dispatch(UIState.actions.hideModal());
                    this.props.dispatch(Wallet.actions.expediteAndClaimCraftableItemOrder(order.id));
                }
            }));
        }
        else{
            const {orders} = await this.props.dispatch(Wallet.actions.claimCraftableItemOrder(order.id));

            if(orders.length === 0){
                this.setState({
                    tabIndex: 0,
                })
            }
        }
    }

    getCraftableItem = (id) => {
        const {craftableItems} = this.props;

        return _.find(craftableItems, (craftableItem) => {
            return (id === craftableItem.id)
        });
    }

    onCraftItem = async () => {
        const {selectedCraftableItemId} = this.state;
        const craftableItem = this.getCraftableItem(selectedCraftableItemId);
        const success = await this.props.dispatch(Wallet.actions.craftItem(craftableItem));
        this.setState({
            isCrafted: success
        })
    }

    onBuy = (nft) => {
        this.props.dispatch(UIState.actions.showNFTPurchaseModal(nft));
    }

    onBack = () => {
        this.setState({
            selectedCraftableItemId: null,
            isCrafted: false
        })
    }

    onContinueAfterCrafted = () => {
        this.setState({
            selectedCraftableItemId: null,
            tabIndex: 1,
            isCrafted: false
        })
    }

    render() {
        const {address, craftableItems, claimableOrders, lockedTokens, dispatch} = this.props;
        const {selectedCraftableItemId, isCrafted, tabIndex} = this.state;
        const selectedCraftableItem = this.getCraftableItem(selectedCraftableItemId);
        const craftedVideo = _.get(selectedCraftableItem, 'outputNft.craftedVideo', null);
        const hasSelectedCraftableItemStarted = selectedCraftableItem ? (selectedCraftableItem.startsAt && (new Date(selectedCraftableItem.startsAt)).getTime() < Date.now()) : false;
        const showClaimableOrders = tabIndex === 1;
        const showLockedTokens = tabIndex === 2;

        return (
            <RoundedModal title={'Craft'}
                          onClose={this.onClose}
                          className={classNames('CraftingModal', {
                              'CraftingModal--crafted': isCrafted,
                              'CraftingModal--expanded': _.isNil(selectedCraftableItem)
                          })}
                          header={(
                              <div className={classNames('RoundedModalHeader', {
                                  'RoundedModalHeader--expanded': _.isNil(selectedCraftableItem)
                              })}>
                                  <div className={'RoundedModalHeader__title'}>
                                      Craft
                                  </div>
                                  {
                                      selectedCraftableItem && !isCrafted &&
                                      <Fragment>
                                          {
                                              !hasSelectedCraftableItemStarted &&
                                              <Countdown
                                                  date={(new Date(selectedCraftableItem.startsAt))}
                                                  renderer={renderAvailableIn}
                                              />
                                          }
                                          {
                                              hasSelectedCraftableItemStarted &&
                                              <Countdown
                                                  date={(new Date(selectedCraftableItem.endsAt))}
                                                  renderer={renderAvailableFor}
                                              />
                                          }
                                      </Fragment>
                                  }
                                  <a className={'RoundedModalHeader__close-button'}
                                     onClick={this.onClose}
                                  >
                                      <img src={modalCloseIcon}/>
                                  </a>

                                  {
                                      _.isNil(selectedCraftableItem) &&
                                      <Tabs selectedIndex={this.state.tabIndex}
                                            onSelect={(index) => {
                                                this.setState({tabIndex: index});

                                                if(index === 1){
                                                    dispatch(Nft.actions.fetchUnclaimedCraftableItemOrders(address));
                                                }
                                                else if(index === 2){
                                                    dispatch(Nft.actions.fetchUnclaimedPassawayTransphormOrders(address));
                                                }
                                            }}
                                            className={'TextTabs'}>
                                          <TabList className={'TextTabList'}>
                                              <Tab>
                                                  <span>Craft</span>
                                              </Tab>
                                              <Tab>
                                                  <span>Orders</span>
                                              </Tab>
                                              <Tab>
                                                  <span>Locked</span>
                                              </Tab>
                                          </TabList>
                                      </Tabs>
                                  }
                              </div>
                          )}
            >
                {
                    showLockedTokens &&
                    <div className={'LockedTokensContent'}>
                        <div className={'LockedTokensContent__description'}>Tokens locked from crafting orders can be re-claimed once they are ready.</div>
                        <div className={'LockedTokensContent__locked-tokens'}>
                            {
                                lockedTokens.map((lockedToken, index) => {
                                    return (
                                        <LockedTokenCard
                                            key={index}
                                            lockedToken={lockedToken}
                                            onSelect={this.onSelectLockedToken}
                                        />
                                    )
                                })
                            }
                        </div>
                    </div>
                }
                {
                    showClaimableOrders &&
                    <div className={'CraftableItemOrdersContent'}>
                        <div className={'CraftableItemOrdersContent__description'}>
                            Crafting time varies per item but can be sped up using PLASM.
                        </div>
                        <div className={'CraftableItemOrdersContent__orders'}>
                            {
                                claimableOrders.map((order, index) => {
                                    return (
                                        <CraftableItemOrderCard
                                            order={order}
                                            onSelect={this.onSelectOrder}
                                        />
                                    )
                                })
                            }
                        </div>
                        <div>
                            <RoundedButton title={claimableOrders.length > 0 ? `Craft Another Item` : `Craft Item`}
                                           color={'green'}
                                           size={'medium'}
                                           className={'CraftableItemOrdersContent__button'}
                                           onClick={() => {
                                               this.setState({
                                                   isLoading: false,
                                                   tabIndex: 0
                                               });
                                           }}
                            />
                        </div>
                    </div>
                }
                {
                    tabIndex === 0 && isCrafted &&
                    <div className={'CraftedContent'}>
                        <div className={classNames('CraftedContent__result', {
                            // Only animate when it's not a video
                            'animate__animated animate__pulseSmall animate__slow animate__infinite' : _.isNil(craftedVideo)
                        })}>
                            {
                                craftedVideo &&
                                <video src={craftedVideo}
                                       muted
                                       playsInline
                                       autoPlay
                                       controls={false}
                                       loop={true}
                                />
                            }
                            {
                                _.isNil(craftedVideo) &&
                                <img src={selectedCraftableItem.outputNft.image}/>
                            }
                        </div>
                        <div className={'CraftedContent__title'}>{selectedCraftableItem.outputNft.craftedTitle}</div>
                        <div className={'CraftedContent__description'}>{selectedCraftableItem.outputNft.craftedSubtitle}</div>
                        <div>
                            <RoundedButton title={`Continue`}
                                           color={'green'}
                                           size={'medium'}
                                           className={'CraftedContent__done-button'}
                                           onClick={this.onContinueAfterCrafted}
                            />
                        </div>
                    </div>
                }
                {
                    tabIndex === 0 && (!_.isNil(selectedCraftableItem) && !isCrafted) &&
                    <div className={'CraftingContent'}>
                        <a className={'CraftingContent__back-button'}
                           onClick={this.onBack}
                        >
                            <img src={backCaretIcon}/>
                            <div>Back</div>
                        </a>
                        <div className={'CraftingContent__title'}>{selectedCraftableItem.outputNft.name}</div>
                        <div className={'CraftingContent__description'}>{selectedCraftableItem.outputNft.description}</div>
                        <div className={'CraftingContent__inputs'}>
                            {
                                _.map(selectedCraftableItem.inputNfts, (nft) => {
                                    return (
                                        <CraftableItemInputCard nft={nft}
                                                                onBuy={this.onBuy}
                                        />
                                    )
                                })
                            }
                        </div>
                        <div>
                            <RoundedButton title={`Craft ${selectedCraftableItem.outputNft.name}`}
                                           color={'green'}
                                           size={'medium'}
                                           className={'CraftingContent__craft-button'}
                                           disabled={selectedCraftableItem.canCraftItem === false}
                                           onClick={this.onCraftItem}
                            />
                            <div className={'CraftingContent__price-container'}>
                                <div className={'CraftingContent__price-prefix'}>
                                    CRAFTING COST
                                </div>
                                <div className={'CraftingContent__price'}>{formatWei(selectedCraftableItem.price)}</div>
                            </div>

                            <RequiredApprovals spenderAddress={getContractAddressByKey('crafting')}
                                               tokenAddress={getContractAddressByKey('plasm')}
                                               tokenAmount={selectedCraftableItem.price}
                                               nftAddresses={selectedCraftableItem.inputNftContracts}
                            />
                        </div>
                    </div>
                }
                {
                    tabIndex === 0 && _.isNil(selectedCraftableItem) &&
                    <div className={'CraftingModal__items'}>
                        {
                            _.map(craftableItems, (craftableItem) => {
                                if(craftableItem.isExpired === true){
                                    return null;
                                }
                                return (
                                    <CraftableItemCard craftableItem={craftableItem}
                                                       onCraft={this.onSelectCraftableItem}
                                                       onDetails={() => {
                                                           this.onSelectCraftableItem(craftableItem);
                                                       }}
                                    />
                                )
                            })
                        }
                    </div>
                }
                {
                    craftableItems.length === 0 &&
                    <div className={'EmptyState'}>
                        <div className={'EmptyState__title'}>
                            Keep your<br/>eyes peeled
                        </div>
                    </div>
                }

            </RoundedModal>
        );
    }
}

const mapStateToProps = (state, props) => {
    const {action} = props;
    const address = selectCurrentUserId(state);

    let craftableItems = selectCraftableItems(state);
    const canCraftItem = (craftableItem) => {
        const requiredByAddress = _.countBy(_.map(craftableItem.inputs, 'tokenAddress'), _.identity);
        let canCraft = true;

        for (const address in requiredByAddress) {
            const requiredAmt = requiredByAddress[address];

            if(requiredAmt > selectNftBalance(state, address)){
                canCraft = false;
                break;
            }
        }
        return canCraft;
    }
    craftableItems = _.map(craftableItems, (craftableItem) => {
        let outputContractInfo = selectContract(state, craftableItem.outputs[0].tokenAddress);
        if(_.isNil(outputContractInfo)){
            return null;
        }

        return Object.assign({}, craftableItem, {
            outputNft: {
                ...outputContractInfo,
                image: getNFTImage(outputContractInfo.key),
                craftedVideo: (_.isNil(outputContractInfo?.craftedVideo) ? null : `/nfts/${outputContractInfo?.craftedVideo}`)
            },
            inputNfts: _.map(craftableItem.inputs, (input) => {
                let contractInfo = selectContract(state, input.tokenAddress);
                return {
                    ...contractInfo,
                    image: getNFTImage(contractInfo.key),
                    balance: selectNftBalance(state, contractInfo.address),
                }
            }),
            canCraftItem: canCraftItem(craftableItem)
        })
    });
    _.remove(craftableItems, _.isNil);

    let claimableOrders = selectClaimableCraftableItemOrders(state);
    // Ensure the UI has the nft it is giving to the user
    claimableOrders = _.map(claimableOrders, (order) => {
        let craftableItem = _.find(craftableItems, (craftableItem) => {
            return (craftableItem.id === order.craftableItemId);
        });

        return Object.assign({}, order, {
            craftableItem: craftableItem
        })
    });
    let lockedTokens = selectUnclaimedLockedTokensFromCraftableItemOrders(state);
    // Ensure the UI has the nft that is locked
    lockedTokens = _.map(lockedTokens, (lockedToken) => {
        let tokenContractInfo = selectContract(state, lockedToken.tokenAddress);

        return Object.assign({}, lockedToken, {
            lockedNft: {
                ...tokenContractInfo,
                image: getNFTImage(tokenContractInfo.key),
            }
        })
    });

    return {
        ...props,
        address: address,
        craftableItems: craftableItems,
        defaultSelectedCraftableItemId: props.defaultSelectedCraftableItemId,
        claimableOrders: claimableOrders,
        lockedTokens: lockedTokens
    }
};

export default connect(mapStateToProps)(CraftingModal);

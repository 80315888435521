import _ from "lodash";
import React, {useState} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux'
import UIState from "../../store/uiState";
import ProductModal from "./Product";
import {countdownRenderText, formatBalance} from "../../utils";
import Wallet from "../../store/models/wallet";
import Countdown from "react-countdown";

const renderAvailableFor = (countDownData) => {
    if(countDownData.completed){
        return (
            <div className={''}>
                Ended
            </div>
        );
    }

    return (
        <div className={''}>
            {`${countdownRenderText(countDownData)}`}
        </div>
    )
}

const renderAvailableIn = (countDownData) => {
    if(countDownData.completed){
        return (
            <div className={''}>
                Now
            </div>
        );
    }

    return (
        <div className={''}>
            {`${countdownRenderText(countDownData)}`}
        </div>
    )
}

export class SupplyStoreProductModal extends React.Component {
    onClose = () => {
        this.props.dispatch(UIState.actions.hideModal());
    }
    onBuy = (qty) => {
        this.props.dispatch(Wallet.actions.purchaseProduct(this.props.product, qty));
    }

    render() {
        const {product} = this.props;
        const {endsAt, startsAt} = product || {};

        return (
            <ProductModal title={'Product Details'}
                          product={product}
                          productName={product.nft.name}
                          productDescription={product.nft.description}
                          productImage={product.nft.image}
                          productLineItems={[
                              ...product.nft?.lineItems || [],
                              {
                                  title: 'Available for',
                                  content: (
                                      <Countdown
                                          date={(new Date(endsAt))}
                                          renderer={renderAvailableFor}
                                      />
                                  ),
                                  hidden: _.isNil(endsAt)
                              },
                              {
                                  title: 'Available in',
                                  content: (
                                      <Countdown
                                          date={(new Date(startsAt))}
                                          renderer={renderAvailableIn}
                                      />
                                  ),
                                  hidden: _.isNil(startsAt) || (Date.now() > new Date(startsAt))
                              },
                              {
                                  title: 'Qty available',
                                  content: (
                                      <div>
                                          {product.remaining}
                                      </div>
                                  )
                              },
                              {
                                  title: 'Price',
                                  content: <div className={'PlasmPrice'}>{formatBalance(product.plasmPrice)}</div>
                              },
                          ]}
                          onBuy={this.onBuy}
            />
        );
    }
}

const mapStateToProps = (state, props) => {
    const {action} = props;

    return {
        ...props,
    }
};

export default connect(mapStateToProps)(SupplyStoreProductModal);

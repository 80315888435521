import _ from 'lodash';
import {ModalTypes, Urls} from "../constants";
import Wallet from "./models/wallet";
import {RoundedButton} from "../components/Button";
import {
    selectActiveNFTDropForNFTAddress,
    selectContractByKey,
    selectNFTDropForNFTAddress,
    selectSupplyStoreProduct
} from "./models/config";
import {store} from "./index";
import {pushTo} from "../utils/history";

// ===========================
// HELPERS
// ===========================


// ===========================
// ACTIONS
// ===========================
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const HIDE_ALL_MODALS = 'HIDE_ALL_MODALS';
export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';
export const SHOW_TOAST = 'SHOW_TOAST';


// ===========================
// SELECTORS
// ===========================
export const selectModals = state => state.ui.modals;
export const selectOpenModal = state => _.first(selectModals(state));
export const selectToasts = state => state.ui.toast;

const ModalTypesThatAreSingletons = new Set([
    ModalTypes.Welcome,
    ModalTypes.Shop,
    ModalTypes.ProveOwnership,
    ModalTypes.PassawaySelector,
    ModalTypes.UseInventory,
    ModalTypes.SupplyStore,
    ModalTypes.Plasm,
    ModalTypes.PowerUpSlotProduct,
    ModalTypes.SupplyStoreProduct,
    ModalTypes.ActivePowerUp,
    ModalTypes.Inventory,
    ModalTypes.Crafting,
    ModalTypes.Redeeming,
    ModalTypes.Transphorm,
    ModalTypes.Marketplaces,
    ModalTypes.MobileWallets,
    ModalTypes.UnstakePlasm,
]);

// ===========================
// MODEL
// ===========================
const UIState = {

    actions: {
        showLoader: (title, subtitle, onCancel) => (dispatch, getState) => {
            return dispatch({
                type: SHOW_LOADER,
                modal: {
                    type: ModalTypes.Loader,
                    props: {
                        title: title,
                        subtitle: subtitle,
                        onCancel: onCancel
                    }
                } });
        },
        hideLoader: () => (dispatch, getState) => {
            return dispatch({
                type: HIDE_LOADER
            });
        },
        showModal: (modal, props) => (dispatch, getState) => {
            dispatch(UIState.actions.showModalUI(modal, props))
        },
        showModalUI: (modal, props) => (dispatch, getState) => {
            if(_.isString(modal))
                modal = { type: modal, props };

            if(ModalTypesThatAreSingletons.has(modal.type)){
                let modals = selectModals(getState());
                let existingModal = _.find(modals, { type: modal.type });
                if(existingModal){
                    return null;
                }
            }

            return dispatch({ type: SHOW_MODAL, modal, });
        },

        hideAllModals: () => (dispatch, getState) => {
            return dispatch({
                type: HIDE_ALL_MODALS
            })
        },
        hideModal: (modalType) => (dispatch, getState) => {
            dispatch(UIState.actions.hideModalUI(modalType))
        },
        hideModalUI: (modalType) => ({ type: HIDE_MODAL, modalType}),

        showToast: (options) => {
            let message = _.isString(options) ? options : options.message || null;
            options = _.omit(options, 'message') || {};
            return {
                type: SHOW_TOAST,
                message,
                options
            }
        },

        showSuccessToast: options => UIState.actions.showToast({
            ...(_.isString(options) ? { message: `SUCCESS: ${options}` } : options),
            type: 'success' }),

        showErrorToast: options => UIState.actions.showToast({
            ...(_.isString(options) ? { message: `ERROR: ${options}` } : options),
            type: 'error' }),

        withSuccessToast: (action, success, options) => async (dispatch) => {
            try {
                let results = await dispatch(action);
                let message = _.isFunction(success)
                    ? success(results)
                    : success;
                if(success)
                    dispatch(UIState.actions.showSuccessToast(message))
                return Promise.resolve(results);
            } catch(err) {

            }
        },

        withErrorToast: (action, error, options) => async (dispatch) => {
            try {
                let results = await dispatch(action);
                return Promise.resolve(results);
            } catch(err) {
                let message = _.isFunction(error)
                    ? error(err)
                    : error ?? err.message ?? _.toString(err);
                dispatch(UIState.actions.showErrorToast(message));
                throw err;
            }
        },

        withToasts: (action, success, error, options) => async (dispatch) => {
            try {
                let results = await dispatch(action);
                let message = _.isFunction(success)
                    ? success(results)
                    : success;
                if(success)
                    dispatch(UIState.actions.showSuccessToast(message))
                return Promise.resolve(results);
            } catch(err) {
                let message = _.isFunction(error)
                    ? error(err)
                    : error ?? err.message ?? _.toString(err);
                dispatch(UIState.actions.showErrorToast(message));
                throw err;
            }
        },

        showPassawayStakingModal: () => (dispatch) => {
            dispatch(UIState.actions.showModal(ModalTypes.PassawaySelector, {
                title: "Select Passaways to Stake",
                cardButtonTitle: 'Stake',
                predicate: ({stake}) => {
                    return _.isNil(stake);
                },
                multiple: true,
                multipleButtonTitle: "Stake All Passaways",
                multipleDisclaimer: '* Only 50 passaways can be staked in a single transaction.',
                onSelect: (nftOrNfts) => {
                    let nfts = _.isArray(nftOrNfts) ? nftOrNfts : [nftOrNfts];
                    nfts = _.take(nfts, 50);
                    const tokenIds = _.map(nfts, 'token_id');
                    dispatch(Wallet.actions.stakePassaways(tokenIds));
                }
            }))
        },

        showPassawayPurchaseModal: () => (dispatch, getState) => {
            const nft = selectContractByKey(getState(), 'passaways');
            dispatch(UIState.actions.showModal(ModalTypes.Marketplaces, {
                nft: nft
            }))
        },

        showNFTPurchaseModal: (nft) => (dispatch, getState) => {
            const product = selectSupplyStoreProduct(getState(), nft.address);
            const nftDrop = selectActiveNFTDropForNFTAddress(getState(), nft.address);
            if(product){
                dispatch(UIState.actions.showModal(ModalTypes.SupplyStoreProduct, {
                    product: product
                }))
            }
            else if(nftDrop){
                pushTo(Urls.TPMC_NFT_DROP_LIVE);
                dispatch(UIState.actions.hideAllModals());
            }
            else{
                dispatch(UIState.actions.showModal(ModalTypes.Marketplaces, {
                    nft: nft
                }))
            }
        }
    },

    spec: {
        modals: [],
        toast: null,
    },

    reducer: (state = UIState.spec, action) => {
        const { type } = action;

        switch(type) {
            case SHOW_MODAL: {
                let { type, props } = action.modal;
                let currentModal = _.first(state.modals);

                if(currentModal?.type === type)
                    props = { ...currentModal.props, ...props }

                return { ...state,
                    modals: [...state.modals, { type, props }],
                };
            }
            case SHOW_LOADER: {
                let { type, props } = action.modal;
                let currentLoader = _.find(state.modals, (modal) => {
                    return modal.type === ModalTypes.Loader;
                });

                if(currentLoader){
                    return { ...state,
                        modals: _.map(state.modals, (modal) => {
                            if(modal.type === ModalTypes.Loader){
                                return { type, props };
                            }
                            return modal;
                        }),
                    };
                }

                return { ...state,
                    modals: [...state.modals, { type, props }],
                };
            }

            case HIDE_MODAL: {
                if(action.modalType){
                    return { ...state, modals: _.filter(state.modals, (modal) => {
                            return modal.type !== action.modalType;
                        })};
                }
                else{
                    return { ...state, modals: _.dropRight(state.modals)};
                }
            }
            case HIDE_ALL_MODALS: {
                return { ...state, modals: []};
            }
            case HIDE_LOADER:
                return { ...state, modals: _.filter(state.modals, (modal) => {
                    return modal.type !== ModalTypes.Loader
                })};

            case SHOW_TOAST:
                return {
                    ...state,
                    toast: {
                        message: action.message,
                        options: action.options,
                    }
                }
        }

        return state;
    },
};
export default UIState;

import _ from 'lodash';
import {post, get, put, authHeaders} from "../../utils/fetch";
import {Urls, RequestState, Hosts, CurrentNetwork} from "../../constants";
import {getNFTImage} from "../../utils/nfts";
import {isSameAddress} from "../../utils";
import {store} from "../index";

// ===========================
// HELPERS
// ===========================


// ACTIONS
// ===========================
export const FETCH_CONFIG = 'FETCH_CONFIG';
export const FETCH_CONFIG_START = 'FETCH_CONFIG_START';

export const FETCH_NFT_DROPS = 'FETCH_NFT_DROPS';

export const FETCH_LOTTERY = 'FETCH_LOTTERY';

// ===========================
// SELECTORS
// ===========================
export const selectConfig = (state) => {
    return state.models.config;
};

export const selectConfigLoadState = (state) => {
    return {
        hasLoadedConfig: state.models.hasLoadedConfig,
        isLoadingConfig: state.models.isLoadingConfig,
    };
};

export const selectSupplyStoreProducts = (state) => {
    let products = state.models.config?.supplyStore?.products || [];
    products = _.map(products, (product) => {
        let contractInfo = selectContract(state, product.nftContract);
        if(_.isNil(contractInfo)){
            return null;
        }

        return Object.assign(product, {}, {
            nft: {
                ...contractInfo,
                image: getNFTImage(contractInfo.key)
            }
        })
    });
    _.remove(products, _.isNil);

    return products;
};
export const selectCraftableItems = (state) => {
    return state.models.config?.crafting?.craftableItems || [];
};
export const selectCraftableItem = (state, outputContractAddress) => {
    const items = selectCraftableItems(state);
    const item = _.find(items, (item) => {
        return isSameAddress(item.outputNftContract, outputContractAddress);
    });
    return item;
};
export const selectRedeemables = (state) => {
    return state.models.config?.redeeming?.redeemables || [];
};
export const selectSupplyStoreProduct = (state, contractAddress) => {
    const products = selectSupplyStoreProducts(state);
    const product = _.find(products, (product) => {
        return isSameAddress(product.nftContract, contractAddress);
    });
    return product;
};
export const selectContract = (state, contractAddress) => {
    const contracts = Object.values(state.models.config?.contracts || {});
    if(_.isNil(contracts)){
        return null;
    }
    return _.find(contracts, (contract) => {
        return isSameAddress(contract.address, contractAddress);
    });
};
export const selectPlasmContract = (state) => {
    return selectContract(state, 'plasm');
};
export const selectPlasmStakingContract = (state) => {
    return selectContract(state, 'plasmStaking');
};
export const selectContractByKey = (state, contractKey) => {
    const contract = state.models.config?.contracts[contractKey];
    return contract;
};
export const selectContractAddressByKey = (state, contractKey) => {
    return selectContract(state, contractKey)?.address;
};
export const getContractAddressByKey = (contractKey) => {
    const state = store.getState();
    const contract = selectContractByKey(state, contractKey);
    return contract?.address;
};
export const selectNFTContracts = (state) => {
    const contracts = Object.values(state.models.config?.contracts);
    return _.filter(contracts, (c) => {
        return c.isNft;
    });
};
export const selectNFTDrops = (state) => {
    let nftDrops = state.models.config?.nftDropManager?.nftDrops || [];
    nftDrops = _.map(nftDrops, (nftDrop) => {
        let contractInfo = selectContract(state, nftDrop.nftContract);
        if(_.isNil(contractInfo)){
            return null;
        }

        return Object.assign(nftDrop, {}, {
            nft: {
                ...contractInfo,
                image: getNFTImage(contractInfo.key)
            }
        })
    });
    _.remove(nftDrops, _.isNil);

    return nftDrops;
};
export const selectNFTDropForNFTAddress = (state, targetNftAddress) => {
    let nftDrops = state.models.config?.nftDropManager?.nftDrops || [];
    return _.find(nftDrops, (nftDrop) => {
        return _.toLower(nftDrop.nftContract) === _.toLower(targetNftAddress);
    });
};
export const selectActiveNFTDropForNFTAddress = (state, targetNftAddress) => {
    let nftDrop = selectNFTDropForNFTAddress(state, targetNftAddress);
    if(nftDrop?.enabled && nftDrop?.remaining > 0){
        return nftDrop;
    }
    return null;
};

export const selectLottery = (state) => {
    let lottery = state.models.config?.tpmcLottery?.lottery;
    if(lottery){
        if(lottery.participationNftContract){
            let contractInfo = selectContract(state, lottery.participationNftContract);

            lottery = Object.assign(lottery, {}, {
                participationNft: {
                    ...contractInfo,
                    image: getNFTImage(contractInfo?.key)
                }
            })
        }
    }
    return lottery;
};

// ===========================
// MODEL
// ===========================
const Config = {
    actions: {
        fetchConfig: () => async (dispatch, getState) => {
            await dispatch({
                type: FETCH_CONFIG_START,
            })
            return get({
                url: "/v1/config",
                headers: {
                    'x-session-token': _.get(getState(), 'app.userToken')
                },
                dispatch,
                action: FETCH_CONFIG,
            }).then(results => results?.body);
        },
        fetchNFTDrops: () => async (dispatch, getState) => {
            return get({
                url: "/v1/config/nft-drops",
                headers: {
                    'x-session-token': _.get(getState(), 'app.userToken')
                },
                dispatch,
                action: FETCH_NFT_DROPS,
            }).then(results => results?.body);
        },
        fetchLottery: (address) => async (dispatch, getState) => {
            return get({
                url: "/v1/config/lottery",
                params: {
                    address: address,
                },
                headers: {
                    'x-session-token': _.get(getState(), 'app.userToken')
                },
                dispatch,
                action: FETCH_LOTTERY,
            }).then(results => results?.body);
        }
    },

    spec: {
        isLoadingConfig: false,
        hasLoadedConfig: false,
        config: {
            contracts: {}
        }
    },

    modelReducer: (state, type, body, action) => {
        if(type === FETCH_CONFIG_START){
            return {
                ...state,
                isLoadingConfig: true
            }
        }

        if (action.url && action.result !== RequestState.SUCCESS)
            return state;

        if (type === FETCH_CONFIG) {
            let contracts = _.mapValues(body.contracts, (contractInfo) => {
                if(_.isNil(contractInfo)){
                    return null;
                }
                return Object.assign({}, contractInfo, {
                    abi: body.abis[contractInfo?.abiName]
                })
            });
            contracts = _.omitBy(contracts, _.isNil);

            return {
                ...state,
                config: Object.assign({}, body, {
                    contracts: contracts
                }),
                hasLoadedConfig: true,
                isLoadingConfig: false
            }
        }
        if (type === FETCH_NFT_DROPS){
            return {
                ...state,
                config: {
                    ...state.config,
                    nftDropManager: {
                        ...state.config.nftDropManager,
                        nftDrops: body.nftDrops
                    }
                }
            }
        }
        if (type === FETCH_LOTTERY){
            return {
                ...state,
                config: {
                    ...state.config,
                    tpmcLottery: {
                        ...state.config.tpmcLottery,
                        lottery: body.lottery
                    }
                }
            }
        }

        return state;
    }
}
export default Config;

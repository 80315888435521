import _ from 'lodash';
import classNames from "classnames";
import {RoundedButton} from "../Button";
import React from "react";

export const InventoryItemCard = ({nft, buttonTitle, onSelect, onBuy, isEligibleForUse}) => {
    const canUse = nft.balance > 0 && isEligibleForUse !== false;
    buttonTitle = (!_.isNil(onBuy) && !canUse) ? 'Buy' : buttonTitle;
    let buttonColor = canUse ? 'green' : 'grey-outline';
    let buttonDisabled = _.isNil(onBuy) && !canUse;
    if(isEligibleForUse === false){
        buttonTitle = 'Not Eligible';
        buttonColor = 'red-outline';
    }


    return (
        <div className={classNames('InventoryItemCard', {
            [`InventoryItemCard--${nft.category}`]: true,
            [`InventoryItemCard--no-button`]: _.isNil(buttonTitle)
        })}>
            {
                nft.balance > 0 &&
                <div className={'NumberBadge'}>x{nft.balance}</div>
            }
            <img src={nft.image}
                 className={'InventoryItemCard__image'}
            />
            <div className={'InventoryItemCard__name'}>{nft.name}</div>
            <div className={'InventoryItemCard__description'}>{nft.descriptionShort || nft.description}</div>
            <div className={'InventoryItemCard__footer'}>
                {
                    buttonTitle &&
                    <RoundedButton title={buttonTitle}
                                   onClick={() => {
                                       if(isEligibleForUse === false){
                                           return;
                                       }
                                       if(canUse){
                                           onSelect(nft);
                                       }
                                       else{
                                           onBuy(nft);
                                       }
                                   }}
                                   disabled={buttonDisabled}
                                   color={buttonColor}
                    />
                }
            </div>
        </div>
    );
}
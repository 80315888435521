import plasmLogo from "../../assets/tokens/plasm.png";
import Button from "../Button";
import UIState from "../../store/uiState";
import {ModalTypes} from "../../constants";
import React from "react";
import {useDispatch} from "react-redux";

const PlasmCTA = () => {
    const dispatch = useDispatch();

    return (
        <div className={'PlasmCTA'}>
            <div className={'PlasmCTA__top-title'}>
                <span>Short on </span> <img src={plasmLogo}/> <span>PLASM?</span>
            </div>
            <Button color={'text-only-green'}
                    title={'GET MORE HERE'}
                    onClick={() => {
                        dispatch(UIState.actions.showModal(ModalTypes.GetPlasm));
                    }}
            />
        </div>
    )
}

export default PlasmCTA;
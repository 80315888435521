import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {
    Switch,
    Route,
    Redirect,
    useLocation
} from "react-router-dom";
import {BrowserRouter, Router} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Provider} from 'react-redux';
import {createSelector} from 'reselect';
import 'animate.css';
import toast, {Toaster} from 'react-hot-toast';
import HomePage from "./pages/Home";
import LoginPage from "./pages/tpmc/Login";
import CollectionPage from "./pages/tpmc/Collection";
import {ModalTypes, Urls} from "./constants";
import {history, pushTo} from "./utils/history";
import {selectCurrentUserId} from "./store/models/user";
import {createAppStore} from './store';
import reducer from './store/reducer';
import AccessDeniedPage from "./pages/tpmc/AccessDenied";
import Modals from "./components/Modals";
import NFTPage from "./pages/tpmc/Nft";
import Airdrop999ersPage from "./pages/tpmc/Airdrop999ers";
import DiscordPage from "./pages/tpmc/Discord";
import FloorRaiserEventPage from "./pages/events/FloorRaiser";
import DreadfulValentineEventPage from "./pages/events/DeadfulValentine";
import CreateDreadfulValentineEventPage from "./pages/events/CreateDreadfulValentine";
import VoteDreadfulValentineEventPage from "./pages/events/VoteDreadfulValentine";
import OperationManhuntEventPage from "./pages/events/OperationManhunt";
import ThetaConBadgeSwapPage from "./pages/tpmc/ThetaConBadgeSwap"
import ThetaConBadgeSwapStatusPage from "./pages/tpmc/ThetaConBadgeSwapStatus";
import Thetacon22Page from "./pages/events/Thetacon22"
import AirdropLoyalists from "./pages/tpmc/AirdropLoyalists";
import AirdropLoyalistsPage from "./pages/tpmc/AirdropLoyalists";
import NFTStakingPage from "./pages/staking/NFTStaking";
import {selectConfig, selectNFTDrops} from "./store/models/config";
import TPMCHomePage from "./pages/tpmc/Home";
import ApprovalsPage from "./pages/tpmc/Approvals";
import NFTDropPage from "./pages/staking/NFTDrop";
import LandingPage from "./pages/Landing";
import appState from "./store/appState";
import Loader from "./components/Loader";
import PlasmStakingPage from "./pages/staking/PlasmStaking";
import uiState from "./store/uiState";
import detectEthereumProvider from '@metamask/detect-provider';
import {isMetaMaskBrowser, isTruthy} from "./utils";
import LotteryPage from "./pages/staking/Lottery";
import ProofOfTransphormPage from "./pages/staking/ProofOfTransphorm";
import VIPPage from "./pages/staking/VIP";

const initialState = {};
const store = createAppStore(reducer, initialState);

const loggedInSelector = createSelector(
    selectCurrentUserId,
    (userId) => ({userId})
)

const LoggedInJumpRoute = ({path, redirectTo, onMatch, ...props}) => {
    let location = useLocation();
    let {pathname, search} = location;
    let {userId} = useSelector(loggedInSelector);
    let allowed = !_.isEmpty(userId);

    if(allowed){
        if(pathname === path) {
            onMatch(location);
        }

        return <Redirect to={redirectTo}/>
    }
    else{
        return <Redirect to={`${Urls.TPMC_LOGIN}?to=${path}`}/>
    }
}

const ProtectedRoute = ({allowed, redirectTo = Urls.TPMC_LOGIN, ...props}) => {
    if (allowed) {
        return <Route {...props} />
    } else {
        return <Redirect to={redirectTo}/>
    }
}

const LoggedInRoute = ({...props}) => {
    let {pathname, search} = useLocation();
    let {userId} = useSelector(loggedInSelector);
    let allowed = !_.isEmpty(userId);
    return <ProtectedRoute {...props} allowed={allowed} redirectTo={`${Urls.TPMC_LOGIN}?to=${pathname}${search ? encodeURIComponent(search) : ''}`}/>
}

function AppContent() {
    const dispatch = useDispatch();
    const location = useLocation();
    const nftDrops = useSelector(selectNFTDrops);
    const [isLoading, setIsLoading] = useState(window.location.pathname !== Urls.HOME); // No need to block UI if we are on the home page

    useEffect(() => {
        async function init() {
            const params = new URLSearchParams(location.search);
            if(isTruthy(params.get('success'))){
                alert('Your Discord account should now be an authorized TPMC member. Welcome.');
                pushTo('/tpmc/home');
            }

            if(isTruthy(params.get('auto-login'))){
                setTimeout(async () => {
                    await dispatch(appState.actions.loginWithMetamask());
                }, 1000);
            }
            else{
                await dispatch(appState.actions.recoverSession());
            }

            setIsLoading(false);
        }

        init();

    }, []);

    if(isLoading){
        return (
            <div className={'App'} style={{
                paddingTop: '50vh',
            }}>
                <Loader size={'large'}/>
            </div>
        );
    }

    const liveNFTDropId = _.get(_.last(nftDrops), 'id');

    return (
        <div className={'App'}>
            <Switch>
                <Route path={Urls.HOME} exact component={LandingPage}/>
                <Switch>
                    <Route exact path={Urls.TPMC_ACCESS_DENIED} component={AccessDeniedPage}/>

                    <LoggedInRoute exact path={Urls.TPMC_HOME} component={TPMCHomePage}/>
                    <LoggedInRoute exact path={Urls.TPMC_DISCORD} component={DiscordPage}/>

                    <LoggedInRoute exact path={Urls.TPMC_STAKE_NFTS} component={NFTStakingPage}/>
                    <Route exact path={Urls.TPMC_STAKE_PLASM} component={PlasmStakingPage}/>

                    <LoggedInRoute exact path={Urls.TPMC_APPROVALS} component={ApprovalsPage}/>
                    <Route exact path={Urls.TPMC_NFT_DROP} component={NFTDropPage}/>

                    <Route exact path={Urls.TPMC_LOTTERY} component={LotteryPage}/>

                    <LoggedInRoute exact path={Urls.TPMC_7734_GUARDIAN} component={VIPPage}/>

                    <Route exact path={Urls.TPMC_LOGIN} component={LoginPage}/>

                    <Route exact path={Urls.PROOF_OF_TRANSPHORM} component={ProofOfTransphormPage}/>

                    {/* Jump links */}
                    <LoggedInJumpRoute exact
                                       path={Urls.JUMP_TPMC_MERCH}
                                       redirectTo={Urls.TPMC_HOME}
                                       onMatch={(location)=> {
                                           dispatch(uiState.actions.showModal(ModalTypes.Shop));
                                       }}/>
                    <LoggedInJumpRoute exact
                                       path={Urls.JUMP_TPMC_CRAFTING}
                                       redirectTo={Urls.TPMC_HOME}
                                       onMatch={(location)=> {
                                           const params = new URLSearchParams(location.search);

                                           dispatch(uiState.actions.showModal(ModalTypes.Crafting, {
                                               defaultSelectedCraftableItemId: params.get('id'),
                                           }));
                    }}/>
                    <LoggedInJumpRoute exact
                                       path={Urls.JUMP_GET_PLASM}
                                       redirectTo={Urls.TPMC_HOME}
                                       onMatch={(location)=> {

                                           dispatch(uiState.actions.showModal(ModalTypes.GetPlasm));
                                       }}/>
                    <LoggedInJumpRoute exact
                                       path={Urls.JUMP_TPMC_SUPPLY_STORE}
                                       redirectTo={Urls.TPMC_HOME}
                                       onMatch={(location)=> {
                                           const params = new URLSearchParams(location.search);

                                           dispatch(uiState.actions.showModal(ModalTypes.SupplyStore, {
                                               defaultSelectedCategory: params.get('category'),
                                           }));
                    }}/>
                    <LoggedInJumpRoute exact
                                       path={Urls.JUMP_TPMC_REDEEM}
                                       redirectTo={Urls.TPMC_HOME}
                                       onMatch={(location)=> {
                                           const params = new URLSearchParams(location.search);

                                           dispatch(uiState.actions.showModal(ModalTypes.Redeeming, {
                                               defaultSelectedRedeemableId: params.get('id'),
                                           }));
                    }}/>
                    <LoggedInJumpRoute exact
                                       path={Urls.JUMP_TPMC_TRANSPHORM}
                                       redirectTo={Urls.TPMC_HOME}
                                       onMatch={(location)=> {
                                           dispatch(uiState.actions.showModal(ModalTypes.Transphorm));
                    }}/>

                    <Redirect exact
                              path={Urls.JUMP_STAKE_PLASM}
                              to={Urls.TPMC_STAKE_PLASM}
                    />

                    {/* NFT drop - assumes only one drop live at once */}
                    {
                        liveNFTDropId &&
                        <Redirect exact
                                  path={Urls.TPMC_NFT_DROP_LIVE}
                                  to={Urls.TPMC_NFT_DROP.replace(':nftDropId', liveNFTDropId)}/>
                    }

                    {/* Past event pages */}
                    <Redirect exact path={Urls.TPMC_AIRDROP_999ERS} to={Urls.TPMC_HOME}/>
                    <Redirect exact path={Urls.TPMC_AIRDROP_LOYALISTS} to={Urls.TPMC_HOME}/>
                    <Redirect exact path={Urls.TPMC_VOTE_VALENTINE} to={Urls.TPMC_HOME}/>
                    <Redirect exact path={Urls.TPMC_THETACON_BADGE_SWAP} to={Urls.TPMC_HOME}/>
                    <Redirect exact path={Urls.TPMC_THETACON22} to={Urls.TPMC_HOME}/>
                    {/*/Deprecated pages*/}
                    <Redirect exact path={Urls.TPMC_COLLECTION_NFT} to={Urls.TPMC_HOME}/>

                    {/* Fallabck*/}
                    <Redirect path="*" to={Urls.TPMC_HOME}/>
                </Switch>

                {/* Past event pages*/}
                <Redirect path={Urls.EVENT_OPERATION_MANHUNT} exact to={Urls.HOME}/>
                <Redirect path={Urls.EVENT_FLOOR_RAISER} exact to={Urls.HOME}/>
                <Redirect path={Urls.EVENT_DREADFUL_VALENTINE}  to={Urls.HOME}/>
                <Redirect path={Urls.EVENT_DREADFUL_VALENTINE_CREATE} exact  to={Urls.HOME}/>
                <Redirect path={`${Urls.EVENT_DREADFUL_VALENTINE}/vote`} to={Urls.TPMC_VOTE_VALENTINE}/>
                <Redirect path={Urls.THETACON_BADGE_SWAP_STATUS} exact to={Urls.TPMC_VOTE_VALENTINE}/>
            </Switch>
        </div>
    );
}

function App() {
    return (
        <Provider store={store}>
            <Router history={history}>
                <AppContent/>

                <Toaster containerStyle={{
                    zIndex: 9999999999
                }}
                         toastOptions={{
                             success: {
                                 style: {
                                     background: 'black',
                                     border: '1px solid #76FF80',
                                     color: '#76FF80'
                                 },
                                 iconTheme: {
                                     primary: '#76FF80',
                                     secondary: 'black',
                                 },
                             },
                             error: {
                                 style: {
                                     background: 'black',
                                     border: '1px solid #FF2965',
                                     color: '#FF2965'
                                 },
                                 iconTheme: {
                                     primary: '#FF2965',
                                     secondary: 'black',
                                 },
                             },
                         }}/>

                <Modals/>
            </Router>
        </Provider>
    );
}

export default App;

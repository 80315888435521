import React, {useState} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux'
import Modal, {RoundedModal} from '../../components/Modal';
import UIState from "../../store/uiState";
import _ from "lodash";
import {ContractCategories, ModalTypes} from "../../constants";
import modalCloseIcon from "../../assets/icons/modal-close@2x.png";
import supplyStoreLogo from "../../assets/stake/supply-store/logo@2x.png";
import {Tab, TabList, Tabs} from "react-tabs";
import Config, {selectContract, selectSupplyStoreProduct, selectSupplyStoreProducts} from "../../store/models/config";
import Wallet, {actions} from "../../store/models/wallet";
import {ProductCard} from "../../components/staking/ProductCard";
import {store} from "../../store";
import {RoundedButton} from "../../components/Button";

const categoryToIndex = {
    [ContractCategories.PowerUp]: 0,
    [ContractCategories.Transphorm]: 1,
    [ContractCategories.Phlair]: 2,
    [ContractCategories.Blueprint]: 3,
    [ContractCategories.Extras]: 4
}

export class SupplyStoreModal extends React.Component {
    constructor(props) {
        super();
        const {defaultSelectedCategory} = props;

        const selectedCategory = defaultSelectedCategory || ContractCategories.PowerUp;
        this.state = {
            tabIndex: categoryToIndex[selectedCategory],
            selectedCategory: selectedCategory
        };
    }

    onClose = () => {
        this.props.dispatch(UIState.actions.hideModal());
    }

    onBuyProduct = (product) => {
        this.props.dispatch(UIState.actions.showModal(ModalTypes.SupplyStoreProduct, {
            product: product
        }))
    }

    componentDidMount() {
        this.props.dispatch(Config.actions.fetchConfig());
    }

    render() {
        const {title, products} = this.props;
        const {selectedCategory} = this.state;
        const productsToShow = _.filter(products, (product) => {
            return (product.nft.category === selectedCategory);
        });

        return (
            <RoundedModal title={title}
                          onClose={this.onClose}
                          className={'SupplyStoreModal'}
                          header={(
                              <div className={'RoundedModalHeader'}>
                                  <img src={supplyStoreLogo}
                                       className={'RoundedModalHeader__logo'}
                                  />
                                  <a className={'RoundedModalHeader__close-button'}
                                     onClick={this.onClose}
                                  >
                                      <img src={modalCloseIcon}/>
                                  </a>

                                  <Tabs selectedIndex={this.state.tabIndex}
                                        onSelect={(index) => {
                                            this.setState({tabIndex: index});
                                            if(index === 0){
                                                this.setState({selectedCategory: ContractCategories.PowerUp});
                                            }
                                            else if(index === 1){
                                                this.setState({selectedCategory: ContractCategories.Transphorm});
                                            }
                                            else if(index === 2){
                                                this.setState({selectedCategory: ContractCategories.Phlair});
                                            }
                                            else if(index === 3){
                                                this.setState({selectedCategory: ContractCategories.Blueprint});
                                            }
                                            else if(index === 4){
                                                this.setState({selectedCategory: ContractCategories.Extras});
                                            }
                                        }}
                                        className={'TextTabs'}>
                                      <TabList className={'TextTabList'}>
                                          <Tab>
                                              <span>Power-ups</span>
                                          </Tab>
                                          <Tab>
                                              <span>Transphorm</span>
                                          </Tab>
                                          <Tab>
                                              <span>Phlair</span>
                                          </Tab>
                                          <Tab>
                                              <span>Blueprints</span>
                                          </Tab>
                                          {/*<Tab>*/}
                                          {/*    <span>Extras</span>*/}
                                          {/*</Tab>*/}
                                      </TabList>
                                  </Tabs>
                              </div>
                          )}
            >
                <div className={'SupplyStoreModal__items'}>
                    {
                        _.map(productsToShow, (product) => {
                            return (
                                <ProductCard product={product}
                                             onDetails={this.onBuyProduct}
                                             onBuy={this.onBuyProduct}
                                />
                            )
                        })
                    }
                </div>
                {
                    productsToShow.length === 0 &&
                    <div className={'EmptyState'}>
                        <div className={'EmptyState__title'}>
                            Keep your<br/>eyes peeled
                        </div>
                    </div>
                }
            </RoundedModal>
        );
    }
}

const mapStateToProps = (state, props) => {
    const {action} = props;
    const products = selectSupplyStoreProducts(state);

    return {
        ...props,
        products: products,
        defaultSelectedCategory: props.defaultSelectedCategory
    }
};

export default connect(mapStateToProps)(SupplyStoreModal);

import _ from 'lodash';
import React, {useState, useEffect, useContext} from 'react';
import airdropLoyalist from '../../assets/airdrops/loyalists/loyalist.png';
import Button from "../../components/Button";
import {thetaDropConnect} from "../../store/appState";
import {pushTo} from "../../utils/history";
import User from "../../store/models/user";
import {useDispatch, useSelector} from "react-redux";
import TPMCWarning from "../../components/TPMCWarning";
import {TPMCNavBar} from "../../components/NavBar";
import Airdrop from "../../store/models/airdrop";
import {createSelector} from "reselect";

const selectAirdropActivationBySlug = createSelector(
    (state) => state.models.airdropActivationsBySlug,
    (_, slug) => slug,
    (airdropActivationsBySlug, slug) => airdropActivationsBySlug[slug]
)

function AirdropLoyalistsPage() {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false);
    const airdropActivation = useSelector((state) =>
        selectAirdropActivationBySlug(state, 'loyalists')
    );

    const onActivateClick = async () => {
        // alert('Oops! Loyalists airdrop activations ended Jan 15th at 5 PM PST.');
        // return;

        if(isLoading){
            return;
        }
        setIsLoading(true);
        try {
            const result = await dispatch(Airdrop.actions.createAirdropActivation('loyalists'));
            if(result.status === 'error'){
                alert(result.body.message);
            }
        }
        catch (e) {

        }
        finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        dispatch(Airdrop.actions.fetchAirdropActivation('loyalists'));
    }, []);

    return (
        <div className={'PageWrapper'}>
            <div className="Page Airdrop999ersPage">
                <TPMCNavBar/>
                <div style={{height: 30}}/>
                <div className={'SectionTitle'}>Loyalists Airdrop</div>
                <img src={airdropLoyalist} className={'Airdrop999ersPage__logo'}/>
                <Button title={_.isNil(airdropActivation) ? 'Activate' : 'Activated'}
                        size={'large'}
                        color={_.isNil(airdropActivation) ? 'green-outline' : 'green'}
                        onClick={onActivateClick}
                        isLoading={isLoading}
                        isDisabled={!_.isNil(airdropActivation)}
                        style={{width: 250}}
                />
                {
                    !_.isNil(airdropActivation) &&
                    <div className={'Airdrop999ersPage__all-set'}>
                        You're all set. Thank you for staying loyal to TPMC. Stick around for a big 2023!
                    </div>
                }

                <div className={'Airdrop999ersPage__description'}>
                    <div>ELIGIBILITY</div>
                    <div>Click 'ACTIVATE' before Jan 26th at 5 PM PST.</div>
                    <div>Passaway holder for at least 330 days (From: 01/7/2022 - 01/14/2023)</div>
                    <div>Packs are excluded.</div>
                    <div>ONE airdrop per account.</div>
                    <div>Airdrop date: ~1 week later</div>
                </div>
            </div>
        </div>
    );
}

export default AirdropLoyalistsPage;

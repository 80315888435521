import _ from 'lodash';
import React, {useState, useEffect, useContext} from 'react';
import airdrop999ers from '../../assets/airdrops/999ers/999ers.png';
import airdrop999ersActivated from '../../assets/airdrops/999ers/999ers-activated.png';
import Button from "../../components/Button";
import {thetaDropConnect} from "../../store/appState";
import {pushTo} from "../../utils/history";
import User from "../../store/models/user";
import {useDispatch, useSelector} from "react-redux";
import TPMCWarning from "../../components/TPMCWarning";
import {TPMCNavBar} from "../../components/NavBar";
import Airdrop from "../../store/models/airdrop";
import {createSelector} from "reselect";

const selectAirdropActivationBySlug = createSelector(
    (state) => state.models.airdropActivationsBySlug,
    (_, slug) => slug,
    (airdropActivationsBySlug, slug) => airdropActivationsBySlug[slug]
)

function Airdrop999ersPage() {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false);
    const airdropActivation = useSelector((state) =>
        selectAirdropActivationBySlug(state, '999ers')
    );

    const onActivateClick = async () => {
        alert('Oops! 999ERS airdrop activations ended Jan 14th at 11 AM PST.');
        return;

        if(isLoading){
            return;
        }
        setIsLoading(true);
        try {
            await dispatch(Airdrop.actions.createAirdropActivation('999ers'));
        }
        catch (e) {

        }
        finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        dispatch(Airdrop.actions.fetchAirdropActivation('999ers'));
    }, []);

    return (
        <div className={'PageWrapper'}>
            <div className="Page Airdrop999ersPage">
                <TPMCNavBar/>
                <div style={{height: 30}}/>
                <div className={'SectionTitle'}>999ERS Airdrop</div>
                <img src={_.isNil(airdropActivation) ? airdrop999ers : airdrop999ersActivated} className={'Airdrop999ersPage__logo'}/>
                {/*<Button title={_.isNil(airdropActivation) ? 'Activate' : 'Activated'}*/}
                {/*        size={'large'}*/}
                {/*        color={_.isNil(airdropActivation) ? 'green-outline' : 'green'}*/}
                {/*        onClick={onActivateClick}*/}
                {/*        isLoading={isLoading}*/}
                {/*        isDisabled={!_.isNil(airdropActivation)}*/}
                {/*        style={{width: 250}}*/}
                {/*/>*/}
                {/*{*/}
                {/*    !_.isNil(airdropActivation) &&*/}
                {/*    <div className={'Airdrop999ersPage__all-set'}>*/}
                {/*        You're all set. Remember to HODL your passaway or you may miss the 999ERS airdrop.*/}
                {/*    </div>*/}
                {/*}*/}

                <div className={'Airdrop999ersPage__description'}>
                    <div>ELIGIBILITY</div>
                    {/*<div>Click 'ACTIVATE' before Jan 14th at 11 AM PST.</div>*/}
                    <div>Passaway containment pack must be open.</div>
                    <div>Passaway must be one of the 999 editions from pre-sale.</div>
                    <div>Passaway must be in inventory during snapshot.</div>
                    <div>Passaway must NOT be for sale during snapshot.</div>
                    <div>ONE airdrop per account.</div>
                    <div>Final snapshot date: 01/20/2022</div>
                    <div>Airdrop date: 01/20/2022</div>
                </div>
            </div>
        </div>
    );
}

export default Airdrop999ersPage;

import {useDispatch} from "react-redux";
import PassawayWithMeta from "./PassawayWithMeta";
import {StakePlotPowerUps} from "./StakePlotPowerUps";
import plasmLogo from "../../assets/tokens/plasm-front-small@2x.png";
import circleXRedIcon from "../../assets/icons/circle-x-red@2x.png";
import React from "react";
import UIState from "../../store/uiState";
import {ModalTypes} from "../../constants";
import {RoundedButton} from "../Button";
import {fromWei} from "@thetalabs/theta-js/src/utils";
import {BigNumber} from "ethers";
import {basisPointsToPercent, formatWei} from "../../utils";

// const calculateTotalBonus = (permanentPowerUp, ephemeralPowerUps) => {
//     const permanentPowerUpBN = BigNumber.from(permanentPowerUp?.multiplier || 0);
//     const ephemeralPowerUpsBN = ephemeralPowerUps.reduce((acc, powerUp) => BigNumber.from(powerUp?.multiplier || 0).add(acc), BigNumber.from(0));
//     return permanentPowerUpBN.add(ephemeralPowerUpsBN);
// }

export function StakePlot({nft, rate, tokenMultiplier, permanentPowerUpSlot, permanentPowerUp, ephemeralPowerUpSlots, ephemeralPowerUps, editing, onUnstake}) {
    const dispatch = useDispatch();
    const tokenMultiplierBonus = BigNumber.from(tokenMultiplier).mul(rate).div(10000);
    const pPowerUpBonus = BigNumber.from(permanentPowerUp?.multiplier || 0).mul(rate).div(10000);
    const ePowerUpsBonus = ephemeralPowerUps.reduce((acc, powerUp) => BigNumber.from(powerUp?.multiplier || 0).add(acc), BigNumber.from(0)).mul(rate).div(10000);
    const rateWithMultiplier = BigNumber.from(rate).add(tokenMultiplierBonus).add(pPowerUpBonus).add(ePowerUpsBonus);
    // const bonusBN = calculateTotalBonus(permanentPowerUp, ephemeralPowerUps);
    // const percentage = basisPointsToPercent(bonusBN.toString());
    // let bonusText = percentage === '0.00' ? '' :` + ${percentage}% bonus`;
    const onUnstakeClick = () => {
        dispatch(UIState.actions.showModal(ModalTypes.Alert, {
            title: 'Unstake Passaway',
            body: 'By unstaking your Passaway, you will keep your purchased power-up slots but will lose any active ephemeral power-ups.',
            buttons: [
                <RoundedButton title={'Unstake'}
                               color={'red'}
                               size={'medium'}
                               style={{
                                   width: 132
                               }}
                               onClick={() => {
                                   onUnstake(nft);
                               }}
                />
            ]
        }))
    }

    return (
        <div className={'StakePlot'}>
            <PassawayWithMeta nft={nft}/>
            <StakePlotPowerUps nft={nft}
                               permanentPowerUpSlot={permanentPowerUpSlot}
                               permanentPowerUp={permanentPowerUp}
                               ephemeralPowerUps={ephemeralPowerUps}
                               ephemeralPowerUpSlots={ephemeralPowerUpSlots}
            />
            <div className="StakePlot__rate">
                <img src={plasmLogo}/>
                {/*<div>{`${fromWei(rateWithMultiplier.toString())} PLASM / DAY${bonusText}`}</div>*/}
                <div>{`${formatWei(rateWithMultiplier.toString())} PLASM / DAY`}</div>
            </div>
            {
                editing &&
                <div className={'StakePlot__editing'}
                     onClick={onUnstakeClick}
                >
                    <img src={circleXRedIcon}/>
                    <div>Tap to Unstake</div>
                </div>
            }
        </div>
    );
}
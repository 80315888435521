import _ from 'lodash';
import React, {useState, useEffect, useContext} from 'react';
import vdayCard from '../../assets/events/dreadful-valentine/card.png';
import Button from "../../components/Button";
import NavBar from "../../components/NavBar";
import {Urls} from "../../constants";
import {useDispatch, useSelector} from "react-redux";
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import Event, {selectEventSocialFeed} from "../../store/models/event";

TimeAgo.addDefaultLocale(en);

const timeAgo = new TimeAgo('en-US')

function DreadfulValentineEntry({entry}){
    let {author_username, author_picture, image_url, created_at, link} = entry;
    return (
        <a className={'DreadfulValentineEntry'}
           href={link}
           target={'_blank'}
        >
            <img src={image_url}
                 className={'DreadfulValentineEntry__card'}
            />
            <div className={'DreadfulValentineEntry__avatar-wrapper'}>
                <img src={author_picture}
                     className={'DreadfulValentineEntry__avatar'}
                />
            </div>
            <div className={'DreadfulValentineEntry__username'}>@{author_username}</div>
            <div className={'DreadfulValentineEntry__date'}>{timeAgo.format(new Date(created_at), 'twitter')}</div>
        </a>
    )
}

function DetailsTab({entries}){
    return (
        <React.Fragment>
            <div className={'DreadfulValentineEventPage__welcome'}>Write and share your funniest or darkest Valentine’s message for a chance to be airdropped a custom 1/1 Passaway.</div>
            <div className={'DreadfulValentineEventPage__welcome-subtitle'}>Ends 2/14/22 9 PM PST</div>
            <img src={vdayCard}
                 className={'DreadfulValentineEventPage__card'}
            />
            <div className={'DreadfulValentineEventPage__welcome-subtitle'}>
                Share image, tag @ThePassaways and use #DreadfulValentine for a chance to own a custom 1/1 Passaway.
            </div>
            {/*<Button title='CREATE YOUR CARD'*/}
            {/*        size={'xlarge'}*/}
            {/*        href={Urls.EVENT_DREADFUL_VALENTINE_CREATE}*/}
            {/*        className={'DreadfulValentineEventPage__cta-button'}*/}
            {/*/>*/}

            <div className={'DreadfulValentineEventPage__section-title'}>Dreadful Valentines from Community</div>
            <div className={'DreadfulValentineEventPage__welcome-subtitle'}>
                Feed updates every ~30 min
            </div>
            <div className={'DreadfulValentineEntries'}>
                {
                    _.map(entries, (entry) => {
                        return <DreadfulValentineEntry key={entry.id}
                                                       entry={entry}/>
                    })
                }
            </div>

        </React.Fragment>
    )
}

function DreadfulValentineEventPage() {
    const eventSlug = 'dreadful-valentine';
    const dispatch = useDispatch();
    let entries = useSelector((state) =>
        selectEventSocialFeed(state, eventSlug)
    );
    entries = entries || [];

    useEffect(() => {
        dispatch(Event.actions.fetchEventSocialFeed(eventSlug));
    }, []);

    return (
        <div className={'PageWrapper'}>
            <div className="Page DreadfulValentineEventPage">
                <NavBar/>
                <div style={{height: 30}}/>
                <div className={'DreadfulValentineEventPage__title'}>Dreadful Valentine</div>
                <DetailsTab entries={entries}/>
            </div>
        </div>
    );
}

export default DreadfulValentineEventPage;

import React from 'react';
import {connect} from 'react-redux'
import Modal from '../../components/Modal';
import logo from '../../assets/logo/misadventure-club-white.svg';
import UIState from "../../store/uiState";
import {selectCurrentUser} from "../../store/models/user";


export class WelcomeModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false
        };
    }

    onClose = () => {
        this.props.dispatch(UIState.actions.hideModal());
    }

    render() {
        const {user} = this.props;
        const {isLoading} = this.state;

        return (
            <Modal>
                <div className="WelcomeModal">
                    <div className={'WelcomeModal__logo'}>
                        <img src={logo}/>
                    </div>
                    <div className={'WelcomeModal__welcome'}>
                        {`Welcome, ${user.username}`}
                    </div>
                    <div className={'WelcomeModal__misadventure-title'}>
                        MISADVENTURE
                        <br/>
                        LIES AHEAD
                    </div>
                    <div className={'WelcomeModal__message'}>
                        We’re so excited to have you on board. We have many exciting plans in store so stay tuned, we’re just getting started.
                        <br/><br/>
                        Thank you for your support!
                    </div>
                    <a className={'WelcomeModal__continue'}
                       onClick={this.onClose}
                    >Continue</a>
                </div>
            </Modal>
        )
    }
}


const mapStateToProps = state => {
    return {
        user: selectCurrentUser(state)
    }
};

export default connect(mapStateToProps)(WelcomeModal);

import { useCallback } from "react";

const useAutoFocus = (autoFocus) => {
    const inputRef = useCallback((inputElement) => {
        if (inputElement && autoFocus) {
            inputElement.focus();
        }
    }, [autoFocus]);

    return inputRef;
};

export default useAutoFocus;
import React, {Fragment, useState} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux'
import Modal, {RoundedModal} from '../../components/Modal';
import UIState from "../../store/uiState";
import _ from "lodash";
import {
    CurrentNetwork,
    ModalTypes,
} from "../../constants";
import modalCloseIcon from "../../assets/icons/modal-close@2x.png";
import supplyStoreLogo from "../../assets/stake/supply-store/logo@2x.png";
import {Tab, TabList, Tabs} from "react-tabs";
import {
    getContractAddressByKey,
    selectContract,
    selectCraftableItems, selectRedeemables,
    selectSupplyStoreProduct,
    selectSupplyStoreProducts
} from "../../store/models/config";
import Wallet, {actions, selectRedemptions} from "../../store/models/wallet";
import {ProductCard} from "../../components/staking/ProductCard";
import {CraftableItemCard} from "../../components/staking/CraftableItemCard";
import {RoundedButton} from "../../components/Button";
import {CraftableItemInputCard} from "../../components/staking/CraftableItemInputCard";
import {formatBalance, formatBlocksToTime, formatWei} from "../../utils";
import backCaretIcon from '../../assets/icons/back-caret@2x.png';
import {selectNftBalance} from "../../store/models/nft";
import {store} from "../../store";
import classNames from "classnames";
import {RedeemableCard} from "../../components/staking/RedeemableCard";
import Countdown, {zeroPad} from "react-countdown";
import {getNFTImage} from "../../utils/nfts";
import {BigNumber} from "ethers";
import {RequiredApprovals} from "../../components/staking/RequiredApprovals";



const countdownRenderText = ({ days, hours, minutes, seconds, completed }) => {
    if(days > 0){
        return `${zeroPad(days)}:${zeroPad(hours)}:${zeroPad(minutes)}:${zeroPad(seconds)}`;
    }
    if(hours > 0){
        return `${zeroPad(hours)}:${zeroPad(minutes)}:${zeroPad(seconds)}`;
    }
    return `${zeroPad(minutes)}:${zeroPad(seconds)}`;
}

const renderUnlocksAt = (countDownData) => {
    if(countDownData.completed){
        return 'Ready to reclaim';
    }

    return (
        `Unlocks in ${countdownRenderText(countDownData)}`
    )
}

const renderAvailableIn = (countDownData) => {
    if(countDownData.completed){
        return null;
    }

    return (
        <div className={'RoundedModalHeader__available-in'}>
            {`Available in ${countdownRenderText(countDownData)}`}
        </div>
    )
}

const renderAvailableFor = (countDownData) => {
    if(countDownData.completed){
        return null;
    }

    return (
        <div className={'RoundedModalHeader__available-for'}>
            {`Available for ${countdownRenderText(countDownData)}`}
        </div>
    )
}

export class RedeemingModal extends React.Component {
    constructor(props) {
        super();

        const {defaultSelectedRedeemableId} = props;

        this.state = {
            selectedRedeemableId: defaultSelectedRedeemableId,
            isRedeemed: false,
            tabIndex: 0
        }
    }

    onClose = () => {
        this.props.dispatch(UIState.actions.hideModal());
    }

    onSelectRedeemable = (redeemable) => {
        this.setState({
            selectedRedeemableId: redeemable.id
        })
    }

    getRedeemable = (id) => {
        const {redeemables} = this.props;

        return _.find(redeemables, (redeemable) => {
            return (id === redeemable.id)
        });
    }

    onRedeem = async () => {
        const {selectedRedeemableId} = this.state;
        const redeemable = this.getRedeemable(selectedRedeemableId);
        const success = await this.props.dispatch(Wallet.actions.redeem(redeemable));
        this.setState({
            isRedeemed: success
        })
    }

    onBuy = (nft) => {
        this.props.dispatch(UIState.actions.showNFTPurchaseModal(nft));
    }

    onReclaimRedemption = async (redemption) => {
        const {dispatch} = this.props;
        const success = await dispatch(Wallet.actions.reclaimRedemption(redemption.redeemableId));

        if(success){
            dispatch(Wallet.actions.getAllRedemptions());
        }
    }

    onBack = () => {
        this.setState({
            selectedRedeemableId: null,
            isRedeemed: false
        })
    }

    render() {
        const {redeemables, redemptions, dispatch} = this.props;
        const {selectedRedeemableId, isRedeemed, tabIndex} = this.state;
        const selectedRedeemable = this.getRedeemable(selectedRedeemableId);
        const hasSelectedRedeemableStarted = selectedRedeemable ? (selectedRedeemable.startsAt && (new Date(selectedRedeemable.startsAt)).getTime() < Date.now()) : false;

        return (
            <RoundedModal title={selectedRedeemable ? 'Redeem' : 'Redeemables'}
                          onClose={this.onClose}
                          className={classNames('RedeemingModal', {
                              'RedeemingModal--redeemed': isRedeemed
                          })}
                          header={(
                              <div className={classNames('RoundedModalHeader', {
                                  'RoundedModalHeader--expanded': _.isNil(selectedRedeemable)
                              })}>
                                  <div className={'RoundedModalHeader__title'}>
                                      {selectedRedeemable ? 'Redeem' : 'Redeemables'}
                                  </div>
                                  {
                                      selectedRedeemable &&
                                      <Fragment>
                                          {
                                              !hasSelectedRedeemableStarted &&
                                              <Countdown
                                                  date={(new Date(selectedRedeemable.startsAt))}
                                                  renderer={renderAvailableIn}
                                              />
                                          }
                                          {
                                              hasSelectedRedeemableStarted &&
                                              <Countdown
                                                  date={(new Date(selectedRedeemable.endsAt))}
                                                  renderer={renderAvailableFor}
                                              />
                                          }
                                      </Fragment>
                                  }
                                  <a className={'RoundedModalHeader__close-button'}
                                     onClick={this.onClose}
                                  >
                                      <img src={modalCloseIcon}/>
                                  </a>

                                  {
                                      _.isNil(selectedRedeemable) &&
                                      <Tabs selectedIndex={this.state.tabIndex}
                                            onSelect={(index) => {
                                                this.setState({tabIndex: index});

                                                if(index === 1){
                                                    dispatch(Wallet.actions.getAllRedemptions());
                                                }
                                            }}
                                            className={'TextTabs'}>
                                          <TabList className={'TextTabList'}>
                                              <Tab>
                                                  <span>Redeem</span>
                                              </Tab>
                                              <Tab>
                                                  <span>Redemptions</span>
                                              </Tab>
                                          </TabList>
                                      </Tabs>
                                  }

                              </div>
                          )}
            >
                {
                    (tabIndex === 0) &&
                    <React.Fragment>
                        {
                            isRedeemed &&
                            <div className={'RedeemedContent'}>
                                <div className={'RedeemedContent__result animate__animated animate__pulseSmall animate__slow animate__infinite'}>
                                    <img src={selectedRedeemable.redeemedImage}/>
                                </div>
                                <div className={'RedeemedContent__title'}>{selectedRedeemable.redeemedTitle}</div>
                                <div className={'RedeemedContent__description'}>{selectedRedeemable.redeemedSubtitle}</div>
                                <div>
                                    <RoundedButton title={`Continue`}
                                                   color={'green'}
                                                   size={'medium'}
                                                   className={'RedeemedContent__done-button'}
                                                   onClick={this.onBack}
                                    />
                                </div>
                            </div>
                        }
                        {
                            (!_.isNil(selectedRedeemable) && !isRedeemed) &&
                            <div className={'RedeemingContent'}>
                                <a className={'RedeemingContent__back-button'}
                                   onClick={this.onBack}
                                >
                                    <img src={backCaretIcon}/>
                                    <div>Back</div>
                                </a>
                                <div className={'RedeemingContent__title'}>{selectedRedeemable.outputName}</div>
                                <div className={'RedeemingContent__description'}>{selectedRedeemable.outputDescription}</div>
                                <div className={classNames('RedeemingContent__inputs', `RedeemingContent__inputs--${selectedRedeemable.inputNfts.length}`)}>
                                    {
                                        _.map(selectedRedeemable.inputNfts, (nft) => {
                                            return (
                                                <CraftableItemInputCard nft={nft}
                                                                        onBuy={this.onBuy}
                                                />
                                            )
                                        })
                                    }
                                </div>
                                <div>
                                    <RoundedButton title={hasSelectedRedeemableStarted ? (selectedRedeemable.burnable ? 'Burn & Redeem' : 'Lock & Redeem') : "SOON"}
                                                   color={'green'}
                                                   size={'medium'}
                                                   className={'RedeemingContent__redeem-button'}
                                                   disabled={!hasSelectedRedeemableStarted || selectedRedeemable.canRedeem === false}
                                                   onClick={this.onRedeem}
                                    />
                                </div>
                                {
                                    selectedRedeemable.burnable &&
                                    <div className={'RedeemingContent__warning'}>
                                        Warning: These NFTs will be burned.
                                    </div>
                                }
                                {
                                    !selectedRedeemable.burnable &&
                                    <div className={'RedeemingContent__warning'}>
                                        {`Warning: ${selectedRedeemable.inputNfts.length === 1 ? 'This NFT' : 'These NFTs'} will be locked for ${formatBlocksToTime(selectedRedeemable.lockDuration, true)}.`}
                                    </div>
                                }
                                <RequiredApprovals spenderAddress={getContractAddressByKey('redeeming')}
                                                   nftAddresses={selectedRedeemable.inputNftContracts}
                                />

                            </div>
                        }
                        {
                            _.isNil(selectedRedeemable) &&
                            <div className={'RedeemingModal__items'}>
                                {
                                    _.map(redeemables, (redeemable) => {
                                        return (
                                            <RedeemableCard redeemable={redeemable}
                                                            onRedeem={this.onSelectRedeemable}
                                                            onDetails={() => {
                                                                this.onSelectRedeemable(redeemable);
                                                            }}
                                            />
                                        )
                                    })
                                }
                            </div>
                        }
                    </React.Fragment>
                }
                {
                    (tabIndex === 1) &&
                    <React.Fragment>
                        <div className={'RedemptionsContent'}>
                            {
                                _.map(redemptions, (redemption) => {
                                    return (
                                        <div className={'RedemptionCard'}>
                                            {
                                                (redemption.burnedTokenIds.length > 0) &&
                                                <div className={'RedemptionCard__info'}>
                                                    <div className={'RedemptionCard__locked'}>
                                                        {`${redemption.burnedTokenIds.length} ${redemption.burnedTokenIds.length === 1 ? 'NFT' : 'NFTs'} Burned`}
                                                    </div>
                                                    <div className={'RedemptionCard__unlocks'}>
                                                        {`No lock`}
                                                    </div>
                                                </div>
                                            }
                                            {
                                                (redemption.lockedTokenIds.length > 0) &&
                                                <div className={'RedemptionCard__info'}>
                                                    <div className={'RedemptionCard__locked'}>
                                                        {`${redemption.lockedTokenIds.length} ${redemption.lockedTokenIds.length === 1 ? 'NFT' : 'NFTs'} Locked`}
                                                    </div>
                                                    <div className={'RedemptionCard__unlocks'}>
                                                        <Countdown
                                                            date={(new Date(redemption.lockEndsAt))}
                                                            renderer={renderUnlocksAt}
                                                        />
                                                    </div>
                                                </div>
                                            }

                                            {
                                                (redemption.lockedTokenIds.length > 0) &&
                                                <RoundedButton title={redemption.claimed ? 'Claimed' : 'Reclaim'}
                                                               className={'RedemptionCard__button'}
                                                               color={'green'}
                                                               size={'small'}
                                                               onClick={() => {
                                                                   this.onReclaimRedemption(redemption);
                                                               }}
                                                               disabled={redemption.claimed || redemption.lockEndsAt > Date.now()}
                                                />
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </React.Fragment>
                }
            </RoundedModal>
        );
    }
}

const mapStateToProps = (state, props) => {
    const {action} = props;
    let redeemables = selectRedeemables(state);
    let redemptions = selectRedemptions(state);

    const canRedeem = (redeemable) => {
        const requiredByAddress = _.countBy(redeemable.inputNftContracts, _.identity);
        let canRedeem = true;

        for (const address in requiredByAddress) {
            const requiredAmt = requiredByAddress[address];

            if(requiredAmt > selectNftBalance(state, address)){
                canRedeem = false;
                break;
            }
        }
        return canRedeem;
    }
    redeemables = _.map(redeemables, (redeemable) => {
        let outputContractInfo = selectContract(state, redeemable.outputNftContract);
        let outputPlasmWei = redeemable.outputPlasmWei;
        let isPLASMOutput = outputPlasmWei !== '0';
        let outputNFTImage = getNFTImage(outputContractInfo?.key);

        return Object.assign({}, redeemable, {
            outputName: isPLASMOutput ? `${formatWei(outputPlasmWei)} PLASM` : outputContractInfo.name,
            outputDescription: isPLASMOutput ? `PLASM will be sent to your wallet` : outputContractInfo.description,
            outputImage: isPLASMOutput ? `/tokens/plasm-shadow@2x.png` : outputNFTImage,
            redeemedImage: isPLASMOutput ? `/tokens/plasm-shadow@2x.png` : outputNFTImage,
            redeemedTitle: isPLASMOutput ? `${formatWei(outputPlasmWei)} PLASM` : outputContractInfo?.redeemedTitle,
            redeemedSubtitle: isPLASMOutput ? `Ready to use or stake` : outputContractInfo?.redeemedSubtitle,
            outputNft: (isPLASMOutput ? null : {
                ...outputContractInfo,
                image: outputNFTImage
            }),
            inputNfts: _.map(redeemable.inputNftContracts, (inputNftContract) => {
                let contractInfo = selectContract(state, inputNftContract);
                return {
                    ...contractInfo,
                    image: getNFTImage(contractInfo.key),
                    balance: selectNftBalance(state, contractInfo.address)
                }
            }),
            canRedeem: canRedeem(redeemable)
        })
    });
    _.remove(redeemables, _.isNil);

    redemptions = _.orderBy(redemptions, (redemption) => {
        return parseInt(redemption.claimBlock.toString());
    }, 'desc');

    return {
        ...props,
        redeemables: redeemables,
        redemptions: redemptions,
        defaultSelectedRedeemableId: props.defaultSelectedRedeemableId
    }
};

export default connect(mapStateToProps)(RedeemingModal);

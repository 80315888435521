import React from "react";
import plasmLogo from "../../assets/tokens/plasm-front-small@2x.png";
import caretIcon from "../../assets/icons/caret-down-green@2x.png";
import {useDispatch, useSelector} from "react-redux";
import UIState from "../../store/uiState";
import {ModalTypes} from "../../constants";
import {selectCurrentUserId} from "../../store/models/user";
import {selectBalances} from "../../store/models/wallet";
import * as thetajs from '@thetalabs/theta-js';
import {formatBalance, numberWithCommas} from "../../utils";
import classNames from "classnames";

const PlasmBalanceColumn = ({title, balance, type}) => {
    return (
        <div className={classNames('PlasmBalanceColumn', `PlasmBalanceColumn--${type}`)}>
            <div className={'PlasmBalanceColumn__title'}>{title}</div>
            <div className={'PlasmBalanceColumn__balance'}>{balance}</div>
        </div>
    )
}

export default function PlasmBalances({}) {
    const dispatch = useDispatch();
    const balances = useSelector(selectBalances);
    const {plasmPending, plasmOnHand, plasmStaked} = balances;
    const onClick = () => {
        dispatch(UIState.actions.showModal(ModalTypes.Plasm));
    };

    return (
        <a className={`PlasmBalances`}
           onClick={onClick}
        >
            <img className={'PlasmBalances__logo'}
                 src={plasmLogo}/>
            <PlasmBalanceColumn title={'Pending'}
                                balance={formatBalance(plasmPending)}
                                type={'pending'}
            />
            <PlasmBalanceColumn title={'On hand'}
                                balance={formatBalance(plasmOnHand)}
                                type={'on-hand'}
            />
            <PlasmBalanceColumn title={'Staked'}
                                balance={formatBalance(plasmStaked)}
                                type={'staked'}
            />
            <div className={'PlasmBalances__caret'}>
                <img src={caretIcon}/>
            </div>
        </a>
    );
}
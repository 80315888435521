import _ from 'lodash';
import {post, get, put, authHeaders} from "../../utils/fetch";
import {Urls, RequestState, Hosts} from "../../constants";
import {selectCurrentUserId} from "./user";

// ===========================
// MODEL
// ===========================
const Analytics = {
    actions: {
        track: (action, data) => async (dispatch, getState) => {
            try {
                const address = selectCurrentUserId(getState());
                return post({
                    url: "/v1/a/event",
                    body: {
                        address: address,
                        action: action,
                        data: data
                    }
                });
            }
            catch (e) {
                console.error(e);
                return null;
            }
        }
    },
}
export default Analytics;

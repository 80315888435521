import _ from 'lodash';
import React from "react";
import classNames from "classnames";
import {getNFTImage, getNFTImageThumb} from "../../utils/nfts";

export function ActivePermaPowerUp({powerUp, size}) {
    const {nftContract, multiplier} = powerUp;
    const imageSrc = _.isNil(size) ? getNFTImageThumb(nftContract) : getNFTImage(nftContract);
    return (
        <div className={classNames({
            'ActivePowerUp': true,
            'ActivePowerUp--Permanent': true,
            [`ActivePowerUp--${size}`]: true
        })}>
            <img src={imageSrc}/>
        </div>
    );
}
import Countdown, {zeroPad} from "react-countdown";
import {formatWei} from "../../utils";
import {RoundedButton} from "../Button";
import React from "react";

const countdownRenderText = ({ days, hours, minutes, seconds, completed }) => {
    if(days > 0){
        return `${zeroPad(days)}:${zeroPad(hours)}:${zeroPad(minutes)}:${zeroPad(seconds)}`;
    }
    if(hours > 0){
        return `${zeroPad(hours)}:${zeroPad(minutes)}:${zeroPad(seconds)}`;
    }
    return `${zeroPad(minutes)}:${zeroPad(seconds)}`;
}

const renderAvailableIn = (countDownData) => {
    if(countDownData.completed){
        return null;
    }

    return (
        <div className={'CraftableItemCard__available-in'}>
            {`Available in ${countdownRenderText(countDownData)}`}
        </div>
    )
}

const renderAvailableFor = (countDownData) => {
    if(countDownData.completed){
        return null;
    }
    if(countDownData.days > 14){
        return null;
    }

    return (
        <div className={'CraftableItemCard__available-for'}>
            {`Available for ${countdownRenderText(countDownData)}`}
        </div>
    )
}

export const CraftableItemCard = ({craftableItem, onDetails, onCraft}) => {
    const {price, remaining, start, end, startsAt, endsAt, enabled, outputNft} = craftableItem;
    const hasStarted = startsAt && (new Date(startsAt)).getTime() < Date.now();

    return (
        <div className={'CraftableItemCard'}
             onClick={onDetails}
        >
            {
                remaining > 0 &&
                <div className={'NumberBadge'}>{`${remaining} LEFT`}</div>
            }
            <img src={outputNft.image}
                 className={'CraftableItemCard__image'}
            />
            <div className={'CraftableItemCard__name'}>{outputNft.name}</div>
            {
                (outputNft.description || outputNft.descriptionShort) &&
                <div className={'CraftableItemCard__description'}>{outputNft.descriptionShort || outputNft.description}</div>
            }
            {
                !hasStarted && startsAt &&
                <Countdown
                    date={(new Date(startsAt))}
                    renderer={renderAvailableIn}
                />
            }
            {
                hasStarted && endsAt &&
                <Countdown
                    date={(new Date(endsAt))}
                    renderer={renderAvailableFor}
                />
            }
            <div className={'CraftableItemCard__footer'}>
                <RoundedButton title={'Craft'}
                               onClick={(e) => {
                                   e.preventDefault();

                                   onCraft(craftableItem)
                               }}
                               color={'green'}
                />
            </div>
        </div>
    );
}
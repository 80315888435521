export const USER_ID = 'TD_USER_ID';
export const USER_TOKEN = 'TPMC_USER_TOKEN';
export const HAS_VISITED = 'TPMC_HAS_VISITED';

const Storage = {
    getItem: key => {
        if (typeof window !== 'undefined') {
            return localStorage.getItem(key)
        }
    },

    setItem: (key, value) => {
        if (typeof window !== 'undefined') {
            localStorage.setItem(key, value);
        }
    },

    clearItem: (key, value) => localStorage.removeItem(key),
    clearUserAuth: () => {
        localStorage.removeItem(USER_ID);
        localStorage.removeItem(USER_TOKEN);
    },

    getUserToken: () => {
        return Storage.getItem(USER_TOKEN)
    },
    setUserToken: (value) => Storage.setItem(USER_TOKEN, value),

    getUserId: () => {
        return Storage.getItem(USER_ID);
    },
    setUserId: (value) => Storage.setItem(USER_ID, value),
}
export default Storage;

import _ from 'lodash';
import {post, get, put, authHeaders} from "../../utils/fetch";
import {Urls, RequestState, Hosts} from "../../constants";

// ===========================
// HELPERS
// ===========================


// ACTIONS
// ===========================
export const FETCH_EVENT_LEADERBOARD = 'FETCH_EVENT_LEADERBOARD';
export const FETCH_EVENT_SOCIAL_FEED = 'FETCH_EVENT_SOCIAL_FEED';
export const FETCH_EVENT_VOTES = 'FETCH_EVENT_VOTES';
export const CREATE_EVENT_VOTE = 'CREATE_EVENT_VOTE';
export const FETCH_EVENT_THETACON22_ELIGIBILITY = 'FETCH_EVENT_THETACON22_ELIGIBILITY';
export const CLAIM_EVENT_THETACON22 = 'CLAIM_EVENT_THETACON22';

// ===========================
// SELECTORS
// ===========================
export const selectEventLeaderboard = (state, slug) => state.models.eventLeaderboardsBySlug[slug];
export const selectEventSocialFeed = (state, slug) => state.models.eventSocialFeedBySlug[slug];
export const selectEventVotes = (state, slug) => state.models.eventVotesBySlug[slug];
export const selectEventVote = (state, slug) => state.models.eventVoteBySlug[slug];

// ===========================
// MODEL
// ===========================
const Event = {
    actions: {
        fetchEventLeaderboard: (slug) => async (dispatch, getState) => {
            return get({
                url: "/v1/events/leaderboard",
                params: {
                    slug: slug,
                    only_eligible: true
                },
                headers: {
                    'x-session-token': _.get(getState(), 'app.userToken')
                },
                dispatch,
                action: FETCH_EVENT_LEADERBOARD,
            }).then(results => results?.body?.leaders);
        },
        fetchEventSocialFeed: (slug) => async (dispatch, getState) => {
            return get({
                url: "/v1/events/social-feed",
                params: {
                    slug: slug
                },
                headers: {
                    'x-session-token': _.get(getState(), 'app.userToken')
                },
                dispatch,
                action: FETCH_EVENT_SOCIAL_FEED,
            }).then(results => results?.body?.posts);
        },
        fetchEventVotes: (slug) => async (dispatch, getState) => {
            return get({
                url: "/v1/events/votes",
                params: {
                    slug: slug
                },
                headers: {
                    'x-session-token': _.get(getState(), 'app.userToken')
                },
                dispatch,
                action: FETCH_EVENT_VOTES,
            }).then(results => results?.body?.votes);
        },
        createEventVote: (slug, entry) => async (dispatch, getState) => {
            return post({
                url: "/v1/events/votes",
                body: {
                    slug: slug,
                    entry: entry
                },
                headers: {
                    'x-session-token': _.get(getState(), 'app.userToken')
                },
                dispatch,
                action: CREATE_EVENT_VOTE,
            }).then(results => results?.body?.vote);
        },
        fetchThetaCon22RaffleTicketEligibility: () => async (dispatch, getState) => {
            return get({
                url: "/v1/events/thetacon22/eligibility",
                headers: {
                    'x-session-token': _.get(getState(), 'app.userToken')
                },
                dispatch,
                action: FETCH_EVENT_THETACON22_ELIGIBILITY,
            }).then(results => results?.body);
        },
        claimThetaCon22RaffleTicket: (location) => async (dispatch, getState) => {
            return post({
                url: "/v1/events/thetacon22/claim",
                body: {
                    location: location
                },
                headers: {
                    'x-session-token': _.get(getState(), 'app.userToken')
                },
                dispatch,
                action: CLAIM_EVENT_THETACON22,
            }).then(results => results?.body);
        }
    },

    spec: {
        eventLeaderboardsBySlug: {},
        eventSocialFeedBySlug: {},
        eventVotesBySlug: {},
        eventVoteBySlug: {}
    },

    modelReducer: (state, type, body, action) => {
        if (action.url && action.result !== RequestState.SUCCESS)
            return state;

        if (type === FETCH_EVENT_LEADERBOARD) {
            return {
                ...state,
                eventLeaderboardsBySlug: {
                    ...state.eventLeaderboardsBySlug,
                    [body.slug]: body.leaders
                },
            }
        }
        else if (type === FETCH_EVENT_SOCIAL_FEED) {
            return {
                ...state,
                eventSocialFeedBySlug: {
                    ...state.eventSocialFeedBySlug,
                    [body.slug]: body.posts
                },
            }
        }
        else if (type === FETCH_EVENT_VOTES || type === CREATE_EVENT_VOTE) {
            return {
                ...state,
                eventVotesBySlug: {
                    ...state.eventVotesBySlug,
                    [body.slug]: body.votes
                },
                eventVoteBySlug: {
                    ...state.eventVoteBySlug,
                    [body.slug]: body.vote
                },
            }
        }

        return state;
    }
}
export default Event;

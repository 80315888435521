import React, {useState} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux'
import UIState from "../../store/uiState";
import ProductModal from "./Product";
import StakingTierBadge from "../../components/staking/StakingTierBadge";
import {ActiveEphemeralPowerUp} from "../../components/staking/ActiveEphemeralPowerUp";
import {EventCountdown, PowerUpTypes} from "../../constants";
import Countdown, {zeroPad} from "react-countdown";
import {RoundedButton} from "../../components/Button";
import {selectContract} from "../../store/models/config";
import {ActivePermaPowerUp} from "../../components/staking/ActivePermaPowerUp";
import alertIcon from "../../assets/icons/alert-red@2x.png";
import {RoundedModal} from "../../components/Modal";
import _ from "lodash";
import {InventoryItemCard} from "../../components/staking/InventoryItemCard";

export class AlertModal extends React.Component {
    onClose = () => {
        this.props.dispatch(UIState.actions.hideModal());

        if(this.props.onClose){
            this.props.onClose();
        }
    }

    render() {
        const {title, body, buttons} = this.props;
        return (
            <RoundedModal title={title}
                          className={'AlertModal'}
                          onClose={this.onClose}
            >
                <img className={'AlertModal__icon'}
                     src={alertIcon}
                />
                <div className={'AlertModal__body'}>
                    {body}
                </div>
                <div className={'AlertModal__footer'}>
                    {buttons}
                </div>
            </RoundedModal>
        );
    }
}

const mapStateToProps = (state, props) => {
    const {title, body, buttons} = props;

    return {
        ...props,
    }
};

export default connect(mapStateToProps)(AlertModal);

import React, {useState, useEffect, useContext} from 'react';
import Modal from 'react-modal';
import Countdown, {zeroPad} from 'react-countdown';
import NavBar from "../components/NavBar";
import netpoLogo from '../assets/logo/netpo-labs@2x.png'
import logo from '../assets/logo/the-passaways-combined.svg';
import logoCharacterRight from '../assets/logo/the-passaways-angel.svg';
import logoCharacterLeft from '../assets/logo/the-passaways-devil.svg';
import floatingCharacterLeft from '../assets/characters/passaway-left@2x.png';
import floatingCharacterRight from '../assets/characters/passaway-right@2x.png';
import floatingCharacterLeftShadow from '../assets/characters/passaway-left-shadow@2x.png';
import floatingCharacterRightShadow from '../assets/characters/passaway-right-shadow@2x.png';
import collectionInfo from '../assets/collection/collection-info.svg';
import collectionInfoBackground from '../assets/backgrounds/editions-background@2x.png';
import sectionSeparator from '../assets/section/section-separator@2x.png';
import tfuelReward from '../assets/rewards/tfuel@2x.png';
import greyReward from '../assets/rewards/grey@2x.png';
import greenReward from '../assets/rewards/green@2x.png';
import redReward from '../assets/rewards/red@2x.png';
import previewWeb from '../assets/previews/preview-web.jpg';
import previewMobile from '../assets/previews/preview-mobile.jpg';
import mikeAvatar from '../assets/team/mike@2x.png';
import { Accordion, AccordionItem } from 'react-light-accordion';
import Button from "../components/Button";
import packVideo  from "../assets/videos/pack.mp4";
import filmPreviewVideo  from "../assets/videos/film-preview.mp4";
import {TwitterUrl, DiscordUrl, EventCountdown, Urls} from '../constants';
import twitterIcon from "../assets/social/twitter-grey@2x.png";
import discordIcon from "../assets/social/discord-grey@2x.svg";
import NFTCalendarIcon from "../assets/as-seen-on/nft-cal@2x.png";
import RoadmapDotGreen from "../assets/icons/roadmap-dot-green.svg";
import RoadmapDotGrey from "../assets/icons/roadmap-dot-grey.svg";
import NFTDrop from "../components/staking/NFTDrop";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'transparent',
        border: 'none',
        outline: 'none'
    },
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.95)',
        zIndex: 1000000
    }
};

const classNames = require('classnames');

function FloatingCharacter({src, shadowSrc}){
    return (
        <div className={'FloatingCharacter'}>
            <img src={src}/>
            <img src={shadowSrc}
                 className={'FloatingCharacter__shadow'}/>
        </div>
    )
}

function CountdownWrapper(){
    const [isCompleted, setCompleted] = useState(false);

    const renderTimeComponent = (title, value) => {
        return (
            <div className={'CountdownTimeComponent'}>
                <div className={'CountdownTimeComponent__value'}>
                    {zeroPad(value)}
                </div>
                <div className={'CountdownTimeComponent__title'}>
                    {title}
                </div>
            </div>
        );
    }
    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            setCompleted(true);
            // Render a completed state
            return <div className={'CountdownEnded'}>SOLD OUT</div>;
        } else {
            // Render a countdown
            return (
                <div className={'Countdown'}>
                    {
                        renderTimeComponent('DAYS', days)
                    }
                    {
                        renderTimeComponent('HRS', hours)
                    }
                    {
                        renderTimeComponent('MINS', minutes)
                    }
                    {
                        renderTimeComponent('SECS', seconds)
                    }
                </div>
            )
        }
    };

    return (
        <React.Fragment>
            <Countdown
                date={EventCountdown.date}
                renderer={renderer}
            />
            <div className={'CountdownUntil'}>
                <div className={'CountdownUntil__date'}>{EventCountdown.subtitle}</div>
                <div className={'CountdownUntil__title'}>{EventCountdown.title}</div>
            </div>
            {
                !isCompleted && EventCountdown.cta &&
                <Button title={EventCountdown.cta.title}
                        size={'xlarge'}
                        style={{marginTop: 30}}
                        href={EventCountdown.cta.href}
                        target={'_blank'}
                />
            }
            {
                isCompleted && EventCountdown.cta &&
                <Button title={'View Marketplace'}
                        size={'xlarge'}
                        style={{marginTop: 30}}
                        href={Urls.THETA_DROP_MICROSITE}
                        target={'_blank'}
                />
            }
        </React.Fragment>

    )
}

function CompanyLogo() {
    return (
        <div className={'CompanyLogo'}>
            <img src={netpoLogo}
            />
            <div className={'CompanyLogo__words'}>
                Never Eat
                <br/>
                The Purple One
            </div>
        </div>
    );
}

function Logo() {
    return (
        <div className={'Logo'}
             id={'logo'}
        >
            <img src={logo}
                 className={'LogoWords'}
            />
            {/*<div className={'LogoCharacters'}>*/}
            {/*    <img src={logoCharacterLeft}*/}
            {/*         className={'LogoCharacters__left'}*/}
            {/*    />*/}
            {/*    <img src={logoCharacterRight}*/}
            {/*         className={'LogoCharacters__right'}*/}
            {/*    />*/}
            {/*</div>*/}
        </div>
    );
}

function CollectionSection() {
    return (
        <div className={'CollectionSection'}>
            <div className={'CollectionInfo HideOnNonMobile'}>
                <img src={collectionInfoBackground}
                     className={'CollectionInfo__background'}/>
                <img src={collectionInfo}
                     className={'CollectionInfo__info'}/>
            </div>
            <div className={'CollectionSection__body'}>
                <FloatingCharacter src={floatingCharacterLeft}
                                   shadowSrc={floatingCharacterLeftShadow}
                />
                <div className={'CollectionInfo HideOnMobile'}>
                    <img src={collectionInfoBackground}
                         className={'CollectionInfo__background'}/>
                    <img src={collectionInfo}
                         className={'CollectionInfo__info'}/>
                </div>
                <FloatingCharacter src={floatingCharacterRight}
                                   shadowSrc={floatingCharacterRightShadow}
                />
            </div>
        </div>
    );
}

function TheProjectSection() {
    return (
        <div id={"project"}
             className={'TheProjectSection'}>
            <img src={sectionSeparator}
            className={'SectionSeparator'}/>
            <div className={'SectionTitle'}>
                The Project
            </div>
            <div className={'TheProjectSection__body'}>
                <div className="TheProjectSection__pack">
                    <video loop autoPlay playsInline muted src={packVideo} />
                </div>
                <div className={'TheProjectSection__text'}>
                    There are exactly 9,666 unique Passaways with some rarer than others. 299 Passaways are being withheld from the sale and will be used for giveaways, puzzle rewards, and the team.
                    <br/>
                    <br/>
                    Each Passaway is programmatically generated from over 300 possible traits. No Passaway is the same.
                    <br/>
                    <br/>
                    The Passaways were captured and stored as TNT-721 tokens on the Theta blockchain.
                    {/*<br/>*/}
                    {/*<br/>*/}
                    {/*Purchasing a passaway will cost 666 TFUEL or approx $199.*/}
                </div>
            </div>
        </div>
    );
}

function Reward({src, name, description}) {
    return (
        <div className={'Reward'}>
            <img src={src}
                 className={'Reward__image'}
            />
            <div className={'Reward__name'}>
                {name}
            </div>
            <div className={'Reward__description'}>
                {description}
                <br/>
                airdrop
            </div>
        </div>
    );
}

function RewardsSection() {
    return (
        <div id={"rewards"}
             className={'RewardsSection'}>
            <img src={sectionSeparator}
                 className={'SectionSeparator'}/>
            <div className={'SectionTitle'}>
                Rewards
            </div>
            <div className={'RewardsSection__body'}>
                <Reward src={tfuelReward}
                        name={'BUYING WITH TFUEL'}
                        description={'Receive a TFuel-inspired'}
                />
                <Reward src={greyReward}
                        name={'PURCHASE 5+'}
                        description={'Receive a Rare'}
                />
                <Reward src={greenReward}
                        name={'PURCHASE 10+'}
                        description={'Receive a Legendary'}
                />
            </div>

        </div>
    );
}

function RoadmapItem({title, percentage, isCompleted}) {
    return (
        <div className={classNames('RoadmapItem', {
            'RoadmapItem--completed': isCompleted
        })}>
            <div className={'RoadmapItem__percentage'}>
                {`${percentage}%`}
            </div>
            <div className={'RoadmapItem__title'}>
                {title}
            </div>
        </div>
    );
}

function RoadmapSection() {
    return (
        <div id={"roadmap"}
             className={'RoadmapSection'}>
            <img src={sectionSeparator}
                 className={'SectionSeparator'}/>
            <div className={'SectionTitle'}>
                Roadmap
            </div>
            <div className={'RoadmapSection__subtitle'}>

            </div>
            <div className={'RoadmapSection__body'}>
                <div className={'RoadmapPhases'}>


                <div className={'RoadmapPhase'}>
                    <img src={RoadmapDotGreen} className={'RoadmapPhaseDot'}/>
                    <div className={'RoadmapPhaseDotLine'}/>
                    <div className={'RoadmapPhaseTitle'}>Phase 1</div>
                    <ul>
                        <li>Public Announcement Nov 2021</li>
                        <li>Community Giveaways (Discord, Twitter, etc)</li>
                        <li>The Obit Contest
                            <ul>
                                <li>Get your story on the blockchain + NFT</li>
                            </ul>
                        </li>
                        <li>Drop Jan 2022</li>
                    </ul>
                </div>
                <div className={'RoadmapPhase'}>
                    <img src={RoadmapDotGreen} className={'RoadmapPhaseDot'}/>
                    <div className={'RoadmapPhaseDotLine'}/>
                    <div className={'RoadmapPhaseTitle'}>Phase 2</div>
                    <ul>
                        <li>Misadventure Club via ThetaPass (Phased)
                        <ul>
                            <li>Merch store</li>
                            <li>IP usage rights</li>
                            <li>Exclusive Discord channel</li>
                            <li>NFT Staking
                            <ul>
                                <li>Earn $PLASM (TNT-20 Token)</li>
                            </ul>
                            </li>
                            <li>Claim and use $PLASM</li>
                        </ul>
                        </li>
                        <li>Ongoing airdrops to holders / stakers</li>
                    </ul>
                </div>
                <div className={'RoadmapPhase'}>
                    <img src={RoadmapDotGreen} className={'RoadmapPhaseDot'}/>
                    <div className={'RoadmapPhaseDotLine'}/>
                    <div className={'RoadmapPhaseTitle'}>Phase 3</div>
                    <ul>
                        <li>HODL Giveaways
                        <ul>
                            <li>Stake for XX days for chance to win</li>
                        </ul>
                        </li>
                        <li>(CONFIDENTIAL) Airdrops</li>
                        <li>Transphorm</li>
                        <li>Phormulate</li>
                    </ul>
                </div>
                <div className={'RoadmapPhase'}>
                    <img src={RoadmapDotGreen} className={'RoadmapPhaseDot'}/>
                    <div className={'RoadmapPhaseDotLine'}/>
                    <div className={'RoadmapPhaseTitle'}>Phase 4</div>
                    <ul>
                        <li>Games using Passaways / $PLASM</li>
                        <li>Community giveaways with $PLASM rewards</li>
                        <li>Developer Hackathons (TFUEL / $PLASM)</li>
                        <li>Various metaverse integrations</li>
                        <li>Artist collabs</li>
                    </ul>
                </div>
                <div className={'RoadmapPhase'}>
                    <img src={RoadmapDotGrey} className={'RoadmapPhaseDot'}/>
                    <div className={'RoadmapPhaseDotLine'} style={{display: 'none'}}/>
                    <div className={'RoadmapPhaseTitle RoadmapPhaseTitle--grey'}>To be Continued</div>
                </div>
                </div>
            </div>
        </div>
    );
}

function TheStorySection() {
    return (
        <div id={"story"}
             className={'TheProjectSection'}>
            <img src={sectionSeparator}
                 className={'SectionSeparator'}/>
            <div className={'SectionTitle'}>
                The Story
            </div>
            <div className={'TheProjectSection__body'}>
                <div className="TheProjectSection__pack">
                    <video loop autoPlay playsInline muted src={filmPreviewVideo} />
                </div>
                <div className={'TheProjectSection__text'}>
                    Good bye. The words last spoken from our friends the Passaways. Interstellar travellers and interdimensional beings who occasionally step back into our world from theirs. Many are searching for the meaning of afterlife while others are just enjoying the afterparty.
                    <br/>
                    <br/>
                    They've been glimpsed at the foot of our beds and behind us on the stairs. They've been caught pulling at our sheets and whispering our name when we drift off to sleep. They’re trying to tell us something.
                    <br/>
                    <br/>
                    Now you can help.
                    <br/>
                    <br/>
                    It is said that capturing a Passaway on film will unite its soul with its caretaker giving them insight into the Passaway world. This is how they will tell their story - through you. So we are releasing 9,666 Passaways that our specialized team has been collecting from all over the globe. These lost souls need to be protected and their stories heard.
                </div>
            </div>
        </div>
    );
}

function GetYoursSection() {
    return (
        <div id={"get-yours"}
             className={'GetYoursSection'}>
            <img src={sectionSeparator}
                 className={'SectionSeparator'}/>
            <div className={'SectionTitle'}>
                Get Yours
            </div>
            <div className={'GetYoursSection__subtitle'}>
                Now available on ThetaDrop!
            </div>
            <div className={'GetYoursSection__body'}>
                <Button title={'Buy Now'}
                        size={'xlarge'}
                        href={Urls.THETA_DROP_MICROSITE}
                        target={'_blank'}
                />
            </div>
        </div>
    );
}

function FAQAnswer({text}){
    return (
        <div className={'FAQAnswer'}>
            {text}
        </div>
    )
}

function FAQSection({onShowFilmPreview}) {
    return (
        <div id={"faq"}
             className={'FAQSection'}>
            <img src={sectionSeparator}
                 className={'SectionSeparator'}/>
            <div className={'SectionTitle'}>
                FAQ
            </div>
            <div className={'FAQSection__body'}>
                <Accordion atomic={true}>
                    <AccordionItem title="Are the Passaways really on film?">
                        <FAQAnswer text={<span>
                            <span>Yes! Each Passaway includes a video which our team captured while traveling around the world.</span>
                            <a style={{color: 'white', cursor: 'pointer'}} onClick={onShowFilmPreview}> Click here to see a preview.</a>
                        </span>}/>
                    </AccordionItem>

                    <AccordionItem title="Where can I buy a Passaway?">
                        <FAQAnswer text={'You can buy your Passaway on Passaways.ThetaDrop.com'}/>
                    </AccordionItem>

                    <AccordionItem title="Will there be a secondary market?">
                        <FAQAnswer text={'Of course! You can buy/sell Passaways on Passaways.ThetaDrop.com'}/>
                    </AccordionItem>

                    <AccordionItem title="Where does my Passaway go after I purchase it?">
                        <FAQAnswer text={'Your Passaway NFT will be securely stored on your ThetaDrop managed Theta Wallet.'}/>
                    </AccordionItem>

                    <AccordionItem title="Where can I get TFuel?">
                        <FAQAnswer text={'TFuel is available in most global crypto exchanges (Binance, Huobi, etc)'}/>
                    </AccordionItem>

                    {/*<AccordionItem title="Are Passaways a good investment?">*/}
                    {/*    <FAQAnswer text={'We think so but we are not financial gurus. Oh before we forget, our lawyers told us to say...this is not financial advice. Please do your own research.'}/>*/}
                    {/*</AccordionItem>*/}

                    <AccordionItem title="Why did you choose Theta?">
                        <FAQAnswer text={'We have tons of GBs of video data to store & serve. Theta is the leading blockchain for video so it was a no-brainer :)'}/>
                    </AccordionItem>

                </Accordion>
            </div>
        </div>
    );
}

function TheTeamSection() {
    return (
        <div id={"team"}
             className={'TheTeamSection'}>
            <img src={sectionSeparator}
                 className={'SectionSeparator'}/>
            <div className={'SectionTitle'}>
                The Team
            </div>
            <div className={'TheTeamSection__body'}>
                <img src={mikeAvatar}
                     className={'TheTeamSection__avatar'}/>
                <div className={'TheTeamSection__text'}>
                    My name is Mike. I'm a Creative Director and Designer. I've been in the creative industry for over 20 years consulting for clients such as Ogilvy, BBDO New York, Aol as well as Theta Labs. I've won numerous awards but I never brag about that. I also never eat the purple one.
                    <br/>
                    <br/>
                    Outside of work, I am a Theta OG staking THETA and TFUEL on my GN and EEN respectively.
                </div>
            </div>
        </div>
    );
}

function AsSeenOnSection() {
    return (
        <div id={"as-seen-on"}
             className={'AsSeenOnSection'}>
            <div className={'AsSeenOnSection__text'}>As seen on</div>
            <div className={'AsSeenOnLinks'}>
                <a className={'AsSeenOnLink AsSeenOnLink--nft-calendar'}
                   href={'https://nftcalendar.io/event/the-passaways/'}
                   target={'_blank'}
                >
                    <img src={NFTCalendarIcon}/>
                </a>
            </div>
        </div>
    );
}


function CompanySection() {
    return (
        <div id={"company"}
             className={'CompanySection'}>
            <CompanyLogo/>
        </div>
    );
}

function HomePage() {
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const showFilmPreview = () => {
        setIsOpen(true);
    };
    const hideFilmPreview = () => {
        setIsOpen(false);
    };



    var startsAT = new Date();
    startsAT.setDate(startsAT.getDate() + 7);

    return (
        <div className={'PageWrapper'}>
            <div className="Page HomePage">
                <NavBar/>
                <NFTDrop nftDrop={{
                    nft: {
                        name: 'EAT ME Perma Power-up',
                        description: 'Use this permanent power-up with your Passaway to increase your PLASM earn rate.',
                        image: '/nfts/powerUpEatMe.png'
                    },
                    tfuelPrice: '240000000000000000000',
                    tdropPrice: '2900000000000000000000',
                    plasmPrice: '0',
                    remaining: '100',
                    startsAt: startsAT,
                    endsAt: null//new Date() + 1000 * 60 * 60 * 24 * 14,
                }}/>
                <div style={{
                    height: 1000,
                }}>

                </div>

                <Logo/>
                {
                    EventCountdown &&
                    <CountdownWrapper/>
                }
                <img src={previewMobile}
                     className={'Previews HideOnNonMobile'}
                     onClick={showFilmPreview}
                />
                <img src={previewWeb}
                     className={'Previews HideOnMobile'}
                     onClick={showFilmPreview}
                />
                <CollectionSection/>
                <TheProjectSection/>
                <RoadmapSection/>
                <TheStorySection/>
                <TheTeamSection/>
                <GetYoursSection/>
                {/*<RewardsSection/>*/}
                <FAQSection onShowFilmPreview={showFilmPreview}/>
                <AsSeenOnSection/>
                <CompanySection/>
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={hideFilmPreview}
                    style={customStyles}
                >
                    <div className={'FilmPreviewModal'}>
                        <div className={'SectionTitle'}>Passaway on Film</div>
                        <iframe src="https://player.thetavideoapi.com/video/video_yzwqnqsss5rm7mudfcd2kqqfi9"
                                border="0"/>
                        <p className={'FilmPreviewModal__shoutout'}>
                            <span>This preview is hosted on </span>
                            <a href={'https://www.thetavideoapi.com'} target={'_blank'}>Theta Video API</a>
                        </p>
                        <Button title={'Close'}
                                size={'medium'}
                                onClick={hideFilmPreview}
                        />
                    </div>

                </Modal>

            </div>
        </div>
    );
}


export default HomePage;

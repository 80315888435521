import React from 'react';
import {truncate} from "../../utils";
import {Jazzicon} from "@ukstv/jazzicon-react"; // This imports the SCSS file for styling

const LotteryLeaderboard = ({ holders, style}) => {
    return (
        <div className="LotteryLeaderboard"
             style={style}
        >
            <h2 className="LotteryLeaderboard__title">{`Top ${holders.length} Ticket Holders`}</h2>
            <ul className="LotteryLeaderboard__list">
                {holders.map((holder, index) => (
                    <li key={index} className={`LotteryLeaderboardItem ${index < 3 ? 'LotteryLeaderboardItem--top-three' : ''}`}>
                        <span className="LotteryLeaderboardItem__rank">{index + 1}</span>
                        <div className="LotteryLeaderboardItem__avatar">
                            <Jazzicon address={holder.address}/>
                        </div>
                        <span className="LotteryLeaderboardItem__address">{truncate(holder.address)}</span>
                        <span className="LotteryLeaderboardItem__points">{holder.tickets} TICKETS</span>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default LotteryLeaderboard;

import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {TPMCNavBar} from "../../components/NavBar";
import {createSelector} from "reselect";
import {useDispatch, useSelector} from "react-redux";
import { ShakeLittle } from 'reshake';
import { Tab, Tabs, TabList } from 'react-tabs';
import {ModalTypes, Urls} from "../../constants";
import Button from "../../components/Button";
import Storage, {HAS_VISITED} from "../../utils/storage";
import UIState from "../../store/uiState";
import {copyToClipboard} from "../../utils";

const selectNftById = createSelector(
    (state) => state.models.nftsById,
    (_, nftId) => nftId,
    (nftsById, nftId) => nftsById[nftId]
)

const underscoreRexEx = new RegExp('_','g');
function NFTAttributeCard(props) {
    let {name, value} = props;

    if(name.startsWith('_') || name === 'tva_player_url'){
        return null;
    }

    name = _.replace(name, underscoreRexEx, ' ');

    return (
        <div className={'NFTAttributeCard'}>
            <div className={'NFTAttributeCard__name'}>
                {name}
            </div>
            <div className={'NFTAttributeCard__value'}>
                {value}
            </div>
        </div>
    );
}

function cropPFPImage(url, width, height, x, y, callback) {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext('2d');
    const imageObj = new Image();
    imageObj.crossOrigin = 'Anonymous'

    canvas.width = width;
    canvas.height = height;

    imageObj.onload = function () {
        context.drawImage(imageObj, x, y, width, height, 0, 0, width, height);
        callback(canvas.toDataURL());
    };

    imageObj.src = url;
}

function downloadURI(uri, name) {
    var link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

function NFTPage(props) {
    const dispatch = useDispatch();
    const {nftId} = props.match.params;
    const nft = useSelector((state) =>
        selectNftById(state, nftId)
    );
    const [assetTabIndex, setAssetTabIndex] = useState(0);
    const [pfpDataUrl, setPfpDataUrl] = useState(null);
    const image = (nft.image || nft.content.image_url);
    const name = (nft.name || nft.content.title);
    const isPack = (nft.type === 'pack');
    const tvaPlayerUrl = _.get(nft, 'attributes.tva_player_url', null);

    const onDownloadPFPClick = () => {
        downloadURI(pfpDataUrl, `passaways-${nft.edition}-pfp.png`);
    }

    useEffect(() => {
        if(!isPack){
            cropPFPImage(image,350,350, 100, 38, (something) => {
                setPfpDataUrl(something);
            });
        }
    }, [image]);

    const onClickTVALink = () => {
        copyToClipboard(tvaPlayerUrl);
    }

    return (
        <div className={'PageWrapper'}>
            <div className="Page NFTPage">
                <TPMCNavBar/>
                <div style={{height: 30}}/>
                <div className={'SectionTitle'}>Collection</div>
                <div className={'NFTPage__panels'}>
                    <div className={'NFTAssetsPanel'}>
                        <div className={'NFTAssetContainer'}>
                            {
                                (assetTabIndex === 0) &&
                                <img src={image}/>
                            }
                            {
                                (assetTabIndex === 1) &&
                                <iframe src={tvaPlayerUrl}/>
                            }
                            {
                                (assetTabIndex === 2) &&
                                <div className={'NFTPFPContainer'}>
                                    <img src={image}
                                         className={'NFTPFPContainer__background-img'}/>
                                    <div className={'NFTPFPContainer__background-overlay'}>
                                        <div className={'NFTPFPContainer__title'}>Download PFP</div>
                                        <div className={'NFTPFPContainer__subtitle'}>Rep your Passaway on Twitter, Discord, etc</div>
                                        <img src={pfpDataUrl}
                                             className={'NFTPFPContainer__avatar-img'}
                                        />
                                        <a className={'NFTPFPContainer__download-link'}
                                           onClick={onDownloadPFPClick}
                                        >Download</a>
                                    </div>

                                </div>
                            }
                        </div>
                        <Tabs selectedIndex={assetTabIndex}
                              onSelect={index => setAssetTabIndex(index)}
                              className={'NFTAssetTabs'}>
                            <TabList className={'NFTAssetTabList'}>
                                <Tab>
                                    <img src={image}/>
                                    <span>Image</span>
                                </Tab>
                                {
                                    !isPack &&
                                    <Tab>
                                        <img src={image}/>
                                        <span>Video</span>
                                    </Tab>
                                }
                                {
                                    !isPack && pfpDataUrl &&
                                    <Tab>
                                        <img src={pfpDataUrl} style={{borderRadius: '50%'}}/>
                                        <span>PFP</span>
                                    </Tab>
                                }
                            </TabList>
                        </Tabs>
                        {
                            (assetTabIndex === 1 && tvaPlayerUrl) &&
                            <a className={'NFTInfoPanel__tva-link'}
                               onClick={onClickTVALink}
                            >{tvaPlayerUrl}</a>
                        }
                    </div>
                    <div className={'NFTInfoPanel'}>
                        <div className={'NFTInfoPanel__name-and-tags'}>
                            <div className={'NFTInfoPanel__name'}>
                                { name }
                            </div>
                        </div>
                        <div className={'NFTInfoPanel__owner'}>
                            {`Owned by ${ nft.owner.username }`}
                        </div>
                        <div className={'NFTInfoPanel__actions'}>
                            {
                                isPack &&
                                <Button title={'Open Pack'}
                                        size={'xlarge'}
                                        style={{width: 250}}
                                        href={`${Urls.THETA_DROP_MICROSITE}/nft/${nft.id}`}
                                        target={'_blank'}
                                />
                            }
                            {
                                !isPack &&
                                    <div style={{width: 250}}>
                                        <ShakeLittle>
                                            <Button title={'S3E P6Y'}
                                                    hoverTitle={'Details to come...'}
                                                    size={'xlarge'}
                                                    style={{width: 250}}
                                            />
                                        </ShakeLittle>
                                    </div>


                            }
                        </div>
                        {
                            !isPack &&
                            <React.Fragment>
                                <div className={'NFTInfoPanel__section-title'}>Attributes</div>
                                <div className={'NFTInfoPanel__attributes'}>
                                    {
                                        _.map(nft.attributes, (v, k) => {
                                            return (
                                                <NFTAttributeCard name={k}
                                                                  value={v}
                                                />
                                            )
                                        })
                                    }
                                </div>
                            </React.Fragment>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NFTPage;

import {NavLink} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import wallet, {selectStakingAPY} from "../store/models/wallet";

export const CTAPillLink = ({ text, onClick, href, style }) => {
    return (
        <NavLink className={'CTAPillLink'}
                 style={style}
                 to={href}
                 onClick={onClick}
        >
            {text}
        </NavLink>
    )
};

export const StakePLASMAPYCTA = ({style}) => {
    const dispatch = useDispatch();
    const stakingAPY = useSelector(selectStakingAPY);

    useEffect(() => {
        dispatch(wallet.actions.fetchStakingAPY());
    }, []);

    return (
        <CTAPillLink text={
            <>
                <span>Stake PLASM and earn up to</span>
                <span style={{marginLeft: 4, marginRight: 4, color: "#76FF80"}}>{`${stakingAPY ? stakingAPY : '~'}% APY`}</span>
                <span> ></span>
            </>}
                     href={'/tpmc/stake/plasm'}
                     style={style}
        />
    )
}

export const StakePLASMAPYInfo = () => {
    const dispatch = useDispatch();
    const stakingAPY = useSelector(selectStakingAPY);

    useEffect(() => {
        dispatch(wallet.actions.fetchStakingAPY());
    }, []);

    return (
        <CTAPillLink text={
            <>
                <span>Earn up to</span>
                <span style={{marginLeft: 4, color: "#76FF80"}}>{`${stakingAPY ? stakingAPY : '~' }% APY`}</span>
            </>}
                     href={'/tpmc/stake/plasm'}
        />
    )
}

export default CTAPillLink;
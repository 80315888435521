import _ from 'lodash';
import React, {useState, useEffect} from 'react';
import {TPMCStakingNavBar} from "../../components/NavBar";
import {useDispatch, useSelector} from "react-redux";
import {selectCurrentUserId} from "../../store/models/user";
import Nft, {selectNftsByContract} from "../../store/models/nft";
import {createSelector} from "reselect";
import {IsPLASMLive, ModalTypes} from "../../constants";
import Button, {RoundedButton} from "../../components/Button";
import UIState from "../../store/uiState";
import Wallet, {selectProvider} from "../../store/models/wallet";
import {StakePlot} from "../../components/staking/StakePlot";
import {EarnPlasmCTABanners, StakePassawaysCTABanner} from "../../components/CTABanner";
import Config, {selectConfig, selectConfigLoadState, selectContractByKey, selectNFTDrops} from "../../store/models/config";
import Loader from "../../components/Loader";
import NFTDrop from "../../components/staking/NFTDrop";
import {useLocation, useParams} from "react-router-dom";
import {useInterval} from "../../hooks/useInterval";
import plasmLogo from "../../assets/tokens/plasm.png";
import PlasmCTA from "../../components/staking/PlasmCTA";

function NFTDropPage() {
    const dispatch = useDispatch();
    const {nftDropId} = useParams()
    const address = useSelector(selectCurrentUserId);
    const nftDrops = useSelector(selectNFTDrops);
    const configLoadState = useSelector(selectConfigLoadState);
    const provider = useSelector(selectProvider)
    const nftDrop = _.find(nftDrops, {id: nftDropId});
    const onBuy = (currencyType, qty) => {
        dispatch(Wallet.actions.purchaseNFTDrop(nftDrop, currencyType, qty));
    }
    useInterval(() => {
        dispatch(Config.actions.fetchNFTDrops());
    }, 6000);

    useEffect(() => {
        if(!configLoadState.hasLoadedConfig && !configLoadState.isLoadingConfig){
            dispatch(Config.actions.fetchConfig());
        }
    }, []);


    return (
        <div className={'PageWrapper'}>
            <TPMCStakingNavBar/>
            {
                _.isNil(nftDrop) &&
                <div className="Page NFTDropPage">
                    <Loader size={'medium'}/>
                </div>
            }
            {
                nftDrop &&
                <div className="Page NFTDropPage">
                    <div className={'NFTDropPage__title'}>
                        {nftDrop.title}
                    </div>
                    <div className={'NFTDropPage__subtitle'}>
                        {nftDrop.description}
                    </div>
                    <NFTDrop nftDrop={nftDrop}
                             canBuy={!_.isNil(address) && provider.readonly !== true}
                             onBuy={onBuy}
                    />
                    <PlasmCTA/>
                </div>
            }
        </div>
    );
}

export default NFTDropPage;

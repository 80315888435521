import _ from 'lodash';
import {post, get, put, authHeaders} from "../../utils/fetch";
import {Urls, RequestState, Hosts} from "../../constants";
import {
    CLAIM_EVENT_THETACON22,
    CREATE_EVENT_VOTE,
    FETCH_EVENT_LEADERBOARD,
    FETCH_EVENT_SOCIAL_FEED,
    FETCH_EVENT_THETACON22_ELIGIBILITY,
    FETCH_EVENT_VOTES
} from "./event";

// ===========================
// HELPERS
// ===========================


// ACTIONS
// ===========================
export const FETCH_NFTS = 'FETCH_NFTS';
export const FETCH_NFT = 'FETCH_NFT';
export const FETCH_UNCLAIMED_PASSAWAY_TRANSPHORM_ORDERS = 'FETCH_UNCLAIMED_PASSAWAY_TRANSPHORM_ORDERS';
export const FETCH_UNCLAIMED_CRAFTABLE_ITEM_ORDERS = 'FETCH_UNCLAIMED_CRAFTABLE_ITEM_ORDERS';
export const FETCH_UNCLAIMED_LOCKED_TOKENS_FROM_CRAFTABLE_ITEM_ORDERS = 'FETCH_UNCLAIMED_LOCKED_TOKENS_FROM_CRAFTABLE_ITEM_ORDERS';
export const CREATE_PROOF_OF_TRANSPHORM = 'CREATE_PROOF_OF_TRANSPHORM';
export const FETCH_PROOF_OF_TRANSPHORM = 'FETCH_PROOF_OF_TRANSPHORM';



// ===========================
// SELECTORS
// ===========================

// TODO delete me
export const selectNft = (state, nftId) => state.models.nftsById[nftId];
export const selectUserNfts = (state, userId) => {
    return state.models.nftsByOwnerId[userId];
};
// end

export const selectNftsByContract = (state, contractAddress) => {
    return state.models.nftsByContract[contractAddress];
};
export const selectAllNfts = (state) => {
    return _.flatten(_.values(state.models.nftsByContract));
};
export const selectNftBalances = (state) => {
    return state.models.nftBalancesByContract;
};
export const selectNftBalance = (state, contract) => {
    return state.models.nftBalancesByContract[contract] || 0;
};
export const selectClaimablePassawayTransphormOrders = (state) => {
    return state.models.unclaimedPassawayTransphormOrders;
}
export const selectClaimableCraftableItemOrders = (state) => {
    return state.models.unclaimedCraftableItemOrders;
}
export const selectUnclaimedLockedTokensFromCraftableItemOrders = (state) => {
    return state.models.unclaimedLockedTokensFromCraftableItemOrders;
}
// ===========================
// MODEL
// ===========================
const Nft = {
    actions: {
        fetchNfts: (address, contract_addresses) => async (dispatch, getState) => {
            return get({
                url: "/v1/nfts",
                params: {
                    address: address,
                    contract_addresses: contract_addresses
                },
                headers: {
                    'x-session-token': _.get(getState(), 'app.userToken')
                },
                dispatch,
                action: FETCH_NFTS,
            }).then(results => results?.body?.nfts);
        },
        fetchUnclaimedCraftableItemOrders: (address) => async (dispatch, getState) => {
            return get({
                url: "/v1/nfts/unclaimed-craftable-item-orders",
                params: {
                    address: address,
                },
                headers: {
                    'x-session-token': _.get(getState(), 'app.userToken')
                },
                dispatch,
                action: FETCH_UNCLAIMED_CRAFTABLE_ITEM_ORDERS,
            }).then(results => results?.body?.orders);
        },
        fetchUnclaimedLockedTokensFromCraftableItemOrders: (address) => async (dispatch, getState) => {
            return get({
                url: "/v1/nfts/unclaimed-locked-tokens-from-craftable-item-orders",
                params: {
                    address: address,
                },
                headers: {
                    'x-session-token': _.get(getState(), 'app.userToken')
                },
                dispatch,
                action: FETCH_UNCLAIMED_LOCKED_TOKENS_FROM_CRAFTABLE_ITEM_ORDERS,
            }).then(results => results?.body?.lockedTokens);
        },
        fetchUnclaimedPassawayTransphormOrders: (address) => async (dispatch, getState) => {
            return get({
                url: "/v1/nfts/unclaimed-passaway-transphorm-orders",
                params: {
                    address: address,
                },
                headers: {
                    'x-session-token': _.get(getState(), 'app.userToken')
                },
                dispatch,
                action: FETCH_UNCLAIMED_PASSAWAY_TRANSPHORM_ORDERS,
            }).then(results => results?.body?.orders);
        },
        createProofOfTransphorm: (hash, passawayTokenId, transphormedPassawayTokenId) => async (dispatch, getState) => {
            return post({
                url: "/v1/nfts/proof-of-transphorm",
                body: {
                    hash,
                    passawayTokenId,
                    transphormedPassawayTokenId
                },
                headers: {
                    'x-session-token': _.get(getState(), 'app.userToken')
                },
                dispatch,
                action: CREATE_PROOF_OF_TRANSPHORM,
            }).then(results => results?.body?.success);
        },
        fetchProofOfTransphorm: (edition) => async (dispatch, getState) => {
            return get({
                url: "/v1/nfts/proof-of-transphorm",
                params: {
                    edition,
                    redirect: false
                },
                headers: {
                    'x-session-token': _.get(getState(), 'app.userToken')
                },
                dispatch,
                action: FETCH_PROOF_OF_TRANSPHORM,
            }).then(results => results?.body?.proofOfTransphorm);
        }
    },

    spec: {
        nftsByContract: {},
        nftBalancesByContract: {},
        unclaimedPassawayTransphormOrders: [],
        unclaimedCraftableItemOrders: [],
        unclaimedLockedTokensFromCraftableItemOrders: [],
    },

    modelReducer: (state, type, body, action) => {
        if (action.url && action.result !== RequestState.SUCCESS)
            return state;

        if (type === FETCH_NFTS) {
            return {
                ...state,
                nftsByContract: {
                    ...state.nftsByContract,
                    ..._.groupBy(body.nfts, 'contract_address'),
                },
                nftBalancesByContract: {
                    ...state.nftBalancesByContract,
                    ...body.balances
                }
            }
        }
        else if (type === FETCH_UNCLAIMED_PASSAWAY_TRANSPHORM_ORDERS) {
            return {
                ...state,
                unclaimedPassawayTransphormOrders: body.orders
            }
        }
        else if (type === FETCH_UNCLAIMED_CRAFTABLE_ITEM_ORDERS) {
            return {
                ...state,
                unclaimedCraftableItemOrders: body.orders
            }
        }
        else if (type === FETCH_UNCLAIMED_LOCKED_TOKENS_FROM_CRAFTABLE_ITEM_ORDERS) {
            return {
                ...state,
                unclaimedLockedTokensFromCraftableItemOrders: body.lockedTokens
            }
        }

        return state;
    }
}
export default Nft;

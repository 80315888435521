import React from 'react';
import {connect} from 'react-redux'
import Modal from '../../components/Modal';
import thetaDropLogo from '../../assets/login/theta-drop@2x.png';
import thetaWalletLogo from '../../assets/login/theta-wallet@2x.png';
import thetaRarityLogo from '../../assets/login/theta-rarity@2x.png';
import metamaskLogo from '../../assets/login/metamask@2x.png';
import UIState from "../../store/uiState";
import {selectCurrentUser} from "../../store/models/user";
import Button from "../../components/Button";
import {LoginTypes} from "../../constants";
import MobileWallets from "./MobileWallets";

export class MobileWalletsModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false
        };
    }

    onClose = () => {
        this.props.dispatch(UIState.actions.hideModal());
    }

    onOpenApp = (appUri) => {
        window.sessionStorage.setItem('theta-wallet-connection-pending', 'true');
        window.open(appUri, '_blank');
        this.props.dispatch(UIState.actions.hideModal());
    }

    render() {
        const {uri} = this.props;

        return (
            <Modal>
                <div className="MobileWalletsModal">
                    <div className={'MobileWalletsModal__title'}>
                        Mobile Wallets
                    </div>
                    <div className={'MobileWalletsModal__subtitle'}>
                        Tap your mobile wallet icon to continue
                    </div>

                    <div className={'MobileWalletsModal__apps'}>
                        <a className={'MobileWalletsModal__app'}
                           onClick={this.onOpenApp.bind(this, uri)}>
                            <img src={'/wallets/theta-wallet@2x.png'}/>
                            <span>Theta Wallet</span>
                        </a>
                    </div>


                    <a className={'MobileWalletsModal__cancel'}
                       onClick={this.onClose}
                    >Cancel</a>
                </div>
            </Modal>
        )
    }
}


const mapStateToProps = (state, modalProps) => {
    return {
        ...modalProps
    }
};

export default connect(mapStateToProps)(MobileWalletsModal);

import React, {useState} from 'react';
import MDSpinner from "react-md-spinner";
import plasmLogo from "../assets/tokens/plasm-front-small@2x.png";

const classNames = require('classnames');

export default function Button({title, hoverTitle, href, target, rel, onClick, size, isLoading, className, style, color, disabled, rounded, pill, children}) {
    const [isHovering, setIsHovering] = useState(false);
    let innerContent = null;
    const colorClass = `Button--${color}`;
    let onHover = () => {
        setIsHovering(true);
    }
    let onMouseEnter = () => {
        setIsHovering(true);
    }
    let onMouseLeave = () => {
        setIsHovering(false);
    }
    let spinnerColor = '#16171A';
    if(color === 'green-outline'){
        spinnerColor = '#76FF80';
    }

    if(isLoading){
        innerContent = (
            <div>
                <MDSpinner singleColor={spinnerColor}/>
            </div>
        );
    }
    else if(children){
        innerContent = children;
    }
    else{
        innerContent = (
            <div>
                { (hoverTitle && isHovering) ? hoverTitle : title}
            </div>
        );
    }

    return (
        <a className={classNames(`Button Button--is-${size}`, className, {[colorClass]: true, 'Button--is-disabled': disabled, 'Button--is-rounded': rounded, 'Button--is-pill': pill})}
           style={style}
           href={href}
           target={target}
           rel={rel}
           onClick={disabled ? null : onClick}
           onMouseEnter={onMouseEnter}
           onMouseLeave={onMouseLeave}
        >
            {
                innerContent
            }
        </a>
    );
}

export function RoundedButton(props) {
    return <Button {...props}
        rounded={true}/>
}

export function PillButton(props) {
    return <Button {...props}
                   pill={true}/>
}

export function GetMorePlasmButton(props) {
    return <Button {...props}
                   color={'green'}
                   size={'medium'}
                   title={(
                          <div className={'GetMorePlasmButton__title'}>
                              <span>SHORT ON</span>
                              <img src={plasmLogo}/>
                              <span>PLASM? GET MORE HERE</span>
                          </div>
                   )}
                   className={'GetMorePlasmButton'}
                   rounded={true}/>
}
import _ from 'lodash';
import React, {useState, useEffect} from 'react';
import {TPMCStakingNavBar} from "../../components/NavBar";
import {useDispatch, useSelector} from "react-redux";
import {selectCurrentUserId} from "../../store/models/user";
import Nft, {selectNftsByContract} from "../../store/models/nft";
import {createSelector} from "reselect";
import {IsPLASMLive, ModalTypes} from "../../constants";
import {RoundedButton} from "../../components/Button";
import UIState from "../../store/uiState";
import Wallet, {selectProvider} from "../../store/models/wallet";
import {StakePlot} from "../../components/staking/StakePlot";
import {EarnPlasmCTABanners, StakePassawaysCTABanner} from "../../components/CTABanner";
import Config, {
    getContractAddressByKey,
    selectConfig,
    selectConfigLoadState,
    selectContractByKey,
    selectLottery,
    selectNFTDrops
} from "../../store/models/config";
import Loader from "../../components/Loader";
import NFTDrop from "../../components/staking/NFTDrop";
import {useLocation, useParams} from "react-router-dom";
import {useInterval} from "../../hooks/useInterval";
import Lottery from "../../components/staking/Lottery";
import {RequiredApprovals} from "../../components/staking/RequiredApprovals";
import shirtReward from "../../assets/events/floor-raiser/prizes/shirt.png";
import {truncate} from "../../utils";
import LotteryLeaderboard from "../../components/staking/LotteryLeaderboard";
import PlasmCTA from "../../components/staking/PlasmCTA";

function LotteryPage() {
    const dispatch = useDispatch();
    const address = useSelector(selectCurrentUserId);
    const lottery = useSelector(selectLottery);
    const configLoadState = useSelector(selectConfigLoadState);
    const provider = useSelector(selectProvider);
    const leaderboard = lottery?.leaderboard || [];
    const onBuy = (currencyType, qty) => {
        dispatch(Wallet.actions.purchaseLotteryTickets(lottery, qty));
    }
    useInterval(() => {
        dispatch(Config.actions.fetchLottery(address));
    }, 6000);

    useEffect(() => {
        if(!configLoadState.hasLoadedConfig && !configLoadState.isLoadingConfig){
            dispatch(Config.actions.fetchConfig());
        }
        dispatch(Config.actions.fetchLottery(address));
    }, []);
    const getPrize = (idx) => {
        return null;
    }

    return (
        <div className={'PageWrapper'}>
            <TPMCStakingNavBar/>
            {
                _.isNil(lottery) &&
                <div className="Page LotteryPage">
                    <Loader size={'medium'}/>
                </div>
            }
            {
                lottery &&
                <div className="Page LotteryPage">
                    <div className={'LotteryPage__title'}>
                        {lottery.title}
                    </div>
                    <div className={'LotteryPage__subtitle'}>
                        {lottery.description}
                    </div>
                    <Lottery lottery={lottery}
                             canBuy={!_.isNil(address) && provider.readonly !== true}
                             onBuy={onBuy}
                    />
                    {
                        lottery.accountTickets > 0 &&
                        <LotteryLeaderboard holders={leaderboard}
                                            style={{marginTop: 30}}
                        />
                    }
                </div>
            }

        </div>
    );
}

export default LotteryPage;

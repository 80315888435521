import _ from 'lodash';
import React, {useState, useEffect, useContext} from 'react';
import thetaConBadgeImage from '../../assets/airdrops/thetacon/badge.png';
import Button from "../../components/Button";
import {thetaDropConnect} from "../../store/appState";
import {pushTo} from "../../utils/history";
import User, {selectCurrentUser} from "../../store/models/user";
import {useDispatch, useSelector} from "react-redux";
import TPMCWarning from "../../components/TPMCWarning";
import {TPMCNavBar} from "../../components/NavBar";
import Airdrop from "../../store/models/airdrop";
import {createSelector} from "reselect";
import * as ethers from 'ethers';

const ThetaConBadgeCard = ({token, onClaimClick}) => {
    const {edition, is_claimed} = token;

    return (
        <div className={'BadgeCard'}
             style={{
                 minWidth: 200
             }}
        >
            <div className={'BadgeCard__name'}>{`Edition #${edition}`}</div>
            <Button title={is_claimed ? 'Claimed' : 'Claim' }
                    size={'small'}
                    color='thetacon-green'
                    disabled={is_claimed}
                    onClick={() => {
                        onClaimClick(token)
                    }}
                    style={{width: '100%', marginTop: 10}}
            />
        </div>
    )
}

const getCurrentUser = createSelector(
    (state) => selectCurrentUser(state),
    (currentUser) => ({currentUser})
);

function ThetaConBadgeSwapPage() {
    const dispatch = useDispatch();
    const {currentUser} = useSelector(getCurrentUser);
    const [isLoading, setIsLoading] = useState(false);
    const [connectedAddress, setConnectedAddress] = useState(null);
    const [thetaConTokens, setThetaConTokens] = useState([]);

    const onClaimClick = async (token) => {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const address = await signer.getAddress();
        const message = `Claim Passaways ThetaCon Badge (Token ID: ${token.id})\n\nNonce: ${token.nonce}`;
        const signature = await signer.signMessage(message);
        if(signature){
            const {nft} = await dispatch(Airdrop.actions.claimThetaConToken(address, message, signature));
            // Fetch the tokens again
            const tokens = await dispatch(Airdrop.actions.fetchThetaConTokens(address));
            setThetaConTokens(tokens);

            if(nft){
                alert('Success. Check your ThetaDrop account.');
            }
        }
    }

    const onMetaMaskLoginClick = async () => {
        try {
            if (!window.ethereum){
                throw new Error('MetaMask not found. Please install it.');
            }
            const accounts = await window.ethereum.send('eth_requestAccounts');
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const address = await signer.getAddress();
            setConnectedAddress(address);
            const tokens = await dispatch(Airdrop.actions.fetchThetaConTokens(address));
            setThetaConTokens(tokens);
        }
        catch (e){
            alert(e.message);
        }
    }

    return (
        <div className={'PageWrapper'}>
            <div className="Page ThetaConBadgeSwapPage">
                <TPMCNavBar/>
                <div style={{height: 30}}/>
                <div className={'SectionTitle'} style={{color: '#3f9870'}}>ThetaCon Badge Claim</div>
                <img src={thetaConBadgeImage}
                     className={'ThetaConBadgeSwapPage__logo'}/>
                <div className={'ThetaConBadgeSwapPage__description'}>
                    <div>Claim the stand-alone version of your 'Passaways ThetaCon Badge' from the 'ThetaCon 2022 Collection'.</div>
                    <br/>
                    <div>{`The claimed NFT will be transferred to the ThetaDrop account @${currentUser.username}.`}</div>
                    <br/>
                    <div>ELIGIBILITY</div>
                    <div>Each token can ONLY be claimed ONCE.</div>
                    <div>Program ends: 05/20/2022 @ 3 PM PT</div>
                </div>

                {
                    _.isNil(connectedAddress) &&
                    <Button title={'Login with MetaMask'}
                            size={'large'}
                            color='thetacon-pink'
                            onClick={onMetaMaskLoginClick}
                            isLoading={isLoading}
                            style={{width: 250, marginTop: 30}}
                    />
                }

                {
                    connectedAddress &&
                    <React.Fragment>
                        <div className={'SectionTitle'} style={{color: '#3f9870', marginTop: 40, marginBottom: 15}}>ThetaCon Badges</div>
                        {
                            connectedAddress &&
                            <div>Connected to: {connectedAddress}</div>
                        }
                        <div className={'NFTCards'}
                             style={{marginTop: 20}}
                        >
                            {
                                thetaConTokens.map((token) => {
                                    return (
                                        <ThetaConBadgeCard token={token}
                                                           onClaimClick={onClaimClick}
                                        />
                                    );
                                })
                            }
                        </div>
                        {
                            _.isEmpty(thetaConTokens) &&
                            <div>No ThetaCon Badges Owned</div>
                        }
                    </React.Fragment>
                }
            </div>
        </div>
    );
}

export default ThetaConBadgeSwapPage;

import _ from 'lodash';
import React from 'react'
import modalCloseIcon from '../assets/icons/modal-close@2x.png';
import classNames from "classnames";
import { useLockedBody } from 'usehooks-ts'

export default class Modal extends React.Component {
    render() {
        const {className} = this.props;

        return (
            <div className={classNames('Modal', className)}>
                {this.props.children}
            </div>
        )
    }
}

export class RoundedModal extends React.Component {
    render() {
        const {className, title, subtitle, header, children, onClose} = this.props;

        return (
            <Modal>
                <div className={classNames('RoundedModal', className)}>
                    {
                        header
                    }
                    {
                        _.isNil(header) &&
                        <div className={'RoundedModalHeader'}>
                            <div className={'RoundedModalHeader__title'}>
                                {title}
                            </div>
                            {
                                subtitle &&
                                <div className={'RoundedModalHeader__subtitle'}>
                                    {subtitle}
                                </div>
                            }
                            <a className={'RoundedModalHeader__close-button'}
                               onClick={onClose}
                            >
                                <img src={modalCloseIcon}/>
                            </a>
                        </div>
                    }
                    <div className={'RoundedModal__content'}>
                        {children}
                    </div>
                </div>
            </Modal>
        )
    }
}
import _ from 'lodash';
import React, {useState} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux'
import UIState from "../../store/uiState";
import ProductModal from "./Product";
import StakingTierBadge from "../../components/staking/StakingTierBadge";
import {formatBalance} from "../../utils";
import Wallet from "../../store/models/wallet";
import {PowerUpTypes} from "../../constants";

export class PowerUpSlotProductModal extends React.Component {
    constructor() {
        super();

        this.state = {
            price: null
        }
    }

    componentDidMount() {
        this.fetchPrice();
    }

    onClose = () => {
        this.props.dispatch(UIState.actions.hideModal());
    }

    fetchPrice = async () => {
        const {nft, powerUpType} = this.props;

        let price;
        if(powerUpType === PowerUpTypes.Ephemeral){
            price = await this.props.dispatch(Wallet.actions.getEphemeralPowerUpSlotPrice(nft));
        }
        else if(powerUpType === PowerUpTypes.Permanent){
            price = await this.props.dispatch(Wallet.actions.getPermanentPowerUpSlotPrice(nft));
        }
        this.setState({
            price: price
        });
    }

    render() {
        const {nft, ephemeralPowerUpSlots, onBuy, powerUpType} = this.props;
        const {price} = this.state;
        const name = (powerUpType === PowerUpTypes.Ephemeral ? '1 Ephemeral Power-up Slot' : '1 Perma Power-up Slot');
        const description = (powerUpType === PowerUpTypes.Ephemeral ? 'Purchase slots to add more ephemeral power-ups.' : 'Purchase slot to add a perma power-up.');

        return (
            <ProductModal title={'Buy Power-Up Slot'}
                          productName={name}
                          productDescription={description}
                          productImage={'/products/ephemeral-power-up-slot@2x.png'}
                          productLineItems={[
                              {
                                  title: 'Pricing tier',
                                  content: <StakingTierBadge tier={nft?.metadata?.attributes?.tier}/>
                              },
                              {
                                  title: 'Price',
                                  content: _.isNil(price) ? <div/> : <div className={'PlasmPrice'}>{formatBalance(price.toString())}</div>
                              }]}
                          onBuy={onBuy}
            />
        );
    }
}

const mapStateToProps = (state, props) => {
    const {action} = props;

    return {
        ...props,
    }
};

export default connect(mapStateToProps)(PowerUpSlotProductModal);

import _ from 'lodash';
import React, {useState, useEffect, useContext, useRef} from 'react';
import logo from '../../assets/landing/hero-tpmc@2x.png';
import thetaPassLogo from '../../assets/logo/theta-pass@2x.png';
import Button, {RoundedButton} from "../../components/Button";
import {
    getWalletSigninMessage,
    LoginTypes,
    ModalTypes,
    ThetaDropAppId,
    ThetaDropRedirectURL,
    Urls
} from "../../constants";
import {useDispatch} from "react-redux";
import TPMCWarning from "../../components/TPMCWarning";
import {useLocation} from "react-router-dom";
import Wallet from "../../store/models/wallet";
import * as ThetaPass from '@thetalabs/theta-pass';
import UIState from "../../store/uiState";
import {useEventListener} from 'usehooks-ts';
import thetaWalletLogo from "../../assets/login/theta-wallet@2x.png";
import metamaskLogo from "../../assets/login/metamask@2x.png";
import thetaDropLogo from "../../assets/login/theta-drop@2x.png";
import thetaRarityLogo from "../../assets/login/theta-rarity@2x.png";
import NavBar from "../../components/NavBar";
import {isMetaMaskBrowser, isMobileBrowser, isThetaWalletBrowser} from "../../utils";

export const LoginButton = ({logo, type, isLoading, onClick}) => {
    let href = null;
    if((type === LoginTypes.MetaMask && isMobileBrowser() && !isMetaMaskBrowser())){
        onClick = null;
        let queryStr = `login-type=metamask&auto-login=true`;
        let dappUrl = `${window.location.href}?${queryStr}`;
        if(window.location.href.includes('?')){
            dappUrl = `${window.location.href}&${queryStr}`;
        }
        href = `https://metamask.app.link/dapp/${encodeURI(dappUrl)}`;
    }

    return (
        <RoundedButton size={'large'}
                target={'_blank'}
                color={'black'}
                onClick={onClick}
                isLoading={isLoading}
                href={href}
                className={'LoginButton'}
                style={{width: '100%', marginBottom: 14, height: 60}}
        >
            <img src={logo} style={{height: 38}}/>
        </RoundedButton>
    );
}

function LoginPage() {
    let {pathname, search} = useLocation();
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false);
    const [showOtherWallets, setShowOtherWallets] = useState(false);

    const finishConnectToThetaDrop = async () => {
        const response = await ThetaPass.getResponse();

        if (response) {
            setIsLoading(true);
            dispatch(Wallet.actions.finishLoginWithThetaDrop(response));
        }
    };
    const finishConnectToThetaRarity = async () => {
        const searchParams = new URLSearchParams(window.location.search);
        const address = searchParams.get('address');
        const signature = searchParams.get('signature');
        const nonce = searchParams.get('nonce');
        if (address && signature && nonce) {
            setIsLoading(true);
            dispatch(Wallet.actions.finishLoginWithThetaRarity(address, signature, nonce));
        }
    };

    const onLoginOptionClick = async (loginType) => {
        if (loginType === LoginTypes.ThetaDrop) {
            dispatch(Wallet.actions.loginWithThetaDrop());
        } else if (loginType === LoginTypes.MetaMask) {
            dispatch(Wallet.actions.loginWithMetamask());
        } else if (loginType === LoginTypes.ThetaWallet) {
            dispatch(Wallet.actions.loginWithWalletConnect());
        } else if (loginType === LoginTypes.ThetaRarity) {
            dispatch(Wallet.actions.loginWithThetaRarity());
        }
    }

    const onLoginWithThetaWalletBrowserClick = async () => {
        dispatch(Wallet.actions.loginWithMetamask());
    }

    useEffect(() => {
        finishConnectToThetaDrop();
        finishConnectToThetaRarity();

        // In-wallet browser
        if (isThetaWalletBrowser() || isMetaMaskBrowser()) {
            dispatch(Wallet.actions.loginWithMetamask());
        }
    }, []);

    const onVisibilityChange = (event) => {
        const visible = !document.hidden;
        if (visible && !_.isNil(window.sessionStorage.getItem('theta-wallet-connection-pending'))) {
            window.sessionStorage.removeItem('theta-wallet-connection-pending');
            dispatch(Wallet.actions.maybeFinishLoginWithThetaWallet());
        }
    }
    const documentRef = useRef(document)
    useEventListener('visibilitychange', onVisibilityChange, documentRef)

    return (
        <div className={'PageWrapper'}>
            <div className="Page LoginPage">
                <img src={logo} className={'LoginPage__logo'}/>
                <div className={'LoginPage__members-only'}>
                    Members Only
                </div>
                <div className={'LoginPage__description'}>
                    Connect your wallet to stake PLASM and NFTs.
                </div>
                {
                    !isThetaWalletBrowser() &&
                    <>
                        <LoginButton type={LoginTypes.MetaMask}
                                     logo={metamaskLogo}
                                     isLoading={isLoading}
                                     onClick={() => {
                                         onLoginOptionClick(LoginTypes.MetaMask);
                                     }}/>
                        <LoginButton type={LoginTypes.ThetaWallet}
                                     logo={thetaWalletLogo}
                                     isLoading={isLoading}
                                     onClick={() => {
                                         onLoginOptionClick(LoginTypes.ThetaWallet);
                                     }}/>
                        {
                            showOtherWallets &&
                            <div className={'LoginPage__other-wallets'}>
                                <div>Custodial Wallets</div>
                                <LoginButton type={LoginTypes.ThetaDrop}
                                             logo={thetaDropLogo}
                                             isLoading={isLoading}
                                             onClick={() => {
                                                 onLoginOptionClick(LoginTypes.ThetaDrop);
                                             }}/>
                                <LoginButton type={LoginTypes.ThetaRarity}
                                             logo={thetaRarityLogo}
                                             isLoading={isLoading}
                                             onClick={() => {
                                                 onLoginOptionClick(LoginTypes.ThetaRarity);
                                             }}/>
                                <p>
                                    These accounts <u><b>cannot</b></u> be used for staking, supply store, crafting, transphorm, redeeming, burning, etc.<br/><br/>You <u><b>can</b></u> use them for Merch and Discord.
                                </p>
                            </div>
                        }
                        {
                            !showOtherWallets &&
                            <div className={'LoginPage__show-other-wallets'}
                                    onClick={() => {
                                        setShowOtherWallets(true);
                                    }}>
                                ThetaDrop or ThetaRarity?
                            </div>
                        }

                        <div className={'LoginPage__clear-cache'}
                             onClick={() => {
                                 window.localStorage.clear();
                                 window.location.reload();
                             }}>
                            Having issues with Theta Wallet login?
                        </div>
                    </>
                }

                {
                    isThetaWalletBrowser() &&
                    <Button size={'large'}
                            target={'_blank'}
                            color={'black'}
                            title={'Login'}
                            onClick={onLoginWithThetaWalletBrowserClick}
                            isLoading={isLoading}
                            className={'LoginButton'}
                            style={{width: '100%'}}
                    />
                }
            </div>
        </div>
    );
}

export default LoginPage;

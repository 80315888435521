import _ from 'lodash';
import Countdown, {zeroPad} from "react-countdown";
import {formatWei} from "../../utils";
import {RoundedButton} from "../Button";
import React from "react";

const countdownRenderText = ({ days, hours, minutes, seconds, completed }) => {
    if(days > 0){
        return `${zeroPad(days)}:${zeroPad(hours)}:${zeroPad(minutes)}:${zeroPad(seconds)}`;
    }
    if(hours > 0){
        return `${zeroPad(hours)}:${zeroPad(minutes)}:${zeroPad(seconds)}`;
    }
    return `${zeroPad(minutes)}:${zeroPad(seconds)}`;
}

const renderAvailableIn = (countDownData) => {
    if(countDownData.completed){
        return null;
    }

    return (
        <div className={'RedeemableCard__available-in'}>
            {`Available in ${countdownRenderText(countDownData)}`}
        </div>
    )
}

const renderAvailableFor = (countDownData) => {
    if(countDownData.completed){
        return null;
    }

    return (
        <div className={'RedeemableCard__available-for'}>
            {`Available for ${countdownRenderText(countDownData)}`}
        </div>
    )
}

export const RedeemableCard = ({redeemable, onDetails, onRedeem}) => {
    const {price, remaining, start, end, startsAt, endsAt, enabled, outputNft,
        outputName, outputDescription, outputImage
    } = redeemable;
    const hasStarted = startsAt && (new Date(startsAt)).getTime() < Date.now();

    return (
        <div className={'RedeemableCard'}
             onClick={onDetails}
        >
            {
                remaining > 0 &&
                <div className={'NumberBadge'}>{`${remaining} LEFT`}</div>
            }
            <img src={outputImage}
                 className={'RedeemableCard__image'}
            />
            <div className={'RedeemableCard__name'}>{outputName}</div>
            <div className={'RedeemableCard__description'}>
                {redeemable.burnable ? 'FOR BURNING' : 'FOR LOCKING UP'}
            </div>
            <div className={'RedeemableCard__inputs'}>
                {
                    _.map(redeemable.inputNfts, (nft) => {
                        return (
                            <img className={'RedeemableCard__input'}
                                 src={nft.image}
                            />
                        )
                    })
                }

            </div>
            {
                !hasStarted && startsAt &&
                <Countdown
                    date={(new Date(startsAt))}
                    renderer={renderAvailableIn}
                />
            }
            {
                hasStarted && endsAt &&
                <Countdown
                    date={(new Date(endsAt))}
                    renderer={renderAvailableFor}
                />
            }
            <div className={'RedeemableCard__footer'}>
                <RoundedButton title={'View'}
                               onClick={(e) => {
                                   e.preventDefault();

                                   onRedeem(redeemable)
                               }}
                               color={'green'}
                />
            </div>
        </div>
    );
}
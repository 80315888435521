import _ from 'lodash';
import React, {useState, useEffect, useContext} from 'react';
import NavBar from "../components/NavBar";
import TPMCHeroBanners from "../components/staking/TPMCHeroBanners";
import HeroBanners from "../heroBanners";
import heroPassawaysImage from '../assets/landing/hero-passaways@2x.png';
import heroPlasmImage from '../assets/landing/hero-plasm@2x.png';
import heroTPMCImage from '../assets/landing/hero-tpmc@2x.png';
import deployedOnImage from '../assets/landing/deployed-on@2x.png';
import featureNFTStakeImage from '../assets/landing/feature-nft-stake@2x.png';
import featureTransphormImage from '../assets/landing/feature-transphorm@2x.png';
import featurePowerUpsImage from '../assets/landing/feature-power-ups@2x.png';
import featurePermaPowerUpsImage from '../assets/landing/feature-perma-power-ups@2x.png';
import featurePhlairImage from '../assets/landing/feature-phlair@2x.png';
import featureCraftingImage from '../assets/landing/feature-crafting@2x.png';
import featureDiscordImage from '../assets/landing/tpmc-perk-discord@2x.png';
import featureMerchImage from '../assets/landing/tpmc-perk-merch@2x.png';
import earnPLASMTokensImage from '../assets/landing/earn-plasm-tokens@2x.png';
import marketplaceThetaDropImage from '../assets/landing/thetadrop@2x.png';
import marketplaceOpenThetaImage from '../assets/landing/open-theta@2x.png';
import marketplaceThetaRarityImage from '../assets/landing/theta-rarity@2x.png';
import sectionSeparatorImage1 from '../assets/landing/section-separator-1@2x.png';
import sectionSeparatorImage2 from '../assets/landing/section-separator-2@2x.png';
import sectionSeparatorImage3 from '../assets/landing/section-separator-3@2x.png';
import sectionSeparatorImage4 from '../assets/landing/section-separator-4@2x.png';
import plasmGovernanceImage from '../assets/landing/plasm-governance@2x.png';
import plasmAccessImage from '../assets/landing/plasm-access@2x.png';
import plasmUtilityImage from '../assets/landing/plasm-utility@2x.png';
import plasmBuildImage from '../assets/landing/plasm-build@2x.png';
import footerImage from '../assets/landing/netpo-footer@2x.png';
import roadmapItemIconComplete from '../assets/landing/roadmap-complete@2x.png';
import roadmapItemIconInProgress from '../assets/landing/roadmap-in-progress@2x.png';
import roadmapItemIconPending from '../assets/landing/roadmap-pending@2x.png';
import quantstampImage from '../assets/landing/quantstamp@2x.png';
import Button, {RoundedButton} from "../components/Button";
import {pushTo} from "../utils/history";
import {ModalTypes, Urls} from "../constants";
import {NavLink} from "react-router-dom";
import UIState from "../store/uiState";
import {useDispatch} from "react-redux";
import {StakePLASMAPYCTA} from "../components/CTAPillLink";


const classNames = require('classnames');

const SectionSeparator = ({image}) => {
    return (
        <div className={'SectionSeparator'}>
            <img src={image}/>
        </div>
    );
}

export const PurchaseNFTsAt = () => {
    return (
        <div className={'PurchaseNFTsAt'}>
            <div className={'PurchaseNFTsAt__title'}>DON’T HAVE A PASSAWAY? GET ONE HERE:</div>
            <div className={'PurchaseNFTsAt__cards'}>
                <a className={'PurchaseNFTsAtCard'}
                   target={'_blank'}
                   href={Urls.THETA_DROP_MARKETPLACE_PASSAWAYS_ONLY}
                >
                    <img src={marketplaceThetaDropImage}/>
                </a>
                <a className={'PurchaseNFTsAtCard'}
                   target={'_blank'}
                   href={Urls.THETA_RARITY_MARKETPLACE}>
                    <img src={marketplaceThetaRarityImage}/>
                </a>
                <a className={'PurchaseNFTsAtCard'}
                   target={'_blank'}
                   href={Urls.OPEN_THETA_MARKETPLACE}>
                    <img src={marketplaceOpenThetaImage}/>
                </a>
            </div>
        </div>
    );
}

const AndEarnPLASMTokens = () => {
    return (
        <div className={'AndEarnPLASMTokens'}>
            <div>& earn</div>
            <img src={earnPLASMTokensImage}/>
            <div>tokens</div>
        </div>
    )
}


const PlasmPurposeCard = ({title, description, image}) => {
    return (
        <div className={'PlasmPurposeCard'}>
            <img src={image}/>
            <div className={'PlasmPurposeCard__title'}>{title}</div>
            <div className={'PlasmPurposeCard__description'}>
                {description}
            </div>
        </div>
    );
}

export const FeatureImageCard = ({image, href, onClick}) => {
    if(href) {
        return (
            <NavLink to={href}
                     className={'FeatureImageCard'}
                     onClick={onClick}
            >
                <img src={image}/>
            </NavLink>
        )
    }

    return (
        <a className={'FeatureImageCard'}
           href={href}
           onClick={onClick}
        >
            <img src={image}/>
        </a>
    )
}

const FeatureCard = ({title, description, image, links, color, isComingSoon}) => {
    return (
        <div className={classNames('FeatureCard', {[`FeatureCard--${color}`]: color})}>
            {
                isComingSoon &&
                <div className={'FeatureCard__coming-soon'}>COMING SOON</div>
            }
            <img className={'FeatureCard__image'} src={image}/>
            <div className={'FeatureCard__title'}>{title}</div>
            <div className={'FeatureCard__description'}>{description}</div>
            {
                links &&
                <div className={'FeatureCard__links'}>
                    {
                        links.map(({title, href, onClick}) => {
                            return (
                                <a className={'FeatureCard__link'}
                                   href={href}
                                   onClick={onClick}
                                   target={_.startsWith(href, '/') ? '_self' : '_blank'}
                                >
                                    {title}
                                </a>
                            );
                        })
                    }
                </div>
            }
        </div>
    )
}

const PassawaysSection = () => {
    return (
        <div  id={'nfts'}
              className={'PassawaysSection'}>
            <img className={'PassawaysSection__hero-image'}
                 src={heroPassawaysImage}/>
            <div className={'PassawaysSection__title'}>
                HELLO. WE ARE
                <br/>
                THE PASSAWAYS!
            </div>
            <div className={'TitleSeparatorImage'}>
                <div className={'TitleSeparatorImage__title'}>GENERATIVE NFTs</div>
                <div className={'TitleSeparatorImage__separator'}/>
                <img className={'TitleSeparatorImage__image'} src={deployedOnImage}/>
            </div>
            <div className={'PassawaysSection__description'}>
                We are interstellar travelers who occasionally step into your world from ours. In Jan 2022, 9,666 of us were dropped on ThetaDrop.com and were sold out immediately. It was an exciting time but nothing compared to what we have planned for you.
            </div>

            <div className={'FeatureCards'}>
                <FeatureCard title={'STAKE AND\nEARN PLASM'}
                             description={'Staking is tier-based. Power-up slots are available to increase PLASM earning rates. Power-ups can be purchased with PLASM in the Supply Store.'}
                             image={featureNFTStakeImage}
                             color={'green'}
                             links={[
                                 {title: 'VISIT THE SUPPLY STORE', href: Urls.JUMP_TPMC_SUPPLY_STORE},
                             ]}
                />
                <FeatureCard title={'TRANSPHORM\nYOUR\nPASSAWAY'}
                             description={'Burn NFTs to mint an AWESOME new one. A Transphormed Passaway also increases your PLASM earn rate when staked.'}
                             image={featureTransphormImage}
                             color={'pink'}
                             links={[
                                 {title: 'TRANSPHORM PASSAWAY', href: Urls.JUMP_TPMC_TRANSPHORM}
                             ]}
                />
            </div>

            <RoundedButton title={'Stake your Passaway'}
                           color={'green'}
                           size={'xlarge'}
                           className={'PassawaysSection__stake-button'}
                           onClick={() => {
                                 pushTo(Urls.TPMC_STAKE_NFTS);
                           }}
            />
            <AndEarnPLASMTokens/>
            <PurchaseNFTsAt/>
            <SectionSeparator image={sectionSeparatorImage1}/>
        </div>
    )
}

const PlasmSection = () => {
    return (
        <div  id={'plasm'}
              className={'PlasmSection'}>
            <img className={'PassawaysSection__hero-image'}
                 src={heroPlasmImage}/>
            <div className={'PlasmSection__description'}>
                PLASM is a TNT-20 utility & governance token used within the PASSAWAY/TPMC ecosystem on THETA
            </div>
            <div className={'PlasmSection__title'}>
                <span>STAKE PLASM</span>
                <br/>
                <span>MAKE PLASM</span>
            </div>
            <StakePLASMAPYCTA/>
            <div className={'PlasmSection__description'}>
                The PLASM you earn from staking Passaways (or swapping) can be staked at any time to earn even more PLASM!
            </div>
            <RoundedButton title={'Stake your PLASM'}
                           color={'green'}
                           size={'xlarge'}
                           className={'PlasmSection__stake-button'}
                           onClick={() => {
                               pushTo(Urls.TPMC_STAKE_PLASM);
                           }}
            />
            <AndEarnPLASMTokens/>
            <div className={'PlasmPurposes'}>
                <div className={'PlasmPurposes__title'}>
                    PLASM SERVES SEVERAL PURPOSES:
                </div>
                <div className={'PlasmPurposeCards'}>
                    <PlasmPurposeCard title={'GOVERNANCE'}
                                      description={'PLASM is the ecosystem’s governance token, allowing PLASM holders to participate in shaping the future.'}
                                      image={plasmGovernanceImage}
                    />
                    <PlasmPurposeCard title={'UTILITY'}
                                      description={'PLASM is the ecosystem’s utility token, giving all of its participants a shared currency that can be used within the ecosystem.'}
                                      image={plasmUtilityImage}
                    />
                    <PlasmPurposeCard title={'ACCESS'}
                                      description={'PLASM provides access to certain parts of the ecosystem that are otherwise unavailable, such as games, merch, and services.'}
                                      image={plasmAccessImage}
                    />
                    <PlasmPurposeCard title={'BUILD'}
                                      description={'PLASM is a tool for third-party developers to participate in the ecosystem by incorporating our token into games, services and other projects.'}
                                      image={plasmBuildImage}
                    />
                </div>
            </div>
            <div className={'PlasmSection__darkpaper-and-audit-info'}>
                <RoundedButton title={'READ PLASM Darkpaper'}
                               color={'black-green'}
                               size={'xlarge'}
                               className={'PlasmSection__darkpaper-button'}
                               target={'_blank'}
                               href={'/pdfs/plasm-darkpaper-latest.pdf'}
                />
                <div className={'PlasmAudit'}>
                    <span>
                        PLASM Token audited by:
                    </span>
                    <img src={quantstampImage}/>
                </div>
                <a className={'PlasmSection__read-audit-button'}
                   target={'_blank'}
                   href={'/pdfs/plasm-token-audit-report.pdf'}
                >
                    READ AUDIT REPORT
                </a>
            </div>
            <SectionSeparator image={sectionSeparatorImage2}/>
        </div>
    )
}

const TPMCSection = () => {
    return (
        <div  id={'tpmc'}
              className={'TPMCSection'}>
            <img className={'TPMCSection__hero-image'}
                 src={heroTPMCImage}/>
            <div className={'TPMCSection__title'}>
                MEMBERS ONLY BENEFITS
            </div>
            <div className={'TPMCSection__description'}>
                Owning a Passaway grants you exclusive access into The Passaways Misadventure Club (TPMC). A membership-only community which includes ongoing giveaways, airdrops to owners, a Supply Store where you can purchase Power-ups, Transphorm kits, Phlair, Merch and more...
            </div>

            <RoundedButton title={'Prove Membership'}
                           color={'red'}
                           size={'xlarge'}
                           className={'TPMCSection__prove-membership-button'}
                           onClick={() => {
                               pushTo(Urls.TPMC_HOME);
                           }}
            />

            <div className={'FeatureCards'}>
                <FeatureCard title={'POWER-UP\nYOUR\nEARNINGS'}
                             description={'Ephemeral power-ups can be used in combination with Perma power-ups (badges) to increase PLASM earnings'}
                             image={featurePowerUpsImage}
                             color={'green'}
                             links={[
                                 {title: 'VISIT THE SUPPLY STORE', href: Urls.JUMP_TPMC_SUPPLY_STORE},
                             ]}
                />
                <FeatureCard title={'CRAFTING\nKITS'}
                             description={'Collect and combine multiple NFTs to mint a new one. These can be used to enhance your Passaway and in turn increase your staking rewards.'}
                             image={featureCraftingImage}
                             color={'pink'}
                             links={[
                                 {title: 'VISIT THE SUPPLY STORE', href: Urls.JUMP_TPMC_SUPPLY_STORE},
                                 {title: 'CRAFT KITS', href: Urls.JUMP_TPMC_CRAFTING},
                             ]}
                />
                <FeatureCard title={'BADGES AS\nPOWER-UPS'}
                             description={'Badges that have been earned or acquired through giveaways or burn events can be used as Perma (Permanent) power-ups. If you’re not a current owner, it’s never too late.'}
                             image={featurePermaPowerUpsImage}
                             color={'pink'}
                             links={[
                                 {title: 'BUY THEM HERE', href: Urls.THETA_DROP_MARKETPLACE_BADGES_ONLY},
                             ]}
                />
                <FeatureCard title={'ADD\nPHLAIRITY'}
                             description={'Phlair is a new layer that can be swapped with an existing layer to customize your Passaway and increase it’s rarity. Adding Phlair will also increase your PLASM earnings.'}
                             image={featurePhlairImage}
                             color={'red'}
                             links={[
                                 {title: 'COMING SOON', href: Urls.JUMP_TPMC_SUPPLY_STORE},
                             ]}
                />
                <FeatureImageCard image={featureMerchImage}
                                  href={Urls.TPMC_HOME}
                />
                <FeatureImageCard image={featureDiscordImage}
                                  href={Urls.TPMC_DISCORD}
                />
            </div>
            <RoundedButton title={'Prove Membership'}
                           color={'red'}
                           size={'xlarge'}
                           style={{marginTop: -30, marginBottom: 10}}
                           onClick={() => pushTo(Urls.TPMC_HOME)}
                           className={'TPMCSection__prove-membership-button'}
            />
            <SectionSeparator image={sectionSeparatorImage3}/>
        </div>
    )
}

const RoadmapStatuses = {
    PENDING: 'pending',
    IN_PROGRESS: 'in-progress',
    COMPLETE: 'complete',
}

const RoadmapPhases = [
    {
        title: 'PHASE 1',
        items: [
            {
                text: 'Public Announcement Nov 2021',
                status: RoadmapStatuses.COMPLETE
            },
            {
                text: 'Community Giveaways (Discord, Twitter, etc)',
                status: RoadmapStatuses.COMPLETE
            },
            {
                text: 'Drop Jan 2022 (Sold out)',
                status: RoadmapStatuses.COMPLETE
            }
        ]
    },
    {
        title: 'PHASE 2',
        items: [
            {
                text: 'TPMC via ThetaPass',
                status: RoadmapStatuses.COMPLETE
            },
            {
                text: 'Merch Store',
                status: RoadmapStatuses.COMPLETE
            },
            {
                text: 'Exclusive Discord Channels',
                status: RoadmapStatuses.COMPLETE
            },
            {
                text: 'Community engagement events',
                status: RoadmapStatuses.COMPLETE
            },
            {
                text: 'Launch $PLASM (TNT-20 Token)',
                status: RoadmapStatuses.COMPLETE
            },
            {
                text: 'Tier-based NFT Staking',
                status: RoadmapStatuses.COMPLETE
            },
            {
                text: 'Perma/Ephemeral Power-ups',
                status: RoadmapStatuses.COMPLETE
            },
            {
                text: 'Supply Store (Spend $PLASM)',
                status: RoadmapStatuses.COMPLETE
            }
        ]
    },
    {
        title: 'PHASE 3',
        items: [
            {
                text: 'HODL Giveways (STAKE-2-WIN)',
                status: RoadmapStatuses.PENDING
            },
            {
                text: 'DOOM/7734 Airdrops',
                status: RoadmapStatuses.COMPLETE
            },
            {
                text: 'Crafting',
                status: RoadmapStatuses.COMPLETE
            },
            {
                text: 'Ongoing airdrops to holders / stakers',
                status: RoadmapStatuses.IN_PROGRESS
            },
            {
                text: 'Transphorm Passaway',
                status: RoadmapStatuses.COMPLETE
            },
            {
                text: 'Phormulate',
                status: RoadmapStatuses.IN_PROGRESS
            },
            {
                text: 'Phlair',
                status: RoadmapStatuses.IN_PROGRESS
            },
            {
                text: 'The Obit Contest',
                status: RoadmapStatuses.PENDING
            },
            {
                text: 'TPMC NFT Drops',
                status: RoadmapStatuses.COMPLETE
            }
        ]
    },
    {
        title: 'PHASE 4',
        items: [
            {
                text: 'Developer Hackathons ($PLASM)',
                status: RoadmapStatuses.PENDING
            },
            {
                text: 'Collabs',
                status: RoadmapStatuses.PENDING
            },
            {
                text: 'IP usage rights',
                status: RoadmapStatuses.PENDING
            },
            {
                text: 'Games using Passaways / $PLASM',
                status: RoadmapStatuses.PENDING
            },
        ]
    }
];

const TimelineItem = ({ data }) => {
    let image = roadmapItemIconPending;
    if (data.status === RoadmapStatuses.IN_PROGRESS) {
        image = roadmapItemIconInProgress;
    }
    else if (data.status === RoadmapStatuses.COMPLETE) {
        image = roadmapItemIconComplete;
    }
    return (
        <div className="timeline-item">
            <div className="timeline-item-content">
                <p>{data.text}</p>
                <img className="icon"
                     src={image}
                />
            </div>
        </div>
    )
};

const RoadmapPhase = ({title, items}) => {
    return (
        <div className={'RoadmapPhase'}>
            <div className={'RoadmapPhase__title'}>
                {title}
            </div>
            <div className="timeline-container">
                {items.map((data, idx) => (
                    <TimelineItem data={data} key={idx} />
                ))}
            </div>
        </div>
    )
}

const RoadmapSection = () => {
    return (
        <div  id={'roadmap'}
              className={'RoadmapSection'}>
            <div className={'RoadmapSection__title'}>
                Roadmap
                <br/>
                Activations
            </div>
            <div className={'RoadmapSection__phases'}>
                {
                    RoadmapPhases.map((data, idx) => (
                        <RoadmapPhase title={data.title}
                                      items={data.items}
                        />
                    ))
                }
            </div>
            <SectionSeparator image={sectionSeparatorImage4}/>
        </div>
    )
}

const TeamSection = () => {
    return (
        <div  id={'team'}
              className={'TeamSection'}>
            <div className={'TeamSection__title'}>
                Team
            </div>
            <div className={'TeamSection__body'}>
                WE ARE A SMALL TEAM OF WEB3/THETA ENTHUSIASTS WITH LIFETIMES OF EXPERIENCE BUILDING SHIT THAT USERS WANT AND USE.
            </div>
        </div>
    )
}

const FooterSection = () => {
    return (
        <div className={'FooterSection'}>
            <img src={footerImage}/>
        </div>
    )
}

function LandingPage() {
    return (
        <div className={'PageWrapper'}>
            <div className="Page LandingPage">
                <NavBar/>
                <TPMCHeroBanners banners={HeroBanners}/>
                <StakePLASMAPYCTA style={{
                    marginTop: -30,
                    marginBottom: 30
                }}/>
                <PassawaysSection/>
                <PlasmSection/>
                <TPMCSection/>
                <RoadmapSection/>
                <TeamSection/>
                <FooterSection/>
            </div>
        </div>
    );
}


export default LandingPage;

import _ from "lodash";
import React, {useState} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux'
import UIState from "../../store/uiState";
import ProductModal from "./Product";
import {countdownRenderText, formatBalance} from "../../utils";
import Wallet from "../../store/models/wallet";
import Countdown from "react-countdown";
import {RoundedButton} from "../../components/Button";
import {RoundedModal} from "../../components/Modal";
import QtySelector from "../../components/staking/QtySelector";

export class OpenPackModal extends React.Component {
    onClose = () => {
        this.props.dispatch(UIState.actions.hideModal());
    }
    onOpen = () => {
        const {nft} = this.props;

        // Burn the pack
        this.props.dispatch(Wallet.actions.openPack(nft));
    }

    render() {
        const {nft} = this.props;

        return (
            <RoundedModal title={'Pack'}
                          onClose={this.onClose}
                          className={'ProductModal'}
            >
                <div className={'ProductModal__content'}>
                    <img className={'ProductModal__image'}
                         src={nft.image}
                    />
                    <div className={'ProductModal__details'}>
                        <div className={'ProductModal__name'}>
                            <span>{nft.name}</span>
                        </div>
                        <div className={'ProductModal__description'}>{nft.description}</div>

                        <div className={'ProductModal__footer'}>
                            <RoundedButton title={'Open Pack'}
                                           color={'green'}
                                           size={'medium'}
                                           className={'ProductModal__buy-button'}
                                           onClick={() => {
                                               this.onOpen();
                                           }}
                            />
                        </div>

                    </div>
                </div>
            </RoundedModal>
        );
    }
}

const mapStateToProps = (state, props) => {
    const {action} = props;

    return {
        ...props,
    }
};

export default connect(mapStateToProps)(OpenPackModal);

import _ from 'lodash';
import React, {useState, useEffect, useContext} from 'react';
import logo from '../../assets/logo/the-passaways-combined.svg';
import {ModalTypes, Urls} from "../../constants";
import {thetaDropConnect} from "../../store/appState";
import {TPMCNavBar} from "../../components/NavBar";
import sectionSeparator from "../../assets/section/section-separator@2x.png";
import {createSelector} from "reselect";
import {selectUserNfts} from "../../store/models/nft";
import {selectCurrentUserId} from "../../store/models/user";
import {useDispatch, useSelector} from "react-redux";
import TPMCWarning from "../../components/TPMCWarning";
import UIState from "../../store/uiState";
import Storage, {HAS_VISITED} from "../../utils/storage";
import samplePassaway from '../../assets/samples/passaway.png';
import samplePack from '../../assets/samples/pack.png';
import {mergeUrl} from "../../utils/url";
import {NavLink} from "react-router-dom";

const getUserNfts = createSelector(
    (state) => selectUserNfts(state, selectCurrentUserId(state)),
    (nfts) => ({nfts})
);

const BadgeCard = ({nft}) => {
    let image = (nft.image || nft.content.image_url || nft.content.image);
    let name = (nft.name || nft.content.title || nft.content.name);
    image = image + '?width=200';
    name = name.replace('Badge', '').trim();

    return (
        <div className={'BadgeCard'}
        >
            <img className={'BadgeCard__img'} src={image}/>
            <div className={'BadgeCard__name'}>{name}</div>
        </div>
    )
}

const NFTCard = ({nft}) => {
    const image = (nft.image || nft.content.image_url);
    const name = (nft.name || nft.content.title);

    return (
        <NavLink className={'NFTCard'}
           to={mergeUrl(Urls.TPMC_COLLECTION_NFT, {
               nftId: nft.id
           })}
        >
            <img className={'NFTCard__img'} src={image}/>
            <div className={'NFTCard__name'}>{name}</div>
        </NavLink>
    )
}

function CollectionPage() {
    return (
        <div className={'PageWrapper'}>
            <div className="Page CollectionPage">
                <TPMCNavBar/>
            </div>
        </div>
    );

    const dispatch = useDispatch();
    const {nfts} = useSelector(getUserNfts);
    const nftsByType = _.groupBy(nfts, 'content_id');
    const tokenNfts = nftsByType['type_ijg5w4r8nwzb823ycg5hizatc6a'];
    const packNfts = nftsByType['pack_a2zndc39x7ab0121wfmw7v0grkp'];
    const badgeNfts = _.filter(nfts, (nft) => {
        return (
            nft.content_id !== 'type_ijg5w4r8nwzb823ycg5hizatc6a' &&
            nft.content_id !== 'pack_a2zndc39x7ab0121wfmw7v0grkp'
        );
    });

    useEffect(() => {
        if(_.isNil(Storage.getItem(HAS_VISITED))){
            dispatch(UIState.actions.showModal(ModalTypes.Welcome, {

            }));
            Storage.setItem(HAS_VISITED, true);
        }

    }, []);

    return (
        <div className={'PageWrapper'}>
            <div className="Page CollectionPage">
                <TPMCNavBar/>
                <div style={{height: 30}}/>
                {
                    !_.isEmpty(badgeNfts) &&
                    <React.Fragment>
                        <div className={'SectionTitle'}>Badges</div>
                        <div className={'NFTCards'}
                             style={{marginBottom: 30}}
                        >
                            {
                                badgeNfts.map((nft) => {
                                    return (
                                        <BadgeCard nft={nft}
                                        />
                                    );
                                })
                            }
                        </div>
                        <img src={sectionSeparator}
                             className={'SectionSeparator'}/>
                    </React.Fragment>
                }
                {
                    !_.isEmpty(tokenNfts) &&
                        <React.Fragment>
                            <div className={'SectionTitle'}>Passaways</div>
                            <div className={'NFTCards'}
                                 style={{marginBottom: 30}}
                            >
                                {
                                    tokenNfts.map((nft) => <NFTCard nft={nft}/>)
                                }
                            </div>
                            <img src={sectionSeparator}
                                 className={'SectionSeparator'}/>
                        </React.Fragment>
                }
                {
                    !_.isEmpty(packNfts) &&
                    <React.Fragment>
                        <div className={'SectionTitle'}>Containment Packs</div>
                        <div className={'NFTCards'}
                             style={{marginBottom: 30}}
                        >
                            {
                                packNfts.map((nft) => <NFTCard nft={nft}
                                                               key={nft.id}
                                />)
                            }
                        </div>
                    </React.Fragment>
                }
            </div>
        </div>
    );
}

export default CollectionPage;

import React, {useState} from "react";
import {useInterval} from "../../hooks/useInterval";
import {buildStyles, CircularProgressbar} from "react-circular-progressbar";
import 'react-circular-progressbar/dist/styles.css';
import classNames from "classnames";
import {getNFTImageThumb} from "../../utils/nfts";

export function ActiveEphemeralPowerUp({powerUp, size}) {
    const {nftContract, multiplier, start, end, startedAt, endsAt} = powerUp;
    const calcProgress = () => {
        return ((endsAt - (new Date().getTime())) / (endsAt - startedAt));
    };
    const [progress, setProgress] = useState(calcProgress());
    useInterval(() => {
        setProgress(calcProgress);
    }, 1000);

    return (
        <div className={classNames({
            'ActivePowerUp': true,
            'ActivePowerUp--Ephemeral': true,
            [`ActivePowerUp--${size}`]: true
        })}>
            <img src={getNFTImageThumb(nftContract)}/>
            <CircularProgressbar value={progress}
                                 maxValue={1}
                                 background={false}
                                 styles={buildStyles({
                                     pathColor: `#76FF80`,
                                     trailColor: 'transparent',
                                     strokeLinecap: 'butt',
                                 })}
                                 counterClockwise={true}
            />
        </div>
    );
}
import User from './user';
import Nft from './nft';
import Airdrop from './airdrop';
import Event from './event';
import Wallet from './wallet';
import Config from './config';

const modelTypes = {
    User,
    Nft,
    Airdrop,
    Event,
    Wallet,
    Config
}

export default modelTypes;

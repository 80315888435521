import React from 'react';
import {connect} from 'react-redux'
import Modal from '../../components/Modal';
import thetaDropLogo from '../../assets/login/theta-drop@2x.png';
import thetaWalletLogo from '../../assets/login/theta-wallet@2x.png';
import thetaRarityLogo from '../../assets/login/theta-rarity@2x.png';
import metamaskLogo from '../../assets/login/metamask@2x.png';
import UIState from "../../store/uiState";
import {selectCurrentUser} from "../../store/models/user";
import Button from "../../components/Button";
import {LoginTypes} from "../../constants";

export class ProveOwnershipModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false
        };
    }

    onClose = () => {
        this.props.dispatch(UIState.actions.hideModal());
    }

    render() {
        const {onLoginOptionClick} = this.props;
        const {isLoading} = this.state;
        const loginButton = (logo, type) => {
            return (
                <Button size={'large'}
                        target={'_blank'}
                        color={'black'}
                        onClick={() => {
                            this.onClose();
                            onLoginOptionClick(type);
                        }}
                        isLoading={isLoading}
                        style={{width: '100%', marginBottom: 14, height: 60}}
                >
                    <img src={logo} style={{height: 38}}/>
                </Button>
            );
        }

        return (
            <Modal>
                <div className="ProveOwnershipModal">
                    <div className={'ProveOwnershipModal__title'}>
                        Prove
                        <br/>
                        Ownership
                    </div>
                    <div className={'ProveOwnershipModal__subtitle'}>
                        Prove Passaway ownership using one of the options below.
                    </div>

                    {loginButton(thetaWalletLogo, LoginTypes.ThetaWallet)}
                    {loginButton(metamaskLogo, LoginTypes.MetaMask)}
                    {loginButton(thetaDropLogo, LoginTypes.ThetaDrop)}
                    {loginButton(thetaRarityLogo, LoginTypes.ThetaRarity)}

                    <a className={'ProveOwnershipModal__cancel'}
                       onClick={this.onClose}
                    >Cancel</a>
                </div>
            </Modal>
        )
    }
}


const mapStateToProps = (state, modalProps) => {
    return {
        ...modalProps
    }
};

export default connect(mapStateToProps)(ProveOwnershipModal);

import {useDispatch} from "react-redux";
import {useHover} from "../../hooks/useHover";
import {PowerUpTypes} from "../../constants";
import {ActivePermaPowerUp} from "./ActivePermaPowerUp";
import {ActiveEphemeralPowerUp} from "./ActiveEphemeralPowerUp";
import pPowerUpIcon from "../../assets/stake/power-up-slots/p-power-up@2x.png";
import pPowerUpIconHover from "../../assets/stake/power-up-slots/p-power-up-hover@2x.png";
import pPowerUpIconReady from "../../assets/stake/power-up-slots/p-power-up-ready@2x.png";
import pPowerUpIconReadyHover from "../../assets/stake/power-up-slots/p-power-up-ready-hover@2x.png";
import ePowerUpIconReady from "../../assets/stake/power-up-slots/e-power-up-ready@2x.png";
import ePowerUpIconReadyHover from "../../assets/stake/power-up-slots/e-power-up-ready-hover@2x.png";
import ePowerUpIcon from "../../assets/stake/power-up-slots/e-power-up@2x.png";
import ePowerUpIconHover from "../../assets/stake/power-up-slots/e-power-up-hover@2x.png";
import classNames from "classnames";
import _ from "lodash";
import React from "react";

export function PowerUpSlot({powerUpType, powerUp, isReady, onClick}) {
    const dispatch = useDispatch();
    let innerContent = null;
    const [hoverRef, isHovered] = useHover();

    if(powerUp){
        if(powerUpType === PowerUpTypes.Permanent){
            innerContent = <ActivePermaPowerUp powerUp={powerUp}/>
        }
        else if(powerUpType === PowerUpTypes.Ephemeral){
            innerContent = <ActiveEphemeralPowerUp powerUp={powerUp}/>
        }
    }
    else{
        let images = [];
        if(powerUpType === PowerUpTypes.Permanent){
            if(isReady){
                images = [pPowerUpIconReady, pPowerUpIconReadyHover];
            }
            else{
                images = [pPowerUpIcon, pPowerUpIconHover];
            }
        }
        else if(powerUpType === PowerUpTypes.Ephemeral){
            if(isReady){
                images = [ePowerUpIconReady, ePowerUpIconReadyHover];
            }
            else{
                images = [ePowerUpIcon, ePowerUpIconHover];
            }
        }
        innerContent = (
            <img src={isHovered ? images[1] : images[0]}/>
        );
    }

    return (
        <a onClick={onClick}
           ref={hoverRef}
           className={classNames({
               'PowerUpSlot': true,
               'PowerUpSlot--perma-active': (powerUpType === PowerUpTypes.Permanent && !_.isNil(powerUp))
           })}
        >
            {innerContent}
        </a>
    );
}
import _ from 'lodash';
import {ThetaDropConnect} from '@thetalabs/theta-drop-connect';
import Storage, {USER_TOKEN} from '../utils/storage';
import User, { LOGOUT } from "./models/user";
import {getJWTExpirationDate, getJWTPayload, isJwtExpired} from "../utils/security";
import {history, pushTo} from "../utils/history";
import storage from "../utils/storage";
import Wallet, {SET_LOGIN_TYPE} from "./models/wallet";
import Config from "./models/config";
import Nfts from "./models/nft";
import {LoginTypes} from "../constants";

// ===========================
// HELPERS
// ===========================
export const thetaDropConnect = new ThetaDropConnect();


// ===========================
// ACTIONS
// ===========================
export const APP_START = 'APP_START';
export const SET_USER = 'SET_USER';
export const SET_PARTNER = 'SET_PARTNER';



// ===========================
// SELECTORS
// ===========================

// ===========================
// MODEL
// ===========================

const AppState = {
    actions: {
        startApp: () => async (dispatch, getState) => {
            const userId = null;

            dispatch({
                type: APP_START,
                userId
            });
        },

        recoverSession: () => async (dispatch, getState) => {
            try {
                const userToken = storage.getItem(USER_TOKEN);
                if(_.isNil(userToken) || isJwtExpired(userToken)) {
                    storage.clearItem(USER_TOKEN);
                    return;
                }

                const payload = getJWTPayload(userToken);
                const {login_type: loginType, sub: address, is_owner: isOwner} = payload || {};

                console.log('payload == ', payload);
                console.log('recoverSession :: loginType == ', loginType);

                if(loginType === LoginTypes.ThetaDrop || loginType === LoginTypes.ThetaRarity) {
                    // Don't recover these read-only sessions
                    storage.clearItem(USER_TOKEN);
                    return;
                }

                if (userToken && address) {
                    dispatch({
                        type: SET_LOGIN_TYPE,
                        loginType
                    });
                    dispatch(User.actions.setUserCredentials(address, userToken, isOwner));
                    switch (loginType) {
                        case LoginTypes.MetaMask:
                            await dispatch(Wallet.actions.recoverMetamaskProvider());
                            break;
                        case LoginTypes.ThetaWallet:
                            await dispatch(Wallet.actions.recoverWalletConnectProvider());
                    }
                    await dispatch(Config.actions.fetchConfig());
                    dispatch(Wallet.actions.getBalances());
                    dispatch(Nfts.actions.fetchNfts(address));
                }
            }
            catch (e) {
                console.error(e);
            }
        },

        setAuth: (userId, userToken, isOwner) => (dispatch, getState) => {
            const expirationDate = getJWTExpirationDate(userToken);
            const expiresInSecs = (expirationDate.getTime() - new Date().getTime());

            if(expiresInSecs < 2000000000){
                setTimeout(() => {
                    dispatch(AppState.actions.logout());
                }, expiresInSecs);
            }

            storage.setItem(USER_TOKEN, userToken);

            dispatch({
                type: SET_USER,
                userId,
                userToken,
                isOwner
            });
        },

        logout: (dispatch, getState) => {
            storage.clearItem(USER_TOKEN);

            pushTo('/tpmc/login');
            window.location.reload();
        }
    },

    spec: {
        userId: null,
        appStarted: false,
        userToken: null,
        isOwner: false
    },

    reducer
        (state = AppState.spec, action) {
        let {result, url, body, json, type} = action;
        switch (type) {
            case APP_START:
                return {...state,
                    appStarted: true,
                    userId: action.userId,
                };
            case SET_USER:
                return {
                    ...state,
                    userId: action.userId,
                    userToken: action.userToken,
                    isOwner: action.isOwner};
            case LOGOUT:
                return {...state, userId: null, userToken: null, isOwner: false};
        }
        return state;
    },
}
export default AppState;

import React from 'react';
import {connect, useSelector} from 'react-redux'
import Modal, {RoundedModal} from '../../components/Modal';
import UIState from "../../store/uiState";
import _ from "lodash";
import {RoundedButton} from "../../components/Button";
import {ContractCategories, CurrentNetwork, ModalTypes, UseInventoryActions} from "../../constants";
import {selectNftBalance} from "../../store/models/nft";
import {selectConfig, selectSupplyStoreProduct} from "../../store/models/config";
import classNames from "classnames";
import {InventoryItemCard} from "../../components/staking/InventoryItemCard";
import {getNFTImage} from "../../utils/nfts";

export class UseInventoryModal extends React.Component {
    onClose = () => {
        this.props.dispatch(UIState.actions.hideModal());
    }

    onSelect = (nft) => {
        const {onSelect} = this.props;

        onSelect(nft);
    }

    onBuy = (nft) => {
        this.props.dispatch(UIState.actions.showNFTPurchaseModal(nft));
    }

    render() {
        const {title, nfts, action, canUse} = this.props;

        return (
            <RoundedModal title={title}
                          onClose={this.onClose}
            >
                <div className={'UseInventoryModal__items'}>
                    {
                        _.map(nfts, (nft) => {
                            const isEligible = _.isNil(canUse) || canUse(nft);
                            return (
                                <InventoryItemCard nft={nft}
                                                   buttonTitle={isEligible ? 'Use' : 'Not Eligible'}
                                                   isEligibleForUse={isEligible}
                                                   onSelect={this.onSelect}
                                                   onBuy={this.onBuy}
                                />
                            )
                        })
                    }
                </div>
            </RoundedModal>
        );
    }
}

const mapStateToProps = (state, props) => {
    let config = selectConfig(state);
    const {action, onSelect, canUse} = props;
    let nfts = [];

    const contracts = _.filter(Object.values(config.contracts), (contract) => {
        if(action === UseInventoryActions.PermanentPowerUp){
            return contract.permanentPowerUpEligible;
        }
        else if(action === UseInventoryActions.EphemeralPowerUp){
            return (contract.category === ContractCategories.PowerUp && contract.permanentPowerUpEligible !== true);
        }
    });
    nfts = _.map(contracts, (contract) => {
        return {
            ...contract,
            image: getNFTImage(contract.key),
            balance: selectNftBalance(state, contract.address),
        }
    });
    nfts = _.orderBy(nfts, 'balance', ['desc']);

    return {
        ...props,
        nfts: nfts
    }
};

export default connect(mapStateToProps)(UseInventoryModal);

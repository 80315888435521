export const TwitterUrl = 'https://twitter.com/thepassaways';
export const DiscordUrl = 'https://discord.gg/8j6jGwyNsV';

export const NotionUrl = 'https://passaways.notion.site/TPMC-Wiki-1e085b0426ac407687ce599d7b42d5a2';

export const SHOW_TPMC_LINK = true;

export const SHOW_TPMC_DISCORD_LINK = true;

export const ThetaDropAppId = 'dapp_0ivf9fx0fyn5y1dmui6q9t9w42a';
export const ThetaDropRedirectURL = process.env.REACT_APP_THETA_DROP_REDIRECT_URL;
export const DiscordOAuthURL = process.env.REACT_APP_DISCORD_OAUTH_URL;

export const CurrencyType = {
    TFUEL: 0,
    TDROP: 1,
    PLASM: 2
}

export const Hosts = {
    API: process.env.REACT_APP_API_HOST,
    THETA_DROP_API: process.env.REACT_APP_THETA_DROP_API_HOST,
}

export const Colors = {
    RED: '#FF185F',
    GREEN: '#76FF80',
    THETACON_GREEN: '#3f9870'
}

export const Urls = {
    HOME: '/',
    TERMS: '/terms',
    PRIVACY: '/privacy',

    TWITTER: TwitterUrl,
    DISCORD: DiscordUrl,
    THETA_DROP_MICROSITE: 'https://passaways.thetadrop.com',
    THETA_DROP_MARKETPLACE: 'https://passaways.thetadrop.com/marketplace?content_creator=user_c14333f0y1c7hnd7jzjf1wz4hsi',
    THETA_DROP_MARKETPLACE_PASSAWAYS_ONLY: 'https://passaways.thetadrop.com/marketplace/edition/type_ijg5w4r8nwzb823ycg5hizatc6a',
    THETA_DROP_MARKETPLACE_BADGES_ONLY: 'https://passaways.thetadrop.com/marketplace?content_creator=user_c14333f0y1c7hnd7jzjf1wz4hsi&type=token&search=badge',
    THETA_RARITY_MARKETPLACE: 'https://passawaysrarity.com/?sale=1',
    OPEN_THETA_MARKETPLACE: 'https://opentheta.io/collection/the-passaways',
    THETA_RARITY_MARKETPLACE_FOR_CONTRACT_ADDRESS: 'https://passawaysrarity.com/collection/:contractAddressOrTypeId?sort=price&sale=1&rarity=0&_ui=compact',


    SHOP_ACCESS_URI: `${Hosts.API}/v1/shop/access`,

    // TPMC
    TPMC: '/tpmc',
    TPMC_LOGIN: '/tpmc/login',
    TPMC_ACCESS_DENIED: '/tpmc/access-denied',
    // TPMC_HOME: '/tpmc/home',
    TPMC_HOME: '/tpmc/home',
    TPMC_COLLECTION_NFT: '/tpmc/collection/nft/:nftId',
    TPMC_AIRDROP_999ERS: '/tpmc/airdrop/999ers',
    TPMC_AIRDROP_LOYALISTS: '/tpmc/airdrop/loyalists',
    TPMC_DISCORD: '/tpmc/discord',
    TPMC_VOTE_VALENTINE: '/tpmc/valentine/vote',
    TPMC_THETACON_BADGE_SWAP: '/tpmc/thetacon/claim',
    TPMC_THETACON22: '/tpmc/thetacon22',
    TPMC_APPROVALS: '/tpmc/approvals',
    TPMC_NFT_DROP_LIVE: '/tpmc/drops',
    TPMC_NFT_DROP: '/tpmc/drop/:nftDropId',
    TPMC_LOTTERY: '/tpmc/raffle',
    TPMC_7734_GUARDIAN: '/tpmc/7734-guardian',

    PROOF_OF_TRANSPHORM: '/transphorm/:edition',

    // TPMC / Staking
    TPMC_STAKE_NFTS: '/tpmc/stake/nfts',
    TPMC_STAKE_PLASM: '/tpmc/stake/plasm',

    // Swaps
    THETACON_BADGE_SWAP_STATUS: '/thetacon/check',

    // Events
    EVENT_FLOOR_RAISER: '/e/floor-raiser',
    EVENT_DREADFUL_VALENTINE: '/e/valentine',
    EVENT_DREADFUL_VALENTINE_CREATE: '/e/valentine/create',
    EVENT_OPERATION_MANHUNT: '/e/manhunt',

    //OAUTH
    OAUTH_DISCORD: '/oauth/discord',

    // Jump links
    JUMP_TPMC_MERCH: '/tpmc/merch',
    JUMP_TPMC_CRAFTING: '/tpmc/crafting',
    JUMP_TPMC_SUPPLY_STORE: '/tpmc/supply-store',
    JUMP_TPMC_REDEEM: '/tpmc/redeem',
    JUMP_TPMC_TRANSPHORM: '/tpmc/transphorm',
    JUMP_STAKE_PLASM: '/stake',
    JUMP_GET_PLASM: '/tpmc/get-plasm',
}

export const RequestState = {
    REQUEST: 'REQUEST',
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
    NOT_AUTHORIZED: 'NOT_AUTHORIZED',
}

export const ModalTypes = {
    Welcome: 'Welcome',
    Shop: 'Shop',
    ConnectWallet: 'ConnectWallet',
    ProveOwnership: 'ProveOwnership',
    PassawaySelector: 'PassawaySelector',
    UseInventory: 'UseInventory',
    SupplyStore: 'SupplyStore',
    Plasm: 'Plasm',
    Product: 'Product',
    PowerUpSlotProduct: 'PowerUpSlotProduct',
    SupplyStoreProduct: 'SupplyStoreProductModal',
    ActivePowerUp: 'ActivePowerUp',
    Inventory: 'Inventory',
    Alert: 'Alert',
    Crafting: 'Crafting',
    Redeeming: 'Redeeming',
    Transphorm: 'Transphorm',
    Loader: 'Loader',
    Marketplaces: 'Marketplaces',
    MobileWallets: 'MobileWallets',
    UnstakePlasm: 'UnstakePlasm',
    GetPlasm: 'GetPlasm',
    ExpeditePassawayTransphormOrder: 'ExpeditePassawayTransphormOrder',
    ExpediteCraftableItemOrder: 'ExpediteCraftableItemOrder',
    VIPDeposit: 'VIPDeposit',
    OpenPack: 'OpenPack',
}

export const EventCountdown = {
    title: 'Claim your Passaways Badge on ThetaDrop',
    subtitle: 'THETACON BADGE',
    date: Date.parse('20 May 2022 14:00:00 PDT'),
    cta: {
        title: 'Learn More',
        href: Urls.THETACON_BADGE_SWAP_STATUS
    }
}

export const StorageKeys = {
    CurrentAccount: 'WALLET_CURRENT_ACCOUNT'
}

export const LoginTypes = {
    ThetaDrop: 'ThetaDrop',
    MetaMask: 'MetaMask',
    ThetaWallet: 'ThetaWallet',
    ThetaRarity: 'ThetaRarity',
}

export const PowerUpTypes = {
    Permanent: 'permanent',
    Ephemeral: 'ephemeral'
}

export const getWalletSigninNonce = () => {
    return (new Date()).getTime();
};

export const getWalletSigninMessage = () => {
    const nonce = getWalletSigninNonce();
    return `Login to TPMC\n\nNonce: ${nonce}`;
};

export const ContractCategories = {
    Badge: 'Badge',
    PowerUp: 'PowerUp',
    Transphorm: 'Transphorm',
    Phlair: 'Phlair',
    Phormulate: 'Phormulate',
    Blueprint: 'Blueprint',
    Extras: 'Extras',
    Pack: 'Pack',
}

export const SupplyStore = {
    products: [

    ]
};

export const UseInventoryActions = {
    PermanentPowerUp: 'PermanentPowerUp',
    EphemeralPowerUp: 'EphemeralPowerUp'
};

export const CurrentNetwork = process.env.REACT_APP_CURRENT_NETWORK;
export const IsPLASMLive = true;
export const IsNFTStakingLive = true;

export const BlockTimeInSecsByNetwork = {
    'privatenet': 1,
    'testnet': 6,
    'mainnet': 6
}

export const AddressZero = "0x0000000000000000000000000000000000000000";

export const NetworkInfoByChainId = {
    ['privatenet']: {
        ethRpc: 'http://localhost:18888/rpc',
        chainId: 'privatenet',
        chainIdNum: 366,
        chainIdHex: '0x16E'
    },
    ['testnet']: {
        ethRpc: 'https://eth-rpc-api-testnet.thetatoken.org/rpc',
        chainId: 'testnet',
        chainIdNum: 365,
        chainIdHex: '0x16D'
    },
    ['mainnet']: {
        ethRpc: 'https://eth-rpc-api.thetatoken.org',
        chainId: 'mainnet',
        chainIdNum: 361,
        chainIdHex: '0x169',
    }
}

export const CurrentNetworkInfo = NetworkInfoByChainId[CurrentNetwork];

export const WalletConnectProjectId = '84bb8112d3b2c6f944e3fec6bd3b8c2d';

export const WalletConnectionTypes = {
    MetaMask: 'MetaMask',
    ThetaWallet: 'ThetaWallet',
}

export const getSecondaryMarketUrl = (nft) => {
    const {typeId, address} = nft;

    return Urls.THETA_RARITY_MARKETPLACE_FOR_CONTRACT_ADDRESS.replace(':contractAddressOrTypeId', (typeId || address));
}

export const ThetaSwap = {
    PAIRS:{
      TFUEL_PLASM: {
          address: '0xd81e916c837aef1373ccff42aceb5e4e61d10cc3',
          tokenA: {
              address: '0x4dc08b15ea0e10b96c41aec22fab934ba15c983e',
              symbol: 'WTFUEL',
              decimals: 18
          },
          tokenB: {
              address: '0x5c2fb1e2594e85c5f1579b07dd5b8dfea3f929e0',
              symbol: 'PLASM',
              decimals: 18
          },
          tokenAddressA: '0x4dc08b15ea0e10b96c41aec22fab934ba15c983e',
          tokenAddressB: '0x5c2fb1e2594e85c5f1579b07dd5b8dfea3f929e0',
      }
    },
    SETTINGS: {
        SLIPPAGE_TOLERANCE: 0.02,
        TRANSACTION_DEADLINE_IN_MS: 20 * 60 * 1000
    },
    WTFUEL: {
        address: "0x4dc08b15ea0e10b96c41aec22fab934ba15c983e",
        abi: [
            {
                "constant": true,
                "inputs": [],
                "name": "name",
                "outputs": [
                    {
                        "name": "",
                        "type": "string"
                    }
                ],
                "payable": false,
                "stateMutability": "view",
                "type": "function"
            },
            {
                "constant": false,
                "inputs": [
                    {
                        "name": "guy",
                        "type": "address"
                    },
                    {
                        "name": "wad",
                        "type": "uint256"
                    }
                ],
                "name": "approve",
                "outputs": [
                    {
                        "name": "",
                        "type": "bool"
                    }
                ],
                "payable": false,
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "constant": true,
                "inputs": [],
                "name": "totalSupply",
                "outputs": [
                    {
                        "name": "",
                        "type": "uint256"
                    }
                ],
                "payable": false,
                "stateMutability": "view",
                "type": "function"
            },
            {
                "constant": false,
                "inputs": [
                    {
                        "name": "src",
                        "type": "address"
                    },
                    {
                        "name": "dst",
                        "type": "address"
                    },
                    {
                        "name": "wad",
                        "type": "uint256"
                    }
                ],
                "name": "transferFrom",
                "outputs": [
                    {
                        "name": "",
                        "type": "bool"
                    }
                ],
                "payable": false,
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "constant": false,
                "inputs": [
                    {
                        "name": "wad",
                        "type": "uint256"
                    }
                ],
                "name": "withdraw",
                "outputs": [],
                "payable": false,
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "constant": true,
                "inputs": [],
                "name": "decimals",
                "outputs": [
                    {
                        "name": "",
                        "type": "uint8"
                    }
                ],
                "payable": false,
                "stateMutability": "view",
                "type": "function"
            },
            {
                "constant": true,
                "inputs": [
                    {
                        "name": "",
                        "type": "address"
                    }
                ],
                "name": "balanceOf",
                "outputs": [
                    {
                        "name": "",
                        "type": "uint256"
                    }
                ],
                "payable": false,
                "stateMutability": "view",
                "type": "function"
            },
            {
                "constant": true,
                "inputs": [],
                "name": "symbol",
                "outputs": [
                    {
                        "name": "",
                        "type": "string"
                    }
                ],
                "payable": false,
                "stateMutability": "view",
                "type": "function"
            },
            {
                "constant": false,
                "inputs": [
                    {
                        "name": "dst",
                        "type": "address"
                    },
                    {
                        "name": "wad",
                        "type": "uint256"
                    }
                ],
                "name": "transfer",
                "outputs": [
                    {
                        "name": "",
                        "type": "bool"
                    }
                ],
                "payable": false,
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "constant": false,
                "inputs": [],
                "name": "deposit",
                "outputs": [],
                "payable": true,
                "stateMutability": "payable",
                "type": "function"
            },
            {
                "constant": true,
                "inputs": [
                    {
                        "name": "",
                        "type": "address"
                    },
                    {
                        "name": "",
                        "type": "address"
                    }
                ],
                "name": "allowance",
                "outputs": [
                    {
                        "name": "",
                        "type": "uint256"
                    }
                ],
                "payable": false,
                "stateMutability": "view",
                "type": "function"
            },
            {
                "payable": true,
                "stateMutability": "payable",
                "type": "fallback"
            },
            {
                "anonymous": false,
                "inputs": [
                    {
                        "indexed": true,
                        "name": "src",
                        "type": "address"
                    },
                    {
                        "indexed": true,
                        "name": "guy",
                        "type": "address"
                    },
                    {
                        "indexed": false,
                        "name": "wad",
                        "type": "uint256"
                    }
                ],
                "name": "Approval",
                "type": "event"
            },
            {
                "anonymous": false,
                "inputs": [
                    {
                        "indexed": true,
                        "name": "src",
                        "type": "address"
                    },
                    {
                        "indexed": true,
                        "name": "dst",
                        "type": "address"
                    },
                    {
                        "indexed": false,
                        "name": "wad",
                        "type": "uint256"
                    }
                ],
                "name": "Transfer",
                "type": "event"
            },
            {
                "anonymous": false,
                "inputs": [
                    {
                        "indexed": true,
                        "name": "dst",
                        "type": "address"
                    },
                    {
                        "indexed": false,
                        "name": "wad",
                        "type": "uint256"
                    }
                ],
                "name": "Deposit",
                "type": "event"
            },
            {
                "anonymous": false,
                "inputs": [
                    {
                        "indexed": true,
                        "name": "src",
                        "type": "address"
                    },
                    {
                        "indexed": false,
                        "name": "wad",
                        "type": "uint256"
                    }
                ],
                "name": "Withdrawal",
                "type": "event"
            }
        ]
    },
    V2_ROUTER: {
        address: '0x8366537d56cf2b86ca90e9dbc89450207a29f6e3',
        abi: [
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "_factory",
                        "type": "address"
                    },
                    {
                        "internalType": "address",
                        "name": "_WETH",
                        "type": "address"
                    }
                ],
                "stateMutability": "nonpayable",
                "type": "constructor"
            },
            {
                "inputs": [],
                "name": "WETH",
                "outputs": [
                    {
                        "internalType": "address",
                        "name": "",
                        "type": "address"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "tokenA",
                        "type": "address"
                    },
                    {
                        "internalType": "address",
                        "name": "tokenB",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "amountADesired",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "amountBDesired",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "amountAMin",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "amountBMin",
                        "type": "uint256"
                    },
                    {
                        "internalType": "address",
                        "name": "to",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "deadline",
                        "type": "uint256"
                    }
                ],
                "name": "addLiquidity",
                "outputs": [
                    {
                        "internalType": "uint256",
                        "name": "amountA",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "amountB",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "liquidity",
                        "type": "uint256"
                    }
                ],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "token",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "amountTokenDesired",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "amountTokenMin",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "amountETHMin",
                        "type": "uint256"
                    },
                    {
                        "internalType": "address",
                        "name": "to",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "deadline",
                        "type": "uint256"
                    }
                ],
                "name": "addLiquidityETH",
                "outputs": [
                    {
                        "internalType": "uint256",
                        "name": "amountToken",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "amountETH",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "liquidity",
                        "type": "uint256"
                    }
                ],
                "stateMutability": "payable",
                "type": "function"
            },
            {
                "inputs": [],
                "name": "factory",
                "outputs": [
                    {
                        "internalType": "address",
                        "name": "",
                        "type": "address"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "uint256",
                        "name": "amountOut",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "reserveIn",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "reserveOut",
                        "type": "uint256"
                    }
                ],
                "name": "getAmountIn",
                "outputs": [
                    {
                        "internalType": "uint256",
                        "name": "amountIn",
                        "type": "uint256"
                    }
                ],
                "stateMutability": "pure",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "uint256",
                        "name": "amountIn",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "reserveIn",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "reserveOut",
                        "type": "uint256"
                    }
                ],
                "name": "getAmountOut",
                "outputs": [
                    {
                        "internalType": "uint256",
                        "name": "amountOut",
                        "type": "uint256"
                    }
                ],
                "stateMutability": "pure",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "uint256",
                        "name": "amountOut",
                        "type": "uint256"
                    },
                    {
                        "internalType": "address[]",
                        "name": "path",
                        "type": "address[]"
                    }
                ],
                "name": "getAmountsIn",
                "outputs": [
                    {
                        "internalType": "uint256[]",
                        "name": "amounts",
                        "type": "uint256[]"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "uint256",
                        "name": "amountIn",
                        "type": "uint256"
                    },
                    {
                        "internalType": "address[]",
                        "name": "path",
                        "type": "address[]"
                    }
                ],
                "name": "getAmountsOut",
                "outputs": [
                    {
                        "internalType": "uint256[]",
                        "name": "amounts",
                        "type": "uint256[]"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "factory",
                        "type": "address"
                    },
                    {
                        "internalType": "address",
                        "name": "tokenA",
                        "type": "address"
                    },
                    {
                        "internalType": "address",
                        "name": "tokenB",
                        "type": "address"
                    }
                ],
                "name": "getReserves",
                "outputs": [
                    {
                        "internalType": "uint256",
                        "name": "reserveA",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "reserveB",
                        "type": "uint256"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "uint256",
                        "name": "amountA",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "reserveA",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "reserveB",
                        "type": "uint256"
                    }
                ],
                "name": "quote",
                "outputs": [
                    {
                        "internalType": "uint256",
                        "name": "amountB",
                        "type": "uint256"
                    }
                ],
                "stateMutability": "pure",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "tokenA",
                        "type": "address"
                    },
                    {
                        "internalType": "address",
                        "name": "tokenB",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "liquidity",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "amountAMin",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "amountBMin",
                        "type": "uint256"
                    },
                    {
                        "internalType": "address",
                        "name": "to",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "deadline",
                        "type": "uint256"
                    }
                ],
                "name": "removeLiquidity",
                "outputs": [
                    {
                        "internalType": "uint256",
                        "name": "amountA",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "amountB",
                        "type": "uint256"
                    }
                ],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "token",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "liquidity",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "amountTokenMin",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "amountETHMin",
                        "type": "uint256"
                    },
                    {
                        "internalType": "address",
                        "name": "to",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "deadline",
                        "type": "uint256"
                    }
                ],
                "name": "removeLiquidityETH",
                "outputs": [
                    {
                        "internalType": "uint256",
                        "name": "amountToken",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "amountETH",
                        "type": "uint256"
                    }
                ],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "token",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "liquidity",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "amountTokenMin",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "amountETHMin",
                        "type": "uint256"
                    },
                    {
                        "internalType": "address",
                        "name": "to",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "deadline",
                        "type": "uint256"
                    }
                ],
                "name": "removeLiquidityETHSupportingFeeOnTransferTokens",
                "outputs": [
                    {
                        "internalType": "uint256",
                        "name": "amountETH",
                        "type": "uint256"
                    }
                ],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "token",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "liquidity",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "amountTokenMin",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "amountETHMin",
                        "type": "uint256"
                    },
                    {
                        "internalType": "address",
                        "name": "to",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "deadline",
                        "type": "uint256"
                    },
                    {
                        "internalType": "bool",
                        "name": "approveMax",
                        "type": "bool"
                    },
                    {
                        "internalType": "uint8",
                        "name": "v",
                        "type": "uint8"
                    },
                    {
                        "internalType": "bytes32",
                        "name": "r",
                        "type": "bytes32"
                    },
                    {
                        "internalType": "bytes32",
                        "name": "s",
                        "type": "bytes32"
                    }
                ],
                "name": "removeLiquidityETHWithPermit",
                "outputs": [
                    {
                        "internalType": "uint256",
                        "name": "amountToken",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "amountETH",
                        "type": "uint256"
                    }
                ],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "token",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "liquidity",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "amountTokenMin",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "amountETHMin",
                        "type": "uint256"
                    },
                    {
                        "internalType": "address",
                        "name": "to",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "deadline",
                        "type": "uint256"
                    },
                    {
                        "internalType": "bool",
                        "name": "approveMax",
                        "type": "bool"
                    },
                    {
                        "internalType": "uint8",
                        "name": "v",
                        "type": "uint8"
                    },
                    {
                        "internalType": "bytes32",
                        "name": "r",
                        "type": "bytes32"
                    },
                    {
                        "internalType": "bytes32",
                        "name": "s",
                        "type": "bytes32"
                    }
                ],
                "name": "removeLiquidityETHWithPermitSupportingFeeOnTransferTokens",
                "outputs": [
                    {
                        "internalType": "uint256",
                        "name": "amountETH",
                        "type": "uint256"
                    }
                ],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "tokenA",
                        "type": "address"
                    },
                    {
                        "internalType": "address",
                        "name": "tokenB",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "liquidity",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "amountAMin",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "amountBMin",
                        "type": "uint256"
                    },
                    {
                        "internalType": "address",
                        "name": "to",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "deadline",
                        "type": "uint256"
                    },
                    {
                        "internalType": "bool",
                        "name": "approveMax",
                        "type": "bool"
                    },
                    {
                        "internalType": "uint8",
                        "name": "v",
                        "type": "uint8"
                    },
                    {
                        "internalType": "bytes32",
                        "name": "r",
                        "type": "bytes32"
                    },
                    {
                        "internalType": "bytes32",
                        "name": "s",
                        "type": "bytes32"
                    }
                ],
                "name": "removeLiquidityWithPermit",
                "outputs": [
                    {
                        "internalType": "uint256",
                        "name": "amountA",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "amountB",
                        "type": "uint256"
                    }
                ],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "uint256",
                        "name": "amountOut",
                        "type": "uint256"
                    },
                    {
                        "internalType": "address[]",
                        "name": "path",
                        "type": "address[]"
                    },
                    {
                        "internalType": "address",
                        "name": "to",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "deadline",
                        "type": "uint256"
                    }
                ],
                "name": "swapETHForExactTokens",
                "outputs": [
                    {
                        "internalType": "uint256[]",
                        "name": "amounts",
                        "type": "uint256[]"
                    }
                ],
                "stateMutability": "payable",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "uint256",
                        "name": "amountOutMin",
                        "type": "uint256"
                    },
                    {
                        "internalType": "address[]",
                        "name": "path",
                        "type": "address[]"
                    },
                    {
                        "internalType": "address",
                        "name": "to",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "deadline",
                        "type": "uint256"
                    }
                ],
                "name": "swapExactETHForTokens",
                "outputs": [
                    {
                        "internalType": "uint256[]",
                        "name": "amounts",
                        "type": "uint256[]"
                    }
                ],
                "stateMutability": "payable",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "uint256",
                        "name": "amountOutMin",
                        "type": "uint256"
                    },
                    {
                        "internalType": "address[]",
                        "name": "path",
                        "type": "address[]"
                    },
                    {
                        "internalType": "address",
                        "name": "to",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "deadline",
                        "type": "uint256"
                    }
                ],
                "name": "swapExactETHForTokensSupportingFeeOnTransferTokens",
                "outputs": [],
                "stateMutability": "payable",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "uint256",
                        "name": "amountIn",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "amountOutMin",
                        "type": "uint256"
                    },
                    {
                        "internalType": "address[]",
                        "name": "path",
                        "type": "address[]"
                    },
                    {
                        "internalType": "address",
                        "name": "to",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "deadline",
                        "type": "uint256"
                    }
                ],
                "name": "swapExactTokensForETH",
                "outputs": [
                    {
                        "internalType": "uint256[]",
                        "name": "amounts",
                        "type": "uint256[]"
                    }
                ],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "uint256",
                        "name": "amountIn",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "amountOutMin",
                        "type": "uint256"
                    },
                    {
                        "internalType": "address[]",
                        "name": "path",
                        "type": "address[]"
                    },
                    {
                        "internalType": "address",
                        "name": "to",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "deadline",
                        "type": "uint256"
                    }
                ],
                "name": "swapExactTokensForETHSupportingFeeOnTransferTokens",
                "outputs": [],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "uint256",
                        "name": "amountIn",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "amountOutMin",
                        "type": "uint256"
                    },
                    {
                        "internalType": "address[]",
                        "name": "path",
                        "type": "address[]"
                    },
                    {
                        "internalType": "address",
                        "name": "to",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "deadline",
                        "type": "uint256"
                    }
                ],
                "name": "swapExactTokensForTokens",
                "outputs": [
                    {
                        "internalType": "uint256[]",
                        "name": "amounts",
                        "type": "uint256[]"
                    }
                ],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "uint256",
                        "name": "amountIn",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "amountOutMin",
                        "type": "uint256"
                    },
                    {
                        "internalType": "address[]",
                        "name": "path",
                        "type": "address[]"
                    },
                    {
                        "internalType": "address",
                        "name": "to",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "deadline",
                        "type": "uint256"
                    }
                ],
                "name": "swapExactTokensForTokensSupportingFeeOnTransferTokens",
                "outputs": [],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "uint256",
                        "name": "amountOut",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "amountInMax",
                        "type": "uint256"
                    },
                    {
                        "internalType": "address[]",
                        "name": "path",
                        "type": "address[]"
                    },
                    {
                        "internalType": "address",
                        "name": "to",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "deadline",
                        "type": "uint256"
                    }
                ],
                "name": "swapTokensForExactETH",
                "outputs": [
                    {
                        "internalType": "uint256[]",
                        "name": "amounts",
                        "type": "uint256[]"
                    }
                ],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "uint256",
                        "name": "amountOut",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "amountInMax",
                        "type": "uint256"
                    },
                    {
                        "internalType": "address[]",
                        "name": "path",
                        "type": "address[]"
                    },
                    {
                        "internalType": "address",
                        "name": "to",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "deadline",
                        "type": "uint256"
                    }
                ],
                "name": "swapTokensForExactTokens",
                "outputs": [
                    {
                        "internalType": "uint256[]",
                        "name": "amounts",
                        "type": "uint256[]"
                    }
                ],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "stateMutability": "payable",
                "type": "receive"
            }
        ]
    },
    TOKEN: {
        abi: [
            {
                "inputs": [
                    {
                        "internalType": "string",
                        "name": "name_",
                        "type": "string"
                    },
                    {
                        "internalType": "string",
                        "name": "symbol_",
                        "type": "string"
                    },
                    {
                        "internalType": "uint8",
                        "name": "decimals_",
                        "type": "uint8"
                    },
                    {
                        "internalType": "uint256",
                        "name": "initialSupply_",
                        "type": "uint256"
                    },
                    {
                        "internalType": "bool",
                        "name": "mintable_",
                        "type": "bool"
                    }
                ],
                "stateMutability": "nonpayable",
                "type": "constructor"
            },
            {
                "anonymous": false,
                "inputs": [
                    {
                        "indexed": true,
                        "internalType": "address",
                        "name": "owner",
                        "type": "address"
                    },
                    {
                        "indexed": true,
                        "internalType": "address",
                        "name": "spender",
                        "type": "address"
                    },
                    {
                        "indexed": false,
                        "internalType": "uint256",
                        "name": "value",
                        "type": "uint256"
                    }
                ],
                "name": "Approval",
                "type": "event"
            },
            {
                "anonymous": false,
                "inputs": [
                    {
                        "indexed": true,
                        "internalType": "address",
                        "name": "from",
                        "type": "address"
                    },
                    {
                        "indexed": true,
                        "internalType": "address",
                        "name": "to",
                        "type": "address"
                    },
                    {
                        "indexed": false,
                        "internalType": "uint256",
                        "name": "value",
                        "type": "uint256"
                    }
                ],
                "name": "Transfer",
                "type": "event"
            },
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "owner",
                        "type": "address"
                    },
                    {
                        "internalType": "address",
                        "name": "spender",
                        "type": "address"
                    }
                ],
                "name": "allowance",
                "outputs": [
                    {
                        "internalType": "uint256",
                        "name": "",
                        "type": "uint256"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "spender",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "amount",
                        "type": "uint256"
                    }
                ],
                "name": "approve",
                "outputs": [
                    {
                        "internalType": "bool",
                        "name": "",
                        "type": "bool"
                    }
                ],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "account",
                        "type": "address"
                    }
                ],
                "name": "balanceOf",
                "outputs": [
                    {
                        "internalType": "uint256",
                        "name": "",
                        "type": "uint256"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [],
                "name": "decimals",
                "outputs": [
                    {
                        "internalType": "uint8",
                        "name": "",
                        "type": "uint8"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "uint256",
                        "name": "amount",
                        "type": "uint256"
                    }
                ],
                "name": "mint",
                "outputs": [
                    {
                        "internalType": "bool",
                        "name": "",
                        "type": "bool"
                    }
                ],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "inputs": [],
                "name": "mintable",
                "outputs": [
                    {
                        "internalType": "bool",
                        "name": "",
                        "type": "bool"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [],
                "name": "name",
                "outputs": [
                    {
                        "internalType": "string",
                        "name": "",
                        "type": "string"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [],
                "name": "symbol",
                "outputs": [
                    {
                        "internalType": "string",
                        "name": "",
                        "type": "string"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [],
                "name": "totalSupply",
                "outputs": [
                    {
                        "internalType": "uint256",
                        "name": "",
                        "type": "uint256"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "recipient",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "amount",
                        "type": "uint256"
                    }
                ],
                "name": "transfer",
                "outputs": [
                    {
                        "internalType": "bool",
                        "name": "",
                        "type": "bool"
                    }
                ],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "sender",
                        "type": "address"
                    },
                    {
                        "internalType": "address",
                        "name": "recipient",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "amount",
                        "type": "uint256"
                    }
                ],
                "name": "transferFrom",
                "outputs": [
                    {
                        "internalType": "bool",
                        "name": "",
                        "type": "bool"
                    }
                ],
                "stateMutability": "nonpayable",
                "type": "function"
            }
        ]
    },
    PAIR: {
        abi: [
            {
                "anonymous": false,
                "inputs": [
                    {
                        "indexed": true,
                        "internalType": "address",
                        "name": "owner",
                        "type": "address"
                    },
                    {
                        "indexed": true,
                        "internalType": "address",
                        "name": "spender",
                        "type": "address"
                    },
                    {
                        "indexed": false,
                        "internalType": "uint256",
                        "name": "value",
                        "type": "uint256"
                    }
                ],
                "name": "Approval",
                "type": "event"
            },
            {
                "anonymous": false,
                "inputs": [
                    {
                        "indexed": true,
                        "internalType": "address",
                        "name": "sender",
                        "type": "address"
                    },
                    {
                        "indexed": false,
                        "internalType": "uint256",
                        "name": "amount0",
                        "type": "uint256"
                    },
                    {
                        "indexed": false,
                        "internalType": "uint256",
                        "name": "amount1",
                        "type": "uint256"
                    },
                    {
                        "indexed": true,
                        "internalType": "address",
                        "name": "to",
                        "type": "address"
                    }
                ],
                "name": "Burn",
                "type": "event"
            },
            {
                "anonymous": false,
                "inputs": [
                    {
                        "indexed": true,
                        "internalType": "address",
                        "name": "sender",
                        "type": "address"
                    },
                    {
                        "indexed": false,
                        "internalType": "uint256",
                        "name": "amount0",
                        "type": "uint256"
                    },
                    {
                        "indexed": false,
                        "internalType": "uint256",
                        "name": "amount1",
                        "type": "uint256"
                    }
                ],
                "name": "Mint",
                "type": "event"
            },
            {
                "anonymous": false,
                "inputs": [
                    {
                        "indexed": true,
                        "internalType": "address",
                        "name": "sender",
                        "type": "address"
                    },
                    {
                        "indexed": false,
                        "internalType": "uint256",
                        "name": "amount0In",
                        "type": "uint256"
                    },
                    {
                        "indexed": false,
                        "internalType": "uint256",
                        "name": "amount1In",
                        "type": "uint256"
                    },
                    {
                        "indexed": false,
                        "internalType": "uint256",
                        "name": "amount0Out",
                        "type": "uint256"
                    },
                    {
                        "indexed": false,
                        "internalType": "uint256",
                        "name": "amount1Out",
                        "type": "uint256"
                    },
                    {
                        "indexed": true,
                        "internalType": "address",
                        "name": "to",
                        "type": "address"
                    }
                ],
                "name": "Swap",
                "type": "event"
            },
            {
                "anonymous": false,
                "inputs": [
                    {
                        "indexed": false,
                        "internalType": "uint112",
                        "name": "reserve0",
                        "type": "uint112"
                    },
                    {
                        "indexed": false,
                        "internalType": "uint112",
                        "name": "reserve1",
                        "type": "uint112"
                    }
                ],
                "name": "Sync",
                "type": "event"
            },
            {
                "anonymous": false,
                "inputs": [
                    {
                        "indexed": true,
                        "internalType": "address",
                        "name": "from",
                        "type": "address"
                    },
                    {
                        "indexed": true,
                        "internalType": "address",
                        "name": "to",
                        "type": "address"
                    },
                    {
                        "indexed": false,
                        "internalType": "uint256",
                        "name": "value",
                        "type": "uint256"
                    }
                ],
                "name": "Transfer",
                "type": "event"
            },
            {
                "constant": true,
                "inputs": [],
                "name": "DOMAIN_SEPARATOR",
                "outputs": [
                    {
                        "internalType": "bytes32",
                        "name": "",
                        "type": "bytes32"
                    }
                ],
                "payable": false,
                "stateMutability": "view",
                "type": "function"
            },
            {
                "constant": true,
                "inputs": [],
                "name": "MINIMUM_LIQUIDITY",
                "outputs": [
                    {
                        "internalType": "uint256",
                        "name": "",
                        "type": "uint256"
                    }
                ],
                "payable": false,
                "stateMutability": "view",
                "type": "function"
            },
            {
                "constant": true,
                "inputs": [],
                "name": "PERMIT_TYPEHASH",
                "outputs": [
                    {
                        "internalType": "bytes32",
                        "name": "",
                        "type": "bytes32"
                    }
                ],
                "payable": false,
                "stateMutability": "view",
                "type": "function"
            },
            {
                "constant": true,
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "owner",
                        "type": "address"
                    },
                    {
                        "internalType": "address",
                        "name": "spender",
                        "type": "address"
                    }
                ],
                "name": "allowance",
                "outputs": [
                    {
                        "internalType": "uint256",
                        "name": "",
                        "type": "uint256"
                    }
                ],
                "payable": false,
                "stateMutability": "view",
                "type": "function"
            },
            {
                "constant": false,
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "spender",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "value",
                        "type": "uint256"
                    }
                ],
                "name": "approve",
                "outputs": [
                    {
                        "internalType": "bool",
                        "name": "",
                        "type": "bool"
                    }
                ],
                "payable": false,
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "constant": true,
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "owner",
                        "type": "address"
                    }
                ],
                "name": "balanceOf",
                "outputs": [
                    {
                        "internalType": "uint256",
                        "name": "",
                        "type": "uint256"
                    }
                ],
                "payable": false,
                "stateMutability": "view",
                "type": "function"
            },
            {
                "constant": false,
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "to",
                        "type": "address"
                    }
                ],
                "name": "burn",
                "outputs": [
                    {
                        "internalType": "uint256",
                        "name": "amount0",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "amount1",
                        "type": "uint256"
                    }
                ],
                "payable": false,
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "constant": true,
                "inputs": [],
                "name": "decimals",
                "outputs": [
                    {
                        "internalType": "uint8",
                        "name": "",
                        "type": "uint8"
                    }
                ],
                "payable": false,
                "stateMutability": "view",
                "type": "function"
            },
            {
                "constant": true,
                "inputs": [],
                "name": "factory",
                "outputs": [
                    {
                        "internalType": "address",
                        "name": "",
                        "type": "address"
                    }
                ],
                "payable": false,
                "stateMutability": "view",
                "type": "function"
            },
            {
                "constant": true,
                "inputs": [],
                "name": "getReserves",
                "outputs": [
                    {
                        "internalType": "uint112",
                        "name": "reserve0",
                        "type": "uint112"
                    },
                    {
                        "internalType": "uint112",
                        "name": "reserve1",
                        "type": "uint112"
                    },
                    {
                        "internalType": "uint32",
                        "name": "blockTimestampLast",
                        "type": "uint32"
                    }
                ],
                "payable": false,
                "stateMutability": "view",
                "type": "function"
            },
            {
                "constant": false,
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "",
                        "type": "address"
                    },
                    {
                        "internalType": "address",
                        "name": "",
                        "type": "address"
                    }
                ],
                "name": "initialize",
                "outputs": [],
                "payable": false,
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "constant": true,
                "inputs": [],
                "name": "kLast",
                "outputs": [
                    {
                        "internalType": "uint256",
                        "name": "",
                        "type": "uint256"
                    }
                ],
                "payable": false,
                "stateMutability": "view",
                "type": "function"
            },
            {
                "constant": false,
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "to",
                        "type": "address"
                    }
                ],
                "name": "mint",
                "outputs": [
                    {
                        "internalType": "uint256",
                        "name": "liquidity",
                        "type": "uint256"
                    }
                ],
                "payable": false,
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "constant": true,
                "inputs": [],
                "name": "name",
                "outputs": [
                    {
                        "internalType": "string",
                        "name": "",
                        "type": "string"
                    }
                ],
                "payable": false,
                "stateMutability": "view",
                "type": "function"
            },
            {
                "constant": true,
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "owner",
                        "type": "address"
                    }
                ],
                "name": "nonces",
                "outputs": [
                    {
                        "internalType": "uint256",
                        "name": "",
                        "type": "uint256"
                    }
                ],
                "payable": false,
                "stateMutability": "view",
                "type": "function"
            },
            {
                "constant": false,
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "owner",
                        "type": "address"
                    },
                    {
                        "internalType": "address",
                        "name": "spender",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "value",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "deadline",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint8",
                        "name": "v",
                        "type": "uint8"
                    },
                    {
                        "internalType": "bytes32",
                        "name": "r",
                        "type": "bytes32"
                    },
                    {
                        "internalType": "bytes32",
                        "name": "s",
                        "type": "bytes32"
                    }
                ],
                "name": "permit",
                "outputs": [],
                "payable": false,
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "constant": true,
                "inputs": [],
                "name": "price0CumulativeLast",
                "outputs": [
                    {
                        "internalType": "uint256",
                        "name": "",
                        "type": "uint256"
                    }
                ],
                "payable": false,
                "stateMutability": "view",
                "type": "function"
            },
            {
                "constant": true,
                "inputs": [],
                "name": "price1CumulativeLast",
                "outputs": [
                    {
                        "internalType": "uint256",
                        "name": "",
                        "type": "uint256"
                    }
                ],
                "payable": false,
                "stateMutability": "view",
                "type": "function"
            },
            {
                "constant": false,
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "to",
                        "type": "address"
                    }
                ],
                "name": "skim",
                "outputs": [],
                "payable": false,
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "constant": false,
                "inputs": [
                    {
                        "internalType": "uint256",
                        "name": "amount0Out",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "amount1Out",
                        "type": "uint256"
                    },
                    {
                        "internalType": "address",
                        "name": "to",
                        "type": "address"
                    },
                    {
                        "internalType": "bytes",
                        "name": "data",
                        "type": "bytes"
                    }
                ],
                "name": "swap",
                "outputs": [],
                "payable": false,
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "constant": true,
                "inputs": [],
                "name": "symbol",
                "outputs": [
                    {
                        "internalType": "string",
                        "name": "",
                        "type": "string"
                    }
                ],
                "payable": false,
                "stateMutability": "view",
                "type": "function"
            },
            {
                "constant": false,
                "inputs": [],
                "name": "sync",
                "outputs": [],
                "payable": false,
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "constant": true,
                "inputs": [],
                "name": "token0",
                "outputs": [
                    {
                        "internalType": "address",
                        "name": "",
                        "type": "address"
                    }
                ],
                "payable": false,
                "stateMutability": "view",
                "type": "function"
            },
            {
                "constant": true,
                "inputs": [],
                "name": "token1",
                "outputs": [
                    {
                        "internalType": "address",
                        "name": "",
                        "type": "address"
                    }
                ],
                "payable": false,
                "stateMutability": "view",
                "type": "function"
            },
            {
                "constant": true,
                "inputs": [],
                "name": "totalSupply",
                "outputs": [
                    {
                        "internalType": "uint256",
                        "name": "",
                        "type": "uint256"
                    }
                ],
                "payable": false,
                "stateMutability": "view",
                "type": "function"
            },
            {
                "constant": false,
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "to",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "value",
                        "type": "uint256"
                    }
                ],
                "name": "transfer",
                "outputs": [
                    {
                        "internalType": "bool",
                        "name": "",
                        "type": "bool"
                    }
                ],
                "payable": false,
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "constant": false,
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "from",
                        "type": "address"
                    },
                    {
                        "internalType": "address",
                        "name": "to",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "value",
                        "type": "uint256"
                    }
                ],
                "name": "transferFrom",
                "outputs": [
                    {
                        "internalType": "bool",
                        "name": "",
                        "type": "bool"
                    }
                ],
                "payable": false,
                "stateMutability": "nonpayable",
                "type": "function"
            }
        ]
    }
}

export const VIPTierNames = {
    Bronze: "bronze",
    Silver: "silver",
    Gold: "gold",
    ESSU: "essu"
}
export const VIPBenefitIds = {
    EphemeralPowerUps: 0,
    GuardiansBadge: 1,
    GuardiansBoard: 2,
    GuardiansDiscordChannel: 3,
    EarlyAccess: 4,
    ExclusivePhlair: 5,
    ExclusiveAirdrops: 6,
    ExclusiveDrops: 7,
    ExclusiveRaffles: 8,
    ExclusiveSupplyStoreItems: 9,
    GovernanceVoting: 10,
    $GUMMYAirdrops: 11
}
export const VIPBenefits = [
    {
        id: VIPBenefitIds.EphemeralPowerUps,
        title: "Ephemeral Power-ups",
        description: "Monthly power-ups to match normal PLASM staking rewards**",
        tiers: new Set([VIPTierNames.Bronze, VIPTierNames.Silver, VIPTierNames.Gold, VIPTierNames.ESSU])
    },
    {
        id: VIPBenefitIds.GuardiansBadge,
        title: "Guardians Badge",
        description: "Exclusive badge after 12 months of consistent status.",
        tiers: new Set([VIPTierNames.Bronze, VIPTierNames.Silver, VIPTierNames.Gold, VIPTierNames.ESSU])
    },
    {
        id: VIPBenefitIds.GuardiansBoard,
        title: "Guardians Board",
        description: "Get your account on the Guardians board.",
        tiers: new Set([VIPTierNames.Bronze, VIPTierNames.Silver, VIPTierNames.Gold, VIPTierNames.ESSU]),
        comingSoon: true
    },
    {
        id: VIPBenefitIds.GuardiansDiscordChannel,
        title: "Guardians Discord Channel",
        description: "Learn about upcoming features before anyone else.",
        tiers: new Set([VIPTierNames.Bronze, VIPTierNames.Silver, VIPTierNames.Gold, VIPTierNames.ESSU])
    },
    {
        id: VIPBenefitIds.ExclusiveAirdrops,
        title: "Exclusive Airdrops",
        description: "Airdrops of exclusive NFTs and items. Varies per tier.",
        tiers: new Set([VIPTierNames.Bronze, VIPTierNames.Silver, VIPTierNames.Gold, VIPTierNames.ESSU])
    },
    {
        id: VIPBenefitIds.ExclusiveRaffles,
        title: "Exclusive Raffles",
        description: "Limited Edition raffles for guardians only.",
        tiers: new Set([VIPTierNames.Bronze, VIPTierNames.Silver, VIPTierNames.Gold, VIPTierNames.ESSU])
    },
    {
        id: VIPBenefitIds.ExclusiveSupplyStoreItems,
        title: "Exclusive Supply Store Items",
        description: "Items only available to guardians in the supply store. Varies per tier.",
        tiers: new Set([VIPTierNames.Bronze, VIPTierNames.Silver, VIPTierNames.Gold, VIPTierNames.ESSU])
    },
    {
        id: VIPBenefitIds.ExclusivePhlair,
        title: "Exclusive Phlair",
        description: "Access to exclusive Phlair for Phorms. Varies per tier.",
        tiers: new Set([VIPTierNames.Bronze, VIPTierNames.Silver, VIPTierNames.Gold, VIPTierNames.ESSU]),
        comingSoon: true
    },
    {
        id: VIPBenefitIds.ExclusiveDrops,
        title: "Exclusive Drops",
        description: "Guardian only drops on TPMC.",
        tiers: new Set([VIPTierNames.Silver, VIPTierNames.Gold, VIPTierNames.ESSU])
    },
    // {
    //     id: VIPBenefitIds.GovernanceVoting,
    //     title: "Governance Voting",
    //     description: "Decentralized governance voting rights.",
    //     tiers: new Set([VIPTierNames.Silver, VIPTierNames.Gold, VIPTierNames.ESSU]),
    //     comingSoon: true
    // },
    {
        id: VIPBenefitIds.EarlyAccess,
        title: "Early Access",
        description: "Early access to new TPMC features.",
        tiers: new Set([VIPTierNames.Gold, VIPTierNames.ESSU]),
        comingSoon: true
    },
    {
        id: VIPBenefitIds.$GUMMYAirdrops,
        title: "$GUMMY Airdrops",
        description: "Get airdrops of $GUMMY.",
        tiers: new Set([VIPTierNames.ESSU]),
        comingSoon: true
    },
];
export const VIPTiers = {
    Bronze: {
        name: VIPTierNames.Bronze,
        requiredAmount: 50000,
        benefitOverrides: {
            [VIPBenefitIds.EphemeralPowerUps]: {
                title: 'Bronze Power-up Rewards',
                description: 'Get a monthly Bronze 7734 Guardian Reward, equivalent to standard PLASM staking rewards.**'
            }
        }
    },
    Silver: {
        name: VIPTierNames.Silver,
        requiredAmount: 100000,
        benefitOverrides: {
            [VIPBenefitIds.EphemeralPowerUps]: {
                title: 'Silver Power-up Rewards',
                description: 'Get a monthly Silver 7734 Guardian Reward, matching PLASM staking rewards plus up to a 5% bonus.**'
            }
        }
    },
    Gold: {
        name: VIPTierNames.Gold,
        requiredAmount: 200000,
        benefitOverrides: {
            [VIPBenefitIds.EphemeralPowerUps]: {
                title: 'Gold Power-up Rewards',
                description: 'Get a monthly Gold 7734 Guardian Reward, matching PLASM staking rewards plus up to a 10% bonus.**'
            }
        }
    },
    ESSU: {
        name: VIPTierNames.ESSU,
        requiredAmount: 400000,
        benefitOverrides: {
            [VIPBenefitIds.EphemeralPowerUps]: {
                title: 'ESSU Power-up Rewards',
                description: 'Get a monthly ESSU 7734 Guardian Reward, matching PLASM staking rewards plus up to a 20% bonus.**'
            }
        }
    }
}

export const VIPTiersByName = {
    [VIPTierNames.Bronze]: VIPTiers.Bronze,
    [VIPTierNames.Silver]: VIPTiers.Silver,
    [VIPTierNames.Gold]: VIPTiers.Gold,
    [VIPTierNames.ESSU]: VIPTiers.ESSU
}

export const get7734GuardianTier = (amount) => {
    if (amount >= VIPTiers.ESSU.requiredAmount) {
        return VIPTierNames.ESSU;
    }
    if (amount >= VIPTiers.Gold.requiredAmount) {
        return VIPTierNames.Gold;
    }
    if (amount >= VIPTiers.Silver.requiredAmount) {
        return VIPTierNames.Silver;
    }
    if (amount >= VIPTiers.Bronze.requiredAmount) {
        return VIPTierNames.Bronze;
    }
    return null;
}


export const ContractAddresses = {
    GovernanceToken: '0x5c2fb1e2594e85c5f1579b07dd5b8dfea3f929e0',//PLASM
    ChainRegistrarOnMainchain: '0xb164c26fd7970746639151a8C118cce282F272A7',
    TFuelTokenBank: '0xf83239088B8766a27cD1f46772a2E1f88e916322',
    TNT20TokenBank: '0xB3d93735de018Ad48122bf7394734A7d18007e1b',
    TNT721TokenBank: '0xFe2d1bE6bD9d342cfa59e75290F9b0B42cdBCDAF',
    TNT1155TokenBank: '0xA31168d669112937B0826b1Bf15f0eb12e6B1542',
    VCM: '0xF0d53cbE585F8Fa24A5108a1C741CE31AD18b40e',
    VSM: '0xf0716e6221618137fB05D72450e0FC8d9c2919d4',
    wTHETA: '0xaf537fb7e4c77c97403de94ce141b7edb9f7fcf0',
};

export const ChainRegistrarOnMainchainContract = require("./contracts/ChainRegistrarOnMainchain.json");
export const ValidatorStakeManagerContract = require("./contracts/ValidatorStakeManager.json");
export const GovernanceTokenContract = require("./contracts/GovernanceToken.json");

export const TNT20TokenBankContract = require("./contracts/TNT20TokenBank.json");
export const TNT721TokenBankContract = require("./contracts/TNT721TokenBank.json");

export const SubchainId = '7734';
import React, {Fragment, useState} from 'react';
import {connect, useSelector} from 'react-redux'
import Modal, {RoundedModal} from '../../components/Modal';
import logo from '../../assets/logo/misadventure-club-white.svg';
import modalCloseIcon from '../../assets/icons/modal-close@2x.png';
import UIState from "../../store/uiState";
import {selectCurrentUser} from "../../store/models/user";
import {createSelector} from "reselect";
import Nft, {selectNftsByContract} from "../../store/models/nft";
import _ from "lodash";
import PassawayWithMeta from "../../components/staking/PassawayWithMeta";
import plasmLogo from "../../assets/tokens/plasm-front-small@2x.png";
import Button, {GetMorePlasmButton, RoundedButton} from "../../components/Button";
import classNames from "classnames";
import Wallet, {selectBalances, selectGuardianTier} from "../../store/models/wallet";
import {formatBalance, numberWithCommas} from "../../utils";
import * as thetajs from '@thetalabs/theta-js';
import {IsNFTStakingLive, IsPLASMLive, ModalTypes, VIPTiers, VIPTiersByName} from "../../constants";
import useAutoFocus from "../../hooks/useAutoFocus";
import {VIPTabs} from "../../components/staking/VIPTabs";
import Countdown from "react-countdown";
import {Tab, TabList, Tabs} from "react-tabs";
import {ethers} from "ethers";
import {VIPUserTier} from "../../pages/staking/VIP";



export class VIPDepositModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tier: props.tier || VIPTiers.Bronze
        };
    }

    componentDidMount() {
        this.props.dispatch(Wallet.actions.getBalances())
    }

    onClose = () => {
        this.props.dispatch(UIState.actions.hideModal());
    }

    render() {
        const {balances, currentTier} = this.props;
        const {tier} =  this.state;
        const {plasmStakedToSubchainValidator} = balances;
        const canDeposit = parseFloat(ethers.utils.formatEther(plasmStakedToSubchainValidator)) < tier.requiredAmount;
        const isCurrentTier = currentTier === tier.name;
        const amountNeeded = tier.requiredAmount - parseFloat(ethers.utils.formatEther(plasmStakedToSubchainValidator));

        return (
            <RoundedModal title={'7734 Guardian'}
                          className={'VIPDepositModal'}
                          onClose={this.onClose}
                          header={(
                              <div className={classNames('RoundedModalHeader', {
                                  'RoundedModalHeader--expanded': true
                              })}>
                                  <div className={'RoundedModalHeader__title'}>
                                      7734 Guardian
                                  </div>
                                  <VIPTabs tier={tier}
                                           onTierClick={(tier) => {
                                               this.setState({tier});
                                           }}
                                  />
                                  <a className={'RoundedModalHeader__close-button'}
                                     onClick={this.onClose}
                                  >
                                      <img src={modalCloseIcon}/>
                                  </a>
                              </div>
                          )}
            >
                {
                    canDeposit &&
                    <div className={'VIPDepositModal__content'}>
                        <div className={'VIPDepositModal__required-title'}>
                            {
                                _.isNil(currentTier) ? 'Deposit Required' : 'Additional Deposit Required'
                            }
                        </div>
                        <div className={'VIPDepositModal__required-amount'}>
                            {numberWithCommas(amountNeeded)}
                        </div>

                        <RoundedButton title={_.isNil(currentTier) ? `Get ${tier.name} Status` : `Upgrade to ${tier.name} Status` }
                                       size={'large'}
                                       color={`vip-tier-${tier.name}`}
                                       style={{width: 260}}
                                       disabled={!canDeposit}
                                       onClick={() => {
                                           this.props.dispatch(Wallet.actions.depositSubchainValidatorStake(tier));
                                       }}
                        >
                        </RoundedButton>

                        <GetMorePlasmButton onClick={() => {
                            this.props.dispatch(UIState.actions.showModal(ModalTypes.GetPlasm))
                        }}/>

                        <div className={'VIPDepositModal__cooldown-messsage'}>
                            Give up status at any time.<br/>
                            Plasm available after ~5 day cooldown.
                        </div>

                    </div>
                }
                {
                    !canDeposit &&
                    <div className={'VIPDepositModal__content'}>
                        {
                            isCurrentTier &&
                            <VIPUserTier tierName={currentTier}
                                         amountStaked={plasmStakedToSubchainValidator}
                            />
                        }

                        {
                            !isCurrentTier && !canDeposit &&
                            <div className={'TPMCWarning'}
                                 style={{paddingTop: 10, paddingBottom: 10}}
                            >
                                <span style={{fontWeight: 'bold', textTransform: 'uppercase'}}>
                                    Cannot Downgrade Status
                                </span><br/>
                                <br/>
                                Please give up your status and wait for the <br/>
                                cooldown period to end.
                            </div>
                        }

                    </div>
                }
            </RoundedModal>
        );
    }
}

const mapStateToProps = (state, props) => {
    const balances = selectBalances(state);

    return {
        ...props,
        balances,
        currentTier: selectGuardianTier(state)
    }
};

export default connect(mapStateToProps)(VIPDepositModal);

import React, {useState} from 'react';
import {connect, useSelector} from 'react-redux'
import Modal, {RoundedModal} from '../../components/Modal';
import UIState from "../../store/uiState";
import plasmLogo from "../../assets/tokens/plasm.png";
import tfuelLogo from "../../assets/tokens/tfuel.png";
import Button, {RoundedButton} from "../../components/Button";
import classNames from "classnames";
import Wallet, {selectBalances, selectTfuelPlasmPair} from "../../store/models/wallet";
import * as thetajs from '@thetalabs/theta-js';
import useAutoFocus from "../../hooks/useAutoFocus";
import {ThetaSwap} from "../../constants";
import {selectContractByKey} from "../../store/models/config";
import {formatBalance} from "../../utils";
import {BigNumber, ethers} from "ethers";
import _ from "lodash";

export const PlasmInput = ({value, placeholder, onChange, onAll, autoFocus}) => {
    const [focused, setFocused] = useState(false);
    const inputRef = useAutoFocus(autoFocus);
    console.log('value == ', value)

    return (
        <div className={classNames('PlasmInput', {
            'PlasmInput--focused': focused,
            'PlasmInput--xlarge': true,
            'PlasmInput--empty': (value === '' || value.length === 0),
        })}>
            <img src={plasmLogo}/>
            <input value={value}
                   onChange={onChange}
                   onFocus={() => {
                       setFocused(true);
                   }}
                   onBlur={() => {
                       setFocused(false);
                   }}
                   placeholder={placeholder}
                   type={'number'}
                   min={0}
                   max={20000}
                   ref={inputRef}
            />
        </div>
    );
}

export const PlasmActionInputField = ({title, children}) => {
    return (
        <div className={'PlasmActionInputField'}>
            <div className={'PlasmActionInputField__title'}>
                {title}
            </div>
            {children}
        </div>
    );
}

export const PlasmActionContainer = ({title, subtitle, balance, className, children}) => {
    return (
        <div className={classNames('PlasmActionContainer', className)}>
            <div className={'PlasmActionContainer__title'}>
                {title}
            </div>
            {
                subtitle &&
                <div className={'PlasmActionContainer__subtitle'}>
                    {subtitle}
                </div>
            }
            {
                balance &&
                <div className={'PlasmActionContainer__balance'}>
                    {balance}
                </div>
            }
            {children}
        </div>
    );
}

export class GetPlasmModal extends React.Component {
    constructor(props) {
        super(props);

        console.log('props == ', props)
        console.log('props.defaultAmount || "" == ', props.defaultAmount || "");
        console.log('props.defaultAmount == ', props.defaultAmount)

        this.state = {
            isLoading: true,
            amountInput: "",
            amountOutput: props.defaultAmount || "",

        };
    }

    async componentDidMount() {
        const done = await this.props.dispatch(Wallet.actions.fetchTfuelPlasmPair());
        console.log('done == ', done);
        this.setState({
            isLoading: false
        });
    }

    onClose = () => {
        this.props.dispatch(UIState.actions.hideModal());
    }

    onChange = (inputName, value) => {
        const newVal = Math.min(20000, parseInt(value))
        this.setState({
            [inputName]: _.isNaN(newVal) ? '' : '' + newVal
        })
    }

    render() {
        const {pair, plasmAddress} = this.props;
        const {amountInput, amountOutput, isLoading} = this.state;
        const swapData = Wallet.helpers.getSwapData(ThetaSwap.WTFUEL.address, plasmAddress, amountInput, amountOutput, pair);
        const {amountLimit} = swapData || {};
        const amountLimitBN = ethers.utils.parseEther(_.isNaN(amountLimit) ? '0' : "" + amountLimit);

        return (
            <RoundedModal title={'GET PLASM'}
                          subtitle={'Powered by Theta Swap'}
                          className={'GetPlasmModal'}
                          onClose={this.onClose}
            >
                <div className={'GetPlasmModal__content'}>
                    <PlasmInput value={amountOutput}
                                placeholder={'Enter amount'}
                                onChange={(e) => {
                                    this.onChange('amountOutput', e.target.value);
                                }}
                                autoFocus={true}
                    />
                    <div className={'GetPlasmModal__cost-title'}>
                        Cost:
                    </div>
                    <div className={'GetPlasmModal__tfuel-amount'}>
                        <img src={tfuelLogo}/>
                        <span>
                            {
                                (amountOutput === '' || amountOutput === '0') ?
                                    '0' :
                                    formatBalance(amountLimitBN)
                            }
                            </span>
                    </div>

                    <RoundedButton size={'medium'}
                                   color={'green'}
                                   title={'Confirm'}
                                   style={{width: '100%'}}
                                   isLoading={isLoading}
                                   onClick={() => {
                                       this.props.dispatch(Wallet.actions.swapTfuelForPlasm(amountLimit, amountOutput));
                                   }}
                    />
                </div>
            </RoundedModal>
        );
    }
}

const mapStateToProps = (state, props) => {
    const pair = selectTfuelPlasmPair(state);
    const plasmAddress = selectContractByKey(state, 'plasm').address;

    return {
        ...props,
        pair,
        plasmAddress
    }
};

export default connect(mapStateToProps)(GetPlasmModal);

import {pushTo} from "./utils/history";
import {store} from "./store";
import UIState from "./store/uiState";
import {IsNFTStakingLive, IsPLASMLive, ModalTypes, Urls} from "./constants";

// {
//     image: '/hero-banners/staking@2x.png',
//         imageMobile: '/hero-banners/staking-mobile@2x.png',
//     onClick: () => {
//     pushTo(Urls.TPMC_STAKE_NFTS);
//     store.dispatch(UIState.actions.showPassawayStakingModal());
// }
// },
// {
//     image: '/hero-banners/supply-store@2x.png',
//         imageMobile: '/hero-banners/supply-store-mobile@2x.png',
//     onClick: () => {
//     pushTo(Urls.TPMC_STAKE_NFTS);
//     store.dispatch(UIState.actions.showModal(ModalTypes.SupplyStore));
// }
// }

const HeroBanners = [
    {

        image: '/hero-banners/desktop-hero_essu-drop_01a@2x.png',
        imageMobile: '/hero-banners/mobile-hero_essu-drop_01a@2x.png',
        onClick: () => {
            pushTo(`${Urls.TPMC_NFT_DROP_LIVE}`);
        }
    },
    // {
    //     image: '/hero-banners/hero_transphorm-glowhead1@2x.png',
    //     imageMobile: '/hero-banners/mobile-hero_transphorm-glowhead1@2x.png',
    //     onClick: () => {
    //         pushTo(`${Urls.TPMC_NFT_DROP_LIVE}`);
    //     }
    // },
    {
        image: '/hero-banners/hero_transphorm-glowhead2@2x.png',
        imageMobile: '/hero-banners/mobile-hero_transphorm-glowhead2@2x.png',
        onClick: () => {
            pushTo(`${Urls.JUMP_TPMC_TRANSPHORM}`);
        }
    },
    // {
    //     image: '/hero-banners/web_hero_test_pack.png',
    //     imageMobile: '/hero-banners/mobile-hero_test_pack.png',
    //     onClick: () => {
    //         pushTo(`${Urls.TPMC_NFT_DROP_LIVE}`);
    //     }
    // },
    {
        image: '/hero-banners/hero_vip@2x.png',
        imageMobile: '/hero-banners/mobile-hero_vip@2x.png',
        onClick: () => {
            pushTo(Urls.TPMC_7734_GUARDIAN);
        }
    },
    // {
    //     image: '/hero-banners/hero_web_plasm-spitter@2x.png',
    //     imageMobile: '/hero-banners/mobile-hero_plasm-spitter@2x.png',
    //     onClick: () => {
    //         pushTo(Urls.TPMC_LOTTERY);
    //     }
    // },
    // {
    //     image: '/hero-banners/hero_web_y2@2x.png',
    //     imageMobile: '/hero-banners/mobile-hero_y2@2x.png',
    //     onClick: () => {
    //         pushTo(`${Urls.JUMP_TPMC_CRAFTING}?id=8`);
    //     }
    // },
    // {
    //     image: '/hero-banners/desktop-hero_essu-drop@2x.png',
    //     imageMobile: '/hero-banners/mobile-hero_essu-drop@2x.png',
    //     onClick: () => {
    //         pushTo(`${Urls.TPMC_NFT_DROP_LIVE}`);
    //     }
    // },
    {
        image: '/hero-banners/hero_transphorm1@2x.png',
        imageMobile: '/hero-banners/mobile-hero_transphorm1@2x.png',
        onClick: () => {
            pushTo(`${Urls.JUMP_TPMC_TRANSPHORM}`);
        }
    },
    // {
    //     image: '/hero-banners/hero_kit_drop_3@2x.png',
    //     imageMobile: '/hero-banners/mobile_hero_kit_drop_3@2x.png',
    //     onClick: () => {
    //         pushTo(Urls.TPMC_NFT_DROP_LIVE);
    //     }
    // },
    // {
    //     image: '/hero-banners/hero_web_blueprint@2x.png',
    //     imageMobile: '/hero-banners/mobile-hero_blueprints@2x.png',
    //     onClick: () => {
    //         pushTo(`${Urls.JUMP_TPMC_CRAFTING}?id=2`);
    //     }
    // },
    // {
    //     image: '/hero-banners/hero_web_helloween@2x.png',
    //     imageMobile: '/hero-banners/mobile-hero_helloween@2x.png',
    //     onClick: () => {
    //         pushTo(Urls.TPMC_LOTTERY);
    //     }
    // },
    {
        image: '/hero-banners/staking@2x.png',
        imageMobile: '/hero-banners/staking-mobile@2x.png',
        onClick: () => {
            pushTo(Urls.TPMC_STAKE_NFTS);
        }
    },
    {
        image: '/hero-banners/hero_plasm-is-available@2x.png',
        imageMobile: '/hero-banners/mobile-hero_plasm-is-available@2x.png',
        onClick: () => {
            pushTo(Urls.JUMP_GET_PLASM);
        }
    },
    {
        image: '/hero-banners/hero_web_plasm@2x.png',
        imageMobile: '/hero-banners/mobile-hero_plasm@2x.png',
        onClick: () => {
            pushTo(Urls.TPMC_STAKE_PLASM);
        }
    },
    // {
    //     image: '/hero-banners/hero_transphorm-kit@2x.png',
    //     imageMobile: '/hero-banners/mobile-hero_transphorm-kit@2x.png',
    //     onClick: () => {
    //         pushTo(`${Urls.JUMP_TPMC_CRAFTING}?id=`);
    //     }
    // },
    // {
    //     image: '/hero-banners/hero_web_essupack@2x.png',
    //     imageMobile: '/hero-banners/mobile-hero_redeem_@2x.png',
    //     onClick: () => {
    //         pushTo(`${Urls.JUMP_TPMC_REDEEM}?id=3`);
    //     }
    // },
    // {
    //     image: '/hero-banners/hero_web_containment@2x.png',
    //     imageMobile: '/hero-banners/mobile-hero_redeem_containment@2x.png',
    //     onClick: () => {
    //         pushTo(`${Urls.JUMP_TPMC_REDEEM}?id=1`);
    //     }
    // },
    // {
    //     image: '/hero-banners/hero_web_phluid@2x.png',
    //     imageMobile: '/hero-banners/mobile-hero_redeem_phluid@2x.png',
    //     onClick: () => {
    //         pushTo(`${Urls.JUMP_TPMC_REDEEM}?id=2`);
    //     }
    // },
    // {
    //     image: '/hero-banners/hero_web_drink-me_sold-out@2x.png',
    //     imageMobile: '/hero-banners/mobile-hero_drink-me_sold-out@2x.png',
    //     onClick: () => {
    //         pushTo(Urls.TPMC_NFT_DROP_LIVE);
    //     }
    // }
];

export default HeroBanners;
import _ from 'lodash';
import React, {useState, useEffect} from 'react';
import {TPMCStakingNavBar} from "../../components/NavBar";
import {useDispatch, useSelector} from "react-redux";
import {selectCurrentUserId, selectIsOwner} from "../../store/models/user";
import Nft, {selectNftsByContract} from "../../store/models/nft";
import {createSelector} from "reselect";
import {IsNFTStakingLive, IsPLASMLive, ModalTypes} from "../../constants";
import {RoundedButton} from "../../components/Button";
import UIState from "../../store/uiState";
import Wallet from "../../store/models/wallet";
import {StakePlot} from "../../components/staking/StakePlot";
import {EarnPlasmCTABanners, StakePassawaysCTABanner} from "../../components/CTABanner";
import {selectContractByKey} from "../../store/models/config";
import Loader from "../../components/Loader";

const selectStakedPassawayNfts = createSelector(
    (state) => {
        const PassawaysContractInfo = selectContractByKey(state, 'passaways');
        const TransphormedPassawaysContractInfo = selectContractByKey(state, 'transphormedPassaways');
        if(_.isNil(PassawaysContractInfo)){
            return [];
        }
        const passawaysNfts = selectNftsByContract(state,PassawaysContractInfo.address);
        const transphormedPassawaysnfts = selectNftsByContract(state,TransphormedPassawaysContractInfo?.address);
        let nfts = _.flatten([passawaysNfts, transphormedPassawaysnfts]);
        nfts = _.filter(nfts, (x) => !_.isNil(x));
        return _.orderBy(nfts, ['metadata.tierNum'], ['desc']);
    },
    (nfts) => (_.filter(nfts, ({stake}) => {
        return !_.isNil(stake);
    }))
);

const selectUnstakedPassawayNfts = createSelector(
    (state) => {
        const PassawaysContractInfo = selectContractByKey(state, 'passaways');
        const TransphormedPassawaysContractInfo = selectContractByKey(state, 'transphormedPassaways');
        if(_.isNil(PassawaysContractInfo)){
            return [];
        }
        const passawaysNfts = selectNftsByContract(state,PassawaysContractInfo.address);
        const transphormedPassawaysnfts = selectNftsByContract(state,TransphormedPassawaysContractInfo?.address);
        let nfts = _.flatten([passawaysNfts, transphormedPassawaysnfts]);
        nfts = _.filter(nfts, (x) => !_.isNil(x));
        return _.orderBy(nfts, ['metadata.tierNum'], ['desc']);
    },
    (nfts) => (_.filter(nfts, ({stake}) => {
        return _.isNil(stake);
    }))
);

function NFTStakingPage() {
    const dispatch = useDispatch();
    const address = useSelector(selectCurrentUserId);
    const stakedPassaways = useSelector(selectStakedPassawayNfts);
    const unstakedPassaways = useSelector(selectUnstakedPassawayNfts);
    const isOwner = useSelector(selectIsOwner);
    const [isEditMode, setEditMode] = useState(false);

    useEffect(() => {
        dispatch(Wallet.actions.getBalances());
        dispatch(Nft.actions.fetchNfts(address, null));

        const searchParams = new URLSearchParams(window.location.search);
        if(searchParams.get('stake-passaways') === 'true'){
            onStakeMorePassaways();
        }
    }, []);

    const onStakeMorePassaways = async () => {
        setEditMode(false);
        dispatch(UIState.actions.showPassawayStakingModal());
    };

    const onUnstakePassaway = async ({token_id}) => {
        dispatch(UIState.actions.hideModal());
        await dispatch(Wallet.actions.unstakePassaways([token_id]));
    };

    const onToggleEditMode = () => {
        setEditMode(!isEditMode);
    }

    if(!IsNFTStakingLive){
        return (
            <div className={'PageWrapper'}>
                <TPMCStakingNavBar/>
                <div className="Page NFTStakingPage">
                    <div className={'EmptyState'}>
                        <div className={'EmptyState__title'}>
                            NFT Staking<br/>Launching soon
                        </div>
                        <RoundedButton title={'Preview Passaways'}
                                       color={'green'}
                                       size={'large'}
                                       style={{marginTop: 30}}
                                       onClick={onStakeMorePassaways}
                        />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={'PageWrapper'}>
            <TPMCStakingNavBar/>
            <div className="Page NFTStakingPage">
                {
                    IsNFTStakingLive &&
                    <StakePassawaysCTABanner onCTAClick={() => {
                        setEditMode(false);
                    }}/>
                }
                {
                    unstakedPassaways.length === 0 && stakedPassaways.length === 0 && isOwner &&
                    <div>
                        <Loader size={'medium'}/>
                    </div>
                }
                {
                    stakedPassaways.length > 0 &&
                    <>
                        <div className={'DataSectionHeader'}>
                            <div className={'DataSectionHeader__title'}>STAKED PASSAWAYS</div>
                            <div className={'DataSectionHeader__actions'}>
                                <RoundedButton color={isEditMode ? 'green' : 'grey-outline'}
                                               size={'small'}
                                               title={isEditMode ? 'Done' : 'Edit'}
                                               style={{width: 82}}
                                               onClick={onToggleEditMode}
                                />
                            </div>
                        </div>
                        <div className={'StakePlots'}>
                            {
                                _.map(stakedPassaways, (passaway) => {
                                    const {stake, metadata} = passaway;
                                    return (
                                        <StakePlot nft={passaway}
                                                   rate={metadata.rewardRate}
                                                   tokenMultiplier={stake?.tokenMultiplier}
                                                   permanentPowerUpSlot={stake?.permanentPowerUpSlot}
                                                   permanentPowerUp={stake?.permanentPowerUp}
                                                   ephemeralPowerUpSlots={stake?.ephemeralPowerUpSlots}
                                                   ephemeralPowerUps={stake?.ephemeralPowerUps}
                                                   editing={isEditMode}
                                                   onUnstake={onUnstakePassaway}
                                        />
                                    )
                                })
                            }
                        </div>
                        <RoundedButton title={'Stake More Passaways'}
                                       color={'green'}
                                       size={'large'}
                                       style={{marginTop: 30}}
                                       onClick={onStakeMorePassaways}
                        />
                    </>
                }
                {
                    stakedPassaways.length === 0 && unstakedPassaways.length > 0 &&
                    <>
                        <div className={'EmptyState'}>
                            <div className={'EmptyState__title'}>
                                NO PASSAWAYS CURRENTLY STAKED
                            </div>
                            <RoundedButton title={'Stake Your Passaways'}
                                           color={'green'}
                                           size={'large'}
                                           style={{marginTop: 30}}
                                           onClick={onStakeMorePassaways}
                            />
                        </div>
                    </>
                }
                {
                    !isOwner &&
                    <>
                        <div className={'EmptyState'}>
                            <div className={'EmptyState__title'}>
                                NO PASSAWAYS
                            </div>
                            {
                                <RoundedButton title={'Buy a Passaway'}
                                               color={'green'}
                                               size={'large'}
                                               style={{marginTop: 30}}
                                               onClick={() => {
                                                   dispatch(UIState.actions.showPassawayPurchaseModal())
                                               }}
                                />
                            }
                        </div>
                    </>
                }
            </div>
        </div>
    );
}

export default NFTStakingPage;

import _ from 'lodash';
import React, {useState, useEffect, Fragment} from 'react';
import {TPMCStakingNavBar} from "../../components/NavBar";
import {useDispatch, useSelector} from "react-redux";
import {selectCurrentUserId} from "../../store/models/user";
import Nft, {selectNftsByContract} from "../../store/models/nft";
import {createSelector} from "reselect";
import {IsPLASMLive, ModalTypes, VIPBenefits, VIPTiers} from "../../constants";
import Button, {RoundedButton} from "../../components/Button";
import UIState from "../../store/uiState";
import Wallet, {
    selectBalances,
    selectGuardianTier,
    selectProvider,
    selectSubchainStakeWithdrawals
} from "../../store/models/wallet";
import Config, {
    selectConfigLoadState
} from "../../store/models/config";
import {formatBalance, numberWithCommas, truncate} from "../../utils";
import EnabledIcon from "../../assets/vip/enabled@2x.png";
import DisabledIcon from "../../assets/vip/disabled@2x.png";
import useMediaQuery from "../../hooks/useMediaQuery";
import {VIPTabs} from "../../components/staking/VIPTabs";
import toast from "react-hot-toast";
import {BigNumber} from "ethers";
import Countdown from "react-countdown";

const classNames = require('classnames');

const timeLeftRenderer = ({ days, hours, minutes, seconds, completed }) => {
    if(days > 0){
        return (
            <span>
                {`~${days} days ${hours} hrs`}
            </span>
        )
    }
    else if (hours > 0){
        return (
            <span>
                {`~${hours} hrs ${minutes} min`}
            </span>
        )
    }
    else if (minutes > 0){
        return (
            <span>
                {`~${minutes} min`}
            </span>
        )
    }

};


export const VIPUserTier = ({tierName, amountStaked}) => {
    const dispatch = useDispatch();

    return (
        <div className={'VIPUserTier'}>
            <div className={'VIPUserTier__title'}>
                Your Current Tier:
            </div>
            <div className={'VIPUserTier__word-and-amount'}>
                <img src={`/vip-icons/word/${tierName}@2x.png`}
                     className={'VIPUserTier__word'}
                />
                <div className={'VIPUserTier__divider'}/>
                <div className={'VIPUserTier__amount'}>
                    {formatBalance(amountStaked)}
                </div>
            </div>
            <RoundedButton color={'black-with-grey'}
                           title={'Give up status'}
                           onClick={() => {
                               dispatch(UIState.actions.showModal(ModalTypes.Alert, {
                                   title: 'Give Up 7734 Gaurdian Status?',
                                   body: 'Are you sure you want to give up your 7734 Guardian status? You will lose all benefits and rewards.\n\nPLASM will be available for reclaim after ~5 days.',
                                   buttons: [
                                       <RoundedButton title={'Keep Status'}
                                                      color={'green'}
                                                      size={'medium'}
                                                      onClick={() => {
                                                          dispatch(UIState.actions.hideModal());
                                                          toast.success('You have chosen to stay as a 7734 Guardian');
                                                      }}
                                       />,
                                       <RoundedButton title={'Lose Status'}
                                                      color={'red'}
                                                      size={'medium'}
                                                      onClick={() => {
                                                          dispatch(UIState.actions.hideModal());
                                                          dispatch(Wallet.actions.withdrawSubchainValidatorStake())
                                                      }}
                                       />
                                   ]
                               }))
                           }}
            />
        </div>
    )
}

const VIPTierColumn = ({name, requiredAmount, benefitOverrides, onClick}) => {
    return (
        <div className={classNames('VIPTierColumn', `VIPTierColumn--${name}`)}>
            <img src={`/vip-icons/glow/${name}@2x.png`}
                 className={'VIPTierColumn__icon'}
            />
            <RoundedButton title={`${name} Guardian`}
                           className={'VIPTierColumn__button'}
                           onClick={onClick}/>
            <div className={'VIPTierColumn__required-title'}>
                Deposit Required
            </div>
            <div className={'VIPTierColumn__required-amount'}>
                {numberWithCommas(requiredAmount)}
            </div>
            <div className={'VIPTierColumn__divider'}/>
            {
                _.map(VIPBenefits, (benefit) => {
                    const enabled = benefit.tiers.has(name);
                    return (
                        <div className={'VIPTierBenefit'}>
                            <img className={'VIPTierBenefit__icon'}
                                 src={enabled ? EnabledIcon : DisabledIcon}
                            />
                            <div className={'VIPTierBenefit__title'}>
                                {benefitOverrides[benefit.id]?.title || benefit.title}
                            </div>
                            <div className={'VIPTierBenefit__description'}>
                                {benefitOverrides[benefit.id]?.description || benefit.description}
                            </div>
                            <div className={'VIPTierBenefit__coming-soon'}>
                                {
                                    benefit.comingSoon && enabled &&
                                    <span>Coming Soon</span>
                                }
                            </div>

                        </div>
                    )
                })
            }
        </div>
    )
}


function VIPPage() {
    const dispatch = useDispatch();
    const address = useSelector(selectCurrentUserId);
    const configLoadState = useSelector(selectConfigLoadState);
    const provider = useSelector(selectProvider);
    const [tier, setTier] = useState(VIPTiers.Bronze);
    const isMobile = useMediaQuery(800);
    const balances = useSelector(selectBalances);
    let userGuardianTier = useSelector(selectGuardianTier);
    let plasmStakedToSubchainValidator = balances.plasmStakedToSubchainValidator;
    let subchainStakeWithdrawals = useSelector(selectSubchainStakeWithdrawals);
    let totalPlasmPendingWithdrawl = _.reduce(subchainStakeWithdrawals, (total, withdrawal) => {
        return total.add(withdrawal.amount);
    }, BigNumber.from('0'));
    let isAllPlasmPendingWithdrawlIsReadyForClaim = subchainStakeWithdrawals.every((withdrawal) => {
        return withdrawal.isReadyForClaim;
    });
    // isAllPlasmPendingWithdrawlIsReadyForClaim = true;
    const latestPendingWithdrawl = _.maxBy(subchainStakeWithdrawals, (withdrawal) => {
        return withdrawal.returnHeight.toNumber();
    });


    useEffect(() => {
        if (!configLoadState.hasLoadedConfig && !configLoadState.isLoadingConfig) {
            dispatch(Config.actions.fetchConfig());
        }
        dispatch(Wallet.actions.getBalances());
        dispatch(Wallet.actions.getPendingSubchainValidatorStakeWithdrawals());
    }, []);

    const onStakeClick = (tier) => {
        dispatch(UIState.actions.showModal(ModalTypes.VIPDeposit, {tier}));
    }

    return (
        <div className={'PageWrapper'}>
        <TPMCStakingNavBar/>
            <div className="Page VIPPage">
                <div className={'VIPPage__title'}>
                    7734 GUARDIAN TIERS
                </div>
                <div className={'VIPPage__subtitle'}>
                    Deposit your PLASM to the Passaways subchain validators and unlock exclusive rewards & benefits.
                </div>
                {
                    userGuardianTier &&
                    <VIPUserTier amountStaked={plasmStakedToSubchainValidator}
                                 tierName={userGuardianTier}
                    />
                }
                {
                    subchainStakeWithdrawals.length > 0 &&
                    <div className={'VIPWithdrawals'}>
                        <div className={'VIPWithdrawals__title'}>
                            Pending withdrawal requests
                        </div>
                        <div className={'VIPWithdrawals__amount'}>
                            {formatBalance(totalPlasmPendingWithdrawl)}
                        </div>
                        {
                            isAllPlasmPendingWithdrawlIsReadyForClaim &&
                            <RoundedButton title={'Reclaim PLASM'}
                                             color={'green'}
                                           size={'large'}
                                             onClick={() => {
                                                  dispatch(Wallet.actions.claimWithdrawnSubchainValidatorStake());
                                             }}/>
                        }
                        {
                            !isAllPlasmPendingWithdrawlIsReadyForClaim && latestPendingWithdrawl &&
                            <div className={'VIPWithdrawals__available-in'}>
                                <span>Available in</span>
                                <br/>
                                <Countdown
                                    date={(new Date(latestPendingWithdrawl.returnDate))}
                                    renderer={timeLeftRenderer}
                                />
                            </div>
                        }
                    </div>
                }
                {
                    isMobile && (
                        <Fragment>
                            <VIPTabs tier={tier}
                                     onTierClick={(tier) => setTier(tier)}
                            />
                            <div className={"VIPTierColumns"}>
                                <VIPTierColumn onClick={() => onStakeClick(tier)}
                                               {...tier}/>
                            </div>
                        </Fragment>
                    )
                }
                {
                    !isMobile && (
                        <div className={"VIPTierColumns"}>
                            <VIPTierColumn onClick={() => onStakeClick(VIPTiers.Bronze)}
                                           {...VIPTiers.Bronze}/>
                            <VIPTierColumn onClick={() => onStakeClick(VIPTiers.Silver)}
                                           {...VIPTiers.Silver}/>
                            <VIPTierColumn onClick={() => onStakeClick(VIPTiers.Gold)}
                                           {...VIPTiers.Gold}/>
                            <VIPTierColumn onClick={() => onStakeClick(VIPTiers.ESSU)}
                                           {...VIPTiers.ESSU}/>
                        </div>
                    )
                }

            </div>

            <div className={'VIPPage__disclaimers'}>
                *Rewards and benefits are subject to change at anytime.<br/>
                **7734 Guardian Reward Power-ups are only airdropped if the status was held for the entire month.<br/>
                7734 Guardian status can be given up at any time. PLASM will be available for reclaim after ~5 days.
            </div>


        </div>
    );
}

export default VIPPage;

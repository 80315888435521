import _, {noop} from 'lodash';
import React, {useState, useEffect} from 'react';
import {TPMCNavBar, TPMCStakingNavBar} from "../../components/NavBar";
import {useDispatch, useSelector} from "react-redux";
import {selectCurrentUserId} from "../../store/models/user";
import Nft, {selectNftsByContract} from "../../store/models/nft";
import {createSelector} from "reselect";
import {ModalTypes, Urls} from "../../constants";
import {RoundedButton} from "../../components/Button";
import UIState from "../../store/uiState";
import Wallet from "../../store/models/wallet";
import {StakePlot} from "../../components/staking/StakePlot";
import {RoundedModal} from "../../components/Modal";
import classNames from "classnames";
import {NavLink} from "react-router-dom";
import useMediaQuery from "../../hooks/useMediaQuery";
import discordCTAImage from '../../assets/cta-buttons/button_discord@2x.jpg';
import merchCTAImage from '../../assets/cta-buttons/button_merch@2x.jpg';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {pushTo} from "../../utils/history";
import {CTABanner, EarnPlasmCTABanners} from "../../components/CTABanner";
import {store} from "../../store";
import {ethers} from "ethers"; // requires a loader
import "react-toggle/style.css" // for ES6 modules
import Toggle from 'react-toggle'


const ApprovalCard = ({approval, onRevoke}) => {
    const {type, spender, target, value, action} = approval;
    const [approved, setApproved] = useState(value);

    return (
        <div className={'ApprovalCard'}>
            {
                type === 'token' &&
                <div className={'ApprovalCard__description'}>
                    <span className={'ApprovalCard__contract'}>
                        {spender.name}
                    </span>
                    <span> can {action} your </span>
                    <span className={'ApprovalCard__contract'}>
                        {target.name}
                    </span>
                </div>
            }
            {
                type === 'nft' &&
                <div className={'ApprovalCard__description'}>
                    <span className={'ApprovalCard__contract'}>
                        {spender.name}
                    </span>
                    <span> can { action } your </span>
                    <span className={'ApprovalCard__contract'}>
                        {target.name} NFTs
                    </span>
                </div>
            }
            <RoundedButton title={'Revoke'}
                           color={'red'}
                           size={'small'}
                           onClick={() => {
                               onRevoke(approval)
                           }}/>

        </div>
    )
}

function ApprovalsPage() {
    const dispatch = useDispatch();
    const [approvals, setApprovals] = useState([]);

    const fetchApprovals = async () => {
        const approvals = await dispatch(Wallet.actions.getApprovals());
        console.log('approvals == ');
        console.log(approvals);
        const trueApprovals = _.filter(approvals, (approval) => {
            if(approval.type === 'token'){
                console.log('approval.value == ' + approval.value.toString());
                return approval.value.gt(ethers.utils.parseEther('0'));
            }
            return approval.value === true;
        });
        console.log(trueApprovals);
        setApprovals(trueApprovals);
    }

    useEffect( () => {
        fetchApprovals();
    }, []);

    const onRevoke = (approval) => {
        dispatch(UIState.actions.showModal(ModalTypes.Alert, {
            title: 'Revoke Approval',
            body: `Are you sure you want to revoke this approval? You may need to re-approve this permission when using the ${approval.spender.name} contract in the future.`,
            buttons: [
                <RoundedButton title={'Cancel'}
                               color={'grey'}
                               size={'medium'}
                               style={{width: 132}}
                               onClick={() => {
                                   dispatch(UIState.actions.hideModal());
                               }}
                />,
                <RoundedButton title={`Revoke`}
                               color={'red'}
                               size={'medium'}
                               style={{width: 132}}
                               onClick={async () => {
                                   dispatch(UIState.actions.hideModal());
                                   await dispatch(Wallet.actions.revokeApproval(approval));
                                   fetchApprovals();
                               }}
                />
            ],
        }));
    }

    return (
        <div className={'PageWrapper'}>
            <TPMCNavBar/>
            <div className="Page ApprovalsPage">
                <div className={'ApprovalsPage__title'}>
                    Approvals
                </div>
                <div className={'ApprovalsPage__subtitle'}>
                    Review & manage all of your account's asset approvals with TPMC related smart contracts.
                </div>
                <div className={'ApprovalsPage__cards'}>
                    {
                        _.map(approvals, (approval) => {
                            return <ApprovalCard approval={approval}
                                                 onRevoke={onRevoke}
                                                 key={approval.id}
                            />
                        })
                    }
                </div>
            </div>
        </div>
    );
}

export default ApprovalsPage;

import React, {useState, useEffect, useContext} from 'react';
import vdayCard from '../../assets/events/dreadful-valentine/card_blank.png';
import Button from "../../components/Button";
import {useDispatch} from "react-redux";
import $ from 'jquery';
import html2canvas from 'html2canvas';

const download = require("downloadjs");

var autoSizeText;

autoSizeText = function() {
    var el, elements, i, len, results;
    elements = $('#card-text');
    console.log(elements);
    if (elements.length < 0) {
        return;
    }
    results = [];
    for (i = 0, len = elements.length; i < len; i++) {
        el = elements[i];
        results.push((function(el) {
            var resizeText, results1;
            resizeText = function() {
                var elNewFontSize;
                elNewFontSize = (parseInt($(el).css('font-size').slice(0, -2)) - 1) + 'px';
                return $(el).css('font-size', elNewFontSize);
            };
            results1 = [];
            while (el.scrollHeight > el.offsetHeight) {
                results1.push(resizeText());
            }
            return results1;
        })(el));
    }
    return results;
};

function CreateDreadfulValentineEventPage() {
    const dispatch = useDispatch();
    const [text, setText] = useState('');
    const [downloaded, setDownloaded] = useState(false);

    useEffect(() => {
        autoSizeText();
    });

    const save = () => {
        if(text.length < 4){
            alert('Please enter more than 3 characters.');
            return;
        }

        const el = document.getElementById("DreadfulValentineCard");
        // el.style.display = 'block';
        el.style.opacity = 1.0;
        html2canvas(document.querySelector("#DreadfulValentineCard")).then(canvas => {
            // el.style.display = 'none';
            el.style.opacity = 0.0;
            download(canvas.toDataURL('image/png'), 'passaways-vday-card.png', 'image/png');
            setTimeout(() => {
                setDownloaded(true);
            }, 500, true);

        });
    }

    const shareOnTwitter = () => {
        alert('Your Dreadful Valentine has been downloaded. Now post it to Twitter, mention @ThePassaways and use #DreadfulValentine.\n\nMake sure you attach the image!');

        window.open('https://twitter.com/intent/tweet?text=Create%20your%20@ThePassaways%20VDay%20card%20for%20a%20chance%20at%20a%201/1%20Custom%20Passaway%21%20%0a%0aDetails%20here%3A%20passaways.com/e/valentine&hashtags=DreadfulValentine', '_blank');
    }

    const twitterUrl = 'https://twitter.com/intent/tweet?text=Create%20your%20@ThePassaways%20VDay%20card%20for%20a%20chance%20at%20a%201/1%20Custom%20Passaway%21%20%0a%0aDetails%20here%3A%20passaways.com/e/valentine&hashtags=DreadfulValentine%2CNFT%2CNFTS';

    return (
        <div className={'PageWrapper'}>
            <div className="Page CreateDreadfulValentineEventPage">
                <div style={{height: 30}}/>
                {
                    downloaded &&
                    <div className={'DreadfulValentineEventPage__title'}>Share Card</div>
                }
                {
                    !downloaded &&
                    <div className={'DreadfulValentineEventPage__title'}>Create Card</div>
                }
                {
                    !downloaded &&
                    <textarea value={text}
                              className={'CreateDreadfulValentineEventPage__input'}
                              placeholder={'Enter your dreadful valentine here...'}
                              onChange={(e) => {
                                  setText(e.target.value);
                              }}
                    />
                }
                {
                    downloaded &&
                    <Button title='Share on Twitter'
                            size={'large'}
                            target={'_blank'}
                            href={twitterUrl}
                            style={{width: 250, marginTop: 30, marginBottom: 30}}
                    />
                }
                {
                    !downloaded &&
                    <Button title='Download Card'
                            size={'large'}
                            target={'_blank'}
                            onClick={save}
                            style={{width: 250, marginTop: 30, marginBottom: 30}}
                    />
                }
                {
                    downloaded &&
                    <div className={'DreadfulValentineEventPage__welcome-subtitle'}>
                        <div>Now share the image on Twitter!</div>
                        <br/>
                        <div>Make sure you tag @ThePassaways and use #DreadfulValentine for a chance to own a custom 1/1 Passaway.</div>
                    </div>
                }
                {
                    !downloaded &&
                    <div className={'DreadfulValentineEventPage__welcome-subtitle'}>
                        <div>Download your card and post on Twitter.</div>
                        <br/>
                        <div>Make sure you tag @ThePassaways and use #DreadfulValentine for a chance to own a custom 1/1 Passaway.</div>
                    </div>
                }
                <div style={{overflow: 'scroll', width: '100vw'}}>
                    <div className={'DreadfulValentineCard'}
                         id={'DreadfulValentineCard'}
                    >
                        <img src={vdayCard}/>
                        <div id={'card-text-wrapper'}
                             className="DreadfulValentineCard__text-wrapper"
                             lang={'en'}>
                            <div className={'DreadfulValentineCard__text'}
                                 id={'card-text'}>
                                {text}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default CreateDreadfulValentineEventPage;

import jwtDecode from "jwt-decode";

export const isJwtExpired = (token) => {
    if (typeof(token) !== 'string' || !token) throw new Error('Invalid token provided');

    let isJwtExpired = false;
    const { exp } = jwtDecode(token);
    const currentTime = new Date().getTime() / 1000;

    if (currentTime > exp) isJwtExpired = true;

    return isJwtExpired;
}

export const getJWTExpirationDate = (token) => {
    if (typeof(token) !== 'string' || !token) throw new Error('Invalid token provided');

    const payload = jwtDecode(token);

    return new Date(payload.exp * 1000);
}

export const getJWTPayload = (token) => {
    if (typeof(token) !== 'string' || !token) throw new Error('Invalid token provided');

    const payload = jwtDecode(token);

    return payload;
}

import classNames from "classnames";
import {RoundedButton} from "./Button";
import React, {useEffect} from "react";
import {pushTo} from "../utils/history";
import {IsNFTStakingLive, ModalTypes, Urls} from "../constants";
import UIState from "../store/uiState";
import {useDispatch, useSelector} from "react-redux";
import {selectCurrentUserId} from "../store/models/user";
import {createSelector} from "reselect";
import {selectNftsByContract} from "../store/models/nft";
import _ from "lodash";
import wallet, {selectBalances, selectStakingAPY} from "../store/models/wallet";
import {BigNumber, ethers} from "ethers";
import {selectContractByKey} from "../store/models/config";

export const CTABanner = ({icon, title, subtitle, color, ctaTitle, onClick}) => {
    return (
        <div className={classNames('CTABanner', `CTABanner--${color}`)}>
            <img src={icon}
                 className={'CTABanner__icon'}
            />
            <div className={'CTABanner__titles-and-button'}>
                <div className={'CTABanner__titles'}>
                    <div className={'CTABanner__title'}>
                        {title}
                    </div>
                    <div className={'CTABanner__subtitle'}>
                        {subtitle}
                    </div>
                </div>
                <RoundedButton title={ctaTitle}
                               onClick={onClick}
                               color={'green'}
                               size={'medium'}
                               className={'CTABanner__button'}
                />
            </div>

        </div>
    )
}


const selectHasUnstakedPassaways = createSelector(
    (state) => {
        const PassawaysContractInfo = selectContractByKey(state, 'passaways');
        const TransphormedPassawaysContractInfo = selectContractByKey(state, 'transphormedPassaways');
        const passawaysNfts = selectNftsByContract(state,PassawaysContractInfo?.address);
        const transphormedPassawaysnfts = selectNftsByContract(state,TransphormedPassawaysContractInfo?.address);
        console.log('transphormedPassawaysnfts == ', transphormedPassawaysnfts);
        let nfts = _.flatten([passawaysNfts, transphormedPassawaysnfts]);
        nfts = _.filter(nfts, (x) => !_.isNil(x));
        return nfts;
    },
    (nfts) => ((_.filter(nfts, ({stake}) => {
        return _.isNil(stake);
    })).length > 0)
);

const selectHasUnstakedPlasm = createSelector(
    (state) => selectBalances(state),
    (balances) => {
        const onHand = balances['plasmOnHand'] || "0";
        return BigNumber.from(onHand).gte(ethers.utils.parseEther('10'));
    }
);

export const StakePassawaysCTABanner = ({onCTAClick}) => {
    const dispatch = useDispatch();
    const hasUnstakedPassaways = useSelector(selectHasUnstakedPassaways);

    return (
        <React.Fragment>
            {
                hasUnstakedPassaways &&
                <CTABanner title={'YOU HAVE UNSTAKED PASSAWAYS'}
                           subtitle={'Stake your Passaways to earn PLASM.'}
                           icon={'/cta-icons/essu@2x.png'}
                           ctaTitle={'Stake'}
                           color={'purple'}
                           onClick={() => {
                               if(onCTAClick){
                                   onCTAClick();
                               }
                               pushTo(`${Urls.TPMC_STAKE_NFTS}`);
                               dispatch(UIState.actions.showPassawayStakingModal());
                           }}
                />
            }
        </React.Fragment>
    )
}

export const StakePlasmCTABanner = () => {
    const dispatch = useDispatch();
    const hasUnstakedPlasm = useSelector(selectHasUnstakedPlasm);
    const stakingAPY = useSelector(selectStakingAPY);

    useEffect(() => {
        dispatch(wallet.actions.fetchStakingAPY());
    }, []);

    return (
        <React.Fragment>
            {
                hasUnstakedPlasm &&
                <CTABanner title={'YOU HAVE UNSTAKED PLASM'}
                           subtitle={<span>
                               <span>Stake your PLASM and earn up to </span>
                               <span>{`${stakingAPY ? stakingAPY : '~'}% APY`}</span>
                </span>}
                           icon={'/cta-icons/plasm@2x.png'}
                           ctaTitle={'Stake'}
                           color={'green'}
                           onClick={() => {
                               pushTo(`${Urls.TPMC_STAKE_PLASM}`);
                           }}
                />
            }

        </React.Fragment>
    )
}

export const EarnPlasmCTABanners = () => {
    return (
        <React.Fragment>
            {
                IsNFTStakingLive &&
                <StakePassawaysCTABanner/>
            }
            <StakePlasmCTABanner/>
        </React.Fragment>
    )
}
import React from "react";
import StakingTierBadge from "./StakingTierBadge";
import NineNineNineIcon from "../../assets/icons/999badge@2x.png";
import TransphormNineNineNineIcon from "../../assets/icons/transphorm-999badge@2x.png";
import TransphormIcon from "../../assets/icons/transphorm-badge@2x.png";
import {getContractAddressByKey} from "../../store/models/config";
import ReactTooltip  from 'react-tooltip'

export default function PassawayWithMeta({nft}) {
    const {metadata} = nft;
    let {edition, image, attributes, is_999er} = metadata || {};
    let {tier, Tier} = attributes;
    tier = tier || Tier; // Naming inconsistency across passaways and transphormed passaways
    let isTransphormedPassaway = nft.contract_address === getContractAddressByKey('transphormedPassaways');
    let tooltipContent = null;

    if(isTransphormedPassaway){
        tooltipContent = `Transphormed Passaway`
    }
    else if(is_999er){
        tooltipContent = `999er Passaway`;
    }

    return (
        <div className={'PassawayWithMeta'}>
            <img className="PassawayWithMeta__image" src={`${image}?w=380`}/>
            {
                tier &&
                <StakingTierBadge tier={tier}/>
            }
            <div className="PassawayWithMeta__edition">#{edition}</div>
            <div className={'PassawayWithMeta__icons'}>
                {
                    isTransphormedPassaway &&
                    <img src={TransphormIcon}
                         className={'PassawayWithMeta__transphorm-badge'}
                         data-tip={tooltipContent}
                         data-for={`PassawayWithMeta-${nft.token_id}`}
                    />
                }
                {
                    is_999er && !isTransphormedPassaway &&
                    <img src={NineNineNineIcon} className={'PassawayWithMeta__999-badge'}/>
                }
                {
                    is_999er && isTransphormedPassaway &&
                    <img src={TransphormNineNineNineIcon} className={'PassawayWithMeta__999-badge'}/>
                }
            </div>

            {
                (isTransphormedPassaway || is_999er) &&
                <ReactTooltip id={`PassawayWithMeta-${nft.token_id}`}
                              place="top" type="dark" effect="solid" className='Tooltip'/>
            }
        </div>
    );
}
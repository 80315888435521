import React from 'react';
import _ from 'lodash';
import { createBrowserHistory } from 'history';
import { useParams } from 'react-router-dom';

import { path, mergeUrl } from '../utils/url';



// ===========================
// HISTORY
// ===========================
export const history = (() => {
    let h = {};
    try {
        h = createBrowserHistory();
    } catch(e) {

    }
    return h;
})();


// ===========================
// HELPERS
// ===========================
export const newTab = (url, params) => window.open(mergeUrl(url, params))
export const back = () => history.back?.()
export const pushTo = (url, params) => history.push?.(mergeUrl(url, params))
export const replaceTo = (url, params) => history.replace?.(mergeUrl(url, params));
export const pathTo = (url, params) => mergeUrl(url, params);



// ===========================
// SELECTORS
// ===========================
export const selectRoute = (state, key) => state.route;
export const selectQuery = (state, key) => state.route.query[key];
export const selectParam = (state, key) => state.route.param[key];

import _ from 'lodash';
import {post, get, put, authHeaders} from "../../utils/fetch";
import {Urls, RequestState, Hosts} from "../../constants";
import Storage, {USER_TOKEN} from '../../utils/storage';

import AppState from "../appState";
import {pushTo} from "../../utils/history";
import storage from "../../utils/storage";


// ===========================
// HELPERS
// ===========================


// ACTIONS
// ===========================
export const FETCH_USER = 'FETCH_USER';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const FINISH_OAUTH = 'FINISH_OAUTH';
export const FINISH_DISCORD_OAUTH = 'FINISH_DISCORD_OAUTH';

// ===========================
// SELECTORS
// ===========================
export const selectUser = (state, userId) => state.models.users[userId];

export const selectCurrentUserId = state => state?.app.userId;
export const selectCurrentUser = state => selectUser(state, selectCurrentUserId(state));
export const selectIsOwner = state => state?.app.isOwner;

// ===========================
// MODEL
// ===========================
const User = {
    actions: {
        finishOAuth: (authorizationCode) => async (dispatch, getState) => {
            const result = await post({
                url: "/v1/auth/oauth/theta-drop",
                host: Hosts.API,
                body: {
                    authorization_code: authorizationCode,
                },
                dispatch,
                action: FINISH_OAUTH,
            });
            const user = _.get(result, 'body.user', null);
            const token = _.get(result, 'body.token', null);
            const isOwner = _.get(result, 'body.is_owner', false);

            dispatch(User.actions.setUserCredentials(user.id, token, isOwner));

            return result;
        },
        finishDiscordOAuth: (authorizationCode) => async (dispatch, getState) => {
            const result = await post({
                url: "/v1/auth/oauth/discord",
                host: Hosts.API,
                body: {
                    authorization_code: authorizationCode,
                },
                headers: {
                    'x-session-token': _.get(getState(), 'app.userToken')
                },
                dispatch,
                action: FINISH_DISCORD_OAUTH,
            });

            return result;
        },

        fetchUser: (id, useAuth = true, params = {}) => async (dispatch, getState) =>
            get({
                url: "/user/:userId",
                params: {
                    userId: id ?? selectCurrentUserId(getState()),
                    expand: ['tps_id'],
                    ...params,
                },
                headers: useAuth ? authHeaders() : {},
                dispatch,
                action: FETCH_USER,
            })
                .then(results => results?.body?.user_profiles?.[0]),

        setUserCredentials: (userId, userToken, isOwner) => async (dispatch, getState) => {
            await dispatch(AppState.actions.setAuth(userId, userToken, isOwner));
        },

        logout: (withRedirect = true) => async (dispatch) => {
            await Storage.clearUserAuth();
            await dispatch({type: LOGOUT});

            if (withRedirect) {
                pushTo(Urls.TPMC_LOGIN)
            }
            window.location.reload();
        },
    },

    spec: {
        users: {},
    },

    modelReducer: (state, type, body, action) => {
        if (action.url && action.result !== RequestState.SUCCESS)
            return state;

        if (type === FINISH_OAUTH) {
            const nfts = body.nfts;
            return {
                ...state,
                users: _.keyBy(_.flatten([Object.values(state.users), body.user]) , 'id'),
                nfts: nfts,
                nftsById: _.keyBy(_.flatten([Object.values(state.nftsById), body.nfts]) , 'id'),
                nftsByOwnerId: _.merge(state.nftsByOwnerId, _.groupBy(nfts, 'owner_tps_id'))
            }
        }
        else if (type === FINISH_DISCORD_OAUTH){
            console.log('FINISH DISCORD');
        }

        return state;
    }
}
export default User;

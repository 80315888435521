import {useDispatch, useSelector} from "react-redux";
import approvalApprovedIcon from "../../assets/icons/approval-approved@2x.png";
import approvalMissingIcon from "../../assets/icons/approval-missing@2x.png";
import React, {useEffect, useState} from "react";
import wallet, {checkNFTApproval, checkTokenApproval} from "../../store/models/wallet";
import {formatWeiWithoutCommas} from "../../utils";
import ReactTooltip  from 'react-tooltip'
import Loader, {InlineLoader} from "../Loader";
import {selectCurrentUserId} from "../../store/models/user";
import UIState from "../../store/uiState";

export function RequiredApprovals({spenderAddress, tokenAddress, tokenAmount, nftAddresses}) {
    const dispatch = useDispatch();
    const address = useSelector(selectCurrentUserId);
    const [approvals, setApprovals] = useState([]);
    const refreshApprovals = async () => {
        const approvals = await dispatch(wallet.actions.getApprovalsForSpender(spenderAddress, tokenAddress, nftAddresses));
        setApprovals(approvals);
    };
    useEffect(() => {
        if(address){
            refreshApprovals()
        }
    }, [address, spenderAddress, tokenAddress, nftAddresses]);

    const Approval = ({approval}) => {
        const {action, type, value, spender, target} = approval;
        const onClick = async () => {
            try {
                if (type === 'token') {
                    await checkTokenApproval(target.contract, address, spender.address, tokenAmount, target.name.toUpperCase());
                }
                else{
                    await checkNFTApproval(target.contract, address, spender.address);
                }

            }
            catch (e){

            }
            finally {
                dispatch(UIState.actions.hideLoader());
                refreshApprovals();

                setTimeout(() => {
                    refreshApprovals();
                }, 4000);
            }
        };
        const approved = type === 'nft' ? (value === true) : parseFloat(formatWeiWithoutCommas(value)) >= parseFloat(formatWeiWithoutCommas(tokenAmount));
        const approvedTooltipContent = type === 'nft'
            ? `You have approved ${spender.name} to ${action} your ${target.name} NFTs`
            : `You have approved ${spender.name} to ${action} your ${target.name} tokens`;
        const missingTooltipContent = type === 'nft'
            ? `You must approve ${spender.name} to ${action} your ${target.name} NFTs`
            : `You must approve ${spender.name} to ${action} your ${target.name} tokens`;
        const tooltipContent = approved ? approvedTooltipContent : missingTooltipContent;

        return <div className={'RequiredApproval'}
                    onClick={onClick}
                    data-tip={tooltipContent}
                    data-for={approval.id}
                    data-tooltip-id={'RequiredApprovalsTooltip'}
                    data-tooltip-content={tooltipContent}
        >
            <div className={'RequiredApproval__name'}>{approval.target.name}</div>
            <img src={approved ? approvalApprovedIcon : approvalMissingIcon}/>
            <ReactTooltip id={approval.id}
                          place="top" type="dark" effect="solid" className='Tooltip'/>
        </div>
    }

    return (
        <div className={'RequiredApprovals'}>
            <span className={'RequiredApprovals__title'}>Required Approvals:</span>
            <div className={'RequiredApprovals__approvals'}>
                {
                    approvals.map(approval => <Approval approval={approval}
                                                        key={approval.id}/>)
                }
                {
                    approvals.length === 0 && <InlineLoader size={'14px'}/>
                }
            </div>
            <ReactTooltip id={'RequiredApprovalsTooltip'} />
        </div>
    );
}
import React, {useState} from 'react';
import {connect, useSelector} from 'react-redux'
import Modal, {RoundedModal} from '../../components/Modal';
import logo from '../../assets/logo/misadventure-club-white.svg';
import modalCloseIcon from '../../assets/icons/modal-close@2x.png';
import UIState from "../../store/uiState";
import {selectCurrentUser} from "../../store/models/user";
import {createSelector} from "reselect";
import {selectNftsByContract} from "../../store/models/nft";
import _ from "lodash";
import PassawayWithMeta from "../../components/staking/PassawayWithMeta";
import plasmLogo from "../../assets/tokens/plasm-front-small@2x.png";
import Button, {RoundedButton} from "../../components/Button";
import classNames from "classnames";
import Wallet, {selectBalances} from "../../store/models/wallet";
import {formatBalance} from "../../utils";
import * as thetajs from '@thetalabs/theta-js';
import {IsNFTStakingLive, IsPLASMLive, ModalTypes} from "../../constants";
import useAutoFocus from "../../hooks/useAutoFocus";


export const PercentageInput = ({value, onChange, onAll}) => {
    const [focused, setFocused] = useState(false);

    return (
        <div className={classNames('PercentageInput', {
            'PercentageInput--focused': focused
        })}>
            <input value={value}
                   onChange={onChange}
                   onFocus={() => {
                       setFocused(true);
                   }}
                   onBlur={() => {
                       setFocused(false);
                   }}
                   type={'number'}
                   min={0}
                   max={100}
            />
            <div className={'PercentageInput__percentage'}>%</div>
        </div>
    );
}

export const PlasmInput = ({value, placeholder, onChange, onAll, autoFocus}) => {
    const [focused, setFocused] = useState(false);
    const inputRef = useAutoFocus(autoFocus);

    return (
        <div className={classNames('PlasmInput', {
            'PlasmInput--focused': focused
        })}>
            <img src={plasmLogo}/>
            <input value={value}
                   onChange={onChange}
                   onFocus={() => {
                       setFocused(true);
                   }}
                   onBlur={() => {
                       setFocused(false);
                   }}
                   placeholder={placeholder}
                   type={'number'}
                   min={0}
                   ref={inputRef}
            />
            <a className={'PlasmInput__all-button'}
               onClick={onAll}
            >
                All
            </a>
        </div>
    );
}

export const PlasmActionInputField = ({title, children}) => {
    return (
        <div className={'PlasmActionInputField'}>
            <div className={'PlasmActionInputField__title'}>
                {title}
            </div>
            {children}
        </div>
    );
}

export const PlasmActionContainer = ({title, subtitle, balance, className, children}) => {
    return (
        <div className={classNames('PlasmActionContainer', className)}>
            <div className={'PlasmActionContainer__title'}>
                {title}
            </div>
            {
                subtitle &&
                <div className={'PlasmActionContainer__subtitle'}>
                    {subtitle}
                </div>
            }
            <div className={'PlasmActionContainer__balance'}>
                {balance}
            </div>
            {children}
        </div>
    );
}

export class PlasmModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            amountToStake: "",
            amountToUnstake: ""
        };
    }

    componentDidMount() {
        this.props.dispatch(Wallet.actions.getBalances())
    }

    onClose = () => {
        this.props.dispatch(UIState.actions.hideModal());
    }

    onClaimPassawayRewards = () => {
        this.props.dispatch(Wallet.actions.claimPassawayStakingRewards());
    }

    onChange = (inputName, value) => {
        this.setState({
            [inputName]: value
        })
    }

    onStake = async () => {
        const success = await this.props.dispatch(Wallet.actions.stakePlasm(this.state.amountToStake));
        if (success) {
            this.setState({
                amountToStake: ''
            });
        }
    }

    onUnstake = async () => {
        const success = await this.props.dispatch(Wallet.actions.unstakePlasm(this.state.amountToUnstake));
        if (success) {
            this.setState({
                amountToUnstake: ''
            });
        }
    }

    onUnstakeClick = () => {
        this.props.dispatch(UIState.actions.hideModal());
        this.props.dispatch(UIState.actions.showModal(ModalTypes.UnstakePlasm));
    }

    render() {
        const {balances} = this.props;
        const {plasmPending, plasmOnHand, plasmStaked} = balances;

        if (!IsPLASMLive) {
            return (
                <RoundedModal title={'PLASM'}
                              className={'PlasmModal'}
                              onClose={this.onClose}>
                    <div className={'PlasmModal__content'}>

                        <div className={'EmptyState'}>
                            <div className={'EmptyState__title'}>
                                Keep your<br/>eyes peeled
                            </div>
                        </div>
                    </div>
                </RoundedModal>
            )
        }

        return (
            <RoundedModal title={'PLASM'}
                          className={'PlasmModal'}
                          onClose={this.onClose}
            >
                <div className={'PlasmModal__content'}>
                    <PlasmActionContainer title={'Pending Rewards'}
                                          subtitle={'(NFT Staking)'}
                                          balance={formatBalance(plasmPending)}
                                          className={'PlasmActionContainer--center'}
                    >
                        <PlasmActionInputField title={'Claim Your Rewards'}>
                            <RoundedButton color={'green'}
                                           size={'small'}
                                           title={'Claim All'}
                                           disabled={!IsNFTStakingLive}
                                           onClick={this.onClaimPassawayRewards}
                            />
                        </PlasmActionInputField>
                    </PlasmActionContainer>
                    <PlasmActionContainer title={'PLASM On Hand'}
                                          subtitle={'(Spendable)'}
                                          balance={formatBalance(plasmOnHand)}
                                          className={'PlasmActionContainer--center'}
                    >
                        <PlasmActionInputField title={'Stake Your PLASM'}>
                            <PlasmInput value={this.state.amountToStake}
                                        placeholder={'Enter amount'}
                                        onChange={(e) => {
                                            this.onChange('amountToStake', e.target.value);
                                        }}
                                        onAll={() => {
                                            // Unstake 100%
                                            this.setState({
                                                amountToStake: `${parseInt(thetajs.utils.fromWei(plasmOnHand))}`
                                            });
                                        }}
                            />
                            <RoundedButton color={'green'}
                                           size={'small'}
                                           title={'Stake'}
                                           onClick={this.onStake}
                            />
                            <RoundedButton title={'GET MORE PLASM'}
                                           color={'green'}
                                           size={'large'}
                                           style={{marginTop: 30,
                                           width: 200}}
                                           onClick={() => {
                                               this.props.dispatch(UIState.actions.showModal(ModalTypes.GetPlasm))
                                           }}
                            />
                        </PlasmActionInputField>
                    </PlasmActionContainer>
                    <PlasmActionContainer title={'Staked PLASM'}
                                          subtitle={'(+EST. REWARD)'}
                                          className={'PlasmActionContainer--center'}
                                          balance={formatBalance(plasmStaked)}
                    >
                        <a className={'PlasmStakingPage__unstake-button TextLink'}
                           onClick={this.onUnstakeClick}
                        >
                            Unstake
                        </a>
                        {/*<PlasmActionInputField title={'Unstake Your PLASM'}>*/}
                        {/*    <PercentageInput value={this.state.amountToUnstake}*/}
                        {/*                onChange={(e) => {*/}
                        {/*                    this.onChange('amountToUnstake', e.target.value);*/}
                        {/*                }}*/}
                        {/*                onAll={() => {*/}
                        {/*                    // Unstake 100%*/}
                        {/*                    this.setState({*/}
                        {/*                        amountToUnstake: 100*/}
                        {/*                    });*/}
                        {/*                }}*/}
                        {/*    />*/}
                        {/*    <RoundedButton color={'green'}*/}
                        {/*                   size={'small'}*/}
                        {/*                   title={'Unstake'}*/}
                        {/*                   onClick={this.onUnstake}*/}
                        {/*    />*/}
                        {/*</PlasmActionInputField>*/}
                    </PlasmActionContainer>
                </div>
            </RoundedModal>
        );
    }
}

const mapStateToProps = (state, props) => {
    const balances = selectBalances(state);

    return {
        ...props,
        balances,
    }
};

export default connect(mapStateToProps)(PlasmModal);

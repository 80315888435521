import React from 'react';
import logo from '../../assets/logo/misadventure-club-white.svg';
import Button from "../../components/Button";
import {Urls} from "../../constants";

function AccessDeniedPage() {

    return (
        <div className={'PageWrapper'}>
            <div className="Page AccessDeniedPage">
                <img src={logo} className={'AccessDeniedPage__logo'}/>
                <div className={'AccessDeniedPage__title'}>
                    Access Denied
                </div>
                <div className={'AccessDeniedPage__description'}>
                    A Passaway is your ticket into the Misadventure Club. Benefits of membership will include ongoing airdrops to users, a dedicated Discord Channel, exclusive merch, $PLASM token earnings and much much more...
                </div>
                <Button title={'GET YOUR PASSAWAY'}
                        size={'xlarge'}
                        href={'https://passaways.thetadrop.com'}
                        target={'_blank'}
                />
                <a className={'AccessDeniedPage__prove-ownership-link'}
                   href={Urls.TPMC_LOGIN}
                >
                    Prove Ownership
                </a>
            </div>
        </div>
    );
}

export default AccessDeniedPage;

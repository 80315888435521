import {useDispatch} from "react-redux";
import _ from "lodash";
import UIState from "../../store/uiState";
import {ModalTypes, PowerUpTypes, UseInventoryActions} from "../../constants";
import Wallet from "../../store/models/wallet";
import {PowerUpSlot} from "./PowerUpSlot";
import React from "react";

export function StakePlotPowerUps({nft, permanentPowerUpSlot, permanentPowerUp, ephemeralPowerUpSlots, ephemeralPowerUps}) {
    const dispatch = useDispatch();
    const tokenId = nft.token_id;
    ephemeralPowerUps = ephemeralPowerUps || [];
    const onUseEphemeralPowerUpClick = (slotIdx) => {
        if(!_.isNil(ephemeralPowerUps[slotIdx])){
            dispatch(UIState.actions.showModal(ModalTypes.ActivePowerUp, {
                powerUp: ephemeralPowerUps[slotIdx],
                powerUpType: PowerUpTypes.Ephemeral
            }));
        }
        else if(ephemeralPowerUpSlots === 0 || ephemeralPowerUpSlots < slotIdx + 1){
            dispatch(UIState.actions.showModal(ModalTypes.PowerUpSlotProduct, {
                nft: nft,
                ephemeralPowerUpSlots: ephemeralPowerUpSlots,
                powerUpType: PowerUpTypes.Ephemeral,
                onBuy: () => {
                    dispatch(Wallet.actions.purchaseEphemeralPowerUpSlot(tokenId));
                }
            }));
        }
        else{
            dispatch(UIState.actions.showModal(ModalTypes.UseInventory, {
                title: "Use Ephemeral Power-Up",
                action: UseInventoryActions.EphemeralPowerUp,
                onSelect: (nft) => {
                    console.log(nft);
                    dispatch(Wallet.actions.addEphemeralPowerUp(tokenId, nft.address, null));
                }
            }));
        }
    }
    const onUsePermanentPowerUpClick = () => {
        if(!_.isNil(permanentPowerUp)){
            dispatch(UIState.actions.showModal(ModalTypes.ActivePowerUp, {
                powerUp: permanentPowerUp,
                powerUpType: PowerUpTypes.Permanent,
                onRemovePermanentPowerUp: () => {
                    console.log('onRemovePermanentPowerUp');
                    dispatch(Wallet.actions.removePermanentPowerUp(tokenId));
                }
            }));
        }
        else{
            // Check if they have the slot
            if(!permanentPowerUpSlot){
                dispatch(UIState.actions.showModal(ModalTypes.PowerUpSlotProduct, {
                    nft: nft,
                    powerUpType: PowerUpTypes.Permanent,
                    onBuy: () => {
                        dispatch(Wallet.actions.purchasePermanentPowerUpSlot(tokenId));
                    }
                }));
            }
            else{
                dispatch(UIState.actions.showModal(ModalTypes.UseInventory, {
                    title: "Use Perma Power-Up",
                    action: UseInventoryActions.PermanentPowerUp,
                    onSelect: (nft) => {
                        console.log(nft);
                        dispatch(Wallet.actions.addPermanentPowerUp(tokenId, nft.address, null));
                    },
                    canUse: (nft) => {
                        if(nft.permanentPowerUp?.eligiblePassawayTokenIds?.length > 0){
                            return nft.permanentPowerUp.eligiblePassawayTokenIds.includes(tokenId);
                        }
                        else if(nft.permanentPowerUp?.eligiblePassawayTokenIdRange?.length > 0){
                            return (
                                parseInt(tokenId) >= nft.permanentPowerUp.eligiblePassawayTokenIdRange[0] &&
                                parseInt(tokenId) <= nft.permanentPowerUp.eligiblePassawayTokenIdRange[1]
                            );
                        }

                        return true;
                    }
                }));
            }
        }
    }

    return (
        <div className={'StakePlotPowerUps'}>
            <div className={'StakePlotPowerUps__title'}>Power-Ups</div>
            <div className={'StakePlotPowerUps__slots'}>
                <PowerUpSlot powerUpType={PowerUpTypes.Permanent}
                             isReady={permanentPowerUpSlot}
                             powerUp={permanentPowerUp}
                             onClick={onUsePermanentPowerUpClick}
                />
                <PowerUpSlot powerUpType={PowerUpTypes.Ephemeral}
                             isReady={ephemeralPowerUpSlots > 0}
                             powerUp={ephemeralPowerUps[0]}
                             onClick={() => {
                                 onUseEphemeralPowerUpClick(0);
                             }}
                />
                <PowerUpSlot powerUpType={PowerUpTypes.Ephemeral}
                             isReady={ephemeralPowerUpSlots > 1}
                             powerUp={ephemeralPowerUps[1]}
                             onClick={() => {
                                 onUseEphemeralPowerUpClick(1);
                             }}
                />
                <PowerUpSlot powerUpType={PowerUpTypes.Ephemeral}
                             isReady={ephemeralPowerUpSlots > 2}
                             powerUp={ephemeralPowerUps[2]}
                             onClick={() => {
                                 onUseEphemeralPowerUpClick(2);
                             }}
                />
            </div>
        </div>
    );
}
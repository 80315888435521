import _ from 'lodash';
import React, {useState} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux'
import UIState from "../../store/uiState";
import ProductModal from "./Product";
import StakingTierBadge from "../../components/staking/StakingTierBadge";
import {formatBalance} from "../../utils";
import Wallet from "../../store/models/wallet";
import {PowerUpTypes} from "../../constants";
import ExpeditePassawayTransphormOrder from "./ExpeditePassawayTransphormOrder";

export class ExpeditePassawayTransphormOrderModal extends React.Component {
    constructor() {
        super();

        this.state = {
            price: null
        }
    }

    componentDidMount() {
        this.fetchPrice();
    }

    onClose = () => {
        this.props.dispatch(UIState.actions.hideModal());
    }

    fetchPrice = async () => {
        const {order} = this.props;

        const price = await this.props.dispatch(Wallet.actions.getExpeditePassawayTransphormOrderPrice(order));
        this.setState({
            price: price
        });
    }

    render() {
        const {order, onBuy} = this.props;
        const {price} = this.state;
        const name = 'Speed up Passaway Transphormation';
        const description = 'Expedite Transphormation for immediate usage of your Transphormed Passaway';

        return (
            <ProductModal title={'Expedite Transphorm'}
                          productName={name}
                          productDescription={description}
                          productImage={'/products/expedite@2x.png'}
                          productLineItems={[
                              {
                                  title: 'Price',
                                  content: _.isNil(price) ? <div/> : <div className={'PlasmPrice'}>{formatBalance(price.toString())}</div>
                              }]}
                          onBuy={onBuy}
            />
        );
    }
}

const mapStateToProps = (state, props) => {
    const {action} = props;

    return {
        ...props,
    }
};

export default connect(mapStateToProps)(ExpeditePassawayTransphormOrderModal);

import React from 'react';
import {connect, useSelector} from 'react-redux'
import Modal, {RoundedModal} from '../../components/Modal';
import UIState from "../../store/uiState";
import _ from "lodash";
import {RoundedButton} from "../../components/Button";
import {ContractCategories, CurrentNetwork, ModalTypes, PowerUpTypes, UseInventoryActions} from "../../constants";
import {selectNftBalance} from "../../store/models/nft";
import {selectConfig, selectContract, selectNFTContracts} from "../../store/models/config";
import classNames from "classnames";
import {InventoryItemCard} from "../../components/staking/InventoryItemCard";
import supplyStoreLogo from "../../assets/stake/supply-store/logo@2x.png";
import modalCloseIcon from "../../assets/icons/modal-close@2x.png";
import {Tab, TabList, Tabs} from "react-tabs";
import {getNFTImage} from "../../utils/nfts";

export class InventoryModal extends React.Component {
    constructor() {
        super();

        this.state = {
            tabIndex: 0,
            selectedCategory: ContractCategories.PowerUp,
            powerUpTypeTabIndex: 0,
            selectedPowerUpType: PowerUpTypes.Permanent
        };
    }

    onClose = () => {
        this.props.dispatch(UIState.actions.hideModal());
    }

    onSelect = (nft) => {
        const {onSelect} = this.props;

        if(onSelect){
            onSelect(nft);
        }

        if(nft.category === ContractCategories.Pack){
            this.props.dispatch(UIState.actions.showModal({
                type: ModalTypes.OpenPack,
                props: {
                    nft: nft
                }
            }));
        }
    }

    render() {
        const {nfts} = this.props;
        const {selectedCategory, selectedPowerUpType} = this.state;
        const nftsToShow = _.filter(nfts, (nft) => {
            if(selectedCategory === ContractCategories.PowerUp){
                return (nft.category === selectedCategory || nft.permanentPowerUpEligible);
            }
            return (nft.category === selectedCategory);
        });

        return (
            <RoundedModal title={'Inventory'}
                          onClose={this.onClose}
                          className={'InventoryModal'}
                          header={(
                              <div className={'RoundedModalHeader RoundedModalHeader--expanded'}>
                                  <div className={'RoundedModalHeader__title'}>
                                      My Inventory
                                  </div>
                                  <a className={'RoundedModalHeader__close-button'}
                                     onClick={this.onClose}
                                  >
                                      <img src={modalCloseIcon}/>
                                  </a>

                                  <Tabs selectedIndex={this.state.tabIndex}
                                        onSelect={(index) => {
                                            this.setState({tabIndex: index});
                                            if(index === 0){
                                                this.setState({selectedCategory: ContractCategories.PowerUp});
                                            }
                                            else if(index === 1){
                                                this.setState({selectedCategory: ContractCategories.Transphorm});
                                            }
                                            else if(index === 2){
                                                this.setState({selectedCategory: ContractCategories.Phlair});
                                            }
                                            else if(index === 3){
                                                this.setState({selectedCategory: ContractCategories.Blueprint});
                                            }
                                            else if(index === 4){
                                                this.setState({selectedCategory: ContractCategories.Extras});
                                            }
                                            else if(index === 5){
                                                this.setState({selectedCategory: ContractCategories.Pack});
                                            }
                                        }}
                                        className={'TextTabs'}>
                                      <TabList className={'TextTabList'}>
                                          <Tab>
                                              <span>Power-ups</span>
                                          </Tab>
                                          <Tab>
                                              <span>Transphorm</span>
                                          </Tab>
                                          <Tab>
                                              <span>Phlair</span>
                                          </Tab>
                                          <Tab>
                                              <span>Blueprints</span>
                                          </Tab>
                                          <Tab>
                                              <span>Extras</span>
                                          </Tab>
                                          <Tab>
                                              <span>Packs</span>
                                          </Tab>
                                      </TabList>
                                  </Tabs>
                              </div>
                          )}
            >
                {
                    (selectedCategory === ContractCategories.PowerUp) &&
                    <>
                        <Tabs selectedIndex={this.state.powerUpTypeTabIndex}
                              onSelect={(index) => {
                                  this.setState({powerUpTypeTabIndex: index});
                                  if(index === 0){
                                      this.setState({selectedPowerUpType: PowerUpTypes.Permanent});
                                  }
                                  else if(index === 1){
                                      this.setState({selectedPowerUpType: PowerUpTypes.Ephemeral});
                                  }
                              }}
                              className={'TextTabs TextTabs--no-underline'}>
                            <TabList className={'TextTabList'}>
                                <Tab>
                                    <span>Perma</span>
                                </Tab>
                                <Tab>
                                    <span>Ephemeral</span>
                                </Tab>
                            </TabList>
                        </Tabs>
                        <div className={'InventoryModal__items'}>
                            {
                                _.map(nftsToShow, (nft) => {
                                    if(selectedPowerUpType === PowerUpTypes.Permanent && !nft.permanentPowerUpEligible){
                                        return null;
                                    }
                                    else if(selectedPowerUpType === PowerUpTypes.Ephemeral && nft.permanentPowerUpEligible){
                                        return null;
                                    }

                                    return (
                                        <InventoryItemCard nft={nft}
                                                           buttonTitle={null}
                                                           onSelect={this.onSelect}
                                        />
                                    )
                                })
                            }
                        </div>
                    </>
                }
                {
                    (selectedCategory !== ContractCategories.PowerUp) &&
                    <div className={'InventoryModal__items'}>
                        {
                            _.map(nftsToShow, (nft) => {
                                return (
                                    <InventoryItemCard nft={nft}
                                                       buttonTitle={(nft.category === ContractCategories.Pack && nft.balance > 0) ? 'Open' : null}
                                                       onSelect={this.onSelect}
                                    />
                                )
                            })
                        }
                    </div>
                }
                {
                    nftsToShow.length === 0 &&
                    <div className={'EmptyState'}>
                        <div className={'EmptyState__title'}>
                            Keep your<br/>eyes peeled
                        </div>
                    </div>
                }
            </RoundedModal>
        );
    }
}

const mapStateToProps = (state, props) => {
    let nfts = selectNFTContracts(state);
    let items = _.map(nfts, (nft) => {
        return {
            ...nft,
            image: getNFTImage(nft.key),
            balance: selectNftBalance(state, nft.address),
        }
    });

    return {
        ...props,
        nfts: items
    }
};

export default connect(mapStateToProps)(InventoryModal);

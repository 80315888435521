import _, {noop} from 'lodash';
import React, {useState, useEffect} from 'react';
import {IsPLASMLive, ModalTypes, Urls} from "../../constants";
import UIState from "../../store/uiState";
import classNames from "classnames";
import useMediaQuery from "../../hooks/useMediaQuery";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import prevArrow from '../../assets/landing/prev-arrow@2x.png';
import nextArrow from '../../assets/landing/next-arrow@2x.png';


const TPMCHeroBanner = ({banner}) => {
    const isMobile = useMediaQuery(768);

    return (
        <a className={'TPMCHeroBanner'}
           onClick={banner.onClick}
        >
            <img src={isMobile ? banner.imageMobile : banner.image}/>
        </a>
    )
}

const TPMCHeroBanners = ({banners}) => {
    return (
        <div className={'TPMCHeroBanners'}>
            <Carousel centerMode={true}
                      infiniteLoop={true}
                      showThumbs={false}
                      showStatus={false}
                      autoPlay={true}
                      interval={5000}
                      centerSlidePercentage={100}
                      onClickItem={(index) => {
                          const banner = banners[index];
                          if(banner.onClick){
                              banner.onClick();
                          }
                      }}
                      renderIndicator={(clickHandler, isSelected, index, label) => {
                          return (
                              <div className={classNames('CarouselIndicator', {
                                  'CarouselIndicator--selected': isSelected
                              })}
                                   onClick={clickHandler}>
                                  <div className={'CarouselIndicator__inner'}/>
                              </div>
                          )
                      }}
                      renderThumbs={noop}
                      renderArrowPrev={(onClickHandler, hasPrev, label) =>
                          hasPrev && (
                              <a onClick={onClickHandler}
                                 className={'CarouselNavButton CarouselNavButton--prev'}
                              >
                                  <img src={prevArrow}/>
                              </a>
                          )
                      }
                      renderArrowNext={(onClickHandler, hasNext, label) =>
                          hasNext && (
                              <a onClick={onClickHandler}
                                 className={'CarouselNavButton CarouselNavButton--next'}
                              >
                                  <img src={nextArrow}/>
                              </a>
                          )
                      }
            >
                {
                    _.map(banners, (banner) => {
                        return <TPMCHeroBanner banner={banner}/>
                    })
                }
            </Carousel>
        </div>
    )
}

export default TPMCHeroBanners;
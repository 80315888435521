import Countdown, {zeroPad} from "react-countdown";
import {countdownRenderText, formatWei} from "../../utils";
import {RoundedButton} from "../Button";
import React from "react";

const renderAvailableIn = (countDownData) => {
    if(countDownData.completed){
        return null;
    }

    return (
        <div className={'ProductCard__available-in'}>
            {`Available in ${countdownRenderText(countDownData)}`}
        </div>
    )
}

const renderAvailableFor = (countDownData) => {
    if(countDownData.completed){
        return null;
    }

    return (
        <div className={'ProductCard__available-for'}>
            {`Available for ${countdownRenderText(countDownData)}`}
        </div>
    )
}

export const ProductCard = ({product, onDetails, onBuy}) => {
    const {plasmPrice, gummyPrice, remaining, start, end, startsAt, endsAt, enabled, nft} = product;
    const now = new Date();

    return (
        <div className={'ProductCard'}
             onClick={(e) => {
                 e.preventDefault();
                 e.stopPropagation();
                 onDetails(product);
             }}
        >
            {
                nft.beta &&
                <div className={'BetaBadge'}>{`BETA`}</div>
            }
            {
                remaining > 0 &&
                <div className={'NumberBadge'}>{`${remaining} LEFT`}</div>
            }
            <img src={nft.image}
                 className={'ProductCard__image'}
            />
            <div className={'ProductCard__name'}>{nft.name}</div>
            <div className={'ProductCard__description'}>{nft.descriptionShort || nft.description}</div>
            <div className={'ProductCard__price'}>{formatWei(plasmPrice)}</div>
            {
                startsAt &&
                <Countdown
                    date={(new Date(startsAt))}
                    renderer={renderAvailableIn}
                />
            }
            {
                endsAt &&
                <Countdown
                    date={(new Date(endsAt))}
                    renderer={renderAvailableFor}
                />
            }
            <div className={'ProductCard__footer'}>
                <RoundedButton title={'Buy'}
                               onClick={(e) => {
                                   e.preventDefault();
                                   e.stopPropagation();

                                   onBuy(product)
                               }}
                               disabled={remaining === 0 || !enabled ||
                                   (startsAt && new Date(startsAt) > now) ||
                                   (endsAt && new Date(endsAt) < now)}
                               color={'green'}
                />
            </div>
        </div>
    );
}
import React from 'react';
import {connect} from 'react-redux'
import Modal from '../../components/Modal';
import thetaDropLogo from '../../assets/login/theta-drop@2x.png';
import thetaWalletLogo from '../../assets/login/theta-wallet@2x.png';
import thetaRarityLogo from '../../assets/login/theta-rarity@2x.png';
import metamaskLogo from '../../assets/login/metamask@2x.png';
import UIState from "../../store/uiState";
import {selectCurrentUser} from "../../store/models/user";
import Button from "../../components/Button";
import {LoginTypes} from "../../constants";
import Wallet from "../../store/models/wallet";
import {isMobileBrowser} from "../../utils";
import {LoginButton} from "../../pages/tpmc/Login";

export class ConnectWalletModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false
        };
    }

    onLoginOptionClick = async (loginType) => {
        const {dispatch} = this.props;

        if (loginType === LoginTypes.MetaMask) {
            dispatch(Wallet.actions.loginWithMetamask());
        } else if (loginType === LoginTypes.ThetaWallet) {
            dispatch(Wallet.actions.loginWithWalletConnect());
        }
    }

    onClose = () => {
        this.props.dispatch(UIState.actions.hideModal());
    }

    render() {
        const {isLoading} = this.state;
        const loginButton = (logo, type) => {
            return (
                <LoginButton type={type}
                             logo={logo}
                             isLoading={isLoading}
                             onClick={() => {
                                 this.onClose();
                                 this.onLoginOptionClick(type);
                             }}/>
            )

            let onClick = () => {
                this.onClose();
                this.onLoginOptionClick(type);
            };
            let href = null;
            if((type === LoginTypes.MetaMask && isMobileBrowser())){
                onClick = null;
                href = 'https://metamask.app.link/dapp/www.passaways.com/tpmc';
            }

            return (
                <Button size={'large'}
                        target={'_blank'}
                        color={'black'}
                        onClick={onClick}
                        href={href}
                        isLoading={isLoading}
                        style={{width: '100%', marginBottom: 14, height: 60}}
                >
                    <img src={logo} style={{height: 38}}/>
                </Button>
            );
        }

        return (
            <Modal>
                <div className="ProveOwnershipModal">
                    <div className={'ProveOwnershipModal__title'}>
                        Connect Wallet
                    </div>

                    {loginButton(metamaskLogo, LoginTypes.MetaMask)}
                    {loginButton(thetaWalletLogo, LoginTypes.ThetaWallet)}

                    <a className={'ProveOwnershipModal__cancel'}
                       onClick={this.onClose}
                    >Cancel</a>
                </div>
            </Modal>
        )
    }
}


const mapStateToProps = (state, modalProps) => {
    return {
        ...modalProps
    }
};

export default connect(mapStateToProps)(ConnectWalletModal);

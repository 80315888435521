import React from 'react';
import {connect, useSelector} from 'react-redux'
import Modal, {RoundedModal} from '../../components/Modal';
import logo from '../../assets/logo/misadventure-club-white.svg';
import modalCloseIcon from '../../assets/icons/modal-close@2x.png';
import UIState from "../../store/uiState";
import {selectCurrentUser} from "../../store/models/user";
import {createSelector} from "reselect";
import {selectNftsByContract} from "../../store/models/nft";
import _ from "lodash";
import PassawayWithMeta from "../../components/staking/PassawayWithMeta";
import Button, {RoundedButton} from "../../components/Button";
import {selectContractByKey} from "../../store/models/config";
import {IsNFTStakingLive, ModalTypes} from "../../constants";
import {countdownRenderText} from "../../utils";
import Countdown from "react-countdown";

const renderTransphormationIn = (countDownData) => {
    if(countDownData.completed){
        return null;
    }

    return (
    <div className={'PassawayCard__transphormation-in'}>
        <span>Ready in: </span>
        <span className={'color--green'}>{countdownRenderText(countDownData)}</span>
    </div>
    )
}

export const TransphormPassawayOrderCard = ({order, onSelect}) => {
    const nft = order.transphormedPassaway;
    const buttonTitle = order.claimableAt > Date.now() ? 'Expedite' : 'Claim';

    return (
        <div className={'PassawayCard'}>
            <PassawayWithMeta nft={nft}/>
            <div className={'PassawayCard__footer'}>
                {
                    order.claimableAt &&
                    <Countdown
                        date={(new Date(order.claimableAt))}
                        renderer={renderTransphormationIn}
                    />
                }
                <RoundedButton title={buttonTitle}
                               onClick={() => {
                                   onSelect(order);
                               }}
                               color={'green'}
                />
            </div>
        </div>
    );
}

const PassawayCard = ({nft, buttonTitle, buttonDisabled, onSelect}) => {
    return (
        <div className={'PassawayCard'}>
            <PassawayWithMeta nft={nft}/>
            <div className={'PassawayCard__footer'}>
                <RoundedButton title={buttonTitle}
                               onClick={() => {
                                   onSelect(nft);
                               }}
                               disabled={buttonDisabled}
                               color={'green'}
                />
            </div>
        </div>
    );
}

export class PassawaySelectorModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false
        };
    }

    onClose = () => {
        this.props.dispatch(UIState.actions.hideModal());
    }

    render() {
        let {title, subtitle, nfts, onSelect, cardButtonTitle, multiple, multipleButtonTitle, multipleDisclaimer, isCardButtonEnabled, cardButtonDisabledTitle} = this.props;
        const isNFTEnabled = (nft) => {
            if(_.isFunction(isCardButtonEnabled)){
                return isCardButtonEnabled(nft);
            }
            else{
                return true;
            }
        };

        return (
            <RoundedModal title={title}
                          subtitle={subtitle}
                          className={'PassawaySelectorModal'}
                          onClose={this.onClose}
            >
                {
                    (multiple && nfts.length > 8) &&
                    <div className={'PassawaySelectorModal__header'}>
                        <RoundedButton title={multipleButtonTitle}
                                       onClick={() => {
                                           onSelect(nfts);
                                       }}
                                       color={'green'}
                                       size={'large'}/>
                        {
                            multipleDisclaimer &&
                            <div className={'PassawaySelectorModal__disclaimer'}>
                                {multipleDisclaimer}
                            </div>
                        }
                    </div>
                }
                <div className={'PassawaySelectorModal__passaways'}>
                    {
                        _.map(nfts, (nft) => {
                            const isEnabled = isNFTEnabled(nft);
                            return (
                                <PassawayCard nft={nft}
                                              buttonTitle={isEnabled ? cardButtonTitle : cardButtonDisabledTitle}
                                              buttonDisabled={!isEnabled}
                                              onSelect={onSelect}
                                />
                            )
                        })
                    }
                </div>
                {
                    _.isEmpty(nfts) &&
                    <div className={'EmptyState'}>
                        <div className={'EmptyState__title'}>
                            No eligible passaways
                        </div>
                        <RoundedButton title={'Buy More Passaways'}
                                       color={'green'}
                                       size={'large'}
                                       onClick={() => {
                                           this.props.dispatch(UIState.actions.showPassawayPurchaseModal())
                                       }}
                        />
                    </div>
                }

                {
                    (multiple && nfts.length > 1) &&
                    <div className={'PassawaySelectorModal__footer'}>
                        <RoundedButton title={multipleButtonTitle}
                                       onClick={() => {
                                            onSelect(nfts);
                                        }}
                                       color={'green'}
                                       size={'large'}/>
                        {
                            multipleDisclaimer &&
                            <div className={'PassawaySelectorModal__disclaimer'}>
                                {multipleDisclaimer}
                            </div>
                        }
                    </div>
                }
            </RoundedModal>
        );
    }
}

const selectPassawayNfts = createSelector(
    (state) => {
        const PassawaysContractInfo = selectContractByKey(state, 'passaways');
        const TransphormedPassawaysContractInfo = selectContractByKey(state, 'transphormedPassaways');
        const passawaysNfts = selectNftsByContract(state,PassawaysContractInfo.address);
        const transphormedPassawaysnfts = selectNftsByContract(state,TransphormedPassawaysContractInfo?.address);
        let nfts = _.flatten([passawaysNfts, transphormedPassawaysnfts]);
        nfts = _.filter(nfts, (x) => !_.isNil(x));

        return _.orderBy(nfts, ['metadata.tierNum'], ['asc']);
    },
    (nfts) => (nfts)
);

const mapStateToProps = (state, props) => {
    const {predicate, cardButtonTitle} = props;
    const passaways = _.filter(selectPassawayNfts(state), predicate);

    return {
        ...props,
        predicate: null,
        nfts: passaways
    }
};

export default connect(mapStateToProps)(PassawaySelectorModal);

import _ from 'lodash';
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {countdownRenderText, formatBalance, numberWithCommas} from "../../utils";
import classNames from "classnames";
import Countdown from "react-countdown";
import {RoundedButton} from "../Button";
import QtySelector from "./QtySelector";
import {CurrencyType, ModalTypes} from "../../constants";
import UIState from "../../store/uiState";
import {
    checkTokenApproval,
    getNFTDropManagerContract,
    getPlasmContract, getTPMCLotteryContract,
    isTokenApprovedForAmount,
    selectProvider
} from "../../store/models/wallet";
import {selectCurrentUserId} from "../../store/models/user";
import {getContractAddressByKey} from "../../store/models/config";
import {RequiredApprovals} from "./RequiredApprovals";
import PlasmCTA from "./PlasmCTA";

const CurrencyNames = {
    [CurrencyType.TFUEL]: 'TFUEL',
    [CurrencyType.TDROP]: 'TDROP',
    [CurrencyType.PLASM]: 'PLASM',
}

const NFTDropPaymentOption = ({price, currencyType, selected, onClick}) => {
    const currencyName = CurrencyNames[currencyType];
    const currencyNameLower = _.toLower(currencyName);

    return (
        <a className={classNames('NFTDropPaymentOption', {
            'NFTDropPaymentOption--selected': selected,
            [`NFTDropPaymentOption--${currencyNameLower}`]: true
        })}
           onClick={onClick}>
            <div className={'NFTDropPaymentOption__left'}>
                {CurrencyNames[currencyType]}
            </div>
            <div className={'NFTDropPaymentOption__right'}>
                <span>{formatBalance(price)}</span>
                <img src={`/tokens/${currencyNameLower}.png`}
                     className={'NFTDropPaymentOption__token-icon'}
                />
            </div>
        </a>
    );
}

const renderAvailableFor = (countDownData) => {
    if (countDownData.completed) {
        return (
            <div className={''}>
                Ended
            </div>
        );
    }

    return (
        <div className={''}>
            {`${countdownRenderText(countDownData)}`}
        </div>
    )
}

const renderAvailableIn = (countDownData) => {
    if (countDownData.completed) {
        return (
            <div className={''}>
                Now
            </div>
        );
    }

    return (
        <div className={''}>
            {`${countdownRenderText(countDownData)}`}
        </div>
    )
}

export default function Lottery({lottery, onBuy, canBuy, showParticipationNftDetails}) {
    const dispatch = useDispatch();
    const address = useSelector(selectCurrentUserId);
    const provider = useSelector(selectProvider);
    const signer = provider?.getSigner();
    const plasm = getPlasmContract(signer);
    const tpmcLottery = getTPMCLotteryContract(signer);
    const {participationNft, startsAt, endsAt} = lottery;
    const [imageExpanded, setImageExpanded] = React.useState(false);
    const [qty, setQty] = React.useState(1);
    const [currencyType, setCurrencyType] = React.useState(CurrencyType.PLASM);
    const currencyTypeName = _.toLower(CurrencyNames[currencyType]);
    const price = lottery.ticketPrice;
    const lotteryStarted = Date.now() > new Date(startsAt);
    const lotteryEnded = Date.now() > new Date(endsAt);
    const [isPLASMApproved, setIsPLASMApproved] = useState(undefined);

    async function checkPlasmApproval() {
        if(provider && address) {
            const isApproved = await isTokenApprovedForAmount(plasm, address, tpmcLottery.address, price);
            setIsPLASMApproved(isApproved);
        }
    }

    useEffect(() => {
        checkPlasmApproval()
    }, [provider]);

    return (
        <div className={`NFTDrop`}
        >
            {
                participationNft &&
                <div className={classNames('NFTDrop__asset', {
                    'NFTDrop__asset--expanded': imageExpanded
                })}
                     onClick={() => {
                         setImageExpanded(!imageExpanded);
                     }}
                >
                    <img className={'NFTDrop__image'}
                         src={participationNft.image}
                    />
                </div>
            }
            {
                lottery.image &&
                <div className={classNames('NFTDrop__asset', {
                    'NFTDrop__asset--expanded': imageExpanded
                })}
                     onClick={() => {
                         setImageExpanded(!imageExpanded);
                     }}
                >
                    <img className={'NFTDrop__image'}
                         src={lottery.image}
                    />
                </div>
            }

            <div className={'NFTDrop__info'}>
                {
                    showParticipationNftDetails &&
                    <div className={'NFTDropSection'}>
                        <div className={'NFTDrop__nft-name'}>{participationNft.name}</div>
                        <div className={'NFTDrop__nft-description'}>{participationNft.description}</div>
                        {
                            _.map(participationNft.lineItems, (lineItem, index) => {
                                return (
                                    <div className={'NFTDropSectionRow'} key={index}>
                                        <div className={'NFTDropSectionRow__left'}>{lineItem.title}</div>
                                        <div className={'NFTDropSectionRow__right'}>{lineItem.content}</div>
                                    </div>
                                )
                            })
                        }
                    </div>
                }


                <div className={'NFTDropSection'}>
                    <div className={'NFTDropSection__title'}>
                        Raffle Details
                    </div>
                    <div className={'NFTDropSection__rows'}>
                        {
                            !_.isNil(startsAt) && !lotteryStarted &&
                            <div className={'NFTDropSectionRow'}>
                                <div className={'NFTDropSectionRow__left'}>Starts in</div>
                                <div className={'NFTDropSectionRow__right'}>
                                    <Countdown
                                        date={(new Date(startsAt))}
                                        renderer={renderAvailableIn}
                                    />
                                </div>
                            </div>
                        }
                        {
                            !_.isNil(endsAt) && (Date.now() < new Date(endsAt)) && lotteryStarted &&
                            <div className={'NFTDropSectionRow'}>
                                <div className={'NFTDropSectionRow__left'}>Ends in</div>
                                <div className={'NFTDropSectionRow__right'}>
                                    <Countdown
                                        date={(new Date(endsAt))}
                                        renderer={renderAvailableFor}
                                    />
                                </div>
                            </div>
                        }
                        <div className={'NFTDropSectionRow'}>
                            <div className={'NFTDropSectionRow__left'}>Ticket price</div>
                            <div className={'NFTDropSectionRow__right'}>
                                <div className={'PlasmPrice'}>{formatBalance(lottery.ticketPrice)}</div>
                            </div>
                        </div>
                        <div className={'NFTDropSectionRow'}>
                            <div className={'NFTDropSectionRow__left'}>My entries</div>
                            <div className={'NFTDropSectionRow__right'}>{lottery.accountTickets}</div>
                        </div>
                        <div className={'NFTDropSectionRow'}>
                            <div className={'NFTDropSectionRow__left'}>Total entries</div>
                            <div className={'NFTDropSectionRow__right'}>{lottery.totalTickets}</div>
                        </div>
                    </div>
                </div>
                <div className={'NFTDropSection NFTDropSection--buy'}>
                    <QtySelector qty={qty}
                                 max={Math.min(lottery.remaining, 5)}
                                 onChange={(qty) => {
                                     setQty(qty);
                                 }}
                                 amounts={[10, 20, 50, 100]}
                    />
                    <div className={'NFTDropSectionRow NFTDropSectionRow--no-top-border'}>
                        <div className={'NFTDropSectionRow__left'}>Total</div>
                        <div className={'NFTDropSectionRow__right'}>
                            <div className={classNames('NFTDropPurchaseTotal', {
                                [`NFTDropPurchaseTotal--${currencyTypeName}`]: true,
                            })}>
                                <span className={'NFTDropPurchaseTotal__amount'}>
                                {formatBalance((qty * price))}
                            </span>
                                <img src={`/tokens/${currencyTypeName}.png`}
                                     className={'NFTDropPurchaseTotal__icon'}
                                />
                            </div>
                        </div>
                    </div>
                    {
                        canBuy && lotteryStarted && !lotteryEnded && lottery &&
                        <RoundedButton title={'Buy'}
                                       color={currencyTypeName}
                                       size={'medium'}
                                       className={'NFTDrop__buy-button'}
                                       onClick={() => {
                                           onBuy(currencyType, qty);
                                       }}
                        />
                    }
                    {
                        canBuy && lotteryStarted && lotteryEnded && lottery &&
                        <RoundedButton title={'Ticket sale ended'}
                                       color={'red'}
                                       size={'medium'}
                                       className={'NFTDrop__buy-button'}
                        />
                    }
                    {
                        !canBuy && lottery &&
                        <RoundedButton title={'Connect Wallet'}
                                       color={currencyTypeName}
                                       size={'medium'}
                                       className={'NFTDrop__buy-button'}
                                       onClick={() => {
                                           dispatch(UIState.actions.showModal(ModalTypes.ConnectWallet));
                                       }}
                        />
                    }
                    {
                        canBuy && lottery && !lotteryStarted &&
                        <RoundedButton title={isPLASMApproved === false ? 'Pre-Approve Now' : 'Starts Soon'}
                                       color={currencyTypeName}
                                       size={'medium'}
                                       className={'NFTDrop__buy-button'}
                                       onClick={async () => {
                                           await checkTokenApproval(plasm, address, tpmcLottery.address, price, 'PLASM');
                                           dispatch(UIState.actions.hideLoader());
                                           checkPlasmApproval();
                                       }}
                        />
                    }

                    <RequiredApprovals spenderAddress={getContractAddressByKey('tpmcLottery')}
                                       tokenAddress={getContractAddressByKey('plasm')}
                                       tokenAmount={price}
                                       nftAddresses={null}
                    />

                    <PlasmCTA/>


                </div>
            </div>
        </div>
    );
}
import _, {noop} from 'lodash';
import React, {useState, useEffect} from 'react';
import {TPMCNavBar, TPMCStakingNavBar} from "../../components/NavBar";
import {useDispatch, useSelector} from "react-redux";
import {selectCurrentUserId} from "../../store/models/user";
import Nft, {selectNftsByContract} from "../../store/models/nft";
import {createSelector} from "reselect";
import {IsPLASMLive, ModalTypes, Urls} from "../../constants";
import {RoundedButton} from "../../components/Button";
import UIState from "../../store/uiState";
import Wallet from "../../store/models/wallet";
import {StakePlot} from "../../components/staking/StakePlot";
import {RoundedModal} from "../../components/Modal";
import classNames from "classnames";
import {NavLink} from "react-router-dom";
import useMediaQuery from "../../hooks/useMediaQuery";
import discordCTAImage from '../../assets/cta-buttons/button_discord@2x.jpg';
import merchCTAImage from '../../assets/cta-buttons/button_merch@2x.jpg';
import {pushTo} from "../../utils/history";
import {CTABanner, EarnPlasmCTABanners} from "../../components/CTABanner";
import {store} from "../../store";
import {selectContractByKey} from "../../store/models/config";
import TPMCHeroBanners from "../../components/staking/TPMCHeroBanners";
import HeroBanners from "../../heroBanners";
import {FeatureImageCard, PurchaseNFTsAt} from "../Landing";
import featurePowerUpsImage from "../../assets/landing/feature-power-ups@2x.png";
import featureCraftingImage from "../../assets/landing/feature-crafting@2x.png";
import featurePermaPowerUpsImage from "../../assets/landing/feature-perma-power-ups@2x.png";
import featurePhlairImage from "../../assets/landing/feature-phlair@2x.png";
import featureMerchImage from "../../assets/landing/tpmc-perk-merch@2x.png";
import featureDiscordImage from "../../assets/landing/tpmc-perk-discord@2x.png"; // requires a loader


function TPMCHomePage() {
    const dispatch = useDispatch();

    return (
        <div className={'PageWrapper'}>
            <TPMCNavBar/>
            <div className="Page TPMCHomePage">
                {
                    IsPLASMLive &&
                    <EarnPlasmCTABanners/>
                }
                {
                    <TPMCHeroBanners banners={HeroBanners}/>
                }
                {/*<div className={'TPMCHomePage__cta-cards'}>*/}
                {/*    <NavLink className={'TPMCHomePage__cta-card'}*/}
                {/*             to={Urls.TPMC_DISCORD}*/}
                {/*    >*/}
                {/*        <img src={discordCTAImage}/>*/}
                {/*    </NavLink>*/}
                {/*    <a className={'TPMCHomePage__cta-card'}*/}
                {/*       onClick={() => {*/}
                {/*           dispatch(UIState.actions.showModal(ModalTypes.Shop, {}));*/}
                {/*       }}*/}
                {/*    >*/}
                {/*        <img src={merchCTAImage}/>*/}
                {/*    </a>*/}
                {/*</div>*/}
                <div className={'LandingPage'} style={{marginTop: 80, marginBottom: 80}}>
                    <div className={'FeatureCards'}>
                        <FeatureImageCard image={featureMerchImage}
                                          onClick={() => {
                                              dispatch(UIState.actions.showModal(ModalTypes.Shop, {}));
                                          }}
                        />
                        <FeatureImageCard image={featureDiscordImage}
                                          href={Urls.TPMC_DISCORD}
                        />
                    </div>
                    <PurchaseNFTsAt/>
                </div>
            </div>
        </div>
    );
}

export default TPMCHomePage;

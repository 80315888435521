import React from 'react';
import {connect} from 'react-redux'
import Modal from '../../components/Modal';
import thetaDropLogo from '../../assets/login/theta-drop@2x.png';
import thetaRarityLogo from '../../assets/login/theta-rarity@2x.png';
import openThetaLogo from '../../assets/login/open-theta@2x.png';
import UIState from "../../store/uiState";
import {selectCurrentUser} from "../../store/models/user";
import Button from "../../components/Button";
import {getSecondaryMarketUrl, LoginTypes, Urls} from "../../constants";

export class MarketplacesModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false
        };
    }

    onClose = () => {
        this.props.dispatch(UIState.actions.hideModal());
    }

    render() {
        const {nft, contractAddress} = this.props;
        const {isLoading} = this.state;
        const marketplaceButton = (logo, url) => {
            return (
                <Button size={'large'}
                        target={'_blank'}
                        color={'black'}
                        onClick={() => {
                            this.onClose();
                        }}
                        href={url}
                        isLoading={isLoading}
                        style={{width: '100%', marginBottom: 14, height: 60}}
                >
                    <img src={logo} style={{height: 38}}/>
                </Button>
            );
        }

        return (
            <Modal>
                <div className="MarketplacesModal">
                    <div className={'MarketplacesModal__title'}>
                        Find one
                        <br/>
                        for sale
                    </div>
                    <div className={'MarketplacesModal__subtitle'}>
                        Purchase this item on a secondary marketplace.
                    </div>

                    {/*{marketplaceButton(thetaDropLogo, Urls.THETA_DROP_MARKETPLACE)}*/}
                    {/*{marketplaceButton(openThetaLogo, Urls.OPEN_THETA_MARKETPLACE)}*/}
                    {marketplaceButton(thetaRarityLogo, getSecondaryMarketUrl(nft))}

                    <div className={'MarketplacesModal__disclaimer'}>
                        Theta Rarity allows you to view NFTs listed on all secondary marketplaces
                    </div>

                    <a className={'MarketplacesModal__cancel'}
                       onClick={this.onClose}
                    >Cancel</a>
                </div>
            </Modal>
        )
    }
}


const mapStateToProps = (state, modalProps) => {
    return {
        ...modalProps
    }
};

export default connect(mapStateToProps)(MarketplacesModal);

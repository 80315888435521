import _ from 'lodash';
import {post, get, put, authHeaders} from "../../utils/fetch";
import {Urls, RequestState, Hosts} from "../../constants";

// ===========================
// HELPERS
// ===========================


// ACTIONS
// ===========================
export const FETCH_AIRDROP_ACTIVATION = 'FETCH_AIRDROP_ACTIVATION';
export const CREATE_AIRDROP_ACTIVATION = 'CREATE_AIRDROP_ACTIVATION';
export const FETCH_THETACON_TOKENS = 'FETCH_THETACON_TOKENS';
export const CLAIM_THETACON_TOKEN = 'CLAIM_THETACON_TOKEN';
export const FETCH_THETACON_TOKEN_STATUS = 'FETCH_THETACON_TOKEN_STATUS';

// ===========================
// SELECTORS
// ===========================
export const selectAirdropActivation = (state, slug) => state.models.airdropActivationsBySlug[slug];

// ===========================
// MODEL
// ===========================
const Airdrop = {
    actions: {
        createAirdropActivation: (slug) => async (dispatch, getState) => {
            const result = await post({
                url: "/v1/airdrop/activations",
                host: Hosts.API,
                body: {
                    slug: slug,
                },
                headers: {
                    'x-session-token': _.get(getState(), 'app.userToken')
                },
                dispatch,
                action: CREATE_AIRDROP_ACTIVATION,
            });
            return result;
        },

        fetchAirdropActivation: (slug) => async (dispatch, getState) => {
            return get({
                url: "/v1/airdrop/activations",
                params: {
                    slug: slug
                },
                headers: {
                    'x-session-token': _.get(getState(), 'app.userToken')
                },
                dispatch,
                action: FETCH_AIRDROP_ACTIVATION,
            }).then(results => results?.body?.airdrop_activation);
        },

        fetchThetaConTokenStatus: (tokenId) => async (dispatch, getState) => {
            return get({
                url: "/v1/thetacon/tokens/status",
                params: {
                    token_id: tokenId
                },
                dispatch,
                action: FETCH_THETACON_TOKEN_STATUS,
            }).then(results => results?.body?.token);
        },

        fetchThetaConTokens: (address) => async (dispatch, getState) => {
            return get({
                url: "/v1/thetacon/tokens",
                params: {
                    address: address
                },
                headers: {
                    'x-session-token': _.get(getState(), 'app.userToken')
                },
                dispatch,
                action: FETCH_THETACON_TOKENS,
            }).then(results => results?.body?.tokens);
        },

        claimThetaConToken: (address, message, signature) => async (dispatch, getState) => {
            return post({
                url: "/v1/thetacon/tokens/claim",
                body: {
                    address: address,
                    message: message,
                    signature: signature
                },
                headers: {
                    'x-session-token': _.get(getState(), 'app.userToken')
                },
                dispatch,
                action: CLAIM_THETACON_TOKEN,
            }).then(results => results?.body?.nft);
        }
    },

    spec: {
        airdropActivationsBySlug: {},
    },

    modelReducer: (state, type, body, action) => {
        if (action.url && action.result !== RequestState.SUCCESS)
            return state;

        if (type === FETCH_AIRDROP_ACTIVATION || type === CREATE_AIRDROP_ACTIVATION) {
            return {
                ...state,
                airdropActivationsBySlug: _.keyBy(_.flatten([Object.values(state.airdropActivationsBySlug), body.airdrop_activation]) , 'slug'),
            }
        }

        return state;
    }
}
export default Airdrop;

import React, {useState} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux'
import UIState from "../../store/uiState";
import ProductModal from "./Product";
import StakingTierBadge from "../../components/staking/StakingTierBadge";
import {ActiveEphemeralPowerUp} from "../../components/staking/ActiveEphemeralPowerUp";
import {EventCountdown, PowerUpTypes} from "../../constants";
import Countdown, {zeroPad} from "react-countdown";
import {RoundedButton} from "../../components/Button";
import {selectContract} from "../../store/models/config";
import {ActivePermaPowerUp} from "../../components/staking/ActivePermaPowerUp";
import infiniteIcon from "../../assets/icons/infinite@2x.png";
import {basisPointsToPercent} from "../../utils";

const timeLeftRenderer = ({ days, hours, minutes, seconds, completed }) => {
    if(days > 0){
        return (
            <div className={'color--red'}>
                {`${days}days ${hours}hrs ${minutes}min remaining`}
            </div>
        )
    }
    else if (hours > 0){
        return (
            <div className={'color--red'}>
                {`${hours}hrs ${minutes}min remaining`}
            </div>
        )
    }
    else if (minutes > 0){
        return (
            <div className={'color--red'}>
                {`${minutes}min remaining`}
            </div>
        )
    }

};

export class ActivePowerUpModal extends React.Component {
    onClose = () => {
        this.props.dispatch(UIState.actions.hideModal());
    }

    render() {
        const {powerUp, powerUpType, onRemovePermanentPowerUp} = this.props;
        const {nft} = powerUp;
        const productComponent = (
            powerUpType === PowerUpTypes.Ephemeral ?
                <ActiveEphemeralPowerUp powerUp={powerUp}
                                        size={'large'}
                />
                :
                <ActivePermaPowerUp powerUp={powerUp}
                                    size={'large'}
                />
        );
        const timeRemaining = (
            powerUpType === PowerUpTypes.Ephemeral ?
                <Countdown
                    date={(new Date(powerUp.endsAt))}
                    renderer={timeLeftRenderer}
                />
                :
                <img src={infiniteIcon}
                     style={{
                         width: 16
                     }}
                />
        )

        return (
            <ProductModal title={'Power-up In Use'}
                          productName={nft.name}
                          productDescription={powerUpType === PowerUpTypes.Ephemeral ?
                              'This power-up temporarily increases a Passaway\'s staking rewards.' :
                              'This perma power-up increases a Passaway\'s staking rewards without expiring.'}
                          productComponent={productComponent}
                          productLineItems={[
                              {
                                  title: 'Power-up boost',
                                  content: <div className={'color--white'}>{`${basisPointsToPercent(powerUp.multiplier)}%`}</div>
                              },
                              {
                                  title: 'Time remaining',
                                  content: timeRemaining
                              }]}
                          footerComponent={(
                              <div>
                                  {
                                      powerUpType === PowerUpTypes.Permanent &&
                                      <RoundedButton color={'red-outline'}
                                                     title={'Remove Power-up'}
                                                     size={'medium'}
                                                     style={{
                                                         width: 160
                                                     }}
                                                     onClick={onRemovePermanentPowerUp}
                                      />
                                  }
                              </div>
                          )}
            />
        );
    }
}

const mapStateToProps = (state, props) => {
    const {powerUp} = props;
    let contractInfo = selectContract(state, powerUp.nftContract);

    return {
        ...props,
        powerUp: {
            ...powerUp,
            nft: contractInfo
        }
    }
};

export default connect(mapStateToProps)(ActivePowerUpModal);

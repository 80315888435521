import _ from 'lodash';
import React, {useState, useEffect} from 'react';
import {TPMCStakingNavBar} from "../../components/NavBar";
import {useDispatch, useSelector} from "react-redux";
import {selectCurrentUserId, selectIsOwner} from "../../store/models/user";
import Nft, {selectNftsByContract} from "../../store/models/nft";
import {createSelector} from "reselect";
import {IsPLASMLive, LoginTypes, ModalTypes, Urls} from "../../constants";
import {RoundedButton} from "../../components/Button";
import UIState from "../../store/uiState";
import Wallet, {selectBalances} from "../../store/models/wallet";
import {StakePlot} from "../../components/staking/StakePlot";
import {EarnPlasmCTABanners, StakePassawaysCTABanner} from "../../components/CTABanner";
import Config, {
    getContractAddressByKey,
    selectConfig,
    selectContractByKey,
    selectPlasmContract,
    selectPlasmStakingContract
} from "../../store/models/config";
import Loader from "../../components/Loader";
import arrowDownGreen from '../../assets/icons/arrow-down-green@2x.png';
import {formatBalance, isThetaWalletBrowser} from "../../utils";
import * as thetajs from "@thetalabs/theta-js";
import {PercentageInput, PlasmActionContainer, PlasmActionInputField, PlasmInput} from "../../modals/staking/Plasm";
import useAutoFocus from "../../hooks/useAutoFocus";
import logo from "../../assets/landing/hero-tpmc@2x.png";
import thetaWalletLogo from "../../assets/login/theta-wallet@2x.png";
import metamaskLogo from "../../assets/login/metamask@2x.png";
import {LoginButton} from "../tpmc/Login";
import {RequiredApprovals} from "../../components/staking/RequiredApprovals";
import {BigNumber} from "ethers";
import {StakePLASMAPYInfo} from "../../components/CTAPillLink";
import vipTierImage from "../../assets/vip/vip-tiers@2x.png";

function PlasmStakingPage() {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [amountToStake, setAmountToStake] = useState('');
    const userId = useSelector(selectCurrentUserId);
    const provider = useSelector(Wallet.selectors.selectProvider);
    const plasmContract = useSelector(selectPlasmContract);
    const plasmStakingContract = useSelector(selectPlasmStakingContract);
    const {plasmOnHand, plasmStaked} = useSelector(selectBalances);

    const onStakeClick = async () => {
        const success = await dispatch(Wallet.actions.stakePlasm(amountToStake));
    }

    const onUnstakeClick = async () => {
        dispatch(UIState.actions.showModal(ModalTypes.UnstakePlasm));
    }

    useEffect(async () => {
        // In-wallet browser
        if (_.isNil(userId) && isThetaWalletBrowser()) {
            await dispatch(Wallet.actions.connectWithMetamask());
        }

        if (_.isNil(plasmContract) || _.isNil(plasmStakingContract)) {
            await dispatch(Config.actions.fetchConfig());
        }

        dispatch(Wallet.actions.getBalances());
        dispatch(Wallet.actions.fetchStakingAPY());
    }, []);

    const onLoginOptionClick = async (loginType) => {
        setIsLoading(true);
        if (loginType === LoginTypes.MetaMask) {
            await dispatch(Wallet.actions.connectWithMetamask());
        } else if (loginType === LoginTypes.ThetaWallet) {
            await dispatch(Wallet.actions.connectWithWalletConnect());
        }
        dispatch(Wallet.actions.getBalances());
        setIsLoading(false);
    }

    if(!IsPLASMLive){
        return (
            <div className={'PageWrapper'}>
                <TPMCStakingNavBar/>
                <div className="Page PlasmStakingPage">
                    <div className={'EmptyState'}>
                        <div className={'EmptyState__title'}>
                            PLASM Staking<br/>Launching soon
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    if (!isThetaWalletBrowser() && _.isNil(provider)) {
        return (
            <div className={'PageWrapper'}>
                <TPMCStakingNavBar/>
                <div className="Page PlasmStakingPage">
                    <div className={'PlasmStakingPage__title'}>
                        Stake PLASM
                        <br/>
                        Earn PLASM
                    </div>
                    <StakePLASMAPYInfo/>
                    <div className={'PlasmStakingPage__description'}>
                        Connect your wallet to stake
                    </div>

                    <LoginButton type={LoginTypes.MetaMask}
                                 logo={metamaskLogo}
                                 isLoading={isLoading}
                                 onClick={() => {
                                     onLoginOptionClick(LoginTypes.MetaMask);
                                 }}/>
                    <LoginButton type={LoginTypes.ThetaWallet}
                                 logo={thetaWalletLogo}
                                 isLoading={isLoading}
                                 onClick={() => {
                                     onLoginOptionClick(LoginTypes.ThetaWallet);
                                 }}/>
                </div>
            </div>
        )
    }

    return (
        <div className={'PageWrapper'}>
            <TPMCStakingNavBar/>
            <div className="Page PlasmStakingPage">
                <div className={'PlasmStakingPage__title'}>
                    Stake PLASM
                    <br/>
                    Earn PLASM
                </div>
                <StakePLASMAPYInfo/>
                <PlasmActionContainer title={'PLASM On Hand'}
                                      className={'PlasmActionContainer--center'}
                                      balance={formatBalance(plasmOnHand)}
                >
                    <PlasmActionInputField title={'Stake Your PLASM'}>
                        <PlasmInput value={amountToStake}
                                    placeholder={'Enter amount'}
                                    onChange={(e) => {
                                        setAmountToStake(e.target.value);
                                    }}
                                    onAll={() => {
                                        setAmountToStake(`${parseInt(thetajs.utils.fromWei(plasmOnHand))}`);
                                    }}
                                    autoFocus={true}
                        />
                        <RoundedButton color={'green'}
                                       size={'small'}
                                       title={'Stake'}
                                       onClick={onStakeClick}
                        />
                    </PlasmActionInputField>
                </PlasmActionContainer>
                <img src={arrowDownGreen}
                     className={'PlasmStakingPage__arrow'}
                />
                <PlasmActionContainer title={'Staked PLASM'}
                                      subtitle={'(+EST. REWARD)'}
                                      className={'PlasmActionContainer--center'}
                                      balance={formatBalance(plasmStaked)}
                >
                    <a className={'PlasmStakingPage__unstake-button TextLink'}
                       onClick={onUnstakeClick}
                    >
                        Unstake
                    </a>
                </PlasmActionContainer>

                <div className={'PlasmStakingPage__7734-guardian-program'}>
                    <img src={vipTierImage}/>
                    <span>Looking for the 7734 Guardian Program?</span>
                    <br/>
                    <RoundedButton title={'Learn More'}
                                   color={'black-with-grey'}
                                   size={'small'}
                                   href={Urls.TPMC_7734_GUARDIAN}
                                   />
                </div>
            </div>
        </div>
    );
}

export default PlasmStakingPage;

import classNames from "classnames";
import React from "react";

const QtySelector = ({qty, max, onChange, amounts}) => {
    return (
        <div className={'QtySelectorWrapper'}>
            <div className={'QtySelector'}>
                <a className={classNames('QtySelectorButton', {
                    'QtySelectorButton--disabled': (qty <= 1  || max === 0)
                })}
                   onClick={(e) => {onChange(qty - 1)}}
                >
                    <div className={'QtySelectorButton__icon'}>—</div>
                </a>
                <div className={'QtySelectorAmount'}>
                    {qty}
                </div>
                <a className={classNames('QtySelectorButton', {
                    'QtySelectorButton--disabled': (qty >= max || max === 0)
                })}
                   onClick={(e) => {onChange(qty + 1)}}
                >
                    <div className={'QtySelectorButton__icon'}>＋</div>
                </a>
            </div>
            {
                amounts && amounts.length > 0 &&
                <div className={'QtySelectorAmountShortcuts'}>
                    {
                        amounts.map((amount, index) => {
                            return (
                                <a key={index}
                                   className={classNames('QtySelectorAmountShortcut', {
                                       'QtySelectorAmountShortcut--active': amount === qty
                                   })}
                                   onClick={(e) => {onChange(amount)}}
                                >
                                    {amount}
                                </a>
                            )
                        })
                    }
                </div>
            }

        </div>
    )
}

export default QtySelector;
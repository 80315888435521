import { combineReducers } from 'redux';
import AppState from './appState';
import UIState from './uiState';
import ModelState from './modelState';
import modelTypes from './models';
import Wallet from "./models/wallet";

ModelState.init(modelTypes);

const reducer = combineReducers({
    models: ModelState.reducer,
    app: AppState.reducer,
    ui: UIState.reducer
});
export { reducer as default };

import _ from "lodash";
import {selectContract} from "../store/models/config";
import {store} from "../store";

const buildNFTImage = (key, sizeDirName) => {
    const sizeDir = _.isNil(sizeDirName) ? '/' : `/${sizeDirName}/`;
    return `/nfts${sizeDir}${key}.png`;
}

export const getNFTImageFromKeyOrAddress = (keyOrAddress, sizeDirName) => {
    let key = keyOrAddress;
    if(_.startsWith(_.toLower(keyOrAddress), '0x')){
        const contract = selectContract(store.getState(), keyOrAddress);
        key = contract.key;
    }
    return buildNFTImage(key, sizeDirName);
}

export const getNFTImage = (keyOrAddress) => {
    return getNFTImageFromKeyOrAddress(keyOrAddress, null);
}

export const getNFTImageThumb = (keyOrAddress) => {
    return getNFTImageFromKeyOrAddress(keyOrAddress, 'thumbs');
}

import _ from 'lodash';
import React, {useState, useEffect} from 'react';
import {TPMCStakingNavBar} from "../../components/NavBar";
import {useDispatch, useSelector} from "react-redux";
import {selectCurrentUserId, selectIsOwner} from "../../store/models/user";
import Nft, {selectNftsByContract} from "../../store/models/nft";
import {createSelector} from "reselect";
import {IsPLASMLive, LoginTypes, ModalTypes, Urls} from "../../constants";
import {RoundedButton} from "../../components/Button";
import UIState from "../../store/uiState";
import Wallet, {selectBalances} from "../../store/models/wallet";
import ShareIcon from '../../assets/icons/share@2x.png';
import PassawayTextLogo from '../../assets/logo/passaway-grey@2x.png';
import Logo7734Initative from '../../assets/logo/brought-to-you-by-7734-initiative@2x.png';
import {StakePlot} from "../../components/staking/StakePlot";
import {EarnPlasmCTABanners, StakePassawaysCTABanner} from "../../components/CTABanner";
import MetaTags from '@zandor300/react-meta-tags';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import classNames from "classnames";
import {
    EmailIcon,
    EmailShareButton,
    FacebookMessengerIcon,
    FacebookMessengerShareButton,
    FacebookIcon,
    FacebookShareButton,
    RedditIcon,
    RedditShareButton,
    XIcon,
    TwitterShareButton,
    TelegramIcon,
    TelegramShareButton,
    WhatsappIcon,
    WhatsappShareButton,
} from "react-share";

import Config, {
    getContractAddressByKey,
    selectConfig,
    selectContractByKey,
    selectPlasmContract,
    selectPlasmStakingContract
} from "../../store/models/config";
import Loader from "../../components/Loader";
import arrowDownGreen from '../../assets/icons/arrow-down-green@2x.png';
import {formatBalance, isThetaWalletBrowser, truncate} from "../../utils";
import * as thetajs from "@thetalabs/theta-js";
import {PercentageInput, PlasmActionContainer, PlasmActionInputField, PlasmInput} from "../../modals/staking/Plasm";
import useAutoFocus from "../../hooks/useAutoFocus";
import logo from "../../assets/landing/hero-tpmc@2x.png";
import thetaWalletLogo from "../../assets/login/theta-wallet@2x.png";
import metamaskLogo from "../../assets/login/metamask@2x.png";
import {LoginButton} from "../tpmc/Login";
import {RequiredApprovals} from "../../components/staking/RequiredApprovals";
import {BigNumber} from "ethers";
import {useLocation, useParams} from "react-router-dom";
import moment from "moment";
import {pushTo} from "../../utils/history";

const NFTAssetsList = ({assets, onSelect, selectedAsset}) => {
    return (
        <div className={'NFTAssetsList'}>
            {
                _.map(assets, (asset, index) => {
                    return (
                        <div className={classNames({
                            'NFTAssetsListItem': true,
                            'NFTAssetsListItem--selected': selectedAsset.name === asset.name,
                            [`NFTAssetsListItem--type-${asset.type}`]: true,
                        })}
                             key={index}
                             onClick={() => onSelect(asset)}
                             style={asset.iconStyle}
                        >
                            <img src={asset.iconUrl || asset.url} alt="" className={'NFTAssetsListItem__image'}/>
                        </div>
                    )
                })
            }
        </div>
    )
};

const KeyValue = ({label, value}) => {
    return (
        <div className={'KeyValue'}>
            <div className={'KeyValue__label'}>{label}</div>
            <div className={'KeyValue__value'}>{value}</div>
        </div>
    )
}

function ProofOfTransphormPage() {
    const dispatch = useDispatch();
    const {edition} = useParams()
    const [proofOfTransphorm, setProofOfTransphorm] = useState(null);
    let [selectedAsset, setSelectedAsset] = useState(null);
    const [showShareButtons, setShowShareButtons] = useState(false);
    const [isAssetFullScreen, setIsAssetFullScreen] = useState(false);
    const nft = proofOfTransphorm?.nft;

    const shareUrl = window.location.href;
    const title = 'Something odd happened to my Passaway.';

    const assets = proofOfTransphorm ? [
        {
            type: 'image',
            url: nft.image,
            name: 'Transphormed Passaway'
        },
        {
            type: 'video',
            url: nft.animation_url,
            iconUrl: nft.image,
            name: 'Transphormation'
        },
        {
            type: 'image',
            url: _.find(nft.attributes, {trait_type: 'Burned Passaway'}).value,
            name: 'Burned Passaway'
        },
        {
            type: 'iframe',
            url: _.find(nft.attributes, {trait_type: 'Burned Passaway on Film'}).value,
            name: 'Burned Passaway on Film',
            iconUrl: _.find(nft.attributes, {trait_type: 'Burned Passaway'}).value,
        },
        {
            type: 'image',
            url: _.find(nft.attributes, {trait_type: 'Proof of Transphorm'}).value,
            iconStyle: {
                width: 82,
            },
            assetStyle: {
                maxWidth: 'unset',
            },
            name: 'Proof of Transphorm'
        },
    ] : [];
    selectedAsset = selectedAsset || assets[0];

    useEffect(async () => {
        const proofOfTransphorm = await dispatch(Nft.actions.fetchProofOfTransphorm(edition));
        setProofOfTransphorm(proofOfTransphorm);
        if(_.isNil(proofOfTransphorm)) {
            pushTo(Urls.JUMP_TPMC_TRANSPHORM);
        }
    }, []);

    return (
        <div className={'PageWrapper'}>
            {
                proofOfTransphorm &&
                <MetaTags>
                    <title>{`Transphormed Passaway #${edition}`}</title>
                    <meta property="og:url" content={window.location.url} />
                    <meta name="description" content='A Transphormation happens when a Passaway consumes a gummy candy (code name: ESSU) which has been injected with a \"Transphorm\" serum.' />
                    <meta property="og:title" content={`Transphormed Passaway #${edition}`} />
                    <meta property="og:image" content={nft.image} />
                    <meta property="og:image:type" content="image/png" />
                    <meta property="og:image:width" content="550" />
                    <meta property="og:image:height" content="550" />
                    <meta property="og:video" content={nft.animation_url} />
                    <meta property="og:video:secure_url" content={nft.animation_url} />
                    <meta property="og:video:type" content="application/mpeg4" />
                    <meta property="og:video:width" content="550" />
                    <meta property="og:video:height" content="550" />
                </MetaTags>
            }
            <TPMCStakingNavBar/>
            {
                proofOfTransphorm &&
                <div className="Page ProofOfTransphormPage">
                    <div className={'ProofOfTransphormPage__title'}>
                        Transphormed Passaway
                    </div>
                    <div className={'ProofOfTransphormPage__edition'}>
                        {
                            `#${edition}`
                        }
                    </div>
                    <div className={'ProofOfTransphormPage__asset'}
                         style={selectedAsset?.assetStyle}
                    >
                        {
                            selectedAsset?.type === 'image' &&
                            <img src={selectedAsset?.url}
                                 alt={selectedAsset.name}
                                 onClick={() => {
                                     // setIsAssetFullScreen(!isAssetFullScreen)
                                 }}
                            />
                        }
                        {
                            selectedAsset?.type === 'video' &&
                            <video src={selectedAsset?.url}
                                   controls={true}
                                   autoPlay={true}
                                   loop={true}
                                   muted={true}
                                   playsInline={true}
                            />
                        }
                        {
                            selectedAsset?.type === 'iframe' &&
                            <iframe src={selectedAsset?.url}
                                    frameBorder="0"
                                    allowFullScreen={true}
                                    allow="autoplay; picture-in-picture"
                            />
                        }
                        {
                            isAssetFullScreen &&
                            <TransformWrapper
                                initialScale={1}
                                initialPositionX={200}
                                initialPositionY={100}
                            >
                                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                    <React.Fragment>
                                        <div className="tools">
                                            <button onClick={() => zoomIn()}>+</button>
                                            <button onClick={() => zoomOut()}>-</button>
                                            <button onClick={() => resetTransform()}>x</button>
                                        </div>
                                        <TransformComponent>
                                            <img src={selectedAsset.src} />
                                        </TransformComponent>
                                    </React.Fragment>
                                )}
                            </TransformWrapper>
                        }
                    </div>
                    <NFTAssetsList assets={assets}
                                   onSelect={(asset) => {
                                       console.log('asset == ', asset);
                                       setSelectedAsset(asset);
                                   }}
                                   selectedAsset={selectedAsset}
                    />
                    {
                        !showShareButtons && <RoundedButton title={(
                            <span>
                            <img src={ShareIcon}
                                 style={{
                                     marginRight: 8,
                                     width: 13, height: 13
                                 }}
                            />
                            <span>Share</span>
                        </span>
                        )}
                                       color={'green'}
                                       size={'small'}
                                       className={'ProofOfTransphormPage__share-button'}
                                       onClick={() => {
                                           setShowShareButtons(true)
                                       }}
                        />
                    }
                    {
                        showShareButtons &&
                        <KeyValue label={'Share Transphorm On'}
                                  value={(
                                      <div className={'ProofOfTransphormPage__share-icons'}>
                                          <TwitterShareButton
                                              url={shareUrl}
                                              title={title}
                                              hashtags={['transphorm', 'nfts']}
                                          >
                                              <XIcon size={32} round/>
                                          </TwitterShareButton>

                                          <RedditShareButton
                                              url={shareUrl}
                                              title={title}
                                              windowWidth={660}
                                              windowHeight={460}
                                          >
                                              <RedditIcon size={32} round/>
                                          </RedditShareButton>

                                          <FacebookShareButton
                                              url={shareUrl}
                                          >
                                              <FacebookIcon size={32} round/>
                                          </FacebookShareButton>

                                          <TelegramShareButton
                                              url={shareUrl}
                                              title={title}
                                          >
                                              <TelegramIcon size={32} round/>
                                          </TelegramShareButton>

                                          <FacebookMessengerShareButton
                                              url={shareUrl}
                                              appId="521270401588372"
                                          >
                                              <FacebookMessengerIcon size={32} round/>
                                          </FacebookMessengerShareButton>

                                          <WhatsappShareButton
                                              url={shareUrl}
                                              title={title}
                                              separator=":: "
                                          >
                                              <WhatsappIcon size={32} round/>
                                          </WhatsappShareButton>

                                          <div className="Demo__some-network">
                                              <EmailShareButton
                                                  url={shareUrl}
                                                  subject={title}
                                                  body="body"
                                              >
                                                  <EmailIcon size={32} round/>
                                              </EmailShareButton>
                                          </div>
                                      </div>
                                  )}
                        />
                    }

                    <KeyValue label={'Transphormed by'}
                              value={(
                                  <a href={`https://explorer.thetatoken.org/account/${proofOfTransphorm.address}`}
                                     target={'_blank'}
                                  >
                                      {truncate(proofOfTransphorm.address, 4)}
                                  </a>
                              )}
                    />

                    <KeyValue label={'Transphormed On'}
                              value={moment(proofOfTransphorm.created_at).format('MMMM Do YYYY @ h:mm a')}
                    />

                    <KeyValue label={'Proof of Transphorm Hash'}
                              value={(
                                  <a href={`https://explorer.thetatoken.org/tx/${proofOfTransphorm.hash}`}
                                     target={'_blank'}

                                  >
                                      {truncate(proofOfTransphorm.hash, 6)}
                                  </a>
                              )}
                    />
                    <div className={'ProofOfTransphormPage__token-ids'}>
                        <KeyValue label={'Burned Token ID'}
                                  value={proofOfTransphorm.passaway_token_id}
                        />

                        <KeyValue label={'New Token ID'}
                                  value={proofOfTransphorm.transphormed_passaway_token_id}
                        />
                    </div>

                    <div className={'ProofOfTransphormPage__transphorm-yours'}>
                        <div className={'ProofOfTransphormPage__transphorm-yours__title'}>
                            Transphorm Yours At:
                        </div>
                        <RoundedButton title={'Passaways.com'}
                                       color={'green'}
                                       size={'large'}
                                       className={'ProofOfTransphormPage__passaways-button'}
                                       target={'_blank'}
                                       href={'https://passaways.com/tpmc/transphorm'}
                        />
                    </div>

                    <div className={'ProofOfTransphormPage__info'}>
                        <div className={'ProofOfTransphormPage__info__title'}>
                            WTF IS A TRANSPHORM?
                        </div>
                        <div className={'ProofOfTransphormPage__info__text'}>
                            A Transphormation happens when a Passaway consumes a gummy candy (code name: ESSU) which has
                            been injected with a “Transphorm” serum. The gummy, created by the 7734 Initiative, may look
                            harmless but a look into its eyes will ensure its ingestion and transform the Passaway into
                            a more extreme version of itself. Originally, they were to be used in psychological warfare
                            but have since proved to be uncontrollable and thus, the project has been terminated.
                            <br/><br/>
                            TPMC members can purchase and craft these Transphorm Kits to Transphorm their own Passaways
                            which will have increased rarity and unlock higher staking earnings.
                        </div>
                    </div>

                    <a href={'https://www.7734initiative.com'}
                       target={'_blank'}
                       className={'ProofOfTransphormPage__7734-initiative-logo'}
                    >
                        <img src={Logo7734Initative}/>
                    </a>
                </div>
            }
        </div>
    )
}

export default ProofOfTransphormPage;

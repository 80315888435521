import React from 'react';
import classNames from "classnames";

export default function Loader({style, size, className}) {
    return (
        <div className={classNames(`Loader Loader--center`, className, `Loader--${size}`)}
             style={style}
        />
    );
}

export function InlineLoader({style, size, className}) {
    return (
        <div className={classNames(`Loader Loader--inline`, className, `Loader--${size}`)}
             style={style}
        />
    );
}

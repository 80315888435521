import _ from 'lodash';
import React, {useState, useEffect, useContext} from 'react';
import tpmcDiscordLogo from '../../assets/logo/tpmc-discord.png';
import Button, {RoundedButton} from "../../components/Button";
import User from "../../store/models/user";
import {useDispatch, useSelector} from "react-redux";
import {TPMCNavBar} from "../../components/NavBar";
import {createSelector} from "reselect";
import OauthPopup from "react-oauth-popup";
import {DiscordOAuthURL, DiscordUrl, Hosts} from "../../constants";
import Storage from "../../utils/storage";
import {useLocation} from "react-router-dom";
import {pushTo} from "../../utils/history";
import {get} from "../../utils/fetch";
import {FETCH_AIRDROP_ACTIVATION} from "../../store/models/airdrop";
import Wallet from "../../store/models/wallet";

const selectAirdropActivationBySlug = createSelector(
    (state) => state.models.airdropActivationsBySlug,
    (_, slug) => slug,
    (airdropActivationsBySlug, slug) => airdropActivationsBySlug[slug]
)

function DiscordPage() {
    const dispatch = useDispatch();
    let {search} = useLocation();
    const params = new URLSearchParams(search);
    console.log(params);
    const [isLoading, setIsLoading] = useState(false);
    const [code, setCode] = useState(params.get('code') || '');

    const onLoginClick = async () => {
        if(isLoading){
            return;
        }
        setIsLoading(true);
    };

    const onCode = async (code) => {
        setIsLoading(true);
        const result = await dispatch(User.actions.finishDiscordOAuth(code));
        setIsLoading(false);

        alert('Your Discord account should now be an authorized TPMC member. Welcome.')
    }

    const onClose = () => {
        setIsLoading(false);
    }

    const onCodeChange = (e) => {
        setCode(e.target.value);
    }

    const onSendCodeClick = async () => {
        setIsLoading(true);
        // const json = get({
        //     url: "/v1/auth/connect/discord",
        //     params: {
        //         code: code
        //     },
        //     headers: {
        //         'x-session-token': _.get(getState(), 'app.userToken')
        //     },
        //     dispatch,
        //     action: FETCH_AIRDROP_ACTIVATION,
        // })
        //
        // const result = await fetch(`${Hosts.API}/v1/auth/connect/discord?code=${code}`);
        // const json = await result.json();
        // if(json.body.success){
        //     alert('Your Discord account should now be an authorized TPMC member. Welcome.');
        //     pushTo('/tpmc/home');
        // }
        // else{
        //     alert(json.body.message);
        // }
        await dispatch(Wallet.actions.connectDiscord(code));
        setIsLoading(false);
    }

    useEffect(() => {
        const params = new URLSearchParams(search);
        const truthy = params.get('success');
        if(truthy === 'true'){
            alert('Your Discord account should now be an authorized TPMC member. Welcome.');
            pushTo('/tpmc/home');
        }
    }, [search]);

    return (
        <div className={'PageWrapper'}>
            <TPMCNavBar/>
            <div className="Page DiscordPage">
                <div style={{height: 30}}/>

                {/*<img src={tpmcDiscordLogo}*/}
                {/*     className={'DiscordPage__logo'}*/}
                {/*/>*/}

                {/*<div className={'DiscordPage__perks'}>*/}
                {/*    <div className={'DiscordPage__perks-title'}>ROLES</div>*/}
                {/*    <div>TPMC Member - Own 1+ Passaway</div>*/}
                {/*    <div>999ER - Own 999ER Passaway + 999ER Badge</div>*/}
                {/*    <div>TPMC Whale - Own 20+ Passaways</div>*/}
                {/*</div>*/}

                <div className={'DiscordPage__perks'}>
                    <div className={'DiscordPage__perks-title'} style={{color: 'white'}}>HOW TO JOIN</div>
                    <div><span>Join the </span><a className={'Link'} href={DiscordUrl} target={'_blank'}>Passaways Discord</a> server, if you haven't yet.</div>
                    <br/>
                    <div>Run the <span style={{color: "white", fontWeight: 'bold'}}>/tpmc-get-verified</span> command.</div>
                    <div>Follow the steps provided in the response.</div>
                    {/*<div>Passaways must NOT be listed for sale</div>*/}
                </div>

                <div className={'DiscordPage__perks'}>
                    <div className={'DiscordPage__perks-title'} style={{color: 'white'}}>HAVE A CODE?</div>
                    <div className={'CodeInput'}>
                        <input value={code} onChange={onCodeChange} placeholder={'Enter code from Discord command'}/>
                    </div>
                    <RoundedButton title='Submit'
                                   color={'green'}
                                   size={'large'}
                                   isLoading={isLoading}
                                   onClick={onSendCodeClick}
                                   style={{maxWidth: 300, width: '100%', marginTop: 8}}
                    />
                </div>

                <div className={'DiscordPage__perks'}>
                    <div className={'DiscordPage__perks-title'}>PERKS</div>
                    <div>Exclusive TPMC Channel (owners-only)</div>
                    <div>TPMC Member Role</div>
                    <div>20+ Roles</div>
                </div>

                {/*<a className={'LoginPage__buy-link'}*/}
                {/*   style={{marginBottom: 20, marginTop: 0}}*/}
                {/*   href={DiscordUrl}*/}
                {/*   target={'_blank'}*/}
                {/*>*/}
                {/*    JOIN DISCORD SERVER*/}
                {/*</a>*/}

                {/*<div className={'DiscordPage__perks'} style={{marginBottom: 25}}>*/}
                {/*    <div className={'DiscordPage__perks-title'}>THEN...</div>*/}
                {/*</div>*/}


                {/*<RoundedButton title='Connect Discord'*/}
                {/*               color={'green'}*/}
                {/*               size={'large'}*/}
                {/*               href={`${DiscordOAuthURL}&state=${encodeURIComponent(Storage.getUserToken())}`}*/}
                {/*               style={{width: 250}}*/}
                {/*/>*/}

                {/*<OauthPopup*/}
                {/*    url={`${DiscordOAuthURL}&state=${encodeURIComponent(Storage.getUserToken())}`}*/}
                {/*    onCode={onCode}*/}
                {/*    onClose={onClose}*/}
                {/*    width={500}*/}
                {/*    height={760}*/}
                {/*>*/}
                {/*    <RoundedButton title='Connect Discord'*/}
                {/*                   color={'green'}*/}
                {/*            size={'large'}*/}
                {/*            onClick={onLoginClick}*/}
                {/*            isLoading={isLoading}*/}
                {/*            style={{width: 250}}*/}
                {/*    />*/}
                {/*</OauthPopup>*/}
            </div>
        </div>
    );
}

export default DiscordPage;

import React, {useState} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux'
import UIState from "../store/uiState";
import alertIcon from "../assets/icons/alert-red@2x.png";
import Modal, {RoundedModal} from "../components/Modal";
import Loader from "../components/Loader";

export class LoaderModal extends React.Component {
    onClose = () => {
        this.props.dispatch(UIState.actions.hideModal());
    }

    render() {
        const {title, subtitle, onCancel} = this.props;
        return (
            <Modal className={'LoaderModal'}
                   onClose={this.onClose}
            >
                <Loader size={'xlarge'}/>
                <div className={'LoaderModal__footer'}>
                    {
                        title &&
                        <div className={'LoaderModal__title'}>
                            {title}
                        </div>
                    }
                    {
                        subtitle &&
                        <div className={'LoaderModal__subtitle'}>
                            {subtitle}
                        </div>
                    }
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (state, props) => {
    const {title, subtitle, onCancel} = props;
    console.log('props == ' );
    console.log(props);

    return {
        ...props,
    }
};

export default connect(mapStateToProps)(LoaderModal);

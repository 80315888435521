import {RoundedButton} from "../Button";
import React from "react";
import classNames from "classnames";

export const CraftableItemInputCard = ({nft, onDetails, onBuy, buttonTitle}) => {
    let {address, name, description, descriptionShort, image, balance} = nft;
    console.log('nft == ', nft);
    buttonTitle = (buttonTitle || 'Buy');

    return (
        <div className={classNames('CraftableItemInputCard', {
            'CraftableItemInputCard--missing': (balance === 0)
        })}
             onClick={onDetails}
        >
            {/*// A Nice UX can be collapsing multiple of the same into a single card and showing the badge*/}
            {/*{*/}
            {/*    remaining > 0 &&*/}
            {/*    <div className={'NumberBadge'}>{`${remaining} LEFT`}</div>*/}
            {/*}*/}
            <img src={image}
                 className={'CraftableItemInputCard__image'}
            />
            <div className={'CraftableItemInputCard__name'}>{name}</div>
            {/*{*/}
            {/*    description || descriptionShort &&*/}
            {/*    <div className={'CraftableItemInputCard__description'}>{descriptionShort || description}</div>*/}
            {/*}*/}
            <div className={'CraftableItemInputCard__footer'}>
                <RoundedButton title={balance > 0 ? '✓' : buttonTitle}
                               onClick={(e) => {
                                   e.preventDefault();

                                   onBuy(nft);
                               }}
                               disabled={balance > 0}
                               color={'green'}
                />
            </div>
        </div>
    );
}
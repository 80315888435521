import _ from 'lodash';
import * as thetajs from "@thetalabs/theta-js";
import {BlockTimeInSecsByNetwork, CurrentNetwork} from "../constants";
import {zeroPad} from "react-countdown";

export function copyToClipboard(str){
    //https://gist.githubusercontent.com/Chalarangelo/4ff1e8c0ec03d9294628efbae49216db/raw/cbd2d8877d4c5f2678ae1e6bb7cb903205e5eacc/copyToClipboard.js

    const el = document.createElement('textarea');  // Create a <textarea> element
    el.value = str;                                 // Set its value to the string that you want copied
    el.setAttribute('readonly', '');                // Make it readonly to be tamper-proof
    el.style.position = 'absolute';
    el.style.left = '-9999px';                      // Move outside the screen to make it invisible
    document.body.appendChild(el);                  // Append the <textarea> element to the HTML document
    const selected =
        document.getSelection().rangeCount > 0        // Check if there is any content selected previously
            ? document.getSelection().getRangeAt(0)     // Store selection if found
            : false;                                    // Mark as false to know no selection existed before
    el.select();                                    // Select the <textarea> content
    document.execCommand('copy');                   // Copy - only works as a result of a user action (e.g. click events)
    document.body.removeChild(el);                  // Remove the <textarea> element
    if (selected) {                                 // If a selection existed before copying
        document.getSelection().removeAllRanges();    // Unselect everything on the HTML document
        document.getSelection().addRange(selected);   // Restore the original selection
    }
}

export const truncate = (hash = '', length= 4) => {
    return hash.slice(0, (length + 2)) + '...' + hash.slice((length * -1));
};

export function numberWithCommas(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
}

export const formatWei = (amount) => {
    // Ensure we round down
    return numberWithCommas((Math.floor(parseFloat(thetajs.utils.fromWei(amount)) * 100) / 100).toFixed(2)).replace('.00', '');
}

export const formatWeiWithoutCommas = (amount) => {
    // Ensure we round down
    return (Math.floor(parseFloat(thetajs.utils.fromWei(amount)) * 100) / 100).toFixed(2);
}

export const formatBalance = (balance) => {
    return formatWei(balance);
}

export const formatBlocksToTime = (blocks, onlyDays) => {
    let seconds = parseInt(blocks) * BlockTimeInSecsByNetwork[CurrentNetwork];

    let days = Math.floor(seconds/(24*3600));
    seconds -= days*24*3600;

    let hours = Math.floor(seconds/3600);
    seconds -= hours*3600;

    let minutes = Math.floor(seconds/60);
    seconds -= minutes*60;

    if(onlyDays){
        // Round up... because we rather not disappoint people
        return `~${days + 1} days`;
    }

    if(days > 0){
        return `${days > 9 ? days : '0' + days} days ${hours > 9 ? hours : '0' + hours} hours ${minutes > 9 ? minutes : '0' + minutes} mins`;
    }
    if(hours > 0){
        return `${hours > 9 ? hours : '0' + hours} hours ${minutes > 9 ? minutes : '0' + minutes} mins`;
    }
    if(minutes > 0){
        return `${minutes > 9 ? minutes : '0' + minutes} mins`;
    }
}

export const getTimestampAtBlock = (targetBlock, curBlock) => {
    return (new Date()).getTime() + ((parseInt(targetBlock) - curBlock) * BlockTimeInSecsByNetwork[CurrentNetwork] * 1000);
}

export const countdownRenderText = ({ days, hours, minutes, seconds, completed }) => {
    if(days > 0){
        return `${zeroPad(days)}:${zeroPad(hours)}:${zeroPad(minutes)}:${zeroPad(seconds)}`;
    }
    if(hours > 0){
        return `${zeroPad(hours)}:${zeroPad(minutes)}:${zeroPad(seconds)}`;
    }
    return `${zeroPad(minutes)}:${zeroPad(seconds)}`;
}

export const isSameAddress = (address1, address2) => {
    return _.toLower(address1) === _.toLower(address2);
}

export const basisPointsToPercent = (basisPoints) => {
    return (basisPoints / 100).toFixed(2);
}

export const isThetaWalletBrowser = () => {
    return Boolean(window.ethereum && window.ethereum.isThetaWallet);
}

export const isMetaMaskBrowser = () => {
    return /MetaMask|MetaMaskMobile/i.test(navigator.userAgent);
}

export const isMobileBrowser = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export const isTruthy = (value) => {
    return value === 'true' || value === true;
}

export const truncateToDigits = (num, dec = 6) => {
    if (num === 0) {
        return 0
    } else if (num < 1) {
        let modifiedNumber = num
        let digitsToInt = 0
        while (modifiedNumber < 1) {
            modifiedNumber = modifiedNumber * 10
            digitsToInt++
        }
        const powValue = dec + digitsToInt - 1
        const calcDec = Math.pow(10, powValue);
        return (Math.trunc(num * calcDec) / calcDec).toFixed(dec);
    } else {
        const intDigits = Math.trunc(num).toString().length

        if (intDigits > dec) {
            return Math.trunc(num)
        } else {
            const calcDec = Math.pow(10, dec - intDigits);
            return Math.trunc(num * calcDec) / calcDec;
        }
    }
}

export const preloadImage = async (src) => {
    return new Promise((resolve, reject) => {
        let img = new Image();
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.src = src;
    });
}